import React, { useEffect, useMemo, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Buffer } from "buffer";
import clsx from "clsx";
import ErrorMsg from "components/ErrorMsg";
import MyDefaultButton from "components/MyDefaultButton";
import SectionText from "components/sectionText";
import { DefaultButton, Icon } from "office-ui-fabric-react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useListPageStore } from "store/ListPageStore";

import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import DatePickerField from "components/controls9/DatePickerField";
import {
  resolverFn,
  setYupLocale,
  useYupValidationResolver,
} from "utils/setYupLocale";
import { useFormValidationSchema } from "pages/FormPageNew/useFormValidationSchema";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useTheme } from "@fluentui/react";
import { DocumentBulletListMultipleRegular } from "@fluentui/react-icons";
import { useAppStoreActions } from "store/AppStore";
import { MessageBarType } from "office-ui-fabric-react";
import { reverse_romanian_label } from "utils/types";
import { VSpace } from "components/Spacer";
import { ClearButton, Flex } from "../../components/styled";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "1024px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  gridPremium: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

export const FormSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(201, 201, 201, 0.1);
  padding: 35px;
`;

export const FormSectionItem5 = styled.div`
  width: 16%;
  margin: 9px;
`;

export const FormSectionItem4 = styled.div`
  width: 20%;
  margin: 9px;
`;

export const FormSectionItem100 = styled.div`
  width: 100%;
  margin: 9px;
`;

export const FormSectionItem = styled.div`
  width: auto;
  margin: 9px;
`;

export const FormSectionItem42 = styled.div`
  width: 41%;
  margin: 9px;
`;

export const RenewalFormDialog = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [
    { renewalFormDialog, submitRenewalFormStatus },
    { setListStoreValue, submitRenewalForm },
  ] = useListPageStore();

  const isLoading = submitRenewalFormStatus === "loading";
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (event) => {
        // Do whatever you want with the file contents
        // const binaryStr: any = reader.result;
        const binaryStr: any = reader.result;
        const encode = Buffer.from(binaryStr).toString("base64"); // console.log(file.name);
        setFile({ base64str: encode, name: file.name });
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noDragEventsBubbling: true,
      noClick: true,
      noKeyboard: true,
      accept: {
        "application/vnd.ms-excel": [".xls", ".xlsx"],
      },
      multiple: false,
      onDrop,
    });

  const styles = useStyles();
  const theme = useTheme();

  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({});
  }, [t]);

  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  const resolver = useYupValidationResolver(schema);

  const form = useForm({ resolver });
  const { setError } = form;

  useEffect(() => {
    form.reset({
      file: null,
    });
    setMessage("");
    setErrorMessage("");
    setFile(null);
  }, [renewalFormDialog.formData]);

  return (
    <Dialog open={renewalFormDialog.isOpen}>
      <DialogSurface style={{ maxWidth: "1000px" }}>
        <DialogBody>
          <DialogTitle>{t("bcr.Renewal.label")}</DialogTitle>

          <DialogContent>
            <>
              <VSpace height={10} />
              <p>
                {t(
                  reverse_romanian_label(
                    "Vă rugăm să încărcați fișierul excel cu datele de reînnoire"
                  )
                )}
              </p>
            </>
            <FormSection>
              <FormSectionItem100>
                <Root
                  className={clsx({
                    accept: isDragAccept,
                    reject: isDragReject,
                  })}
                  {...getRootProps()}
                >
                  <input {...(getInputProps() as any)} />
                  {file ? (
                    <Flex
                      direction="column"
                      gap={10}
                      // css={css`
                      //   width: 100%;
                      //   overflow: hidden;
                      //   flex-grow: 1;
                      // `}
                    >
                      <Flex
                        // css={css`
                        //   padding: 0 20px;
                        //   width: 100%;
                        //   overflow: hidden;
                        //   flex-grow: 1;
                        // `}
                        gap={5}
                        justifyContent="center"
                      >
                        <ExcelIcon iconName="ExcelDocument" />
                        <FileName title={file.name}>{file.name}</FileName>
                        <ClearButton
                          iconProps={{ iconName: "Cancel" }}
                          onClick={() => {
                            setFile(null);

                            // dispatch(setFile(null))}
                          }}
                          disabled={isLoading}
                        />
                      </Flex>
                      <MyDefaultButton
                        visible={true}
                        isLoading={isLoading}
                        text={t("bcr.chooseDifferentFile")}
                        onClick={() => {
                          setMessage("");
                          open();
                        }}
                      />
                    </Flex>
                  ) : (
                    <Flex direction="column" gap={5}>
                      <UploadIcon iconName="CloudUpload" />
                      <TitleContainer>
                        <Title>{t("bcr.dragExcelFileHere")}</Title>
                        <Title>{t("bcr.or")}</Title>
                      </TitleContainer>
                      <DefaultButton
                        text={t("bcr.browseFiles")}
                        onClick={open}
                      />
                    </Flex>
                  )}
                </Root>
              </FormSectionItem100>
              <FormSectionItem100>
                <SectionText sectionText={message} />
                <ErrorMsg id="err">{errorMessage}</ErrorMsg>
              </FormSectionItem100>
            </FormSection>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setListStoreValue("renewalFormDialog", {
                  isOpen: false,
                  formData: null,
                });
              }}
            >
              {t("bcr.close.label")}
            </Button>
            {message ? null : (
              <Button
                appearance="primary"
                disabled={isLoading || !file}
                icon={
                  isLoading ? (
                    <Spinner size="tiny" />
                  ) : (
                    <DocumentBulletListMultipleRegular fontSize={16} />
                  )
                }
                onClick={() => {
                  setMessage("");
                  setErrorMessage("");
                  submitRenewalForm(
                    file,
                    (messageText) => {
                      setMessage(t("bcr.reinnoiri.label"));
                    },
                    (messageText) => {
                      setErrorMessage(t("bcr.reinnoiriNonGreco.label"));
                      setListStoreValue("submitRenewalFormStatus", "none");
                    }
                  );
                }}
              >
                {t("bcr.renewOffers.label")}
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Root = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${(p) => p.theme.palette.neutralTertiaryAlt};
  height: 15rem;
  padding: 10px;
  width: 40rem;
  margin: auto;
  font-size: 18px;
  font-weight: 600;
  flex-direction: column;
  user-select: none;
  &:focus {
    outline: none;
  }
  &.accept {
    background-color: ${(p) => p.theme.palette.themeLighterAlt};
  }
  &.reject {
    background-color: ${(p) => p.theme.palette.redDark};
  }
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const FileName = styled.div`
  font-size: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileNameLink = styled.div`
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  font-size: 20px;
`;

const UploadIcon = styled(Icon)`
  font-size: 35px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const ExcelIcon = styled(Icon)`
  font-size: 25px;
  color: ${(p) => p.theme.palette.greenDark};
`;
