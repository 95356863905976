import { ISearchBoxStyles, SearchBox } from "@fluentui/react";
import React, { useCallback, useEffect } from "react";
import { useListPageStore } from "store/ListPageStore";

type Props = {
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
} & {
  [key: string]: any;
};

const debounce = (callback, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: {
    height: 30,
    width: 300,
    marginLeft: 10,
    border: "1px solid",
    selectors: {
      "&::after": {
        borderWidth: 1,
      },
    },
  },
};

export const Search = (props: Props) => {
  const [{ debouncedState }, { setListStoreValue }] = useListPageStore();
  const [value, setValue] = React.useState(debouncedState);

  const handleSearch = useCallback(
    debounce((searchValue) => {
      setListStoreValue("debouncedState", searchValue);
    }, 500),
    []
  );

  useEffect(() => {
    // Use debouncedSearchTerm here for API calls or other side effects
    onChange(debouncedState);
  }, [debouncedState]);

  const handleChange = (value) => {
    setValue(value);
  };
  const { onChange, placeholder, loading } = props;
  return (
    <div>
      <SearchBox
        styles={searchBoxStyles}
        placeholder={placeholder}
        value={value}
        onChange={(_, value) => {
          handleChange(value || "");
          handleSearch(value);
        }}
      />
    </div>
  );
};
