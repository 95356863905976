import { useEffect } from "react";
import { useListPageStore } from "store/ListPageStore";
import { ExpandedColumn } from "types/columns";
import { ColumnOptionsUtils } from "@greco/components";
import { hydrateColumn } from "../../utils/columns";
import { columns } from "./columns";
import { useAppStoreState } from "store/AppStore";

const useListPage = () => {
  const [
    {
      selectedViewId,
      settings,
      selectedView,
      selectedViewColumns,
      cols,
      sort,
      searchTerm,
      offerRequestType,
      showArchived,
    },
    { setListStoreValue, loadVehicles, loadSettings },
  ] = useListPageStore();

  const { taxonomyUad } = useAppStoreState();

  useEffect(() => {
    const selectedView = settings.find(
      (s) =>
        s.userAppSettingId === selectedViewId &&
        s.appSettingCodeId === 900000000010303
    );

    setListStoreValue("selectedView", selectedView);
  }, [selectedViewId, settings]);

  useEffect(() => {
    if (!selectedView) {
      setListStoreValue("selectedViewColumns", columns);
      return;
    }

    const columnsValue = JSON.parse(
      selectedView.userAppSettingValue
    ) as ExpandedColumn[];
    const mappedColumns0 = columnsValue.map((c) => {
      const c0Elem = columns.find((c0) => c0.key === c.key);

      return { ...c0Elem, ...c };
    });
    const mappedColumns = mappedColumns0.map((c) =>
      hydrateColumn({ column: c, defaultColumns: columns })
    );
    setListStoreValue("selectedViewColumns", mappedColumns);
    setListStoreValue("cols", mappedColumns);
  }, [selectedView]);

  useEffect(() => {
    setListStoreValue("tempColumns", selectedViewColumns);
  }, [selectedViewColumns]);

  useEffect(() => {
    setListStoreValue(
      "areAnyColumnsFiltered",
      cols.some((c) => ColumnOptionsUtils.isColumnFiltered(c.filter))
    );
  }, [cols]);

  useEffect(() => {
    if (!taxonomyUad) return;
    const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
      (el) => el.code === "Application.BCR"
    ).id;

    const appSettingCodeId = taxonomyUad?.ApplicationSetting?.find(
      (el) => el.code === "AppSetting.ColumnOptions"
    ).id;

    setListStoreValue(
      "columnOptionsSettings",
      settings.filter((s) => {
        return (
          s.appSettingCodeId === Number(appSettingCodeId) &&
          s.applicationCodeId === Number(applicationCodeId)
        );
      })
    );
  }, [settings, taxonomyUad]);

  useEffect(() => {
    setListStoreValue("tempColumns", selectedViewColumns);
  }, [selectedViewColumns]);

  useEffect(() => {
    if (!taxonomyUad) return;
    const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
      (el) => el.code === "Application.BCR"
    ).id;
    loadSettings(applicationCodeId);
  }, [taxonomyUad]);

  useEffect(() => {
    loadVehicles({ reset: true, delay: true });
    return () => setListStoreValue("vehicleItems", []);
  }, [sort, searchTerm, cols, offerRequestType, showArchived]);
};

export default useListPage;
