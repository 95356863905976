import {
  Card,
  CardFooter,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import React from "react";
import OfferSidebar from "./offer/OfferSidebar";
import { useTheme } from "styled-components";
import { useFormPageStore } from "store/FormPageStore";
import InvoiceSidebar from "./invoice/InvoiceSidebar";
import PolicySidebar from "./policy/PolicySidebar";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "900px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});
const TabCard = (props: any) => {
  const styles = useStyles();
  const theme = useTheme();
  const isDarkMode = theme.isDark;
  const [{ mainTab }, { reset }] = useFormPageStore();
  return (
    <Card
      className={styles.card}
      style={{
        padding: "0px",
        borderTop: "5px solid " + theme.palette.themePrimary,
        gap: "0px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            backgroundColor: isDarkMode ? "#444453" : "#eeeeff",
          }}
        >
          {mainTab.selectedTab === "offer" ? <OfferSidebar /> : null}
          {mainTab.selectedTab === "invoice" ? <InvoiceSidebar /> : null}
          {mainTab.selectedTab === "policy" ? <PolicySidebar /> : null}
        </div>
        <div style={{ flex: 5, padding: "40px" }}>{props.children}</div>
      </div>
      <CardFooter
        style={{
          flexDirection: "row-reverse",
          padding: "20px",
          borderTop: isDarkMode ? "1px solid #444453" : "1px solid #eeeeff",
        }}
      >
        {props.footer}
      </CardFooter>
    </Card>
  );
};

export default TabCard;
