import {
  Tab,
  TabList,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import React from "react";
import { useFormPageStore } from "store/FormPageStore";
import {
  FormRegular,
  VehicleCarProfileLtrRegular,
  HandRightRegular,
  CheckboxCheckedRegular,
  DocumentPercentRegular,
  PersonNoteRegular,
  ContactCardRegular,
  PersonFilled,
  DocumentBulletListRegular,
  DocumentBulletListFilled,
} from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "800px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "100px",
    justifyContent: "center",
  },
  cardTab: {},
});
const OfferSidebar = () => {
  const [
    { offerTab, offer, selectedProduct, offerLoadStatus },
    { setFormStoreValue },
  ] = useFormPageStore();
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div
      style={{
        flexShrink: 0,
        width: "200px",
      }}
    >
      <TabList
        selectedValue={offerTab.selectedTab}
        className={classes.card}
        vertical={true}
        size="large"
      >
        <Tab
          color="white"
          icon={<FormRegular />}
          value="tab1"
          className={classes.cardTab}
          disabled={
            offerTab.disabledTabs.includes("tab1") ||
            offerLoadStatus === "loading"
          }
          onClick={() =>
            setFormStoreValue("offerTab", { ...offerTab, selectedTab: "tab1" })
          }
        >
          {t("bcr.offerTab1General.label")}
        </Tab>
        <Tab
          icon={<VehicleCarProfileLtrRegular />}
          value="tab2"
          disabled={
            offerTab.disabledTabs.includes("tab2") ||
            offerLoadStatus === "loading"
          }
          onClick={() =>
            setFormStoreValue("offerTab", { ...offerTab, selectedTab: "tab2" })
          }
        >
          {t("bcr.offerTab2VehicleInfo.label")}
        </Tab>
        {/* <Tab
          icon={<HandRightRegular />}
          value="tab3"
          disabled={
            offerTab.disabledTabs.includes("tab3") ||
            offerLoadStatus === "loading"
          }
          onClick={() =>
            setFormStoreValue("offerTab", { ...offerTab, selectedTab: "tab3" })
          }
        >
          {t("bcr.offerTab3VehicleUsage.label")}
        </Tab> */}
        {selectedProduct?.hasCasco ? (
          <Tab
            icon={<CheckboxCheckedRegular />}
            value="tab4"
            disabled={
              offerTab.disabledTabs.includes("tab4") ||
              offerLoadStatus === "loading"
            }
            onClick={() =>
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab: "tab4",
              })
            }
          >
            {t("bcr.offerTab4CascoClauses.label")}
          </Tab>
        ) : null}
        {offer.offerRequestType !== "F100018" &&
        offer.offerRequestType !== "F100056" ? (
          <Tab
            icon={<DocumentPercentRegular />}
            value="tab5"
            disabled={
              offerTab.disabledTabs.includes("tab5") ||
              offerLoadStatus === "loading"
            }
            onClick={() =>
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab: "tab5",
              })
            }
          >
            {t("bcr.offerTab5BonusMalus.label")}
          </Tab>
        ) : null}
        <Tab
          icon={<PersonFilled />}
          value="tab6"
          disabled={
            offerTab.disabledTabs.includes("tab6") ||
            offerLoadStatus === "loading"
          }
          onClick={() =>
            setFormStoreValue("offerTab", { ...offerTab, selectedTab: "tab6" })
          }
        >
          {t("bcr.offerTab6ClientInfo.label")}
        </Tab>

        {selectedProduct?.hasMtpl ? (
          <Tab
            icon={<PersonNoteRegular />}
            value="tab8"
            disabled={
              offerTab.disabledTabs.includes("tab8") ||
              offerLoadStatus === "loading"
            }
            onClick={() =>
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab: "tab8",
              })
            }
          >
            {t("bcr.offerTab8DriverInfo.label")}
          </Tab>
        ) : null}
        {selectedProduct?.hasCasco || selectedProduct?.hasMtpl ? (
          <Tab
            icon={<DocumentBulletListRegular />}
            value="tab9"
            disabled={
              offerTab.disabledTabs.includes("tab9") ||
              offerLoadStatus === "loading"
            }
            onClick={() =>
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab: "tab9",
              })
            }
          >
            {t("bcr.offerTab9Offer.label")}
          </Tab>
        ) : null}
      </TabList>
    </div>
  );
};

export default OfferSidebar;
