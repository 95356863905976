import React from "react";
import { Card, CardHeader } from "@fluentui/react-components";
import { Spinner } from "office-ui-fabric-react";
import { SpinnerSize } from "@fluentui/react";

const SpinnerCard = ({ width, height }) => {
  return (
    <div
      style={{
        position: "relative",
        margin: "auto",
        marginTop: "20px",
        width: width,
        height: height,
      }}
    >
      <Spinner
        size={SpinnerSize.large}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
};

export default SpinnerCard;
