import {
  Tab,
  TabList,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import React from "react";
import { useFormPageStore } from "store/FormPageStore";
import { CertificateRegular, CertificateFilled } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "800px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "100px",
    justifyContent: "center",
  },
  cardTab: {},
});
const PolicySidebar = () => {
  const [
    { policyTab, selectedProduct, progress, offerLoadStatus },
    { setFormStoreValue },
  ] = useFormPageStore();

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      style={{
        flexShrink: 0,
        width: "200px",
      }}
    >
      <TabList
        selectedValue={policyTab.selectedTab}
        className={classes.card}
        vertical={true}
        size="large"
      >
        {selectedProduct?.hasCasco ? (
          <Tab
            color="white"
            icon={<CertificateRegular />}
            value="tab1"
            className={classes.cardTab}
            disabled={
              policyTab.disabledTabs.includes("tab1") ||
              offerLoadStatus === "loading" ||
              (!progress?.cascoPolicyWithoutInvoice &&
                !progress?.cascoInvoiceCreated)
            }
            onClick={() =>
              setFormStoreValue("policyTab", {
                ...policyTab,
                selectedTab: "tab1",
              })
            }
          >
            {t("bcr.policyTab1CascoPolicy.label")}
          </Tab>
        ) : null}
        {selectedProduct?.hasMtpl ? (
          <Tab
            icon={<CertificateFilled />}
            value="tab2"
            disabled={
              policyTab.disabledTabs.includes("tab2") ||
              offerLoadStatus === "loading" ||
              (!progress?.mtplPolicyWithoutInvoice &&
                !progress?.mtplInvoiceCreated)
            }
            onClick={() =>
              setFormStoreValue("policyTab", {
                ...policyTab,
                selectedTab: "tab2",
              })
            }
          >
            {t("bcr.policyTab2MtplPolicy.label")}
          </Tab>
        ) : null}
      </TabList>
    </div>
  );
};

export default PolicySidebar;
