import { makeStyles } from "@fluentui/react-components";
import InsurerSelectionCheckbox from "components/InsurerSelectionCheckbox";
import { VSpace } from "components/Spacer";
import NumberInputField from "components/controls9/NumberInputField";
import TextInputField from "components/controls9/TextInputField";
import { Checkbox } from "office-ui-fabric-react";
import { relative } from "path";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormPageStore } from "store/FormPageStore";
import { reverse_romanian_label } from "utils/types";

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const MtplPremiumTable = (props: any) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const styles = useStyles();
  const formPageStore = useFormPageStore();

  const [
    { selectedProduct, progress, selectedMtplInsurers },
    { setFormStoreValue, calculateCasco, calculateCascoAndMtpl, getOffer },
  ] = formPageStore;

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.cascoOfferCreated || progress.mtplOfferCreated;
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  return (
    <>
      <div className="grid-table">
        {/* <!-- Section Headers --> */}
        <div className="grid-header"></div>
        <div className="grid-header" style={{ gridColumn: "span 2" }}>
          {t(reverse_romanian_label("1 lună"))}
        </div>
        <div className="grid-header" style={{ gridColumn: "span 2" }}>
          {t(reverse_romanian_label("6 luni"))}
        </div>
        <div className="grid-header" style={{ gridColumn: "span 2" }}>
          {t(reverse_romanian_label("12 luni"))}
        </div>

        {/* <!-- Sub-Headers --> */}
        <div className="grid-header"></div>
        <div className="grid-header">
          {t(reverse_romanian_label("Fara DD"))}
        </div>
        <div className="grid-header">{t(reverse_romanian_label("Cu DD"))}</div>
        <div className="grid-header">
          {t(reverse_romanian_label("Fara DD"))}
        </div>
        <div className="grid-header">{t(reverse_romanian_label("Cu DD"))}</div>
        <div className="grid-header">
          {t(reverse_romanian_label("Fara DD"))}
        </div>
        <div className="grid-header">{t(reverse_romanian_label("Cu DD"))}</div>

        {/* <!-- Items --> */}
        <div className="grid-cell-insurer">
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedMtplInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedMtplInsurers", value)
            }
            insurerName="omniasig"
          />
          <span className="checkbox-label">Omniasig</span>
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="omniasig_premium_1"
            required={false}
            disabled={disabledTab || disabledMap?.omniasig_premium_1}
            isNegativeAllowed={false}
            visible={visibleMap?.omniasig_premium_1}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="omniasig_premium_1d"
            required={false}
            disabled={disabledTab || disabledMap?.omniasig_premium_1d}
            isNegativeAllowed={false}
            visible={visibleMap?.omniasig_premium_1d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="omniasig_premium_6"
            required={false}
            disabled={disabledTab || disabledMap?.omniasig_premium_6}
            isNegativeAllowed={false}
            visible={visibleMap?.omniasig_premium_6}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="omniasig_premium_6d"
            required={false}
            disabled={disabledTab || disabledMap?.omniasig_premium_6d}
            isNegativeAllowed={false}
            visible={visibleMap?.omniasig_premium_6d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="omniasig_premium_12"
            required={false}
            disabled={disabledTab || disabledMap?.omniasig_premium_12}
            isNegativeAllowed={false}
            visible={visibleMap?.omniasig_premium_12}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="omniasig_premium_12d"
            required={false}
            disabled={disabledTab || disabledMap?.omniasig_premium_12d}
            isNegativeAllowed={false}
            visible={visibleMap?.omniasig_premium_12d}
          />
        </div>

        <div className="grid-cell-insurer">
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedMtplInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedMtplInsurers", value)
            }
            insurerName="asirom"
          />
          <span className="checkbox-label">Asirom</span>
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="asirom_premium_1"
            required={false}
            disabled={disabledTab || disabledMap?.asirom_premium_1}
            isNegativeAllowed={false}
            visible={visibleMap?.asirom_premium_1}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="asirom_premium_1d"
            required={false}
            disabled={disabledTab || disabledMap?.asirom_premium_1d}
            isNegativeAllowed={false}
            visible={visibleMap?.asirom_premium_1d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="asirom_premium_6"
            required={false}
            disabled={disabledTab || disabledMap?.asirom_premium_6}
            isNegativeAllowed={false}
            visible={visibleMap?.asirom_premium_6}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="asirom_premium_6d"
            required={false}
            disabled={disabledTab || disabledMap?.asirom_premium_6d}
            isNegativeAllowed={false}
            visible={visibleMap?.asirom_premium_6d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="asirom_premium_12"
            required={false}
            disabled={disabledTab || disabledMap?.asirom_premium_12}
            isNegativeAllowed={false}
            visible={visibleMap?.asirom_premium_12}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="asirom_premium_12d"
            required={false}
            disabled={disabledTab || disabledMap?.asirom_premium_12d}
            isNegativeAllowed={false}
            visible={visibleMap?.asirom_premium_12d}
          />
        </div>

        <div className="grid-cell-insurer">
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedMtplInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedMtplInsurers", value)
            }
            insurerName="groupama"
          />
          <span className="checkbox-label">Groupama</span>
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="groupama_premium_1"
            required={false}
            disabled={disabledTab || disabledMap?.groupama_premium_1}
            isNegativeAllowed={false}
            visible={visibleMap?.groupama_premium_1}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="groupama_premium_1d"
            required={false}
            disabled={disabledTab || disabledMap?.groupama_premium_1d}
            isNegativeAllowed={false}
            visible={visibleMap?.groupama_premium_1d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="groupama_premium_6"
            required={false}
            disabled={disabledTab || disabledMap?.groupama_premium_6}
            isNegativeAllowed={false}
            visible={visibleMap?.groupama_premium_6}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="groupama_premium_6d"
            required={false}
            disabled={disabledTab || disabledMap?.groupama_premium_6d}
            isNegativeAllowed={false}
            visible={visibleMap?.groupama_premium_6d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="groupama_premium_12"
            required={false}
            disabled={disabledTab || disabledMap?.groupama_premium_12}
            isNegativeAllowed={false}
            visible={visibleMap?.groupama_premium_12}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="groupama_premium_12d"
            required={false}
            disabled={disabledTab || disabledMap?.groupama_premium_12d}
            isNegativeAllowed={false}
            visible={visibleMap?.groupama_premium_12d}
          />
        </div>

        <div className="grid-cell-insurer">
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedMtplInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedMtplInsurers", value)
            }
            insurerName="generali"
          />
          <span className="checkbox-label">Generali</span>
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="generali_premium_1"
            required={false}
            disabled={disabledTab || disabledMap?.generali_premium_1}
            isNegativeAllowed={false}
            visible={visibleMap?.generali_premium_1}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="generali_premium_1d"
            required={false}
            disabled={disabledTab || disabledMap?.generali_premium_1d}
            isNegativeAllowed={false}
            visible={visibleMap?.generali_premium_1d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="generali_premium_6"
            required={false}
            disabled={disabledTab || disabledMap?.generali_premium_6}
            isNegativeAllowed={false}
            visible={visibleMap?.generali_premium_6}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="generali_premium_6d"
            required={false}
            disabled={disabledTab || disabledMap?.generali_premium_6d}
            isNegativeAllowed={false}
            visible={visibleMap?.generali_premium_6d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="generali_premium_12"
            required={false}
            disabled={disabledTab || disabledMap?.generali_premium_12}
            isNegativeAllowed={false}
            visible={visibleMap?.generali_premium_12}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="generali_premium_12d"
            required={false}
            disabled={disabledTab || disabledMap?.generali_premium_12d}
            isNegativeAllowed={false}
            visible={visibleMap?.generali_premium_12d}
          />
        </div>

        <div className="grid-cell-insurer">
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedMtplInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedMtplInsurers", value)
            }
            insurerName="allianz"
          />
          <span className="checkbox-label">Allianz</span>
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="allianz_premium_1"
            required={false}
            disabled={disabledTab || disabledMap?.allianz_premium_1}
            isNegativeAllowed={false}
            visible={visibleMap?.allianz_premium_1}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="allianz_premium_1d"
            required={false}
            disabled={disabledTab || disabledMap?.allianz_premium_1d}
            isNegativeAllowed={false}
            visible={visibleMap?.allianz_premium_1d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="allianz_premium_6"
            required={false}
            disabled={disabledTab || disabledMap?.allianz_premium_6}
            isNegativeAllowed={false}
            visible={visibleMap?.allianz_premium_6}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="allianz_premium_6d"
            required={false}
            disabled={disabledTab || disabledMap?.allianz_premium_6d}
            isNegativeAllowed={false}
            visible={visibleMap?.allianz_premium_6d}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="allianz_premium_12"
            required={false}
            disabled={disabledTab || disabledMap?.allianz_premium_12}
            isNegativeAllowed={false}
            visible={visibleMap?.allianz_premium_12}
          />
        </div>
        <div className="grid-cell">
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="allianz_premium_12d"
            required={false}
            disabled={disabledTab || disabledMap?.allianz_premium_12d}
            isNegativeAllowed={false}
            visible={visibleMap?.allianz_premium_12d}
          />
        </div>
      </div>

      <VSpace height={20} />
      <div className={styles.grid}>
        <div style={labelStyle(1, 1)}>
          {t(reverse_romanian_label("Tariful de referinta calculat"))}
          {requiredMap.out_mtpl_limits ? "*" : ""}
        </div>
        <div style={fieldStyle(1, 2)}>
          <NumberInputField
            label={reverse_romanian_label("Tariful de referinta calculat")}
            form={props.form}
            isLoading={isLoading}
            isFloat={true}
            name="out_mtpl_limits"
            required={false}
            disabled={disabledMap?.out_mtpl_limits}
            isNegativeAllowed={false}
            visible={visibleMap?.out_mtpl_limits}
          />
        </div>
        <div style={labelStyle(2, 1)}>
          {t(reverse_romanian_label("Info BAAR"))}
          {requiredMap.out_mtpl_limits_message ? "*" : ""}
        </div>
        <div style={fieldStyle(2, 2)}>
          <TextInputField
            isLoading={isLoading}
            form={props.form}
            name="out_mtpl_limits_message"
            label={reverse_romanian_label("Info BAAR")}
            required={false}
            disabled={disabledMap?.out_mtpl_limits_message}
            visible={visibleMap?.out_mtpl_limits_message}
          />
        </div>
      </div>
    </>
  );
};

export default MtplPremiumTable;
