import { Button } from "@fluentui/react-components";
import React from "react";
import { FlashAutoRegular } from "@fluentui/react-icons";

const AutomaticOperationButton = (props) => {
  return (
    <Button
      {...props}
      appearance="transparent"
      icon={<FlashAutoRegular />}
      size="small"
    />
  );
};

export default AutomaticOperationButton;
