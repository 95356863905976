import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import { DeleteDismissFilled } from "@fluentui/react-icons";
import { toast } from "components/FluentToast";
import React from "react";
import { useTranslation } from "react-i18next";
import { useListPageStore } from "store/ListPageStore";

export const InvalidateDialog = () => {
  const [
    { invalidateOfferDialog, invalidateOfferStatus },
    {
      setListStoreValue,
      setListStoreValues,
      invalidateOffer,
      loadVehicles,
      loadOffer,
    },
  ] = useListPageStore();

  const isLoading = invalidateOfferStatus === "loading";

  const { t } = useTranslation();

  return (
    <Dialog open={invalidateOfferDialog.isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("bcr.Invalidation_of_the_offer.label")}</DialogTitle>
          <DialogContent>
            {t("bcr.invalidateOfferConfirm.label", {
              offerId: invalidateOfferDialog?.offer?.offerId,
            })}
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setListStoreValue("invalidateOfferDialog", {
                  isOpen: false,
                  offer: null,
                });
              }}
            >
              {t("bcr.close.label")}
            </Button>
            <Button
              appearance="primary"
              disabled={isLoading}
              icon={
                isLoading ? (
                  <Spinner size="tiny" />
                ) : (
                  <DeleteDismissFilled fontSize={16} />
                )
              }
              onClick={() => {
                loadOffer(
                  {
                    appInfo: {
                      dataObj: {
                        offerId: invalidateOfferDialog.offer.offerId,
                      },
                      resolvedProperties: {
                        title: "BCR Leasing",
                      },
                    },
                  },
                  (offerItem) => {
                    invalidateOffer(offerItem, () => {
                      setListStoreValues({
                        invalidateOfferDialog: {
                          isOpen: false,
                          offer: null,
                        },
                      });
                      toast.success(t("greco.success"));
                      loadVehicles({ reset: true, delay: true });
                    });
                  }
                );
              }}
            >
              {t("bcr.Invalidation_of_the_offer.label")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
