import React from "react";
import { useTranslation } from "react-i18next";

import { useListPageStore } from "store/ListPageStore";
import { Button } from "@fluentui/react-components";
import { ArrowClockwiseFilled } from "@fluentui/react-icons";

export const RefreshButton = () => {
  const [{ vehicleItemsLoadStatus }, { loadVehicles }] = useListPageStore();
  const loading = vehicleItemsLoadStatus === "loading";

  return (
    <Button
      disabled={loading}
      appearance="subtle"
      icon={<ArrowClockwiseFilled fontSize={24} />}
      onClick={() => {
        loadVehicles({ reset: true, delay: false });
      }}
    ></Button>
  );
};
