import styled from "@emotion/styled";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import React from "react";
import { createPortal } from "react-dom";

export const TableSpinner = () => {
  return createPortal(
    <Root>
      <Spinner size={SpinnerSize.large} />
    </Root>,
    document.body
  );
};

const Root = styled.div`
  position: absolute;
  bottom: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
`;
