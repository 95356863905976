import { useTheme } from "styled-components";
import { BuildingRegular } from "@fluentui/react-icons";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import SelectField from "components/controls9/SelectField";
import TaxonomyField from "components/controls9/TaxonomyField";
import { MessageBarType } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStore, useAppStoreActions } from "store/AppStore";
import { useFormPageStore, useFormPageStoreState } from "store/FormPageStore";
import { TabProps } from "types/types";
import { resolverFn } from "utils/setYupLocale";
import { reverse_romanian_label, taxonomy } from "utils/types";
import {
  fieldToTab,
  getClasses,
  prepareFormItemsForSubmit,
  prepareFormReturnValue,
} from "utils/utils";
import * as yup from "yup";
import TabCard from "../TabCard";
import { calculateCascoSchema } from "../useFormValidationSchema";
import moment from "moment";
import TextInputField from "components/controls9/TextInputField";
import CheckboxField from "components/controls9/CheckboxField";
import { VSpace } from "components/Spacer";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "config/routes";
import { CertificateRegular } from "@fluentui/react-icons";
import {
  Button,
  Spinner,
  makeStyles,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { toast } from "components/FluentToast";

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const PolicyTab1 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { setValue, getValues, setError } = props.form;
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const location = useLocation();
  const [
    { romanianTranslations },
    { setNotificationMessage, clearAllNotifications },
  ] = useAppStore();
  const [
    { selectedProduct, progress, mainTab, policyTab },
    { issueCascoPolicy, loadOffer, setFormStoreValues },
  ] = formPageStore;
  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.cascoPolicyCreated;
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.isDark;

  const classes = getClasses(theme, isDarkMode);
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  const cardFooter = (
    <>
      {progress.cascoPolicyCreated ? (
        <Button
          icon={
            isLoading ? (
              <Spinner size="tiny" />
            ) : (
              <CertificateRegular fontSize={16} />
            )
          }
          appearance="primary"
          disabled={disabledMap?.downloadCascoPolicy}
          name="downloadCascoPolicy"
          onClick={() => {
            window.open(getValues("pathToPolicyCascoPdf"), "_blank");
          }}
          // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToPolicyCascoPdf,'bcrcascopolicy');")}}
        >
          {t(reverse_romanian_label("Polita"))}
        </Button>
      ) : null}

      {!progress.cascoPolicyCreated ? (
        <>
          <Button
            icon={<CertificateRegular fontSize={16} />}
            appearance="primary"
            // isLoading={isLoading}
            name="generateCascoPolicy"
            // visible={visibleMap?.generateCascoPolicy}
            disabled={
              isLoading || disabledTab || disabledMap?.generateCascoPolicy
            }
            onClick={() => {
              props.form.clearErrors();
              clearAllNotifications();
              resolverFn(
                selectedProduct?.validationMap["policyTab1"](yup, t),
                getValues()
              ).then((res) => {
                if (Object.keys(res.errors).length > 0) {
                  toast.error(t("bcr.validationErrors.label"));
                  Object.keys(res.errors).forEach((key) => {
                    setError(key, res.errors[key]);
                    setNotificationMessage({
                      errors: [],
                      key: key,
                      messageBarType: MessageBarType.error,
                      fieldName: t("bcr." + key + ".label"),
                      handleNotificationClick: (e) => {
                        setFormStoreValues({
                          mainTab: { ...mainTab, selectedTab: "policy" },
                          policyTab: {
                            ...policyTab,
                            selectedTab: fieldToTab(key),
                          },
                        });
                      },
                      notify: {
                        label: "label",
                        type: "error",
                        notifyCode: "notifyCode",
                        notifyText: (
                          <>
                            <b>{t("bcr." + key + ".label")}</b>
                            <br />
                            {res.errors[key].message}
                          </>
                        ),
                      },
                    });
                  });
                } else {
                  setConfirmDialogOpen(true);
                }
              });
            }}
          >
            {t(reverse_romanian_label("Emiteti polita"))}
          </Button>
          <Dialog modalType="alert" open={confirmDialogOpen}>
            <DialogSurface>
              <DialogBody>
                <DialogTitle>
                  {t(reverse_romanian_label("Emiteti polita"))}
                </DialogTitle>
                <DialogContent>
                  {t(
                    reverse_romanian_label(
                      "A fost receptionata oferta semnata?"
                    )
                  )}
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button
                      disabled={isLoading}
                      appearance="secondary"
                      onClick={() => {
                        setConfirmDialogOpen(false);
                      }}
                    >
                      {t("greco.no")}
                    </Button>
                  </DialogTrigger>
                  <Button
                    icon={
                      isLoading ? (
                        <Spinner size="tiny" />
                      ) : (
                        <CertificateRegular fontSize={16} />
                      )
                    }
                    disabled={isLoading}
                    appearance="primary"
                    onClick={() => {
                      setValue("confirmationForCascoPolicyIssuing", true);
                      issueCascoPolicy(
                        {
                          appInfo: {
                            dataObj: {
                              ...prepareFormItemsForSubmit(
                                getValues(),
                                romanianTranslations
                              ),
                            },
                            resolvedProperties: {
                              title: "BCR Leasing",
                            },
                          },
                        },
                        (retVal) => {
                          if (retVal.pathToPolicyCascoPdf) {
                            window.open(retVal.pathToPolicyCascoPdf, "_blank");
                          }
                          window.location.reload();
                        },
                        (err) => {
                          setConfirmDialogOpen(false);
                          setNotificationMessage({
                            errors: [],
                            key: "key",
                            messageBarType: MessageBarType.error,
                            fieldName: "fieldName",
                            handleNotificationClick: (e) => {},
                            notify: {
                              label: "label",
                              type: "error",
                              notifyCode: "notifyCode",
                              notifyText: (
                                <>
                                  <b>{"IssueCascoPolicy"}</b>
                                  <br />
                                  {err.response.data.Message}
                                </>
                              ),
                            },
                          });
                        }
                      );
                    }}
                  >
                    {t("greco.yes")}
                  </Button>
                </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        </>
      ) : null}
    </>
  );
  if (!selectedProduct) return null;
  return (
    <>
      <TabCard footer={cardFooter}>
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label("Data inceput asigurare"))}
            {requiredMap.validFrom ? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <DatePickerField
              isLoading={isLoading}
              form={props.form}
              name="validFrom"
              label={reverse_romanian_label("Data inceput asigurare")}
              disabled={disabledTab || disabledMap?.validFrom}
              data-format="dd.MM.yyyy"
              data-dateFormat="DD.MM.YYYY"
              required={true}
              visible={visibleMap?.validFrom}
              minDate={moment().startOf("day").toDate()}
            />
          </div>
          {selectedProduct?.visibleMap.deliveryType ||
          getValues("insurerCasco") === "bcr.Insurer_ASIROM" ||
          getValues("insurerCasco") === "bcr.Insurer_UNIQA" ? (
            <>
              <div style={labelStyle(2, 1)}>
                {t(reverse_romanian_label("Livrare"))}
                {requiredMap.deliveryType ? "*" : ""}
              </div>
              <div style={fieldStyle(2, 2)}>
                <SelectField
                  isLoading={isLoading}
                  form={props.form}
                  name="deliveryType"
                  label={t(reverse_romanian_label("Livrare"))}
                  options={taxonomy["deliveryType"] || []}
                  required={true}
                  disabled={disabledTab || disabledMap?.deliveryType}
                  visible={
                    visibleMap?.deliveryType ||
                    getValues("insurerCasco") === "bcr.Insurer_ASIROM" ||
                    getValues("insurerCasco") === "bcr.Insurer_UNIQA"
                  }
                />
              </div>
            </>
          ) : null}
          {!progress?.cascoPolicyCreated ? (
            <>
              {selectedProduct?.visibleMap.deliveryType ? (
                <>
                  <div style={labelStyle(3, 1)}>
                    {t(reverse_romanian_label("Serie sasiu"))}
                    {requiredMap.inp_vehicle_vin2 ? "*" : ""}
                  </div>
                  <div style={fieldStyle(3, 2)}>
                    <TextInputField
                      form={props.form}
                      isLoading={isLoading}
                      name="inp_vehicle_vin2"
                      label={reverse_romanian_label("Serie sasiu")}
                      required={true}
                      disabled={disabledTab || disabledMap?.inp_vehicle_vin2}
                      visible={visibleMap?.inp_vehicle_vin2}
                    />
                  </div>
                </>
              ) : null}
              {/* <div style={labelStyle(4, 1)}>
                {t(
                  reverse_romanian_label("A fost receptionata oferta semnata?")
                )}
                {requiredMap.confirmationForCascoPolicyIssuing ? "*" : ""}
              </div>
              <div style={fieldStyle(4, 2)}>
                <CheckboxField
                  form={props.form}
                  isLoading={isLoading}
                  name="confirmationForCascoPolicyIssuing"
                  label={reverse_romanian_label(
                    "A fost receptionata oferta semnata?"
                  )}
                  required={false}
                  disabled={
                    disabledTab ||
                    disabledMap?.confirmationForCascoPolicyIssuing
                  }
                  visible={visibleMap?.confirmationForCascoPolicyIssuing}
                />
              </div> */}
              {/* <div style={labelStyle(5, 1)}>
                {t(reverse_romanian_label(""))}
                {requiredMap.htmlCascoPolicyIssuingWarning ? "*" : ""}
              </div>
              <div style={fieldStyle(5, 2)}>
                {visibleMap.htmlCascoPolicyIssuingWarning ? (
                  <div
                    style={{ marginTop: "30px" }}
                    data-key="htmlCascoPolicyIssuingWarning"
                    data-customclass="warnText"
                  >
                    {t(
                      reverse_romanian_label("Atentie! Oferta trebuie semnata!")
                    )}
                  </div>
                ) : null}
              </div> */}
              {/* <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}
          </div> */}
              {/* <div style={fieldStyle(1, 2)}>
            <MyDefaultButton
              style={{ marginTop: "20px" }}
              isLoading={isLoading}
              text={t(reverse_romanian_label("Emiteti polita"))}
              name="issueCascoPolicy"
              visible={visibleMap?.issueCascoPolicy}
              disabled={disabledTab || disabledMap?.issueCascoPolicy}
              onClick={() => {
                issueCascoPolicy(
                  {
                    appInfo: {
                      dataObj: {
                        ...prepareFormItemsForSubmit(
                          getValues(),
                          romanianTranslations
                        ),
                      },
                      resolvedProperties: {
                        title: "BCR Leasing",
                      },
                    },
                  },
                  (retVal) => {
                    disableWhenPolicyCascoIdExists({
                      data: props.form.getValues(),
                      form: props.form,
                      formPageStore: pageFormStore,
                    });
                    const preparedRetVal = prepareFormReturnValue(retVal, t);
                    for (const key in preparedRetVal) {
                      setValue(key, preparedRetVal[key]);
                    }
                  }
                );
              }}
              // "action success script": "formioForm.checkConditions();"
            />
          </div> */}
            </>
          ) : null}
        </div>
        {progress?.cascoPolicyCreated ? (
          <>
            <VSpace height={20} />
            <hr />
            <VSpace height={20} />
            <div className={styles.grid}>
              <div style={labelStyle(1, 1)}>
                {t(reverse_romanian_label("Numar polita"))}
                {requiredMap.policyCascoId ? "*" : ""}
              </div>
              <div style={fieldStyle(1, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="policyCascoId"
                  label={reverse_romanian_label("Numar polita")}
                  required={false}
                  disabled={disabledTab || disabledMap?.policyCascoId}
                  visible={visibleMap?.policyCascoId}
                />
              </div>
              <div style={labelStyle(2, 1)}>
                {t(reverse_romanian_label("Prima"))}
                {requiredMap.policyCascoPremium ? "*" : ""}
              </div>
              <div style={fieldStyle(2, 2)}>
                <NumberInputField
                  isLoading={isLoading}
                  form={props.form}
                  name="policyCascoPremium"
                  label={reverse_romanian_label("Prima")}
                  required={false}
                  disabled={disabledTab || disabledMap?.policyCascoPremium}
                  isNegativeAllowed={false}
                  visible={visibleMap?.policyCascoPremium}
                  contentAfter={t(getValues("inp_policy_currency"))}
                />
              </div>
              <div style={labelStyle(3, 1)}>
                {t(reverse_romanian_label("Polita emisa la"))}
                {requiredMap.cascoPolicyIssuedAt ? "*" : ""}
              </div>
              <div style={fieldStyle(3, 2)}>
                <DatePickerField
                  isLoading={isLoading}
                  form={props.form}
                  name="cascoPolicyIssuedAt"
                  label={reverse_romanian_label("Polita emisa la")}
                  disabled={disabledTab || disabledMap?.cascoPolicyIssuedAt}
                  data-format="dd.MM.yyyy HH:mm:ss"
                  data-dateFormat="DD.MM.YYYY HH:mm"
                  required={false}
                  visible={visibleMap?.cascoPolicyIssuedAt}
                  includeTime={true}
                />
              </div>
              <div style={labelStyle(4, 1)}>
                {t(reverse_romanian_label("Polita emisa de"))}
                {requiredMap.cascoPolicyIssuedByName ? "*" : ""}
              </div>
              <div style={fieldStyle(4, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="cascoPolicyIssuedByName"
                  label={reverse_romanian_label("Polita emisa de")}
                  required={false}
                  disabled={disabledTab || disabledMap?.cascoPolicyIssuedByName}
                  visible={visibleMap?.cascoPolicyIssuedByName}
                />
              </div>
              {/* <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Polita"))}
              name="downloadCascoPolicy"
              visible={visibleMap?.downloadCascoPolicy}
              disabled={disabledTab || disabledMap?.downloadCascoPolicy}
              onClick={() => {
                window.open(getValues("pathToPolicyCascoPdf"), "_blank");
              }}
              // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToPolicyCascoPdf,'bcrcascopolicy');")}}
            />
          </div> */}
              {/* <div style={labelStyle(10, 1)}>
            {t(reverse_romanian_label("A fost receptionata oferta semnata?"))}
            {requiredMap.confirmationForCascoPolicyTotalIssuing ? "*" : ""}
          </div>
          <div style={fieldStyle(10, 2)}>
            <CheckboxField
              isLoading={isLoading}
              form={props.form}
              name="confirmationForCascoPolicyTotalIssuing"
              label={reverse_romanian_label(
                "A fost receptionata oferta semnata?"
              )}
              required={false}
              disabled={
                disabledTab ||
                disabledMap?.confirmationForCascoPolicyTotalIssuing
              }
              visible={visibleMap?.confirmationForCascoPolicyTotalIssuing}
            />
          </div>
          <div style={labelStyle(11, 1)}>
            {t(reverse_romanian_label(""))}
            {requiredMap.htmlCascoPolicyTotalIssuingWarning ? "*" : ""}
          </div>
          <div style={fieldStyle(11, 2)}>
            {visibleMap.htmlCascoPolicyTotalIssuingWarning ? (
              <div
                data-key="htmlCascoPolicyTotalIssuingWarning"
                data-customclass="warnText"
              >
                {t(reverse_romanian_label("Atentie! Oferta trebuie semnata!"))}
              </div>
            ) : null}
          </div> */}
              {/* <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Emiteti polita total"))}
              name="issueCascoPolicyTotal"
              visible={visibleMap?.issueCascoPolicyTotal}
              disabled={disabledTab || disabledMap?.issueCascoPolicyTotal}
              onClick={() => {
                issueCascoTotalPolicy(
                  {
                    appInfo: {
                      dataObj: {
                        ...prepareFormItemsForSubmit(
                          getValues(),
                          romanianTranslations
                        ),
                      },
                      resolvedProperties: {
                        title: "BCR Leasing",
                      },
                    },
                  },
                  (retVal) => {
                    disableWhenPolicyCascoTotalIdExists({
                      data: props.form.getValues(),
                      form: props.form,
                      formPageStore: pageFormStore,
                    });
                    const preparedRetVal = prepareFormReturnValue(retVal, t);
                    for (const key in preparedRetVal) {
                      setValue(key, preparedRetVal[key]);
                    }
                  }
                );
              }}
              // "action success script": "formioForm.checkConditions();"
            />
          </div> */}
              {/* <div style={labelStyle(12, 1)}>
            {t(reverse_romanian_label("Numar polita casco total"))}
            {requiredMap.policyCascoTotalId ? "*" : ""}
          </div>
          <div style={fieldStyle(12, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="policyCascoTotalId"
              label={reverse_romanian_label("Numar polita casco total")}
              required={false}
              disabled={disabledTab || disabledMap?.policyCascoTotalId}
              visible={visibleMap?.policyCascoTotalId}
            />
          </div>
          <div style={labelStyle(13, 1)}>
            {t(reverse_romanian_label("Prima casco total"))}
            {requiredMap.policyCascoTotalPremium ? "*" : ""}
          </div>
          <div style={fieldStyle(13, 2)}>
            <NumberInputField
              isLoading={isLoading}
              form={props.form}
              name="policyCascoTotalPremium"
              label={reverse_romanian_label("Prima casco total")}
              required={false}
              disabled={disabledTab || disabledMap?.policyCascoTotalPremium}
              isNegativeAllowed={false}
              visible={visibleMap?.policyCascoTotalPremium}
            />
          </div>
          <div style={labelStyle(14, 1)}>
            {t(reverse_romanian_label("Polita casco total emisa la"))}
            {requiredMap.cascoPolicyTotalIssuedAt ? "*" : ""}
          </div>
          <div style={fieldStyle(14, 2)}>
            <DatePickerField
              isLoading={isLoading}
              form={props.form}
              name="cascoPolicyTotalIssuedAt"
              label={reverse_romanian_label("Polita casco total emisa la")}
              disabled={disabledTab || disabledMap?.cascoPolicyTotalIssuedAt}
              data-format="dd.MM.yyyy HH:mm:ss"
              data-dateFormat="DD.MM.YYYY HH:mm"
              required={false}
              visible={visibleMap?.cascoPolicyTotalIssuedAt}
              includeTime={true}
            />
          </div>
          <div style={labelStyle(15, 1)}>
            {t(reverse_romanian_label("Polita casco total emisa de"))}
            {requiredMap.cascoPolicyTotalIssuedByName ? "*" : ""}
          </div>
          <div style={fieldStyle(15, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="cascoPolicyTotalIssuedByName"
              label={reverse_romanian_label("Polita casco total emisa de")}
              required={false}
              disabled={
                disabledTab || disabledMap?.cascoPolicyTotalIssuedByName
              }
              visible={visibleMap?.cascoPolicyTotalIssuedByName}
            />
          </div> */}
              {/* <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Polita casco total"))}
              name="downloadCascoPolicyTotal"
              visible={visibleMap?.downloadCascoPolicyTotal}
              disabled={disabledTab || disabledMap?.downloadCascoPolicyTotal}
              onClick={() => {
                window.open(getValues("pathToPolicyCascoTotalPdf"), "_blank");
              }}
              // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToPolicyCascoTotalPdf,'bcrcascototalpolicy');")}}
            />
          </div> */}
              {/* <div style={labelStyle(16, 1)}>
            {t(
              reverse_romanian_label(
                "Se doreste emitere polita cu acoperire la dauna partiala?"
              )
            )}
            *
          </div>
          <div style={fieldStyle(16, 2)}>
            <CheckboxField
              form={props.form}
              isLoading={isLoading}
              name="confirmationForCascoPolicyPartialIssuing"
              label={reverse_romanian_label(
                "Se doreste emitere polita cu acoperire la dauna partiala?"
              )}
              required={false}
              disabled={
                disabledTab ||
                disabledMap?.confirmationForCascoPolicyPartialIssuing
              }
              visible={visibleMap?.confirmationForCascoPolicyPartialIssuing}
            />
          </div>
          <div style={labelStyle(17, 1)}>
            {t(reverse_romanian_label(""))}
            {requiredMap.htmlCascoPolicyPartialIssuingWarning ? "*" : ""}
          </div>
          <div style={fieldStyle(17, 2)}>
            {visibleMap.htmlCascoPolicyPartialIssuingWarning ? (
              <div
                data-key="htmlCascoPolicyPartialIssuingWarning"
                data-customclass="warnText"
              >
                {t(reverse_romanian_label("Atentie! Oferta trebuie semnata!"))}
              </div>
            ) : null}
          </div> */}
              {/* <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Emiteti polita partial"))}
              name="issueCascoPolicyPartial"
              visible={visibleMap?.issueCascoPolicyPartial}
              disabled={disabledTab || disabledMap?.issueCascoPolicyPartial}
              onClick={() => {
                issueCascoPartialPolicy(
                  {
                    appInfo: {
                      dataObj: {
                        ...prepareFormItemsForSubmit(
                          getValues(),
                          romanianTranslations
                        ),
                      },
                      resolvedProperties: {
                        title: "BCR Leasing",
                      },
                    },
                  },
                  (retVal) => {
                    const preparedRetVal = prepareFormReturnValue(retVal, t);
                    for (const key in preparedRetVal) {
                      setValue(key, preparedRetVal[key]);
                    }
                  }
                );
              }}
              // "action success script": "formioForm.checkConditions();"
            />
          </div> */}
              {/* <div style={labelStyle(18, 1)}>
            {t(reverse_romanian_label("Numar polita casco partial"))}
            {requiredMap.policyCascoPartialId ? "*" : ""}
          </div>
          <div style={fieldStyle(18, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="policyCascoPartialId"
              label={reverse_romanian_label("Numar polita casco partial")}
              required={false}
              disabled={disabledTab || disabledMap?.policyCascoPartialId}
              visible={visibleMap?.policyCascoPartialId}
            />
          </div>
          <div style={labelStyle(19, 1)}>
            {t(reverse_romanian_label("Prima casco partial"))}
            {requiredMap.policyCascoPartialPremium ? "*" : ""}
          </div>
          <div style={fieldStyle(19, 2)}>
            <NumberInputField
              isLoading={isLoading}
              form={props.form}
              name="policyCascoPartialPremium"
              label={reverse_romanian_label("Prima casco partial")}
              required={false}
              disabled={disabledTab || disabledMap?.policyCascoPartialPremium}
              isNegativeAllowed={false}
              visible={visibleMap?.policyCascoPartialPremium}
            />
          </div>
          <div style={labelStyle(20, 1)}>
            {t(reverse_romanian_label("Polita casco partial emisa la"))}
            {requiredMap.cascoPolicyPartialIssuedAt ? "*" : ""}
          </div>
          <div style={fieldStyle(20, 2)}>
            <DatePickerField
              isLoading={isLoading}
              form={props.form}
              name="cascoPolicyPartialIssuedAt"
              label={reverse_romanian_label("Polita casco partial emisa la")}
              disabled={disabledTab || disabledMap?.cascoPolicyPartialIssuedAt}
              data-format="dd.MM.yyyy HH:mm:ss"
              data-dateFormat="DD.MM.YYYY HH:mm"
              required={false}
              visible={visibleMap?.cascoPolicyPartialIssuedAt}
            />
          </div>
          <div style={labelStyle(21, 1)}>
            {t(reverse_romanian_label("Polita casco partial emisa de"))}
            {requiredMap.cascoPolicyPartialIssuedByName ? "*" : ""}
          </div>
          <div style={fieldStyle(21, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="cascoPolicyPartialIssuedByName"
              label={reverse_romanian_label("Polita casco partial emisa de")}
              required={false}
              disabled={
                disabledTab || disabledMap?.cascoPolicyPartialIssuedByName
              }
              visible={visibleMap?.cascoPolicyPartialIssuedByName}
            />
          </div> */}
              {/* <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Polita casco partial"))}
              name="downloadCascoPolicyPartial"
              visible={visibleMap?.downloadCascoPolicyPartial}
              disabled={disabledTab || disabledMap?.downloadCascoPolicyPartial}
              onClick={() => {
                window.open(getValues("pathToPolicyCascoPartialPdf"), "_blank");
              }}
              // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToPolicyCascoPartialPdf,'bcrcascopartialpolicy');")}}
            />
          </div> */}
            </div>
          </>
        ) : null}
      </TabCard>
    </>
  );
};

export default PolicyTab1;
