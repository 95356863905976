import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTheme } from "styled-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";

import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";
import { defaultDatePickerStrings } from "@fluentui/react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Body1Strong } from "@fluentui/react-components";

const datePickerStyles = (theme) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      background: "transparent !important",
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-suffix": {
      //Cancel Icon
      marginRight: "30px",
      background: "none",
      cursor: "pointer",
    },
    ".msDatePickerDisabled": {
      color: "#005aa1",
    },
    ".ms-TextField-fieldGroup input": {
      background: "transparent !important",
    },
  },
  container: {
    ".ms-DatePicker-goToday": {
      right: "14px !important",
    },
  },
  icon: { right: "0" },
});

type Props = {
  form: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  includeTime?: boolean;
  visible?: boolean;
  isLoading: boolean;
};

const DatePickerField = (props: Props) => {
  const {
    name,
    label,
    required = false,
    disabled = false,
    placeholder = "",
    minDate,
    maxDate,
    includeTime = false,
    visible = true,
    form,
    isLoading,
  } = props;
  const dataFormat = props["data-dateFormat"];
  const theme = useTheme();

  const {
    control,
    getValues,
    formState: { errors },
  } = form;

  const { t } = useTranslation();

  const datePickerRef = React.useRef<any>(null);

  const [fieldValue, setFieldValue] = React.useState<Date | undefined>(
    getValues(name)
  );

  // const [fieldValue, setFieldValue] = useState(defaultValues[name]);

  // useEffect(() => {
  //   setValue(name, fieldValue);
  // }, [fieldValue]);

  const onFormatDate = (date?: Date): string => {
    if (includeTime) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    }
    return !date
      ? ""
      : ((date.getDate() + "").length === 1 ? "0" : "") +
          date.getDate() +
          "." +
          ((date.getMonth() + 1 + "").length === 1 ? "0" : "") +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear();
  };
  const onParseDateFromString = React.useCallback(
    (newValue: string, onChange: (...event: any[]) => void): Date => {
      const previousValue = fieldValue || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      if (newValueParts.length === 1 && newValueParts[0].length === 0) {
        onChange(null);
        return null;
      }
      try {
        const day =
          newValueParts.length > 0 && newValueParts[0].length > 0
            ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
            : previousValue.getDate();
        const month =
          newValueParts.length > 1
            ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10)))
            : previousValue.getMonth() + 1;
        const year =
          newValueParts.length > 2
            ? Math.max(0, parseInt(newValueParts[2], 10))
            : previousValue.getFullYear();
        const retVal = new Date(year, month - 1, day);
        if (retVal && !isNaN(retVal.getTime())) {
          onChange(retVal);
          return retVal;
        } else {
          onChange(null);
        }
      } catch (e) {
        onChange(null);
      }
      return null;
    },
    [fieldValue]
  );
  if (!visible) return null;
  return (
    <FieldContainer
      isTooltipHidden={disabled}
      tooltipText={label}
      isReadOnly={disabled}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <>
              {disabled ? (
                <Body1Strong
                  id={"datepicker_readonly_" + name}
                  style={{
                    marginLeft: "15px",
                    color: isLoading ? "#888888" : null,
                  }}
                >
                  {field.value ? moment(field.value).format(dataFormat) : ""}
                </Body1Strong>
              ) : (
                <DatePicker
                  id={"datepicker_" + name}
                  onError={(e) => {
                    return;
                  }}
                  onErrorCapture={(e) => {
                    return;
                  }}
                  componentRef={datePickerRef}
                  allowTextInput
                  value={field.value}
                  onSelectDate={(date: Date | null) => {
                    field.onChange(date);
                  }}
                  parseDateFromString={(dateStr) =>
                    onParseDateFromString(dateStr, field.onChange)
                  }
                  onChange={(e: any) => {
                    //console.log(e.target.value);
                  }}
                  formatDate={onFormatDate}
                  // DatePicker uses English strings by default. For localized apps, you must override this prop.
                  strings={defaultDatePickerStrings}
                  disabled={isLoading}
                  placeholder={placeholder}
                  disableAutoFocus={true}
                  minDate={minDate}
                  maxDate={maxDate}
                  onBlur={(e) => {
                    const inputEl: any = e.currentTarget;
                    setTimeout(() => {
                      inputEl.blur();
                      onParseDateFromString(inputEl.value, field.onChange);
                    }, 300);
                  }}
                />
              )}
              {errors && errors[name] ? (
                <ErrorMsg id={name + "_error"}>{errors[name].message}</ErrorMsg>
              ) : null}
            </>
          );
        }}
      />
    </FieldContainer>
  );
};

export default DatePickerField;
