import { useTheme } from "styled-components";
import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { BuildingRegular } from "@fluentui/react-icons";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import SelectField from "components/controls9/SelectField";
import TextInputField from "components/controls9/TextInputField";
import { MessageBarType } from "office-ui-fabric-react";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppStoreActions } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { resolverFn } from "utils/setYupLocale";
import { reverse_romanian_label, taxonomy } from "utils/types";
import { fieldToTab, getClasses } from "utils/utils";
import * as yup from "yup";
import { calculateCascoSchema } from "../useFormValidationSchema";
import { TabProps } from "types/types";
import TabCard from "../TabCard";
import CardFooterWithInfoCalculation from "./CardFooterWithInfoCalculation";
import { toast } from "components/FluentToast";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const OfferTab8 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { setValue, getValues, setError, clearErrors } = props.form;

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const [
    { selectedProduct, offerTab, progress, mainTab },
    { setFormStoreValue, setFormStoreValues },
  ] = formPageStore;

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.cascoOfferCreated || progress.mtplOfferCreated;

  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  const cardFooter = (
    <CardFooterWithInfoCalculation isLoading={isLoading} form={props.form}>
      <Button
        appearance="secondary"
        icon={<BuildingRegular fontSize={16} />}
        onClick={async () => {
          clearErrors();
          clearAllNotifications();

          resolverFn(
            selectedProduct.validationMap["offerTab8"](yup, t),
            getValues()
          ).then((res) => {
            if (Object.keys(res.errors).length > 0) {
              toast.error(t("bcr.validationErrors.label"));
              Object.keys(res.errors).forEach((key) => {
                setError(key, res.errors[key]);
                setNotificationMessage({
                  errors: [],
                  key: key,
                  messageBarType: MessageBarType.error,
                  fieldName: t("bcr." + key + ".label"),
                  handleNotificationClick: (e) => {
                    setFormStoreValues({
                      mainTab: { ...mainTab, selectedTab: "offer" },
                      offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
                    });
                  },
                  notify: {
                    label: "label",
                    type: "error",
                    notifyCode: "notifyCode",
                    notifyText: (
                      <>
                        <b>{t("bcr." + key + ".label")}</b>
                        <br />
                        {res.errors[key].message}
                      </>
                    ),
                  },
                });
              });
            } else {
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab: "tab9",
                disabledTabs: offerTab.disabledTabs.filter(
                  (el) => el !== "tab9"
                ),
              });
            }
          });
        }}
      >
        {t("bcr.next.label")}
      </Button>
    </CardFooterWithInfoCalculation>
  );
  return (
    <>
      <TabCard footer={cardFooter}>
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label("Conducător C.N.P"))}
            {requiredMap.inp_driver_compreg ? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_driver_compreg"
              label={reverse_romanian_label("Conducător C.N.P")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_driver_compreg}
              visible={visibleMap?.inp_driver_compreg}
            />
          </div>
          <div style={labelStyle(2, 1)}>
            {t(reverse_romanian_label("Conducător Nume"))}
            {requiredMap.inp_driver_lastname ? "*" : ""}
          </div>
          <div style={fieldStyle(2, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_driver_lastname"
              label={reverse_romanian_label("Conducător Nume")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_driver_lastname}
              visible={visibleMap?.inp_driver_lastname}
            />
          </div>
          <div style={labelStyle(3, 1)}>
            {t(reverse_romanian_label("Conducător Prenume"))}
            {requiredMap.inp_driver_firstname ? "*" : ""}
          </div>
          <div style={fieldStyle(3, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_driver_firstname"
              label={reverse_romanian_label("Conducător Prenume")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_driver_firstname}
              visible={visibleMap?.inp_driver_firstname}
            />
          </div>
          <div style={labelStyle(4, 1)}>
            {t(reverse_romanian_label("Conducător Seria"))}
            {requiredMap.inp_driver_serial_idcard ? "*" : ""}
          </div>
          <div style={fieldStyle(4, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_driver_serial_idcard"
              label={reverse_romanian_label("Conducător Seria")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_driver_serial_idcard}
              visible={visibleMap?.inp_driver_serial_idcard}
            />
          </div>
          <div style={labelStyle(5, 1)}>
            {t(reverse_romanian_label("Conducător Numar"))}
            {requiredMap.inp_driver_number_idcard ? "*" : ""}
          </div>
          <div style={fieldStyle(5, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_driver_number_idcard"
              label={reverse_romanian_label("Conducător Numar")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_driver_number_idcard}
              visible={visibleMap?.inp_driver_number_idcard}
            />
          </div>
          <div style={labelStyle(6, 1)}>
            {t(reverse_romanian_label("Conducător Telefon"))}
            {requiredMap.inp_driver_phone ? "*" : ""}
          </div>
          <div style={fieldStyle(6, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_driver_phone"
              label={reverse_romanian_label("Conducător Telefon")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_driver_phone}
              visible={visibleMap?.inp_driver_phone}
            />
          </div>
        </div>
      </TabCard>
    </>
  );
};

export default OfferTab8;
