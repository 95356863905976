import { makeStyles } from "@fluentui/react-components";
import InsurerSelectionCheckbox from "components/InsurerSelectionCheckbox";
import { VSpace } from "components/Spacer";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import TextInputField from "components/controls9/TextInputField";
import { Checkbox } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormPageStore } from "store/FormPageStore";
import { reverse_romanian_label } from "utils/types";

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const CascoPremiumTable = (props: any) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const styles = useStyles();
  const formPageStore = useFormPageStore();

  const [
    { selectedProduct, progress, selectedCascoInsurers },
    { setFormStoreValue, calculateCasco, calculateCascoAndMtpl, getOffer },
  ] = formPageStore;

  const curr = t(props?.form?.getValues()?.inp_policy_currency);

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.cascoOfferCreated || progress.mtplOfferCreated;
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "4% 66% 30%",
          width: "640px",
        }}
      >
        <div
          style={{
            gridRow: `2 / 2`,
            gridColumn: `3 / 3`,
            textAlign: "center",
          }}
        >
          {t(reverse_romanian_label("Prima anuala")) + " (" + curr + ")"}
        </div>

        <div
          style={{
            gridRow: `3 / 3`,
            gridColumn: `1 / 1`,
            textAlign: "left",
          }}
        >
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedCascoInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedCascoInsurers", value)
            }
            insurerName={"omniasig"}
          />
        </div>

        <div
          style={{
            gridRow: `3 / 3`,
            gridColumn: `2 / 2`,
            textAlign: "left",
          }}
        >
          Omniasig
        </div>

        <div
          style={{
            gridRow: `3 / 3`,
            gridColumn: `3 / 3`,
            textAlign: "center",
          }}
        >
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="omniasig_premium"
            required={false}
            disabled={disabledTab || disabledMap?.omniasig_premium}
            isNegativeAllowed={false}
            visible={visibleMap?.omniasig_premium}
          />
        </div>
        <div
          style={{
            gridRow: `4 / 4`,
            gridColumn: `1 / 1`,
            textAlign: "left",
          }}
        >
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedCascoInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedCascoInsurers", value)
            }
            insurerName={"asirom"}
          />
        </div>

        <div
          style={{
            gridRow: `4 / 4`,
            gridColumn: `2 / 2`,
            textAlign: "left",
          }}
        >
          Asirom
        </div>

        <div
          style={{
            gridRow: `4 / 4`,
            gridColumn: `3 / 3`,
            textAlign: "center",
          }}
        >
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="asirom_premium"
            required={false}
            disabled={disabledTab || disabledMap?.asirom_premium}
            isNegativeAllowed={false}
            visible={visibleMap?.asirom_premium}
          />
        </div>
        <div
          style={{
            gridRow: `5 / 5`,
            gridColumn: `1 / 1`,
            textAlign: "left",
          }}
        >
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedCascoInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedCascoInsurers", value)
            }
            insurerName={"allianz"}
          />
        </div>

        <div
          style={{
            gridRow: `5 / 5`,
            gridColumn: `2 / 2`,
            textAlign: "left",
          }}
        >
          Allianz
        </div>

        <div
          style={{
            gridRow: `5 / 5`,
            gridColumn: `3 / 3`,
            textAlign: "center",
          }}
        >
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="allianz_premium"
            required={false}
            disabled={disabledTab || disabledMap?.allianz_premium}
            isNegativeAllowed={false}
            visible={visibleMap?.allianz_premium}
          />
        </div>
        <div
          style={{
            gridRow: `6 / 6`,
            gridColumn: `1 / 1`,
            textAlign: "left",
          }}
        >
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedCascoInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedCascoInsurers", value)
            }
            insurerName={"groupama"}
          />
        </div>

        <div
          style={{
            gridRow: `6 / 6`,
            gridColumn: `2 / 2`,
            textAlign: "left",
          }}
        >
          Groupama
        </div>

        <div
          style={{
            gridRow: `6 / 6`,
            gridColumn: `3 / 3`,
            textAlign: "center",
          }}
        >
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="groupama_premium"
            required={false}
            disabled={disabledTab || disabledMap?.groupama_premium}
            isNegativeAllowed={false}
            visible={visibleMap?.groupama_premium}
          />
        </div>
        <div
          style={{
            gridRow: `7 / 7`,
            gridColumn: `1 / 1`,
            textAlign: "left",
          }}
        >
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedCascoInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedCascoInsurers", value)
            }
            insurerName={"generali"}
          />
        </div>

        <div
          style={{
            gridRow: `7 / 7`,
            gridColumn: `2 / 2`,
            textAlign: "left",
          }}
        >
          Generali
        </div>

        <div
          style={{
            gridRow: `7 / 7`,
            gridColumn: `3 / 3`,
            textAlign: "center",
          }}
        >
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="generali_premium"
            required={false}
            disabled={disabledTab || disabledMap?.generali_premium}
            isNegativeAllowed={false}
            visible={visibleMap?.generali_premium}
          />
        </div>
        <div
          style={{
            gridRow: `8 / 8`,
            gridColumn: `1 / 1`,
            textAlign: "left",
          }}
        >
          <InsurerSelectionCheckbox
            disabled={disabledTab}
            list={selectedCascoInsurers}
            onChange={(value) =>
              setFormStoreValue("selectedCascoInsurers", value)
            }
            insurerName={"uniqa"}
          />
        </div>

        <div
          style={{
            gridRow: `8 / 8`,
            gridColumn: `2 / 2`,
            textAlign: "left",
          }}
        >
          Uniqa
        </div>

        <div
          style={{
            gridRow: `8 / 8`,
            gridColumn: `3 / 3`,
            textAlign: "center",
          }}
        >
          <NumberInputField
            fixedWidth={false}
            label={""}
            isLoading={isLoading}
            form={props.form}
            name="uniqa_premium"
            required={false}
            disabled={disabledTab || disabledMap?.uniqa_premium}
            isNegativeAllowed={false}
            visible={visibleMap?.uniqa_premium}
          />
        </div>
      </div>{" "}
      <VSpace height={20} />
      <div className={styles.grid}>
        <div style={labelStyle(1, 1)}>
          {t(reverse_romanian_label("Curs valutar EUR"))}
          {requiredMap.EURExchangeRate ? "*" : ""}
        </div>
        <div style={fieldStyle(1, 2)}>
          <NumberInputField
            label={reverse_romanian_label("Curs valutar EUR")}
            form={props.form}
            isLoading={isLoading}
            isFloat={true}
            name="EURExchangeRate"
            required={false}
            disabled={disabledMap?.EURExchangeRate}
            isNegativeAllowed={false}
            maxPrecision={4}
            minPrecision={4}
            visible={visibleMap?.EURExchangeRate}
            contentAfter="RON"
          />
        </div>
        <div style={labelStyle(2, 1)}>
          {t(reverse_romanian_label("Data curs valutar EUR"))}
          {requiredMap.EURExchangeRateDate ? "*" : ""}
        </div>
        <div style={fieldStyle(2, 2)}>
          <DatePickerField
            isLoading={isLoading}
            form={props.form}
            label={reverse_romanian_label("Data curs valutar EUR")}
            name="EURExchangeRateDate"
            disabled={true}
            data-format="dd.MM.yyyy"
            data-dateFormat="DD.MM.YYYY"
            required={false}
            visible={visibleMap?.EURExchangeRateDate}
          />
        </div>
      </div>
    </>
  );
};

export default CascoPremiumTable;
