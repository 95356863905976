import {
  Tab,
  TabList,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import React from "react";

import {
  DocumentBulletListRegular,
  MoneyHandRegular,
  CertificateRegular,
} from "@fluentui/react-icons";
import { useFormPageStore } from "store/FormPageStore";
import { reverse_romanian_label } from "utils/types";
import { useTranslation } from "react-i18next";
import { hasCascoOfferValues } from "types/products";
const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    justifyContent: "center",
  },
});

const MainTabList = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const [{ mainTab, offerLoadStatus, progress }, { setFormStoreValue }] =
    useFormPageStore();
  const renderTab = (value, IconComponent, label, disabled) => {
    return (
      <Tab
        icon={<IconComponent />}
        value={value}
        onClick={() =>
          setFormStoreValue("mainTab", {
            ...mainTab,
            selectedTab: value,
          })
        }
        // Use the proper syntax for comments within JSX
        disabled={disabled || offerLoadStatus === "loading"}
      >
        {t(reverse_romanian_label(label))}
      </Tab>
    );
  };
  return (
    <TabList
      selectedValue={mainTab.selectedTab}
      className={classes.card}
      size="large"
    >
      {renderTab("offer", DocumentBulletListRegular, "Oferta", false)}
      {renderTab(
        "invoice",
        MoneyHandRegular,
        "Decont",
        !(progress.cascoOfferCreated || progress.mtplOfferCreated) ||
          progress.policyWithoutInvoice
      )}
      {renderTab(
        "policy",
        CertificateRegular,
        "Polita",
        !(
          progress.cascoInvoiceCreated ||
          progress.mtplInvoiceCreated ||
          progress.policyWithoutInvoice
        )
      )}
    </TabList>
  );
};

export default MainTabList;
