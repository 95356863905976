import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useListPageStore } from "store/ListPageStore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import DatePickerField from "components/controls9/DatePickerField";
import {
  resolverFn,
  setYupLocale,
  useYupValidationResolver,
} from "utils/setYupLocale";
import { useFormValidationSchema } from "pages/FormPageNew/useFormValidationSchema";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useTheme } from "@fluentui/react";
import { DocumentBulletListMultipleRegular } from "@fluentui/react-icons";
import { useAppStoreActions } from "store/AppStore";
import { MessageBarType } from "office-ui-fabric-react";
import { reverse_romanian_label } from "utils/types";
import { VSpace } from "components/Spacer";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  gridPremium: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

export const BcrFormDialog = () => {
  const [
    { bcrFormDialog, submitBcrFormStatus },
    { setListStoreValue, submitBcrForm },
  ] = useListPageStore();

  const isLoading = submitBcrFormStatus === "loading";

  const styles = useStyles();
  const theme = useTheme();

  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const { t } = useTranslation();
  const [message, setMessage] = React.useState<string>("");
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      start_date: yup.date().nullable().required(),
      end_date: yup
        .date()
        .nullable()
        .required()
        .when(["start_date"], (startDate, schema) => {
          return startDate
            ? schema.min(startDate, t("bcr.form.error.endDateStartDate"))
            : schema.required();
        }),
    });
  }, [t]);

  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  const resolver = useYupValidationResolver(schema);

  const form = useForm({ resolver });
  const { setError } = form;

  useEffect(() => {
    form.reset({
      start_date: null,
      end_date: null,
    });
    setMessage("");
  }, [bcrFormDialog.formData]);

  return (
    <Dialog open={bcrFormDialog.isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("bcr.ExportBCR.label")}</DialogTitle>
          <DialogContent>
            {message ? null : (
              <>
                <VSpace height={30} />
                <p>
                  {t(
                    reverse_romanian_label(
                      "Vă rugăm să selectați perioada de emitere a polițelor"
                    )
                  )}
                </p>
                <VSpace height={20} />
              </>
            )}
            <div className={styles.grid}>
              {message ? null : (
                <>
                  <div style={labelStyle(1, 1)}>
                    {t("bcr.Start_date_form.label")}*
                  </div>
                  <div style={fieldStyle(1, 2)}>
                    <DatePickerField
                      isLoading={isLoading}
                      form={form}
                      name="start_date"
                      label={reverse_romanian_label("Start")}
                      data-format="dd.MM.yyyy"
                      data-dateFormat="yyyy-MM-dd"
                      required={true}
                      disabled={false}
                      visible={true}
                    />
                  </div>
                  <div style={labelStyle(2, 1)}>
                    {t("bcr.End_date_form.label")}*
                  </div>
                  <div style={fieldStyle(2, 2)}>
                    <DatePickerField
                      isLoading={isLoading}
                      form={form}
                      name="end_date"
                      label={reverse_romanian_label("Sfârșit")}
                      data-format="dd.MM.yyyy"
                      data-dateFormat="yyyy-MM-dd"
                      required={true}
                      disabled={false}
                      visible={true}
                    />
                  </div>
                </>
              )}
              <div
                style={{
                  ...labelStyle(3, 1),
                  gridColumn: `1 / 3`,
                  margin: "20px",
                  textAlign: "left",
                  color: theme.palette.neutralPrimary,
                }}
              >
                {message}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setListStoreValue("bcrFormDialog", {
                  isOpen: false,
                  formData: null,
                });
              }}
            >
              {t("bcr.close.label")}
            </Button>
            {message ? null : (
              <Button
                appearance="primary"
                disabled={isLoading}
                icon={
                  isLoading ? (
                    <Spinner size="tiny" />
                  ) : (
                    <DocumentBulletListMultipleRegular fontSize={16} />
                  )
                }
                onClick={() => {
                  form.clearErrors();
                  resolverFn(schema, form.getValues()).then((res) => {
                    if (Object.keys(res.errors).length > 0) {
                      Object.keys(res.errors).forEach((key) => {
                        setError(key, res.errors[key]);
                      });
                    } else {
                      submitBcrForm(form.getValues(), t, (responseMessage) => {
                        setMessage(t("bcr.bcrMessage.label"));
                      });
                    }
                  });
                }}
              >
                {t("bcr.Export.label")}
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
