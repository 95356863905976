import React from "react";
import { Checkbox } from "office-ui-fabric-react";

const InsurerSelectionCheckbox = ({
  onChange,
  list,
  insurerName,
  disabled,
}) => {
  let updatedList = list || [];
  return (
    <Checkbox
      disabled={disabled}
      checked={updatedList ? updatedList.includes(insurerName) : false}
      onChange={(e, checked) => {
        updatedList = updatedList.filter((insurer) => insurer !== insurerName);
        if (checked) {
          updatedList.push(insurerName);
        }
        onChange(updatedList);
      }}
    />
  );
};

export default InsurerSelectionCheckbox;
