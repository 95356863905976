import React from "react";
import { useTranslation } from "react-i18next";
import { insurerCasco, insurerMtpl } from "utils/types";

const InsurerField = ({ row, type }) => {
  const { t } = useTranslation();
  const selectedType = type === "casco" ? insurerCasco : insurerMtpl;
  const selectedAttribute = type === "casco" ? "insurerCasco" : "insurerMtpl";

  return (
    <>
      {row?.[selectedAttribute]
        ? t(selectedType[row?.[selectedAttribute]])
        : null}
    </>
  );
};

export default InsurerField;
