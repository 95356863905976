import React from "react";
import { Icon } from "office-ui-fabric-react";
import { CenteredCell } from "components/styled";

const RenewedCell = (props: { value: boolean }) => {
  const { value } = props;
  return (
    <>
      {value ? (
        <Icon iconName="checkmark" style={{ color: "green", flex: 1 }} />
      ) : (
        <Icon iconName="cancel" style={{ color: "red", flex: 1 }} />
      )}
    </>
  );
};
export default RenewedCell;
