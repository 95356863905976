import { getTheme, mergeStyleSets } from "@uifabric/styling";
import {
  Customizer,
  DirectionalHint,
  IStyleSet,
  TooltipHost,
} from "@fluentui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";

type Props = {
  children: React.ReactNode;
  tooltipText?: string;
  isTooltipHidden?: boolean;
  isReadOnly?: boolean;
  styles?: IStyleSet;
  fixedWidth?: boolean;
};
const customize = {
  Tooltip: {
    styles: {
      root: {
        border: "1px solid #eeeeee",
      },
    },
  },
};
export const FieldContainer = ({
  tooltipText,
  isTooltipHidden,
  children,
  isReadOnly,
  styles,
  fixedWidth = true,
}: Props) => {
  // const theme = getTheme();
  const appContext = useAppStoreState();
  const { t } = useTranslation();

  const defaultStyles = {
    root: {
      marginTop: isReadOnly ? 5 : 0, //theme.spacing.m,
      marginBottom: isReadOnly ? 0 : 10, //theme.spacing.m,
      width: fixedWidth ? "350px" : undefined,
    },
  };
  // const [tooltipsStatus, setTooltipsStatus] = useLocalStorage(
  //   "tooltipsStatus",
  //   false
  // );

  const hidden = useMemo(() => {
    return isTooltipHidden || !appContext.appState.headerConfig.tooltipsStatus;
  }, [isTooltipHidden, appContext.appState.headerConfig.tooltipsStatus]);

  const classNames: any = styles
    ? mergeStyleSets(defaultStyles, styles)
    : mergeStyleSets(defaultStyles);
  let tooltipTranslatedText = t(tooltipText?.replaceAll(".label", ".tooltip"));
  if (tooltipTranslatedText.includes(".tooltip")) {
    return <div className={classNames.root}>{children}</div>;
  }
  return (
    <div className={classNames.root}>
      <Customizer scopedSettings={customize}>
        <TooltipHost
          content={tooltipTranslatedText}
          directionalHint={DirectionalHint.rightCenter}
          hidden={hidden}
        >
          {children}
        </TooltipHost>
      </Customizer>
    </div>
  );
};
