export const initialOffer = {
  include_mtpl_offer: false,
  selectedCascoInsurers: null,
  selectedMtplInsurers: null,
  offerId: null,
  offerRequestedAt: null,
  offerRequestType: null,
  offerRequestedBy: null,
  offerRequestedByName: null,
  inp_lessee_type: null,
  inp_vehicle_state: null,
  inp_policy_value: null,
  inp_external_contractdate: null,
  inp_vehicle_construction: null,
  inp_vehicle_firstreg: null,
  inp_vehicle_category: null,
  inp_vehicle_brand: null,
  inp_vehicle_model: null,
  inp_vehicle_maximumgrossweight: null,
  inp_vehicle_places: 0,
  inp_vehicle_carusage: null,
  inp_policy_payment: null,
  inp_leasing_monthsinterval: 0,
  inp_vehicle_fleet: "parc auto intre 0-4",
  inp_policy_currency: null,
  inp_contract_type: null,
  inp_vehicle_fuel: null,
  avarie2: false,
  avarie3: false,
  avarie4: true,
  avarie5: false,
  avarie6: true,
  avarie7: false,
  avarie8: false,
  // inp_asistentarutiera: null,
  avarie9: true,
  avarie10: true,
  inp_discount_p: null,
  inp_discount: 0,
  inp_discount_insurer: null,
  inp_increase_p: null,
  inp_increase: 0,
  inp_lessee_lastname: null,
  inp_lessee_firstname: null,
  inp_lessee_company_name: null,
  inp_lessee_uid: null,
  inp_lessee_compreg: null,
  inp_lessee_street: null,
  inp_lessee_housenr: null,
  inp_lessee_city: null,
  inp_lessee_county: null,
  inp_lessee_mobile: null,
  inp_lessee_email: null,
  inp_vehicle_vin: null,
  inp_vehicle_kw: 0,
  inp_vehicle_cubiccapacity: 0,
  inp_lessee_title: null,
  inp_lessee_building: null,
  inp_lessee_entrance: null,
  inp_lessee_door: null,
  inp_lessee_floor: null,
  inp_lessee_zip: null,
  inp_vehicle_licenseplate: null,
  inp_caen: null,
  inp_start_date: null,
  inp_sicgroup: null,
  inp_vehicle_id: null,
  inp_vehicle_registration: null,
  inp_licence_date: null,
  inp_vehicle_subcategory: null,
  decontare_directa: null,
  inp_driver_compreg: null,
  inp_driver_firstname: null,
  inp_driver_lastname: null,
  inp_driver_number_idcard: null,
  inp_driver_serial_idcard: null,
  inp_driver_phone: null,
  mtpl_commission_broker_p: null,
  omniasig_premium: null,
  omniasig_promotional_premium: null,
  omniasig_partial_premium: null,
  omniasig_partial_monthly_premium: null,
  omniasig_total_premium: null,
  omniasig_total_monthly_premium: null,
  asirom_premium: null,
  asirom_promotional_premium: null,
  asirom_partial_premium: null,
  asirom_partial_monthly_premium: null,
  asirom_total_premium: null,
  asirom_total_monthly_premium: null,
  groupama_premium: null,
  groupama_promotional_premium: null,
  allianz_premium: null,
  allianz_promotional_premium: null,
  generali_premium: null,
  generali_promotional_premium: null,
  uniqa_premium: null,
  uniqa_promotional_premium: null,
  uniqa_partial_premium: null,
  uniqa_partial_monthly_premium: null,
  uniqa_total_premium: null,
  uniqa_total_monthly_premium: null,
  allianz_premium_1: null,
  allianz_premium_1d: null,
  allianz_premium_6: null,
  allianz_premium_6d: null,
  allianz_premium_12: null,
  allianz_premium_12d: null,
  asirom_premium_1: null,
  asirom_premium_1d: null,
  asirom_premium_6: null,
  asirom_premium_6d: null,
  asirom_premium_12: null,
  asirom_premium_12d: null,
  generali_premium_1: null,
  generali_premium_1d: null,
  generali_premium_6: null,
  generali_premium_6d: null,
  generali_premium_12: null,
  generali_premium_12d: null,
  grawe_premium_1: null,
  grawe_premium_1d: null,
  grawe_premium_6: null,
  grawe_premium_6d: null,
  grawe_premium_12: null,
  grawe_premium_12d: null,
  groupama_premium_1: null,
  groupama_premium_1d: null,
  groupama_premium_6: null,
  groupama_premium_6d: null,
  groupama_premium_12: null,
  groupama_premium_12d: null,
  omniasig_premium_1: null,
  omniasig_premium_1d: null,
  omniasig_premium_6: null,
  omniasig_premium_6d: null,
  omniasig_premium_12: null,
  omniasig_premium_12d: null,
  uniqa_premium_1: null,
  uniqa_premium_1d: null,
  uniqa_premium_6: null,
  uniqa_premium_6d: null,
  uniqa_premium_12: null,
  uniqa_premium_12d: null,
  cityinsurance_premium_1: null,
  cityinsurance_premium_1d: null,
  cityinsurance_premium_6: null,
  cityinsurance_premium_6d: null,
  cityinsurance_premium_12: null,
  cityinsurance_premium_12d: null,
  mtplOfferErrorMessage: null,
  cascoOfferErrorMessage: null,
  insurerMtpl: null,
  months: null,
  invoiceMtplId: null,
  invoiceMtplPremium: null,
  insurerCasco: null,
  promotional: false,
  leasingctrno: null,
  deliveryType: null,
  validFrom: null,
  invoiceCascoId: null,
  invoiceCascoPremium: 0,
  invoiceCascoFirstRate: 0,
  cascoInvoiceIssuedAt: null,
  mtplInvoiceIssuedAt: null,
  mtplPolicyErrorMessage: null,
  cascoInvoiceIssuedBy: null,
  cascoInvoiceIssuedByName: null,
  mtplInvoiceIssuedBy: null,
  mtplInvoiceIssuedByName: null,
  paymentDate: null,
  policyMtplId: null,
  policyMtplSeries: null,
  policyMtplPremium: null,
  policyCascoId: null,
  policyCascoTotalId: null,
  policyCascoPartialId: null,
  policyCascoPremium: 0,
  policyCascoTotalPremium: null,
  policyCascoPartialPremium: null,
  cascoPolicyIssuedAt: null,
  cascoPolicyTotalIssuedAt: null,
  cascoPolicyPartialIssuedAt: null,
  mtplPolicyIssuedAt: null,
  cascoPolicyIssuedBy: null,
  cascoPolicyTotalIssuedBy: null,
  cascoPolicyPartialIssuedBy: null,
  cascoPolicyIssuedByName: null,
  cascoPolicyTotalIssuedByName: null,
  cascoPolicyPartialIssuedByName: null,
  mtplPolicyIssuedBy: null,
  mtplPolicyIssuedByName: null,
  lastModifiedAt: null,
  lastModifiedBy: null,
  lastModifiedByName: null,
  status: null,
  comparisonType: null,
  EURExchangeRate: null,
  EURExchangeRateDate: null,
  isRenewed: false,
  pathToOfferPdf: null,
  pathToOfferDocx: null,
  pathToGapOfferPdf: null,
  pathToInvoiceCascoPdf: null,
  pathToInvoiceMtplPdf: null,
  pathToPolicyCascoPdf: null,
  pathToPolicyCascoTotalPdf: null,
  pathToPolicyCascoPartialPdf: null,
  pathToPolicyMtplPdf: null,
  inp_mtpl_nfactor: 0,
  out_mtpl_limits: null,
  out_mtpl_limits_message: null,
  highestRole: "admin",
  isLimitedF100018User: false,
  ALLOW_PARTIAL_CASCO_GUI_FLAG: true,
  leasingctrno_mtpl: null,
};

export const initialVisibilityMap = {
  include_mtpl_offer: false,
  offerId: true,
  offerRequestedAt: true,
  offerRequestType: true,
  offerRequestedBy: true,
  offerRequestedByName: true,
  inp_lessee_type: true,
  inp_vehicle_state: true,
  inp_policy_value: true,
  inp_external_contractdate: true,
  inp_vehicle_construction: true,
  inp_vehicle_firstreg: true,
  inp_vehicle_category: true,
  inp_vehicle_brand: true,
  inp_vehicle_model: true,
  inp_vehicle_maximumgrossweight: true,
  inp_vehicle_places: true,
  inp_vehicle_carusage: true,
  inp_policy_payment: true,
  inp_leasing_monthsinterval: true,
  inp_vehicle_fleet: true,
  inp_policy_currency: true,
  inp_contract_type: true,
  inp_vehicle_fuel: true,
  paymentDate: true,
  avarie2: true,
  avarie3: true,
  avarie4: true,
  avarie5: true,
  avarie6: true,
  avarie7: true,
  avarie8: true,
  // inp_asistentarutiera: true,
  avarie9: true,
  avarie10: true,
  inp_discount_p: true,
  inp_discount: true,
  inp_discount_insurer: false,
  inp_increase_p: true,
  inp_increase: true,
  inp_lessee_lastname: true,
  inp_lessee_firstname: true,
  inp_lessee_company_name: true,
  inp_lessee_uid: true,
  inp_lessee_compreg: true,
  inp_lessee_street: true,
  inp_lessee_housenr: true,
  inp_lessee_city: true,
  inp_lessee_county: true,
  inp_lessee_mobile: true,
  inp_lessee_email: true,
  inp_vehicle_vin: true,
  inp_vehicle_kw: true,
  inp_vehicle_cubiccapacity: true,
  inp_lessee_title: true,
  inp_lessee_building: true,
  inp_lessee_entrance: true,
  inp_lessee_door: true,
  inp_lessee_floor: true,
  inp_lessee_zip: true,
  inp_vehicle_licenseplate: true,
  inp_caen: true,
  inp_start_date: true,
  inp_sicgroup: true,
  inp_vehicle_id: true,
  inp_vehicle_registration: true,
  inp_licence_date: true,
  inp_vehicle_subcategory: true,
  decontare_directa: true,
  inp_driver_compreg: true,
  inp_driver_firstname: true,
  inp_driver_lastname: true,
  inp_driver_number_idcard: true,
  inp_driver_serial_idcard: true,
  inp_driver_phone: true,
  mtpl_commission_broker_p: true,
  omniasig_premium: true,
  omniasig_promotional_premium: true,
  omniasig_partial_premium: true,
  omniasig_partial_monthly_premium: true,
  omniasig_total_premium: true,
  omniasig_total_monthly_premium: true,
  asirom_premium: true,
  asirom_promotional_premium: true,
  asirom_partial_premium: true,
  asirom_partial_monthly_premium: true,
  asirom_total_premium: true,
  asirom_total_monthly_premium: true,
  groupama_premium: true,
  groupama_promotional_premium: true,
  groupama_partial_premium: true,
  groupama_total_premium: true,
  allianz_premium: true,
  allianz_promotional_premium: true,
  generali_premium: true,
  generali_partial_premium: true,
  generali_total_premium: true,
  generali_promotional_premium: true,
  uniqa_premium: true,
  uniqa_promotional_premium: true,
  uniqa_partial_premium: true,
  uniqa_partial_monthly_premium: true,
  uniqa_total_premium: true,
  uniqa_total_monthly_premium: true,
  allianz_premium_1: true,
  allianz_premium_1d: true,
  allianz_premium_6: true,
  allianz_premium_6d: true,
  allianz_premium_12: true,
  allianz_premium_12d: true,
  asirom_premium_1: true,
  asirom_premium_1d: true,
  asirom_premium_6: true,
  asirom_premium_6d: true,
  asirom_premium_12: true,
  asirom_premium_12d: true,
  generali_premium_1: true,
  generali_premium_1d: true,
  generali_premium_6: true,
  generali_premium_6d: true,
  generali_premium_12: true,
  generali_premium_12d: true,
  grawe_premium_1: true,
  grawe_premium_1d: true,
  grawe_premium_6: true,
  grawe_premium_6d: true,
  grawe_premium_12: true,
  grawe_premium_12d: true,
  groupama_premium_1: true,
  groupama_premium_1d: true,
  groupama_premium_6: true,
  groupama_premium_6d: true,
  groupama_premium_12: true,
  groupama_premium_12d: true,
  omniasig_premium_1: true,
  omniasig_premium_1d: true,
  omniasig_premium_6: true,
  omniasig_premium_6d: true,
  omniasig_premium_12: true,
  omniasig_premium_12d: true,
  uniqa_premium_1: true,
  uniqa_premium_1d: true,
  uniqa_premium_6: true,
  uniqa_premium_6d: true,
  uniqa_premium_12: true,
  uniqa_premium_12d: true,
  cityinsurance_premium_1: true,
  cityinsurance_premium_1d: true,
  cityinsurance_premium_6: true,
  cityinsurance_premium_6d: true,
  cityinsurance_premium_12: true,
  cityinsurance_premium_12d: true,
  mtplOfferErrorMessage: true,
  cascoOfferErrorMessage: true,
  insurerMtpl: true,
  months: true,
  invoiceMtplId: true,
  invoiceMtplPremium: true,
  insurerCasco: true,
  promotional: true,
  leasingctrno: true,
  deliveryType: true,
  validFrom: true,
  invoiceCascoId: true,
  invoiceCascoPremium: true,
  invoiceCascoFirstRate: true,
  cascoInvoiceIssuedAt: true,
  mtplInvoiceIssuedAt: true,
  mtplPolicyErrorMessage: true,
  cascoInvoiceIssuedBy: true,
  cascoInvoiceIssuedByName: true,
  mtplInvoiceIssuedBy: true,
  mtplInvoiceIssuedByName: true,
  policyMtplId: true,
  policyMtplSeries: true,
  policyMtplPremium: true,
  policyCascoId: true,
  policyCascoTotalId: false,
  policyCascoPartialId: false,
  policyCascoPremium: true,
  policyCascoTotalPremium: false,
  policyCascoPartialPremium: false,
  cascoPolicyIssuedAt: true,
  cascoPolicyTotalIssuedAt: false,
  cascoPolicyPartialIssuedAt: true,
  mtplPolicyIssuedAt: true,
  cascoPolicyIssuedBy: true,
  cascoPolicyTotalIssuedBy: true,
  cascoPolicyPartialIssuedBy: true,
  cascoPolicyIssuedByName: true,
  cascoPolicyTotalIssuedByName: false,
  cascoPolicyPartialIssuedByName: false,
  mtplPolicyIssuedBy: true,
  mtplPolicyIssuedByName: true,
  lastModifiedAt: true,
  lastModifiedBy: true,
  lastModifiedByName: true,
  status: true,
  comparisonType: true,
  EURExchangeRate: true,
  EURExchangeRateDate: true,
  isRenewed: false,
  pathToOfferPdf: true,
  pathToOfferDocx: true,
  pathToGapOfferPdf: true,
  pathToInvoiceCascoPdf: true,
  pathToInvoiceMtplPdf: true,
  pathToPolicyCascoPdf: true,
  pathToPolicyCascoTotalPdf: true,
  pathToPolicyCascoPartialPdf: true,
  pathToPolicyMtplPdf: true,
  inp_mtpl_nfactor: true,
  out_mtpl_limits: true,
  out_mtpl_limits_message: true,
  highestRole: true,
  isLimitedF100018User: true,
  ALLOW_PARTIAL_CASCO_GUI_FLAG: true,
  leasingctrno_mtpl: true,
  calculateCasco: true,
  calculateCascoAndMtpl: false,
  getOffer: false,
  confirmationForInvalidateOffer: true,
  invalidateOffer: false,
  generateCascoInvoice: false,
  downloadCascoInvoice: true,
  inp_vehicle_vin2: true,
  confirmationForCascoPolicyIssuing: true,
  htmlCascoPolicyIssuingWarning: true,
  issueCascoPolicy: false,
  downloadCascoPolicy: true,
  downloadCascoPolicyTotal: false,
  downloadCascoPolicyPartial: true,
  confirmationForCascoPolicyTotalIssuing: false,
  confirmationForCascoPolicyPartialIssuing: false,
  issueCascoPolicyTotal: false,
  htmlCascoPolicyPartialIssuingWarning: false,
  issueCascoPolicyPartial: false,
  generateMtplInvoice: true,
  confirmationForMtplPolicyIssuing: true,
  issueMtplPolicy: true,
  htmlMtplPolicyIssuingWarning: true,
  htmlMtplPolicyErrorMessage: true,
  btnErrorMessage: false,
  downloadOffer: false,
  downloadGapOffer: true,
  duplicateOffer: false,
  downloadMtplInvoice: false,
  downloadMtplPolicy: false,
  btnBackToListBottom: false,
  htmlCascoPolicyTotalIssuingWarning: false,
};
export const initialRequiredMap = {
  include_mtpl_offer: false,
  offerId: false,
  offerRequestedAt: false,
  offerRequestType: false,
  offerRequestedBy: false,
  offerRequestedByName: false,
  decontare_directa: true,
  leasingctrno_mtpl: true,

  inp_lessee_type: true,
  inp_contract_type: true,
  inp_policy_currency: false,
  inp_policy_value: false,
  inp_leasing_monthsinterval: false,
  inp_external_contractdate: false,
  inp_policy_payment: false,
  mtpl_commission_broker_p: false,
  inp_start_date: false,

  inp_vehicle_state: true,
  inp_vehicle_brand: true,
  inp_vehicle_model: true,
  inp_vehicle_fuel: true,
  inp_vehicle_construction: true,
  inp_vehicle_firstreg: true,
  inp_vehicle_maximumgrossweight: true,
  inp_vehicle_places: true,
  inp_vehicle_vin: true,
  inp_vehicle_kw: true,
  inp_vehicle_cubiccapacity: true,
  inp_vehicle_registration: true,
  inp_vehicle_licenseplate: true,
  inp_licence_date: false,

  inp_vehicle_category: true,
  inp_vehicle_subcategory: false,
  inp_vehicle_carusage: true,
  inp_vehicle_fleet: true,

  inp_lessee_lastname: true,
  inp_lessee_firstname: true,
  inp_lessee_company_name: true,
  inp_lessee_uid: true,
  inp_lessee_compreg: true,

  inp_lessee_street: true,
  inp_lessee_housenr: true,
  inp_lessee_city: true,
  inp_lessee_county: true,
  inp_lessee_building: false,
  inp_lessee_entrance: false,
  inp_lessee_door: false,
  inp_lessee_floor: false,
  inp_lessee_mobile: true,
  inp_lessee_email: true,

  avarie2: false,
  avarie3: false,
  avarie4: false,
  avarie5: false,
  avarie6: false,
  avarie7: false,
  avarie8: false,
  // inp_asistentarutiera: false,
  avarie9: false,
  avarie10: false,
  inp_discount_p: false,
  inp_discount: false,
  inp_discount_insurer: false,
  inp_increase_p: false,
  inp_increase: false,

  inp_lessee_title: false,

  inp_lessee_zip: false,
  inp_caen: false,
  inp_sicgroup: true,
  inp_vehicle_id: true,
  inp_driver_compreg: false,
  inp_driver_firstname: false,
  inp_driver_lastname: false,
  inp_driver_number_idcard: false,
  inp_driver_serial_idcard: false,
  inp_driver_phone: false,
  omniasig_premium: true,
  omniasig_promotional_premium: true,
  omniasig_partial_premium: true,
  omniasig_partial_monthly_premium: true,
  omniasig_total_premium: true,
  omniasig_total_monthly_premium: true,
  asirom_premium: true,
  asirom_promotional_premium: true,
  asirom_partial_premium: true,
  asirom_partial_monthly_premium: true,
  asirom_total_premium: true,
  asirom_total_monthly_premium: true,
  groupama_premium: true,
  groupama_promotional_premium: true,
  groupama_partial_premium: true,
  groupama_total_premium: true,
  allianz_premium: false,
  allianz_promotional_premium: true,
  generali_premium: true,
  generali_partial_premium: true,
  generali_total_premium: true,
  generali_promotional_premium: true,
  uniqa_premium: true,
  uniqa_promotional_premium: true,
  uniqa_partial_premium: true,
  uniqa_partial_monthly_premium: true,
  uniqa_total_premium: true,
  uniqa_total_monthly_premium: true,
  allianz_premium_1: false,
  allianz_premium_1d: false,
  allianz_premium_6: true,
  allianz_premium_6d: true,
  allianz_premium_12: true,
  allianz_premium_12d: true,
  asirom_premium_1: false,
  asirom_premium_1d: false,
  asirom_premium_6: true,
  asirom_premium_6d: true,
  asirom_premium_12: true,
  asirom_premium_12d: true,
  generali_premium_1: false,
  generali_premium_1d: false,
  generali_premium_6: true,
  generali_premium_6d: true,
  generali_premium_12: true,
  generali_premium_12d: true,
  grawe_premium_1: false,
  grawe_premium_1d: false,
  grawe_premium_6: true,
  grawe_premium_6d: true,
  grawe_premium_12: true,
  grawe_premium_12d: true,
  groupama_premium_1: false,
  groupama_premium_1d: false,
  groupama_premium_6: true,
  groupama_premium_6d: true,
  groupama_premium_12: true,
  groupama_premium_12d: true,
  omniasig_premium_1: false,
  omniasig_premium_1d: false,
  omniasig_premium_6: true,
  omniasig_premium_6d: true,
  omniasig_premium_12: true,
  omniasig_premium_12d: true,
  uniqa_premium_1: false,
  uniqa_premium_1d: false,
  uniqa_premium_6: false,
  uniqa_premium_6d: false,
  uniqa_premium_12: false,
  uniqa_premium_12d: false,
  cityinsurance_premium_1: false,
  cityinsurance_premium_1d: false,
  cityinsurance_premium_6: false,
  cityinsurance_premium_6d: false,
  cityinsurance_premium_12: false,
  cityinsurance_premium_12d: false,

  mtplOfferErrorMessage: false,
  cascoOfferErrorMessage: false,
  insurerMtpl: true,
  months: true,
  insurerCasco: true,
  promotional: true,
  leasingctrno: true,
  deliveryType: true,
  validFrom: true,

  invoiceCascoId: false,
  invoiceCascoPremium: false,
  invoiceCascoFirstRate: false,
  cascoInvoiceIssuedAt: false,
  cascoInvoiceIssuedBy: false,
  cascoInvoiceIssuedByName: false,

  invoiceMtplId: false,
  invoiceMtplPremium: false,
  mtplInvoiceIssuedAt: false,
  mtplPolicyErrorMessage: false,
  mtplInvoiceIssuedBy: false,
  mtplInvoiceIssuedByName: false,

  confirmationForMtplPolicyIssuing: true,
  policyMtplSeries: false,
  policyMtplId: false,
  policyMtplPremium: false,
  mtplPolicyIssuedAt: false,
  mtplPolicyIssuedBy: false,
  mtplPolicyIssuedByName: false,

  paymentDate: true,
  policyCascoId: false,
  policyCascoTotalId: true,
  policyCascoPartialId: true,
  policyCascoPremium: false,
  policyCascoTotalPremium: true,
  policyCascoPartialPremium: true,
  cascoPolicyIssuedAt: false,
  cascoPolicyTotalIssuedAt: true,
  cascoPolicyPartialIssuedAt: true,
  cascoPolicyIssuedBy: false,
  cascoPolicyTotalIssuedBy: false,
  cascoPolicyPartialIssuedBy: false,
  cascoPolicyIssuedByName: false,
  cascoPolicyTotalIssuedByName: true,
  cascoPolicyPartialIssuedByName: true,

  lastModifiedAt: false,
  lastModifiedBy: false,
  lastModifiedByName: false,
  status: false,
  comparisonType: false,
  EURExchangeRate: false,
  EURExchangeRateDate: false,
  isRenewed: false,
  pathToOfferPdf: false,
  pathToOfferDocx: false,
  pathToGapOfferPdf: false,
  pathToInvoiceCascoPdf: false,
  pathToInvoiceMtplPdf: false,
  pathToPolicyCascoPdf: false,
  pathToPolicyCascoTotalPdf: false,
  pathToPolicyCascoPartialPdf: false,
  pathToPolicyMtplPdf: false,
  inp_mtpl_nfactor: false,
  out_mtpl_limits: false,
  out_mtpl_limits_message: false,
  highestRole: false,
  isLimitedF100018User: false,
  ALLOW_PARTIAL_CASCO_GUI_FLAG: false,
  calculateCasco: false,
  calculateCascoAndMtpl: false,
  getOffer: false,
  confirmationForInvalidateOffer: false,
  invalidateOffer: false,
  generateCascoInvoice: false,
  downloadCascoInvoice: false,
  inp_vehicle_vin2: false,
  confirmationForCascoPolicyIssuing: false,
  htmlCascoPolicyIssuingWarning: false,
  issueCascoPolicy: false,
  downloadCascoPolicy: false,
  downloadCascoPolicyTotal: false,
  downloadCascoPolicyPartial: false,
  confirmationForCascoPolicyTotalIssuing: false,
  confirmationForCascoPolicyPartialIssuing: false,
  issueCascoPolicyTotal: false,
  htmlCascoPolicyPartialIssuingWarning: false,
  issueCascoPolicyPartial: false,
  generateMtplInvoice: false,
  issueMtplPolicy: false,
  htmlMtplPolicyIssuingWarning: false,
  htmlMtplPolicyErrorMessage: false,
  btnErrorMessage: false,
  downloadOffer: false,
  downloadGapOffer: false,
  duplicateOffer: false,
  downloadMtplInvoice: false,
  downloadMtplPolicy: false,
  btnBackToListBottom: false,
  htmlCascoPolicyTotalIssuingWarning: false,
};

export const initialDisableMap = {
  include_mtpl_offer: false,
  offerId: true,
  offerRequestedAt: true,
  offerRequestType: false,
  offerRequestedBy: false,
  offerRequestedByName: true,
  inp_lessee_type: false,
  inp_vehicle_state: false,
  inp_policy_value: false,
  inp_external_contractdate: false,
  inp_vehicle_construction: false,
  inp_vehicle_firstreg: false,
  inp_vehicle_category: false,
  inp_vehicle_brand: false,
  inp_vehicle_model: false,
  inp_vehicle_maximumgrossweight: false,
  inp_vehicle_places: false,
  inp_vehicle_carusage: false,
  inp_policy_payment: false,
  inp_leasing_monthsinterval: false,
  inp_vehicle_fleet: false,
  inp_policy_currency: false,
  inp_contract_type: false,
  inp_vehicle_fuel: false,
  avarie2: false,
  avarie3: false,
  avarie4: false,
  avarie5: false,
  avarie6: false,
  avarie7: false,
  avarie8: false,
  // inp_asistentarutiera: false,
  avarie9: false,
  avarie10: false,
  inp_discount_p: false,
  inp_discount: false,
  inp_discount_insurer: false,
  inp_increase_p: false,
  inp_increase: false,
  inp_lessee_lastname: false,
  inp_lessee_firstname: false,
  inp_lessee_company_name: false,
  inp_lessee_uid: false,
  inp_lessee_compreg: false,
  inp_lessee_street: false,
  inp_lessee_housenr: false,
  inp_lessee_city: false,
  inp_lessee_county: false,
  inp_lessee_mobile: false,
  inp_lessee_email: false,
  inp_vehicle_vin: false,
  inp_vehicle_kw: false,
  inp_vehicle_cubiccapacity: false,
  inp_lessee_title: false,
  inp_lessee_building: false,
  inp_lessee_entrance: false,
  inp_lessee_door: false,
  inp_lessee_floor: false,
  inp_lessee_zip: false,
  inp_vehicle_licenseplate: false,
  inp_caen: false,
  inp_start_date: false,
  inp_sicgroup: false,
  inp_vehicle_id: false,
  inp_vehicle_registration: false,
  inp_licence_date: false,
  inp_vehicle_subcategory: false,
  decontare_directa: false,
  inp_driver_compreg: false,
  inp_driver_firstname: false,
  inp_driver_lastname: false,
  inp_driver_number_idcard: false,
  inp_driver_serial_idcard: false,
  inp_driver_phone: false,
  mtpl_commission_broker_p: false,
  omniasig_premium: true,
  omniasig_promotional_premium: true,
  omniasig_partial_premium: true,
  omniasig_partial_monthly_premium: true,
  omniasig_total_premium: true,
  omniasig_total_monthly_premium: true,
  asirom_premium: true,
  asirom_promotional_premium: true,
  asirom_partial_premium: true,
  asirom_partial_monthly_premium: true,
  asirom_total_premium: true,
  asirom_total_monthly_premium: true,
  groupama_premium: true,
  groupama_promotional_premium: true,
  groupama_partial_premium: true,
  groupama_total_premium: true,
  allianz_premium: true,
  allianz_promotional_premium: true,
  generali_premium: true,
  generali_partial_premium: true,
  generali_total_premium: true,
  generali_promotional_premium: true,
  uniqa_premium: true,
  uniqa_promotional_premium: true,
  uniqa_partial_premium: true,
  uniqa_partial_monthly_premium: true,
  uniqa_total_premium: true,
  uniqa_total_monthly_premium: true,
  allianz_premium_1: true,
  allianz_premium_1d: true,
  allianz_premium_6: true,
  allianz_premium_6d: true,
  allianz_premium_12: true,
  allianz_premium_12d: true,
  asirom_premium_1: true,
  asirom_premium_1d: true,
  asirom_premium_6: true,
  asirom_premium_6d: true,
  asirom_premium_12: true,
  asirom_premium_12d: true,
  generali_premium_1: true,
  generali_premium_1d: true,
  generali_premium_6: true,
  generali_premium_6d: true,
  generali_premium_12: true,
  generali_premium_12d: true,
  grawe_premium_1: true,
  grawe_premium_1d: true,
  grawe_premium_6: true,
  grawe_premium_6d: true,
  grawe_premium_12: true,
  grawe_premium_12d: true,
  groupama_premium_1: true,
  groupama_premium_1d: true,
  groupama_premium_6: true,
  groupama_premium_6d: true,
  groupama_premium_12: true,
  groupama_premium_12d: true,
  omniasig_premium_1: true,
  omniasig_premium_1d: true,
  omniasig_premium_6: true,
  omniasig_premium_6d: true,
  omniasig_premium_12: true,
  omniasig_premium_12d: true,
  uniqa_premium_1: false,
  uniqa_premium_1d: false,
  uniqa_premium_6: false,
  uniqa_premium_6d: false,
  uniqa_premium_12: false,
  uniqa_premium_12d: false,
  cityinsurance_premium_1: false,
  cityinsurance_premium_1d: false,
  cityinsurance_premium_6: false,
  cityinsurance_premium_6d: false,
  cityinsurance_premium_12: false,
  cityinsurance_premium_12d: false,
  mtplOfferErrorMessage: false,
  cascoOfferErrorMessage: false,
  insurerMtpl: false,
  months: false,
  invoiceMtplId: true,
  invoiceMtplPremium: true,
  insurerCasco: false,
  promotional: false,
  leasingctrno: false,
  deliveryType: false,
  validFrom: false,
  invoiceCascoId: true,
  invoiceCascoPremium: true,
  invoiceCascoFirstRate: true,
  cascoInvoiceIssuedAt: true,
  mtplInvoiceIssuedAt: true,
  mtplPolicyErrorMessage: false,
  cascoInvoiceIssuedBy: false,
  cascoInvoiceIssuedByName: true,
  mtplInvoiceIssuedBy: false,
  mtplInvoiceIssuedByName: true,
  paymentDate: false,
  policyMtplId: true,
  policyMtplSeries: true,
  policyMtplPremium: true,
  policyCascoId: true,
  policyCascoTotalId: true,
  policyCascoPartialId: true,
  policyCascoPremium: true,
  policyCascoTotalPremium: true,
  policyCascoPartialPremium: true,
  cascoPolicyIssuedAt: true,
  cascoPolicyTotalIssuedAt: true,
  cascoPolicyPartialIssuedAt: true,
  mtplPolicyIssuedAt: true,
  cascoPolicyIssuedBy: false,
  cascoPolicyTotalIssuedBy: false,
  cascoPolicyPartialIssuedBy: false,
  cascoPolicyIssuedByName: true,
  cascoPolicyTotalIssuedByName: true,
  cascoPolicyPartialIssuedByName: true,
  mtplPolicyIssuedBy: false,
  mtplPolicyIssuedByName: true,
  lastModifiedAt: false,
  lastModifiedBy: false,
  lastModifiedByName: false,
  status: false,
  comparisonType: false,
  EURExchangeRate: true,
  EURExchangeRateDate: true,
  isRenewed: false,
  pathToOfferPdf: false,
  pathToOfferDocx: false,
  pathToGapOfferPdf: false,
  pathToInvoiceCascoPdf: false,
  pathToInvoiceMtplPdf: false,
  pathToPolicyCascoPdf: false,
  pathToPolicyCascoTotalPdf: false,
  pathToPolicyCascoPartialPdf: false,
  pathToPolicyMtplPdf: false,
  inp_mtpl_nfactor: false,
  out_mtpl_limits: true,
  out_mtpl_limits_message: true,
  highestRole: false,
  isLimitedF100018User: false,
  ALLOW_PARTIAL_CASCO_GUI_FLAG: false,
  leasingctrno_mtpl: false,
  calculateCasco: false,
  calculateCascoAndMtpl: false,
  getOffer: false,
  confirmationForInvalidateOffer: false,
  invalidateOffer: false,
  generateCascoInvoice: false,
  downloadCascoInvoice: false,
  inp_vehicle_vin2: false,
  confirmationForCascoPolicyIssuing: false,
  htmlCascoPolicyIssuingWarning: false,
  issueCascoPolicy: false,
  downloadCascoPolicy: false,
  downloadCascoPolicyTotal: false,
  downloadCascoPolicyPartial: false,
  confirmationForCascoPolicyTotalIssuing: false,
  confirmationForCascoPolicyPartialIssuing: false,
  issueCascoPolicyTotal: false,
  htmlCascoPolicyPartialIssuingWarning: false,
  issueCascoPolicyPartial: false,
  generateMtplInvoice: false,
  confirmationForMtplPolicyIssuing: false,
  issueMtplPolicy: false,
  htmlMtplPolicyIssuingWarning: false,
  htmlMtplPolicyErrorMessage: false,
  btnErrorMessage: false,
  downloadOffer: false,
  downloadGapOffer: false,
  duplicateOffer: false,
  downloadMtplInvoice: false,
  downloadMtplPolicy: false,
  btnBackToListBottom: false,
  htmlCascoPolicyTotalIssuingWarning: false,
};
