import { useTheme } from "styled-components";
import { Button, Spinner, makeStyles } from "@fluentui/react-components";
import { VSpace } from "components/Spacer";
import CheckboxField from "components/controls9/CheckboxField";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import SelectField from "components/controls9/SelectField";
import TextInputField from "components/controls9/TextInputField";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { TabProps } from "types/types";
import { resolverFn } from "utils/setYupLocale";
import { reverse_romanian_label } from "utils/types";
import { fieldToTab, getClasses, prepareFormItemsForSubmit } from "utils/utils";
import * as yup from "yup";
import TabCard from "../TabCard";
import { MessageBarType } from "office-ui-fabric-react";
import { MoneyHandFilled } from "@fluentui/react-icons";
import { toast } from "components/FluentToast";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const InvoiceTab2 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { getValues, setError, clearErrors } = props.form;

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const [
    { romanianTranslations },
    { setNotificationMessage, clearAllNotifications },
  ] = useAppStore();

  const [
    { selectedProduct, months, insurerMtplList, progress, mainTab, invoiceTab },
    { setFormStoreValues, generateMtplInvoice },
  ] = formPageStore;

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.mtplInvoiceCreated;
  const theme = useTheme();
  const isDarkMode = theme.isDark;
  const navigate = useNavigate();

  const classes = getClasses(theme, isDarkMode);
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  const cardFooter = (
    <>
      {progress.mtplInvoiceCreated ? (
        <Button
          icon={
            isLoading ? (
              <Spinner size="tiny" />
            ) : (
              <MoneyHandFilled fontSize={16} />
            )
          }
          appearance="primary"
          disabled={disabledMap?.downloadCascoInvoice}
          name="downloadCascoInvoice"
          onClick={() => {
            window.open(getValues("pathToInvoiceMtplPdf"), "_blank");
          }}
          // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToInvoiceCascoPdf,'bcrcascoinvoice');")}}
        >
          {t(reverse_romanian_label("Decont"))}
        </Button>
      ) : null}
      {!progress.mtplInvoiceCreated ? (
        <Button
          icon={
            isLoading ? (
              <Spinner size="tiny" />
            ) : (
              <MoneyHandFilled fontSize={16} />
            )
          }
          appearance="primary"
          // isLoading={isLoading}
          name="generateMtplInvoice"
          // visible={visibleMap?.generateCascoInvoice}
          disabled={
            isLoading || disabledTab || disabledMap?.generateMtplInvoice
          }
          onClick={() => {
            clearErrors();
            clearAllNotifications();
            resolverFn(
              selectedProduct?.validationMap["invoiceTab2"](yup, t),
              getValues()
            ).then((res) => {
              if (Object.keys(res.errors).length > 0) {
                toast.error(t("bcr.validationErrors.label"));
                Object.keys(res.errors).forEach((key) => {
                  setError(key, res.errors[key]);
                  setNotificationMessage({
                    errors: [],
                    key: key,
                    messageBarType: MessageBarType.error,
                    fieldName: t("bcr." + key + ".label"),
                    handleNotificationClick: (e) => {
                      setFormStoreValues({
                        mainTab: { ...mainTab, selectedTab: "invoice" },
                        invoiceTab: {
                          ...invoiceTab,
                          selectedTab: fieldToTab(key),
                        },
                      });
                    },
                    notify: {
                      label: "label",
                      type: "error",
                      notifyCode: "notifyCode",
                      notifyText: (
                        <>
                          <b>{t("bcr." + key + ".label")}</b>
                          <br />
                          {res.errors[key].message}
                        </>
                      ),
                    },
                  });
                });
              } else {
                generateMtplInvoice(
                  {
                    appInfo: {
                      dataObj: {
                        ...prepareFormItemsForSubmit(
                          getValues(),
                          romanianTranslations
                        ),
                      },
                      resolvedProperties: {
                        title: "BCR Leasing",
                      },
                    },
                  },
                  (retVal) => {
                    if (retVal.pathToInvoiceMtplPdf) {
                      window.open(retVal.pathToInvoiceMtplPdf, "_blank");
                    }
                    window.location.reload();
                  },
                  (err) => {
                    setNotificationMessage({
                      errors: [],
                      key: "key",
                      messageBarType: MessageBarType.error,
                      fieldName: "fieldName",
                      handleNotificationClick: (e) => {},
                      notify: {
                        label: "label",
                        type: "error",
                        notifyCode: "notifyCode",
                        notifyText: (
                          <>
                            <b>{"GenerateMTPLInvoice"}</b>
                            <br />
                            {err.response.data.Message}
                          </>
                        ),
                      },
                    });
                  }
                );
              }
            });
          }}
          // "action success script": "formioForm.checkConditions();"
        >
          {t(reverse_romanian_label("Generați decont"))}
        </Button>
      ) : null}
    </>
  );
  if (!selectedProduct) return null;

  return (
    <>
      <TabCard footer={cardFooter}>
        {/* {!disabledTab ? (
          <>
            <MtplPremiumTable form={props.form} />
            <VSpace height={20} />
          </>
        ) : null} */}
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label("Asigurator"))}
            {requiredMap.insurerMtpl ? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="insurerMtpl"
              label={t(reverse_romanian_label("Asigurator"))}
              options={insurerMtplList}
              required={true}
              disabled={disabledTab || disabledMap?.insurerMtpl}
              visible={visibleMap?.insurerMtpl}
            />
          </div>
          <div style={labelStyle(2, 1)}>
            {t(reverse_romanian_label("Perioada de asigurare (luni)"))}
            {requiredMap.months ? "*" : ""}
          </div>
          <div style={fieldStyle(2, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="months"
              label={t(reverse_romanian_label("Perioada de asigurare (luni)"))}
              options={months || []}
              required={true}
              disabled={disabledTab || disabledMap?.months}
              visible={visibleMap?.months}
            />
          </div>
          <div style={labelStyle(3, 1)}>
            {t(reverse_romanian_label("Decontare directa"))}
            {requiredMap.decontare_directa ? "*" : ""}
          </div>
          <div style={fieldStyle(3, 2)}>
            <CheckboxField
              isLoading={isLoading}
              form={props.form}
              name="decontare_directa"
              label={reverse_romanian_label("Decontare directa")}
              required={false}
              disabled={disabledTab || disabledMap?.decontare_directa}
              visible={visibleMap?.decontare_directa}
            />
          </div>
          <div style={labelStyle(4, 1)}>
            {t(reverse_romanian_label("Numar contract leasing"))}
            {requiredMap.leasingctrno_mtpl ? "*" : ""}
          </div>
          <div style={fieldStyle(4, 2)}>
            <TextInputField
              isLoading={isLoading}
              name="leasingctrno_mtpl"
              label={reverse_romanian_label("Numar contract leasing")}
              form={props.form}
              required={false}
              disabled={
                disabledTab ||
                disabledMap?.leasingctrno_mtpl ||
                progress?.cascoInvoiceCreated
              }
              visible={visibleMap?.leasingctrno_mtpl}
            />
          </div>
        </div>

        {/* <div style={labelStyle(4, 1)}>
            {t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}
          </div>
          <div style={fieldStyle(4, 2)}>
            <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Generați decont"))}
              name="generateMtplInvoice"
              visible={visibleMap?.generateMtplInvoice}
              disabled={disabledTab || disabledMap?.generateMtplInvoice}
              onClick={() => {
                generateMtplInvoice(
                  {
                    appInfo: {
                      dataObj: {
                        ...prepareFormItemsForSubmit(
                          getValues(),
                          romanianTranslations
                        ),
                      },
                      resolvedProperties: {
                        title: "BCR Leasing",
                      },
                    },
                  },
                  (retVal) => {
                    disableWhenInvoiceMtplIdExists({
                      data: props.form.getValues(),
                      form: props.form,
                      formPageStore: pageFormStore,
                    });
                    const preparedRetVal = prepareFormReturnValue(retVal, t);
                    for (const key in preparedRetVal) {
                      setValue(key, preparedRetVal[key]);
                    }
                  }
                );
              }}
              // "action success script": "formioForm.checkConditions();"
            />
          </div> */}
        {progress.mtplInvoiceCreated ? (
          <>
            <VSpace height={20} />
            <hr />
            <VSpace height={20} />
            <div className={styles.grid}>
              <div style={labelStyle(1, 1)}></div>
              <div style={labelStyle(1, 1)}>
                {t(reverse_romanian_label("Decont nr."))}
                {requiredMap.invoiceMtplId ? "*" : ""}
              </div>
              <div style={fieldStyle(1, 2)}>
                <TextInputField
                  isLoading={isLoading}
                  name="invoiceMtplId"
                  label={reverse_romanian_label("Decont nr.")}
                  form={props.form}
                  required={false}
                  disabled={disabledTab || disabledMap?.invoiceMtplId}
                  visible={visibleMap?.invoiceMtplId}
                />
              </div>
              <div style={labelStyle(2, 1)}>
                {t(reverse_romanian_label("Prima"))}
                {requiredMap.invoiceMtplPremium ? "*" : ""}
              </div>
              <div style={fieldStyle(2, 2)}>
                <NumberInputField
                  isLoading={isLoading}
                  name="invoiceMtplPremium"
                  label={reverse_romanian_label("Prima")}
                  form={props.form}
                  required={false}
                  disabled={disabledTab || disabledMap?.invoiceMtplPremium}
                  isNegativeAllowed={false}
                  visible={visibleMap?.invoiceMtplPremium}
                  contentAfter={"RON"}
                />
              </div>
              <div style={labelStyle(3, 1)}>
                {t(reverse_romanian_label("Decont emis la:"))}
                {requiredMap.mtplInvoiceIssuedAt ? "*" : ""}
              </div>
              <div style={fieldStyle(3, 2)}>
                <DatePickerField
                  isLoading={isLoading}
                  form={props.form}
                  name="mtplInvoiceIssuedAt"
                  label={reverse_romanian_label("Decont emis la:")}
                  disabled={disabledTab || disabledMap?.mtplInvoiceIssuedAt}
                  data-format="dd.MM.yyyy HH:mm:ss"
                  data-dateFormat="DD.MM.YYYY HH:mm"
                  required={false}
                  visible={visibleMap?.mtplInvoiceIssuedAt}
                  includeTime={true}
                />
              </div>
              <div style={labelStyle(4, 1)}>
                {t(reverse_romanian_label("Decont emis de:"))}
                {requiredMap.mtplInvoiceIssuedByName ? "*" : ""}
              </div>
              <div style={fieldStyle(4, 2)}>
                <TextInputField
                  isLoading={isLoading}
                  name="mtplInvoiceIssuedByName"
                  label={reverse_romanian_label("Decont emis de:")}
                  form={props.form}
                  required={false}
                  disabled={disabledTab || disabledMap?.mtplInvoiceIssuedByName}
                  visible={visibleMap?.mtplInvoiceIssuedByName}
                />
              </div>
            </div>
          </>
        ) : null}
      </TabCard>
    </>
  );
};

export default InvoiceTab2;
