import React, { useEffect, useState, Suspense } from "react";
import "./App.css";
import Logo from "../src/assets/GrECo_Logo_small.jpg";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

// --- Configurations
import { headerConfig } from "./config/headerConfig";
// --- API
import { logout, getUserPhotoSmall } from "./api/GraphService";
import { ErrorMessage, GlobalStyles, GrecoSpinner } from "@greco/components";
// --- Greco Components
// --- Routes
import { routes } from "./config/routes";
// --- Application context
import { AppContextProvider } from "contexts/appContext";

import { getUserData } from "./api/UserDataLoader";
import AppHeader from "./components/Header/AppHeader";
import { GrecoErrorPage } from "@greco/components";
// --- Theme
import { ThemeProvider } from "@greco/components";
import { loadTheme } from "@fluentui/react";
import {
  FluentProvider,
  webLightTheme,
  webDarkTheme,
} from "@fluentui/react-components";
import { ToastContainer } from "react-toastify";
// --- Insights
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./insights";
import i18next from "i18next";
import { useAppStore, useAppStoreActions } from "store/AppStore";
import { darkStyledTheme, styledTheme } from "theme";
import {
  ThemeProvider as StyledThemeProvider,
  styled,
} from "styled-components";

interface IState {
  headerConfig: any;
  panelIsOpen: boolean;
  user: any;
  error: string;
  loading: boolean;
  search: string;
  darkMode: boolean;
}

export const AppComponent = () => {
  const [
    { appState, taxonomyUad, appInfoData },
    {
      loadAppInfoData,
      setAppStoreValue,
      loadRomanianTranslations,
      loadUADTaxonomy,
    },
  ] = useAppStore();

  const [webTheme, setWebTheme] = useState(webLightTheme);
  const getAllUserData = () => {
    setAppStoreValue("appState", { ...appState, loading: true });
    Promise.all([getUserData(), getUserPhotoSmall()])
      .then((values: any) => {
        return { ...values[0], photoSmall: values[1] };
      })
      .then((results: any) => {
        setAppStoreValue("appState", {
          ...appState,
          user: results,
          loading: false,
        });
      })
      .catch((err) => {
        setAppStoreValue("appState", {
          ...appState,
          loading: false,
        });
        errorHandler(err);
      });
  };

  const closeErrorMessage = (): void => {
    setAppStoreValue("appState", { ...appState, error: "" });
  };

  const panelStatus = (status: boolean): void => {
    setAppStoreValue("appState", { ...appState, panelIsOpen: status });
  };

  const searchOnChange = (value: string): void => {
    setAppStoreValue("appState", { ...appState, search: value });
  };

  const searchOnSearch = (value: string): void => {
    alert(value);
  };

  const errorHandler = (value: string): void => {
    setAppStoreValue("appState", { ...appState, error: value });
  };

  useEffect(() => {
    getAllUserData();
    loadRomanianTranslations();
  }, []);

  useEffect(() => {
    if (appInfoData !== null) return;
    loadAppInfoData();
  }, [loadAppInfoData, appInfoData]);
  useEffect(() => {
    if (appState.darkMode) {
      setWebTheme(webDarkTheme);
    } else {
      setWebTheme(webLightTheme);
    }
  }, [appState.darkMode]);
  useEffect(() => {
    if (taxonomyUad !== null) return;
    loadUADTaxonomy();
  }, [loadUADTaxonomy, taxonomyUad]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Suspense fallback={<GrecoSpinner />}>
        <AppInsightsErrorBoundary
          onError={() => (
            <GrecoErrorPage
              title={(i18next.t("gos.error.title"), "Error")}
              message={i18next.t(
                "gos.error.description",
                "Uh, oh, something went wrong. Refresh is needed."
              )}
              dismissButton={null}
            />
          )}
          appInsights={reactPlugin}
        >
          <div>
            <FluentProvider theme={webTheme}>
              <ThemeProvider isDarkMode={appState.darkMode}>
                <StyledThemeProvider
                  theme={appState.darkMode ? darkStyledTheme : styledTheme}
                >
                  {/* {appState.loading && <GrecoSpinner />} */}
                  {appState.error && (
                    <ErrorMessage
                      message={appState.error}
                      closeError={closeErrorMessage}
                    />
                  )}

                  <AppHeader
                    taxonomyUad={taxonomyUad}
                    user={appState.user}
                    headerConfig={appState.headerConfig}
                    logo={Logo}
                    logout={logout}
                    panelStatus={panelStatus}
                    searchOnChange={searchOnChange}
                    searchOnSearch={searchOnSearch}
                    darkMode={(dark: boolean) => {
                      loadTheme(dark ? darkStyledTheme : (styledTheme as any));
                      setAppStoreValue("appState", {
                        ...appState,
                        darkMode: dark,
                      });
                    }}
                    tooltipStatusChange={(tooltipsStatus) => {
                      const checked = Boolean(
                        tooltipsStatus.target.ariaChecked === "true"
                          ? false
                          : true
                      );
                      localStorage.setItem("tooltipsStatus", String(checked));
                      setAppStoreValue("appState", {
                        ...appState,
                        headerConfig: {
                          ...appState.headerConfig,
                          tooltipsStatus: checked,
                        },
                      });
                    }}
                  />

                  <div
                    className={
                      appState.panelIsOpen
                        ? "app-wrapper app-wrapper--panelIsOpen"
                        : "app-wrapper"
                    }
                  >
                    <BrowserRouter>
                      <Routes>
                        {Object.keys(routes).map((key) => {
                          const route = routes[key];
                          return (
                            <Route
                              key={key}
                              path={route.path}
                              element={<route.component />}
                              // exact
                            />
                          );
                        })}
                        <Route
                          path="*"
                          element={
                            <Navigate to={routes.listPage.path} replace />
                          }
                        />
                      </Routes>
                    </BrowserRouter>
                  </div>
                  <ToastContainer icon={false} />
                  <GlobalStyles />
                </StyledThemeProvider>
              </ThemeProvider>
            </FluentProvider>
          </div>
        </AppInsightsErrorBoundary>
      </Suspense>
    </AppInsightsContext.Provider>
  );
};

const App = () => <AppComponent />;

export default App;
