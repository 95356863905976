import { getTheme, mergeStyleSets } from "office-ui-fabric-react";
import React from "react";

type TitleProps = { sectionText: string };

const classes = (theme) =>
  mergeStyleSets({
    name: {
      padding: "16px 20px 10px 10px",
      whiteSpace: "nowrap",
      flexGrow: "0",
      fontSize: "16px",
      color: theme.palette.themeSecondary,
    },
  });

const SectionText = ({ sectionText }: TitleProps) => {
  const theme = getTheme();
  return <div className={classes(theme).name}>{sectionText}</div>;
};

export default SectionText;
