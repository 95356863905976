import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "@greco/components";
import { useListPageStore, useListPageStoreActions } from "store/ListPageStore";

const CategoriesFilter = (props) => {
  const { loading } = props;
  const { t } = useTranslation();
  const [{ selectedOfferRequestType }, { setListStoreValue }] =
    useListPageStore();

  const options = useMemo(
    () => [
      { value: "", label: t("bcr.Category.TOATE") },
      { value: "F100018", label: t("bcr.Category.RO - BCRL (extern)") },
      { value: "F100004", label: t("bcr.Category.RO - BCRL (intern)") },
      {
        value: "F100004NR",
        label: t("bcr.Category.BCRL (intern) - Contracte Noi"),
      },
      {
        value: "F100004R",
        label: t("bcr.Category.RO - BCRL (intern) - Reînnoit"),
      },
      {
        value: "F100056",
        label: t("bcr.Category.RO - BCRL (intern) - No Reî"),
      },
    ],
    [t]
  );

  // const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    if (selectedOfferRequestType) {
      setListStoreValue("offerRequestType", selectedOfferRequestType.value);
    }
  }, [selectedOfferRequestType]);

  useEffect(() => {
    setListStoreValue("selectedOfferRequestType", {
      value: "",
      label: t("bcr.Category.TOATE"),
    });
  }, [t]);

  useEffect(() => {
    if (selectedOfferRequestType) {
      setListStoreValue(
        "selectedOfferRequestType",
        options.find((el) => el.value === selectedOfferRequestType.value)
      );
    }
  }, [options, selectedOfferRequestType]);

  return (
    <Select
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          // borderColor: state.isFocused ? "grey" : "red",
          width: 300,
          marginLeft: 10,
          marginRight: 10,
        }),
      }}
      isDisabled={loading}
      id="categoriesFilter"
      options={options}
      isSearchable={true}
      onChange={(value) => {
        setListStoreValue("selectedOfferRequestType", value);
      }}
      value={selectedOfferRequestType}
      placeholder={t("greco.form.selectPlaceholder")}
      noOptionsMessage={() => t("greco.noResults")}
    />
  );
};

export default CategoriesFilter;
