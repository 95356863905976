import { ExcelExport } from "@progress/kendo-react-excel-export";
import clsx from "clsx";
import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { transformVehiclesForExcel } from "../../../utils/excel";
import { useListPageStore } from "store/ListPageStore";

type Props = {
  refExcelExport: ExcelExport;
};

export const ExportExcelButton = ({ refExcelExport }: Props) => {
  const { t } = useTranslation();
  const [{ cols, vehicleItemsLoadStatus }, { loadVehiclesForReport }] =
    useListPageStore();
  return (
    <CommandBarButton
      disabled={vehicleItemsLoadStatus === "loading"}
      className={clsx({ loading: vehicleItemsLoadStatus === "loading" })}
      iconProps={{
        iconName:
          vehicleItemsLoadStatus === "loading"
            ? "ProgressRingDots"
            : "ExcelDocument",
      }}
      text={t("greco.exportExcel")}
      onClick={() => {
        loadVehiclesForReport({
          onSuccess: (res) => {
            refExcelExport.save(
              transformVehiclesForExcel({
                vehicles: res,
                columns: cols.filter((c) => c.isVisible),
                t,
              })
            );
          },
          onError: () => {},
        });
      }}
    />
  );
};
