import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  ConstrainMode,
  DetailsListLayoutMode,
  SelectionMode,
  ScrollablePane,
  Modal,
  Selection,
  mergeStyleSets,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IRenderFunction,
  SelectAllVisibility,
  Sticky,
  StickyPositionType,
  TooltipHost,
  MarqueeSelection,
} from "@fluentui/react";
import { useTheme } from "styled-components";
// import { useConst } from "@uifabric/react-hooks";
import { ShimmeredDetailsList } from "@fluentui/react";
import { useListPageStore } from "store/ListPageStore";
import { useTranslation } from "react-i18next";
import { useAppStore } from "store/AppStore";
import {
  CheckboxVisibility,
  DetailsRow,
  IDetailsRowStyles,
  Spinner,
} from "office-ui-fabric-react";
import { css } from "@emotion/css";

import { messaging } from "config/colors";

export const ListPageTable = () => {
  const { t } = useTranslation();
  // console.log(columns);
  const [{ appInfoData }, {}] = useAppStore();

  const [
    { vehicleItemsLoadStatus, vehicleItems, sort, count, cols },
    { setListStoreValue, loadVehicles },
  ] = useListPageStore();

  const [isLoadingNewFiles, setIsLoadingNewFiles] = useState(false);
  const theme = useTheme();

  const columns = useMemo(() => {
    const withTranslatedHeader = cols
      .filter((c) => c.isVisible)

      .filter((el) => {
        if (
          appInfoData?.offerRequestType === "F100018" &&
          el.key === "isRenewed"
        )
          return false;
        return true;
      })
      .map((column: any) => {
        let iconName = column.iconName;

        if (column.key === sort?.key && column.isSortable) {
          iconName =
            sort?.key === column.key
              ? sort!.dir === "asc"
                ? "SortUp"
                : "SortDown"
              : "Sort";
        }
        return {
          ...column,
          name: t(column.labelKey),
          iconName: iconName,
        };
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
    return [...withTranslatedHeader];
  }, [sort, cols, t]);

  function usePrevious<T>(state: T): T | undefined {
    const ref = useRef<T>();

    useEffect(() => {
      ref.current = state;
    });

    return ref.current;
  }

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
  ) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
      stickyClassName={classes.table}
    >
      <>
        {defaultRender!({
          ...props!,
          selectAllVisibility: SelectAllVisibility.hidden,
          onRenderColumnHeaderTooltip,
        })}
      </>
    </Sticky>
  );

  // const onItemInvoked = (vehicle) => {
  //   const link = routes[
  //     vehicle.isTEMP ? "editTempVehicle" : "readOnlyVehicle"
  //   ].getPath(vehicle?.fleetId, vehicle?.vehicleId);
  //   history.push(link);
  // };

  const fetchMoreData = async () => {
    if (vehicleItemsLoadStatus === "loading" || isLoadingNewFiles) return;
    setIsLoadingNewFiles(true);
    loadVehicles({
      skip: vehicleItems.length,
      top: 40,
      reset: false,
    });
  };

  const endReached = vehicleItems.length >= count;
  const fetchNewFiles = (ev) => {
    const element = ev.target;
    if (element && vehicleItemsLoadStatus !== "loading") {
      let remaningPixels =
        element.scrollHeight - element.scrollTop - element.clientHeight <= 150;
      if (remaningPixels && !endReached) {
        fetchMoreData();
      }
    }
  };

  const selection = new Selection({
    onSelectionChanged: () => {},
    getKey: (item: any) => _getKey(item),
  });

  const prevItemsLength = usePrevious(vehicleItems.length);
  useEffect(() => {
    if (prevItemsLength && prevItemsLength !== vehicleItems.length) {
      setIsLoadingNewFiles(false);
    }
  }, [vehicleItems, prevItemsLength]);

  const _getKey = (item: any, index?: number): string => {
    return item && item.vehicleId;
  };
  const onRenderItemColumn = (
    item: any,
    index: any,
    column: any
  ): JSX.Element | string | number => {
    if (column?.key === "options") {
      return (
        <span className="action-icon">
          {/* <ContextButton vehicle={item} />
           */}
          {/* {item.} */}
        </span>
      );
    }
    return item[column.key];
  };

  // if (!vehicleItems) return null;
  // console.log(sort);

  const onClickHeaderCell = (column) => {
    if (!column.isSortable) return;
    const isSorted = sort?.key === column.key;

    if (isSorted) {
      if (sort?.dir === "asc") {
        setListStoreValue("sort", {
          key: column.key,
          dir: "desc",
        });
      } else {
        setListStoreValue("sort", {
          key: "",
          dir: "desc",
        });
      }
    }
    //
    else {
      setListStoreValue("sort", {
        key: column.key,
        dir: "asc",
      });
    }
  };

  const classes = getClassNames(theme);

  const onRenderRow = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const row = props.item;
      if (!row) return undefined;
      if (row.isArchived) {
        customStyles.root = css`
          &&&& {
            background-color: ${
              theme?.isDark === true
                ? "#999999" //theme.palette.themeDark
                : "#999999" //theme.palette.neutralLight};
            }
        `;
      }
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  return (
    <>
      <div className={classes.container} id="scrollableDiv">
        <ScrollablePane
          onScroll={(ev) => {
            if (!endReached && vehicleItemsLoadStatus !== "loading") {
              fetchNewFiles(ev);
            }
          }}
          styles={{
            contentContainer: { overflowY: "scroll", overflowX: "scroll" },
          }}
        >
          {/* <SearchResultsCount count={count} label={"Search results:"}/> */}
          <MarqueeSelection selection={selection}>
            <ShimmeredDetailsList
              items={vehicleItems || []}
              compact={false}
              columns={columns || []}
              selectionMode={SelectionMode.single}
              checkboxVisibility={CheckboxVisibility.onHover}
              setKey="multiple"
              getKey={_getKey}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              isHeaderVisible={true}
              onColumnHeaderClick={(e, column) => {
                onClickHeaderCell(column);
              }}
              // onItemInvoked={onItemInvoked}
              className={classes.table}
              onRenderRow={onRenderRow}
              // onActiveItemChanged={_onActiveItemChanged}
              onRenderItemColumn={onRenderItemColumn}
              selection={selection}
              enableShimmer={
                vehicleItemsLoadStatus === "loading" && !isLoadingNewFiles
              }
              shimmerLines={20}
              onRenderDetailsHeader={(
                props: IDetailsHeaderProps | undefined,
                defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
              ) => onRenderDetailsHeader(props, defaultRender)}
              // onItemInvoked={onItemInvoked}
              selectionPreservedOnEmptyClick={false}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              // onColumnHeaderClick={ .. update state i na kraju onItemsFilter(state)}
              constrainMode={ConstrainMode.unconstrained}
            />
          </MarqueeSelection>
        </ScrollablePane>
      </div>
      <Modal //prevent scrolling on loading data
        styles={{
          root: {
            opacity: 0,
          },
        }}
        isOpen={vehicleItemsLoadStatus === "loading"}
        isBlocking={vehicleItemsLoadStatus === "loading"}
      ></Modal>
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      height: "calc(100vh  - 140px)",
      position: "relative",
      overflow: "auto",
      width: "100%",
    },

    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(187, 187, 187) !important",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(187, 187, 187) !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },

      ".ms-DetailsHeader-cellName ": {
        overflow: "visible",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },
    },
  });

export default ListPageTable;
