import { useTheme } from "styled-components";
import { Button, makeStyles } from "@fluentui/react-components";
import { BuildingRegular } from "@fluentui/react-icons";
import NumberInputField from "components/controls9/NumberInputField";
import SelectField from "components/controls9/SelectField";
import { MessageBarType } from "office-ui-fabric-react";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppStoreActions } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { resolverFn } from "utils/setYupLocale";
import { reverse_romanian_label, taxonomy } from "utils/types";
import { fieldToTab, getClasses } from "utils/utils";
import * as yup from "yup";
import { TabProps } from "types/types";
import TabCard from "../TabCard";
import CardFooterWithInfoCalculation from "./CardFooterWithInfoCalculation";
import { toast } from "components/FluentToast";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const OfferTab5 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { getValues, setValue, setError, clearErrors, watch } = props.form;

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const [
    {
      selectedProduct,
      offerTab,
      progress,
      mainTab,
      showDiscount,
      showIncrease,
    },
    { setFormStoreValue, setFormStoreValues },
  ] = formPageStore;

  watch((data, { name, type }) => {
    if (type === "change" && name === "inp_discount_p") {
      if (data.inp_discount_p && data.inp_discount_p.length > 0) {
        setValue("inp_increase_p", "");
        setFormStoreValue("showIncrease", false);
      } else {
        setFormStoreValue("showIncrease", true);
      }
    } else if (type === "change" && name === "inp_increase_p") {
      if (data.inp_increase_p && data.inp_increase_p.length > 0) {
        setValue("inp_discount_p", "");
        setFormStoreValue("showDiscount", false);
      } else {
        setFormStoreValue("showDiscount", true);
      }
    }
  });

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.cascoOfferCreated || progress.mtplOfferCreated;

  const theme = useTheme();
  const isDarkMode = theme.isDark;

  const classes = getClasses(theme, isDarkMode);
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  const cardFooter = (
    <CardFooterWithInfoCalculation isLoading={isLoading} form={props.form}>
      <Button
        appearance="secondary"
        icon={<BuildingRegular fontSize={16} />}
        onClick={async () => {
          clearErrors();
          clearAllNotifications();

          resolverFn(
            selectedProduct.validationMap["offerTab5"](yup, t),
            getValues()
          ).then((res) => {
            if (Object.keys(res.errors).length > 0) {
              toast.error(t("bcr.validationErrors.label"));
              Object.keys(res.errors).forEach((key) => {
                setError(key, res.errors[key]);
                setNotificationMessage({
                  errors: [],
                  key: key,
                  messageBarType: MessageBarType.error,
                  fieldName: t("bcr." + key + ".label"),
                  handleNotificationClick: (e) => {
                    setFormStoreValues({
                      mainTab: { ...mainTab, selectedTab: "offer" },
                      offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
                    });
                  },
                  notify: {
                    label: "label",
                    type: "error",
                    notifyCode: "notifyCode",
                    notifyText: (
                      <>
                        <b>{t("bcr." + key + ".label")}</b>
                        <br />
                        {res.errors[key].message}
                      </>
                    ),
                  },
                });
              });
            } else {
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab: "tab6",
                disabledTabs: offerTab.disabledTabs.filter(
                  (el) => el !== "tab6"
                ),
              });
            }
          });
        }}
      >
        {t("bcr.next.label")}
      </Button>
    </CardFooterWithInfoCalculation>
  );
  return (
    <>
      <TabCard footer={cardFooter}>
        <div className={styles.grid}>
          {showDiscount ? (
            <>
              <div style={labelStyle(1, 1)}>
                {t(reverse_romanian_label("Reducere (%)"))}
                {requiredMap.inp_discount_p ? "*" : ""}
              </div>
              <div style={fieldStyle(1, 2)}>
                <NumberInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_discount_p"
                  label={reverse_romanian_label("Reducere (%)")}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_discount_p}
                  isNegativeAllowed={false}
                  visible={visibleMap?.inp_discount_p && showDiscount}
                  isFloat={true}
                />
                {/* // customConditional:"show = data.offerRequestType === 'F100004' && isUndefinedOrNullOrEmpty('inp_increase_p')" */}
                {/* /> : null} */}
                {/* {visibleMap.inp_discount_p && ( */}
              </div>
            </>
          ) : null}
          {showIncrease ? (
            <>
              <div style={labelStyle(2, 1)}>
                {t(reverse_romanian_label("Majorare (%)"))}
                {requiredMap.inp_increase_p ? "*" : ""}
              </div>
              <div style={fieldStyle(2, 2)}>
                <NumberInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_increase_p"
                  label={reverse_romanian_label("Majorare (%)")}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_increase_p}
                  isNegativeAllowed={false}
                  visible={visibleMap?.inp_increase_p}
                  isFloat={true}

                  // customConditional:"show = data.offerRequestType === 'F100004' && isUndefinedOrNullOrEmpty('inp_discount_p')"
                />
                {/* )} */}
              </div>
            </>
          ) : null}
          <div style={labelStyle(3, 1)}>
            {t(reverse_romanian_label("Asigurator (Reducere/Majorare)"))}
            {requiredMap.inp_discount_insurer ? "*" : ""}
          </div>
          <div style={fieldStyle(3, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_discount_insurer"
              label={reverse_romanian_label("Asigurator (Reducere/Majorare)")}
              options={taxonomy["inp_discount_insurer"] || []}
              required={false}
              disabled={disabledTab || disabledMap?.inp_discount_insurer}
              visible={true} //visibleMap.inp_discount_insurer}

              // customConditional:"show = data.offerRequestType === 'F100004'"
            />
          </div>
        </div>
      </TabCard>
    </>
  );
};

export default OfferTab5;
