import * as yup from "yup";
import { TFunction } from "i18next";
import { useCallback } from "react";

export const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

export const resolverFn = async (validationSchema, data) => {
  try {
    const values = await validationSchema.validate(data, {
      abortEarly: false,
    });

    return {
      values,
      errors: {},
    };
  } catch (errors: any) {
    return {
      values: {},
      errors: errors.inner.reduce(
        (allErrors, currentError) => ({
          ...allErrors,
          [currentError.path]: {
            type: currentError.type ?? "validation",
            message: currentError.message,
          },
        }),
        {}
      ),
    };
  }
};

export const setYupLocale = (t: TFunction) => {
  yup.setLocale({
    mixed: {
      required: t("greco.required"),
      notType: function notType(_ref) {
        switch (_ref.type) {
          case "number":
            return t("greco.form.errors.number");
          default:
            return t("greco.error");
        }
      },
    },
    number: {
      integer: t("greco.form.errors.integer"),
      min: ({ min }) => t("greco.form.errors.number.min", { min }),
      max: ({ max }) => t("greco.form.errors.number.max", { max }),
      positive: t("greco.form.errors.positiveNumber"),
    },
    string: {
      max: ({ max }) => t("greco.form.errors.string.max", { max }),
    },
    array: {
      min: ({ min }) => t("greco.form.errors.array.min", { min }),
    },
  });
};
