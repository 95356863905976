import { FunctionComponent } from "react";
import { matchPath, useLocation } from "react-router-dom";
import ListPage from "pages/ListPage/ListPage";
import FormPageNew from "pages/FormPageNew/FormPage";
// const calculationPath = '/offer';
// const getCalculationPath = () => `/offer`;

const listPagePath = "/";

const formPageNewOfferPath = "/newOffer";

const formPageNewOfferMtplPath = "/newOfferMtpl";

const formPageNewOfferProductPath = "/offer";

const formPagePath = "/item/:itemId";
const getFormPagePath = (offerId: number) => `/item/${offerId}`;

type RouteDef = {
  key: RouteKey;
  path: string;
  component: FunctionComponent<any>;
  getPath: (...args: any[]) => string;
  matchPath: (loc: any) => any;
};

type Routes = {
  listPage: RouteDef;
  formPage: RouteDef;
  formPageNew: RouteDef;
  formPageNewOffer: RouteDef;
  //
  list: RouteDef;
  offer: RouteDef;
  //
  asirom: RouteDef;
  bcr: RouteDef;
  dropzone: RouteDef;
  dropzoneNonGreco: RouteDef;
};

export type RouteKey = keyof Routes;

export const routes = {
  listPage: {
    key: "listPage",
    path: listPagePath,
    component: ListPage,
    getPath: () => listPagePath,
    matchPath: (l) =>
      matchPath(
        {
          path: listPagePath,
          // exact: true
        },
        l.pathname
      ),
  },
  formPage: {
    key: "formPage",
    path: formPagePath,
    component: FormPageNew,
    getPath: getFormPagePath,
    matchPath: (l) =>
      matchPath(
        {
          path: formPagePath,
          // exact: true
        },
        l.pathname
      ),
  },
  formPageNewOfferMtpl: {
    key: "formPageNewOfferMtpl",
    path: formPageNewOfferMtplPath,
    component: FormPageNew,
    getPath: formPageNewOfferMtplPath,
    matchPath: (l) =>
      matchPath(
        {
          path: formPageNewOfferMtplPath,
          // exact: true
        },
        l.pathname
      ),
  },
  formPageRepeatOffer: {
    key: "formPageRepeatOffer",
    path: formPageNewOfferProductPath,
    component: FormPageNew,
    getPath: formPageNewOfferProductPath,
    matchPath: (l) =>
      matchPath(
        {
          path: formPageNewOfferProductPath,
          // exact: true
        },
        l.pathname
      ),
  },
  formPageNewOfferProduct: {
    key: "formPageNewOfferProduct",
    path: formPageNewOfferProductPath,
    component: FormPageNew,
    getPath: (product) => formPageNewOfferProductPath + "?product=" + product,
    matchPath: (l) =>
      matchPath(
        {
          path: formPageNewOfferProductPath,
          // exact: true
        },
        l.pathname
      ),
  },
  formPageNew: {
    key: "formPageNewOffer",
    path: formPageNewOfferPath,
    component: FormPageNew,
    getPath: formPageNewOfferPath,
    matchPath: (l) =>
      matchPath(
        {
          path: formPageNewOfferPath,
          // exact: true
        },
        l.pathname
      ),
  },
};

export const useCurrentRoute = () => {
  const location = useLocation();
  return Object.values(routes).reduce((match: any | null, r) => {
    const matchResult = r.matchPath(location);
    if (!!matchResult) {
      return r.key;
    }
    return match;
  }, null);
};
