import { CommandBarButton } from "@fluentui/react";
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { createPDFOffers } from "../../../utils/pdf";

import { useListPageStore } from "store/ListPageStore";

export const ExportPDFButton = () => {
  const { t } = useTranslation();
  const [{ cols, vehicleItemsLoadStatus }, { loadVehiclesForReport }] =
    useListPageStore();
  const loading = vehicleItemsLoadStatus === "loading";

  return (
    <CommandBarButton
      text={t("greco.exportPDF")}
      onClick={() => {
        loadVehiclesForReport({
          onSuccess: (res) => {
            createPDFOffers({
              result: res,
              columns: cols.filter((c) => c.isVisible),
              // taxonomy,
              t: t,
            }).open();
          },
          onError: () => {},
        });
      }}
      disabled={loading}
      className={clsx({ loading })}
      iconProps={{
        iconName: loading ? "ProgressRingDots" : "PDF",
      }}
    />
  );
};
