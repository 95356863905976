import { ColumnOptionsButton, SearchResultsCount } from "@greco/components";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Flex,
  FlexRight,
  HorizontalSpacer,
  NavigationBar,
  StyledCheckbox,
} from "components/styled";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useListPageStore } from "store/ListPageStore";
import { ColumnOptions } from "./ColumnOptions";
import { ExportExcelButton } from "./navigationbuttons/ExportExcelButton";
import { ExportPDFButton } from "./navigationbuttons/ExportPDFButton";
import { Search } from "./navigationbuttons/Search";
import CategoriesFilter from "./navigationbuttons/CategoriesFilter";
import { RefreshButton } from "./navigationbuttons/RefreshButton";
import { ResultsScore } from "components/ResultsScore";

type Props = {
  refExcelExport: ExcelExport;
};

const ListPageNavigationFirstLine = ({ refExcelExport }: Props) => {
  const [
    {
      areAnyColumnsFiltered,
      count,
      searchTerm,
      vehicleItemsLoadStatus,
      showArchived,
    },
    { setListStoreValue },
  ] = useListPageStore();
  const { appInfoData } = useAppStoreState();
  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);
  const { t } = useTranslation();

  const loading = vehicleItemsLoadStatus === "loading";
  if (!appInfoData) return null;

  return (
    <NavigationBar style={{ zIndex: 333 }}>
      <RefreshButton />
      <Search
        loading={loading}
        placeholder="Search"
        value={searchTerm}
        onChange={(value) => setListStoreValue("searchTerm", value)}
      />
      {typeof appInfoData.showSelectCategorie !== "undefined" &&
      appInfoData.showSelectCategorie === true ? (
        <CategoriesFilter disabled={loading} />
      ) : null}
      {appInfoData.showButton !== "admin" &&
      appInfoData.showButton !== "both" &&
      (appInfoData?.offerRequestType === "F100004" ||
        appInfoData?.offerRequestType === "F100018") ? null : (
        <ExportExcelButton refExcelExport={refExcelExport} />
      )}
      {appInfoData.showButton !== "admin" &&
      appInfoData.showButton !== "both" &&
      (appInfoData?.offerRequestType === "F100004" ||
        appInfoData?.offerRequestType === "F100018") ? null : (
        <ExportPDFButton />
      )}
      <HorizontalSpacer width={10} />
      {appInfoData.showButton === "admin" ||
      appInfoData.showButton === "both" ? (
        <StyledCheckbox
          label={t("bcr.showArchived.label")}
          checked={showArchived}
          onChange={(e, checked) => {
            setListStoreValue("showArchived", checked);
          }}
          disabled={loading}
        />
      ) : null}
      <FlexRight>
        <Flex>
          <ColumnOptionsButton
            disabled={loading}
            t={t}
            isActive={areAnyColumnsFiltered}
            onClick={() => {
              setIsColumnOptionsOpen(true);
            }}
          />
          <ColumnOptions
            isOpen={isColumnOptionsOpen}
            closePanel={() => {
              setIsColumnOptionsOpen(false);
            }}
          />
          <ResultsScore score={count} />
        </Flex>
      </FlexRight>
    </NavigationBar>
  );
};

export default ListPageNavigationFirstLine;
