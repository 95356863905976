import React from "react";
import {
  ExpandedColumn,
  NumberFilter,
  TaxonomyFilter,
  TextFilter,
} from "../../types/columns";
import { formatDateString } from "../../utils/utils";
import { ContextButton } from "pages/ListPage/ContextButton";
import { CenteredCell, StyledLink } from "components/styled";
import { Link } from "react-router-dom";
import { routes } from "../../config/routes";
import SectionText from "components/sectionText";
import RenewedCell from "./RenewedCell";
import {
  insurerCasco,
  insurerMtpl,
  reverse_romanian_label,
  taxonomy,
} from "utils/types";
import InsurerField from "./InsurerField";
export const isFilteredTaxonomy = (f: TaxonomyFilter) => f.value.length > 0;
export const isFilteredNumber = (f: NumberFilter) => {
  if (f.operator === "between") {
    return (
      f.number1 !== null &&
      f.number2 !== null &&
      f.number1 !== ("" as any) &&
      f.number2 !== ("" as any)
    );
  }
  return f.number1 !== null && f.number1 !== ("" as any);
};
export const columns: ExpandedColumn[] = [
  {
    key: "offerId",
    name: "Offer ID",
    labelKey: "bcr.offers.offerId.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,

    isFixed: true,

    isSortable: true,
    iconName: "Sort",
    excel: {
      width: 150,
      // render: ({ t, taxonomy, row }) =>
      //   t(taxonomy.VehicleStatusCode.byId[row.vehicleStatusCode]?.code),
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <StyledLink
          to={routes.formPage.getPath(row.offerId)}
          style={{ textDecoration: "underline" }}
        >
          {row?.offerId ? row?.offerId : null}
        </StyledLink>
      );
    },
    pdf: {
      width: 100,
      render: ({ offer }) => {
        return {
          text: offer?.offerId,
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },
  {
    key: "options",
    name: "options",
    isResizable: true,
    isSortable: false,
    labelKey: "",
    // maxWidth: 40,
    // minWidth: 40,
    width: 0,
    isVisible: true,
    // isFixed: true,
    onRender: (row) => {
      if (!row) return;
      return (
        <CenteredCell>
          <ContextButton offer={row} />
        </CenteredCell>
      );
    },
  },
  {
    key: "offerRequestedAt",
    name: "Date Of Offer",
    labelKey: "bcr.offers.offerRequestedAt.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,
    isVisible: true,
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    isSortable: true,
    isFixed: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return;
      return row?.offerRequestedAt
        ? formatDateString(row?.offerRequestedAt)
        : null;
    },
    pdf: {
      width: 100,
      render: ({ offer }) => {
        return {
          text: formatDateString(offer?.offerRequestedAt),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "offerRequestedByName",
    name: "Offer Made By",
    labelKey: "bcr.offers.offerRequestedByName.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,
    isFixed: true,
    filter: {
      type: "text",
      value: "",
    },
    isVisible: true,
    // isFixed: true,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "isRenewed",
    name: "Renewed",
    labelKey: "bcr.offers.isRenewed.label",
    isResizable: true,
    // maxWidth: 130,
    // minWidth: 100,
    width: 140,
    align: "center",
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return;
      return (
        <div style={{ display: "flex", textAlign: "center", width: "100%" }}>
          <RenewedCell value={row.isRenewed} />
        </div>
      );
    },
    // insurer icons
  },
  {
    key: "inp_lessee_type",
    name: "Type",
    labelKey: "bcr.offers.inp_lessee_type.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "inp_contract_type",
    name: "Contract Type",
    labelKey: "bcr.Contract_type.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "inp_vehicle_brand",
    name: "Vehicle Brand",
    labelKey: "bcr.offers.inp_vehicle_brand.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "inp_vehicle_model",
    name: "Vehicle Model",
    labelKey: "bcr.offers.inp_vehicle_model.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "inp_lessee_username",
    name: "username",
    labelKey: reverse_romanian_label("Nume utilizator"),
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isVisible: true,
    isSortable: false,
  },
  {
    key: "inp_lessee_id",
    name: "id",
    labelKey: "bcr.offers.inp_lessee_id.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isVisible: true,
    isSortable: false,
  },

  {
    key: "inp_vehicle_vin",
    name: "Vehicle VIN",
    labelKey: "bcr.offers.inp_vehicle_vin.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "leasingctrno",
    name: "No. ctrl",
    labelKey: "bcr.offers.leasingctrno.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "validFrom",
    name: "Valid From",
    labelKey: "bcr.offers.validFrom.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    pdf: {
      width: 100,
      render: ({ offer }) => {
        return {
          text: formatDateString(offer?.validFrom),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "status",
    name: "Document",
    labelKey: "bcr.offers.document.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "insurerCasco",
    name: "CASCO Invoice Issued At",
    labelKey: "bcr.offers.insurerCasco.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "insurerCasco",
      isFiltered: isFilteredTaxonomy,
    },
    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",

    excel: {
      width: 150,
      // render: ({ t, taxonomy, row }) =>
      //   t(taxonomy.VehicleStatusCode.byId[row.vehicleStatusCode]?.code),
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;

      return <InsurerField type={"casco"} row={row} />;
    },
    pdf: {
      width: 100,
      render: ({ offer, t }) => {
        return {
          text: offer?.insurerCasco
            ? t(insurerCasco[offer?.insurerCasco])
            : null,
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "insurerMtpl",
    name: "Mtpl Invoice Issued At",
    labelKey: "bcr.offers.insurerMtpl.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,
    filter: {
      type: "taxonomy",
      value: [],
      taxonomyKey: "insurerMtpl",
      isFiltered: isFilteredTaxonomy,
    },
    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",

    excel: {
      width: 150,
      // render: ({ t, taxonomy, row }) =>
      //   t(taxonomy.VehicleStatusCode.byId[row.vehicleStatusCode]?.code),
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;

      return <InsurerField type={"mtpl"} row={row} />;
    },
    pdf: {
      width: 100,
      render: ({ offer, t }) => {
        return {
          text: offer?.insurerMtpl ? t(insurerMtpl[offer?.insurerMtpl]) : null,
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "invoiceCascoId",
    name: "Casco Invoice Id",
    labelKey: "bcr.offers.invoiceCascoId.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 180,

    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "cascoInvoiceIssuedAt",
    name: "Casco Invoice Issued At",
    labelKey: "bcr.offers.cascoInvoiceIssuedAt.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      width: 150,
      // render: ({ t, taxonomy, row }) =>
      //   t(taxonomy.VehicleStatusCode.byId[row.vehicleStatusCode]?.code),
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row?.offerRequestedAt
        ? formatDateString(row?.cascoInvoiceIssuedAt)
        : null;
    },
    pdf: {
      width: 500,
      render: ({ offer }) => {
        return {
          text: formatDateString(offer?.cascoInvoiceIssuedAt),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "cascoInvoiceIssuedByName",
    name: "Casco Invoice Issued By",
    labelKey: "bcr.offers.cascoInvoiceIssuedByName.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,
    filter: {
      type: "text",
      value: "",
    },
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "invoiceCascoPremium",
    name: "Invoice Casco Premium",
    labelKey: "bcr.offers.invoiceCascoPremium.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "invoiceMtplId",
    name: "MTPL Invoice Id",
    labelKey: "bcr.offers.invoiceMtplId.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,

    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "mtplInvoiceIssuedAt",
    name: "MTPL Invoice Issued At",
    labelKey: "bcr.offers.mtplInvoiceIssuedAt.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,

    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      width: 150,
      // render: ({ t, taxonomy, row }) =>
      //   t(taxonomy.VehicleStatusCode.byId[row.vehicleStatusCode]?.code),
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row?.offerRequestedAt
        ? formatDateString(row?.mtplInvoiceIssuedAt)
        : null;
    },
    pdf: {
      width: 500,
      render: ({ offer }) => {
        return {
          text: formatDateString(offer?.mtplInvoiceIssuedAt),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "mtplInvoiceIssuedByName",
    name: "MTPL Invoice Issued By",
    labelKey: "bcr.offers.mtplInvoiceIssuedByName.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,
    filter: {
      type: "text",
      value: "",
    },
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "invoiceMtplPremium",
    name: "MTPL Invoice Premium",
    labelKey: "bcr.offers.invoiceMtplPremium.label",
    // maxWidth: 100,
    // minWidth: 200,
    width: 350,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "policyCascoId",
    name: "Casco Policy Id",
    labelKey: "bcr.offers.policyCascoId.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 180,

    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "cascoPolicyIssuedAt",
    name: "Casco Policy Issued At",
    labelKey: "bcr.offers.cascoPolicyIssuedAt.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 140,

    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      width: 150,
      // render: ({ t, taxonomy, row }) =>
      //   t(taxonomy.VehicleStatusCode.byId[row.vehicleStatusCode]?.code),
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row?.offerRequestedAt
        ? formatDateString(row?.cascoPolicyIssuedAt)
        : null;
    },
    pdf: {
      width: 500,
      render: ({ offer }) => {
        return {
          text: formatDateString(offer?.cascoPolicyIssuedAt),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "cascoPolicyIssuedByName",
    name: "Casco Policy Issued By",
    labelKey: "bcr.offers.cascoPolicyIssuedByName.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,
    filter: {
      type: "text",
      value: "",
    },
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "policyCascoPremium",
    name: "Policy Casco Premium",
    labelKey: "bcr.offers.policyCascoPremium.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "policyMtplId",
    name: "MTPL Policy Id",
    labelKey: "bcr.offers.policyMtplId.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,

    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "mtplPolicyIssuedAt",
    name: "MTPL Policy Issued At",
    labelKey: "bcr.offers.mtplPolicyIssuedAt.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,

    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    excel: {
      width: 150,
      // render: ({ t, taxonomy, row }) =>
      //   t(taxonomy.VehicleStatusCode.byId[row.vehicleStatusCode]?.code),
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row?.offerRequestedAt
        ? formatDateString(row?.mtplPolicyIssuedAt)
        : null;
    },
    pdf: {
      width: 500,
      render: ({ offer }) => {
        return {
          text: formatDateString(offer?.mtplPolicyIssuedAt),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "mtplPolicyIssuedByName",
    name: "MTPL Policy Issued By",
    labelKey: "bcr.offers.mtplPolicyIssuedByName.label",
    // maxWidth: 170,
    // minWidth: 100,
    width: 350,
    filter: {
      type: "text",
      value: "",
    },
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "policyMtplPremium",
    name: "MTPL Policy Premium",
    labelKey: "bcr.offers.policyMtplPremium.label",
    // maxWidth: 100,
    // minWidth: 200,
    width: 350,
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      isFiltered: isFilteredNumber,
    },
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
  },
];
