import { useTheme } from "styled-components";
import { Button, Spinner, makeStyles } from "@fluentui/react-components";
import { VSpace } from "components/Spacer";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import SelectField from "components/controls9/SelectField";
import TextInputField from "components/controls9/TextInputField";
import { MessageBarType } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { TabProps } from "types/types";
import { resolverFn } from "utils/setYupLocale";
import { reverse_romanian_label } from "utils/types";
import { fieldToTab, getClasses, prepareFormItemsForSubmit } from "utils/utils";
import * as yup from "yup";
import TabCard from "../TabCard";
import { MoneyHandRegular } from "@fluentui/react-icons";
import { toast } from "components/FluentToast";

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const InvoiceTab1 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { setValue, getValues, setError, clearErrors } = props.form;

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const [
    { romanianTranslations },
    { setNotificationMessage, clearAllNotifications },
  ] = useAppStore();

  const [
    { selectedProduct, insurerCascoList, mainTab, invoiceTab, progress },
    { setFormStoreValues, generateCascoInvoice },
  ] = formPageStore;

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.cascoInvoiceCreated;
  const navigate = useNavigate();

  const theme = useTheme();
  const isDarkMode = theme.isDark;

  const classes = getClasses(theme, isDarkMode);
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  const cardFooter = (
    <>
      {progress.cascoInvoiceCreated ? (
        <Button
          icon={
            isLoading ? (
              <Spinner size="tiny" />
            ) : (
              <MoneyHandRegular fontSize={16} />
            )
          }
          appearance="primary"
          disabled={disabledMap?.downloadCascoInvoice}
          name="downloadCascoInvoice"
          onClick={() => {
            window.open(getValues("pathToInvoiceCascoPdf"), "_blank");
          }}
          // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToInvoiceCascoPdf,'bcrcascoinvoice');")}}
        >
          {t(reverse_romanian_label("Decont"))}
        </Button>
      ) : null}

      {!progress.cascoInvoiceCreated ? (
        <Button
          icon={
            isLoading ? (
              <Spinner size="tiny" />
            ) : (
              <MoneyHandRegular fontSize={16} />
            )
          }
          appearance="primary"
          // isLoading={isLoading}
          name="generateCascoInvoice"
          // visible={visibleMap?.generateCascoInvoice}
          disabled={
            isLoading || disabledTab || disabledMap?.generateCascoInvoice
          }
          onClick={() => {
            clearErrors();
            clearAllNotifications();
            resolverFn(
              selectedProduct?.validationMap["invoiceTab1"](yup, t),
              getValues()
            ).then((res) => {
              if (Object.keys(res.errors).length > 0) {
                toast.error(t("bcr.validationErrors.label"));
                Object.keys(res.errors).forEach((key) => {
                  setError(key, res.errors[key]);
                  setNotificationMessage({
                    errors: [],
                    key: key,
                    messageBarType: MessageBarType.error,
                    fieldName: t("bcr." + key + ".label"),
                    handleNotificationClick: (e) => {
                      setFormStoreValues({
                        mainTab: { ...mainTab, selectedTab: "invoice" },
                        invoiceTab: {
                          ...invoiceTab,
                          selectedTab: fieldToTab(key),
                        },
                      });
                    },
                    notify: {
                      label: "label",
                      type: "error",
                      notifyCode: "notifyCode",
                      notifyText: (
                        <>
                          <b>{t("bcr." + key + ".label")}</b>
                          <br />
                          {res.errors[key].message}
                        </>
                      ),
                    },
                  });
                });
              } else {
                generateCascoInvoice(
                  {
                    appInfo: {
                      dataObj: {
                        ...prepareFormItemsForSubmit(
                          {
                            ...getValues(),
                          },
                          romanianTranslations
                        ),
                      },
                      resolvedProperties: {
                        title: "BCR Leasing",
                      },
                    },
                  },
                  (retVal) => {
                    if (retVal.pathToInvoiceCascoPdf) {
                      window.open(retVal.pathToInvoiceCascoPdf, "_blank");
                    }
                    window.location.reload();
                  },
                  (err) => {
                    setNotificationMessage({
                      errors: [],
                      key: "key",
                      messageBarType: MessageBarType.error,
                      fieldName: "fieldName",
                      handleNotificationClick: (e) => {},
                      notify: {
                        label: "label",
                        type: "error",
                        notifyCode: "notifyCode",
                        notifyText: (
                          <>
                            <b>{"GenerateCascoInvoice"}</b>
                            <br />
                            {err.response.data.Message}
                          </>
                        ),
                      },
                    });
                  }
                );
              }
            });
          }}
          // "action success script": "formioForm.checkConditions();"
        >
          {t(reverse_romanian_label("Generați decont"))}
        </Button>
      ) : null}
    </>
  );
  if (!selectedProduct) return null;

  return (
    <>
      <TabCard footer={cardFooter}>
        {/* {!disabledTab ? (
          <>
            <CascoPremiumTable form={props.form} />
            <VSpace height={20} />
          </>
        ) : null} */}
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label("Asigurator"))}
            {requiredMap?.insurerCasco ? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="insurerCasco"
              label={t(reverse_romanian_label("Asigurator"))}
              options={insurerCascoList}
              required={true}
              disabled={disabledTab || disabledMap?.insurerCasco}
              visible={visibleMap?.insurerCasco}
            />
          </div>

          {/* <div style={labelStyle(2, 1)}>
            {t(reverse_romanian_label("Prima promotionala"))}
            {requiredMap?.promotional ? "*" : ""}
          </div>
          <div style={fieldStyle(2, 2)}>
            <CheckboxField
              isLoading={isLoading}
              form={props.form}
              name="promotional"
              label={reverse_romanian_label("Prima promotionala")}
              required={false}
              disabled={disabledTab || disabledMap?.promotional}
              visible={visibleMap?.promotional}
            />
          </div> */}
          <div style={labelStyle(3, 1)}>
            {t(reverse_romanian_label("Numar contract leasing"))}
            {requiredMap?.leasingctrno ? "*" : ""}
          </div>
          <div style={fieldStyle(3, 2)}>
            <TextInputField
              isLoading={isLoading}
              name="leasingctrno"
              label={reverse_romanian_label("Numar contract leasing")}
              form={props.form}
              required={true}
              disabled={
                disabledTab ||
                disabledMap?.leasingctrno ||
                progress?.mtplInvoiceCreated
              }
              visible={visibleMap?.leasingctrno}
            />
          </div>
        </div>
        {progress.cascoInvoiceCreated ? (
          <>
            <VSpace height={20} />
            <hr />
            <VSpace height={20} />
            <div className={styles.grid}>
              <div style={labelStyle(1, 1)}>
                {t(reverse_romanian_label("Decont nr."))}
                {requiredMap?.invoiceCascoId ? "*" : ""}
              </div>
              <div style={fieldStyle(1, 2)}>
                <TextInputField
                  isLoading={isLoading}
                  name="invoiceCascoId"
                  label={reverse_romanian_label("Decont nr.")}
                  form={props.form}
                  required={false}
                  disabled={disabledTab || disabledMap?.invoiceCascoId}
                  visible={visibleMap?.invoiceCascoId}
                />
              </div>
              <div style={labelStyle(2, 1)}>
                {t(reverse_romanian_label("Prima"))}
                {requiredMap?.invoiceCascoPremium ? "*" : ""}
              </div>
              <div style={fieldStyle(2, 2)}>
                <NumberInputField
                  isLoading={isLoading}
                  form={props.form}
                  name="invoiceCascoPremium"
                  label={reverse_romanian_label("Prima")}
                  required={false}
                  disabled={disabledTab || disabledMap?.invoiceCascoPremium}
                  isNegativeAllowed={false}
                  visible={visibleMap?.invoiceCascoPremium}
                  contentAfter={t(getValues("inp_policy_currency"))}
                />
              </div>
              <div style={labelStyle(3, 1)}>
                {t(reverse_romanian_label("Rata nr. 1"))}
                {requiredMap?.invoiceCascoFirstRate ? "*" : ""}
              </div>
              <div style={fieldStyle(3, 2)}>
                <NumberInputField
                  isLoading={isLoading}
                  form={props.form}
                  name="invoiceCascoFirstRate"
                  label={reverse_romanian_label("Rata nr. 1")}
                  required={false}
                  disabled={disabledTab || disabledMap?.invoiceCascoFirstRate}
                  isNegativeAllowed={false}
                  visible={visibleMap?.invoiceCascoFirstRate}
                  contentAfter={t(getValues("inp_policy_currency"))}
                />
              </div>
              <div style={labelStyle(4, 1)}>
                {t(reverse_romanian_label("Decont emis la:"))}
                {requiredMap?.cascoInvoiceIssuedAt ? "*" : ""}
              </div>
              <div style={fieldStyle(4, 2)}>
                <DatePickerField
                  isLoading={isLoading}
                  form={props.form}
                  name="cascoInvoiceIssuedAt"
                  label={reverse_romanian_label("Decont emis la:")}
                  disabled={disabledTab || disabledMap?.cascoInvoiceIssuedAt}
                  data-format="dd.MM.yyyy HH:mm:ss"
                  data-dateFormat="DD.MM.YYYY HH:mm"
                  required={false}
                  visible={visibleMap?.cascoInvoiceIssuedAt}
                  includeTime={true}
                />
              </div>
              <div style={labelStyle(5, 1)}>
                {t(reverse_romanian_label("Decont emis de:"))}
                {requiredMap?.cascoPolicyIssuedByName ? "*" : ""}
              </div>
              <div style={fieldStyle(5, 2)}>
                <TextInputField
                  isLoading={isLoading}
                  name="cascoInvoiceIssuedByName"
                  label={reverse_romanian_label("Decont emis de:")}
                  form={props.form}
                  required={false}
                  disabled={disabledTab || disabledMap?.cascoPolicyIssuedByName}
                  visible={visibleMap?.cascoPolicyIssuedByName}
                />
              </div>
              {/* <div style={labelStyle(10, 1)}>
                {t(reverse_romanian_label(""))}{requiredMap?.downloadCascoInvoice? "*" : ""}
              </div>
              <div style={fieldStyle(10, 2)}> */}
              {/* <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Decont"))}
              disabled={disabledTab || disabledMap?.downloadCascoInvoice}
              name="downloadCascoInvoice"
              visible={visibleMap?.downloadCascoInvoice}
              onClick={() => {
                window.open(getValues("pathToInvoiceCascoPdf"), "_blank");
              }}
              // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToInvoiceCascoPdf,'bcrcascoinvoice');")}}
            /> */}
              {/* </div> */}
            </div>
          </>
        ) : null}
      </TabCard>
    </>
  );
};

export default InvoiceTab1;
