import {
  createStore,
  createHook,
  createActionsHook,
  createStateHook,
} from "react-sweet-state";
import { handleAxiosError } from "utils/utils";
import * as API from "../api/api";
import { defaults } from "react-sweet-state";
import { headerConfig } from "config/headerConfig";
import { LoadStatus, Notification } from "types/types";

import {
  clearAllNotifications,
  clearFormNotifications,
  loadAppInfoData,
  loadRomanianTranslations,
  loadUADTaxonomy,
  removeNotification,
  setAppStoreValue,
  setNotificationMessage,
} from "./AppStoreActions";
defaults.devtools = true;

export type AppInfo = {
  F100018: boolean;
  offerRequestType: string;
  showButton: string;
  showRenewals: boolean;
  showSelectCategorie: boolean;
  highestRole?: string;
};

export type StoreState = {
  appState: any;
  appInfoData: AppInfo | null;
  taxonomyUad: any;
  taxonomyUadLoadStatus: LoadStatus;
  appInfoDataLoadStatus: LoadStatus;
  romanianTranslations: any;
  romanianTranslationsLoadStatus: LoadStatus;
  notifications: Notification[];
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  appState: {
    headerConfig: headerConfig,
    panelIsOpen: false,
    user: {},
    error: "",
    loading: false,
    search: "",
    darkMode: JSON.parse(localStorage.getItem("darkMode")!),
  },
  appInfoData: null,
  appInfoDataLoadStatus: "none",
  taxonomyUad: null,
  taxonomyUadLoadStatus: "none",
  romanianTranslations: {},
  romanianTranslationsLoadStatus: "none",
  notifications: [],
};

// All the actions that mutate the store
const actions = {
  loadAppInfoData: () => loadAppInfoData,
  loadUADTaxonomy: () => loadUADTaxonomy,
  setAppStoreValue: setAppStoreValue,
  loadRomanianTranslations: () => loadRomanianTranslations,
  setNotificationMessage: setNotificationMessage,
  removeNotification: removeNotification,
  clearAllNotifications: clearAllNotifications,
  clearFormNotifications: clearFormNotifications,
};

const AppStore = createStore({ initialState, actions, name: "AppStore" });
export const useAppStore = createHook(AppStore);
export const useAppStoreState = createStateHook(AppStore);
export const useAppStoreActions = createActionsHook(AppStore);
