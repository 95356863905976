import { handleAxiosError, isNotNullOrUndefined } from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./FormPageStore";
import { determineProduct } from "types/products";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const setStoreValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };

export const loadOffer =
  (t, payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.getOffer(payload);
      let data = res.data.appInfo.dataObj;

      const isNewOffer = getState().selectedProduct !== null && !data.offerId;
      const selectedProduct = isNewOffer
        ? { ...getState().selectedProduct }
        : determineProduct(data);

      const cascoOfferCreated = isNotNullOrUndefined(data.offerId); // && hasCascoOfferValues(data);
      const mtplOfferCreated = isNotNullOrUndefined(data.offerId); // && hasMtplOfferValues(data);
      const cascoInvoiceCreated = isNotNullOrUndefined(
        data.cascoInvoiceIssuedAt
      );
      const mtplInvoiceCreated = isNotNullOrUndefined(data.mtplInvoiceIssuedAt);
      const cascoPolicyCreated = isNotNullOrUndefined(data.cascoPolicyIssuedAt);
      const mtplPolicyCreated = isNotNullOrUndefined(data.mtplPolicyIssuedAt);

      const mtplPolicyWithoutInvoice = !mtplInvoiceCreated && mtplPolicyCreated;
      const cascoPolicyWithoutInvoice =
        !cascoInvoiceCreated && cascoPolicyCreated;
      const policyWithoutInvoice =
        mtplPolicyWithoutInvoice || cascoPolicyWithoutInvoice;

      data = { ...selectedProduct.initialOffer, ...data };

      setState({
        offer: data,
        offerLoadStatus: "success",
        mainTab: {
          ...getState().mainTab,
          selectedTab:
            cascoPolicyCreated || mtplPolicyCreated
              ? "policy"
              : cascoInvoiceCreated || mtplInvoiceCreated
              ? "invoice"
              : cascoOfferCreated || mtplOfferCreated
              ? "offer"
              : "offer",
        },
        offerTab: {
          ...getState().offerTab,
          disabledTabs: isNewOffer
            ? ["tab2", "tab3", "tab4", "tab5", "tab6", "tab7", "tab8", "tab9"]
            : [],
          selectedTab: cascoOfferCreated || mtplOfferCreated ? "tab9" : "tab1",
        },
        invoiceTab: {
          ...getState().invoiceTab,
          selectedTab:
            selectedProduct.hasCasco && cascoOfferCreated ? "tab1" : "tab2",
        },
        policyTab: {
          ...getState().policyTab,
          selectedTab:
            selectedProduct.hasCasco && cascoInvoiceCreated ? "tab1" : "tab2",
        },
        progress: {
          cascoOfferCreated,
          mtplOfferCreated,
          cascoInvoiceCreated,
          mtplInvoiceCreated,
          cascoPolicyCreated,
          mtplPolicyCreated,
          mtplPolicyWithoutInvoice,
          cascoPolicyWithoutInvoice,
          policyWithoutInvoice,
        },
        showIncrease:
          data.inp_discount_p !== null && (data.inp_discount_p + "").length > 0
            ? false
            : true,
        showDiscount:
          data.inp_increase_p !== null && (data.inp_increase_p + "").length > 0
            ? false
            : true,
        showLicensePlate:
          data.offerRequestType === "F100056" &&
          (data.inp_vehicle_registration ===
            "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            data.inp_vehicle_registration ===
              "bcr.Registration_type_IN_ORDER_TO_MATRICULATE")
            ? false
            : true,
        avarie8:
          data.avarie8 === "true" || data.avarie8 === true ? true : false,
        selectedCascoInsurers: data.selectedCascoInsurers
          ? data.selectedCascoInsurers
          : selectedProduct.hasCasco
          ? ["omniasig", "asirom", "allianz", "uniqa", "generali", "groupama"]
          : null,
        selectedMtplInsurers: data.selectedMtplInsurers
          ? data.selectedMtplInsurers
          : selectedProduct.hasMtpl
          ? ["omniasig", "asirom", "allianz", "generali", "groupama", "grawe"]
          : null,
      });

      if (!isNewOffer) {
        setState({
          selectedProduct,
        });
      }
      onSuccess &&
        onSuccess(
          res.data.appInfo.resolvedProperties.title +
            (selectedProduct
              ? " | " + t("bcr.product." + selectedProduct?.type)
              : ""),
          data
        );
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const duplicateOffer =
  (offerRequestType, t, payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.duplicateOffer(payload);
      let data = res.data.appInfo.dataObj;

      const isNewOffer = getState().selectedProduct !== null && !data.offerId;
      const selectedProduct = isNewOffer
        ? { ...getState().selectedProduct }
        : determineProduct(data);

      const cascoOfferCreated = isNotNullOrUndefined(data.offerId); // && hasCascoOfferValues(data);
      const mtplOfferCreated = isNotNullOrUndefined(data.offerId); // && hasMtplOfferValues(data);
      const cascoInvoiceCreated = isNotNullOrUndefined(
        data.cascoInvoiceIssuedAt
      );
      const mtplInvoiceCreated = isNotNullOrUndefined(data.mtplInvoiceIssuedAt);
      const cascoPolicyCreated = isNotNullOrUndefined(data.cascoPolicyIssuedAt);
      const mtplPolicyCreated = isNotNullOrUndefined(data.mtplPolicyIssuedAt);

      const mtplPolicyWithoutInvoice = !mtplInvoiceCreated && mtplPolicyCreated;
      const cascoPolicyWithoutInvoice =
        !cascoInvoiceCreated && cascoPolicyCreated;
      const policyWithoutInvoice =
        mtplPolicyWithoutInvoice || cascoPolicyWithoutInvoice;

      const finalOfferRequestType =
        selectedProduct?.type === "generalBusiness"
          ? selectedProduct?.initialOffer?.offerRequestType ??
            data.offerRequestType
          : offerRequestType;

      const finalComparisonType =
        selectedProduct?.type === "mtplRenewal" ? "euro" : data.comparisonType;

      data = {
        ...selectedProduct.initialOffer,
        ...data,
        comparisonType: finalComparisonType,
        offerRequestType: finalOfferRequestType,
      };
      setState({
        selectedProduct: selectedProduct,

        offer: data,
        refreshFormItems: true,

        offerLoadStatus: "success",
        mainTab: {
          ...getState().mainTab,
          selectedTab:
            cascoPolicyCreated || mtplPolicyCreated || policyWithoutInvoice
              ? "policy"
              : cascoInvoiceCreated || mtplInvoiceCreated
              ? "invoice"
              : cascoOfferCreated || mtplOfferCreated
              ? "offer"
              : "offer",
        },
        offerTab: {
          ...getState().offerTab,
          disabledTabs: isNewOffer
            ? ["tab2", "tab3", "tab4", "tab5", "tab6", "tab7", "tab8", "tab9"]
            : [],
          selectedTab: cascoOfferCreated || mtplOfferCreated ? "tab9" : "tab1",
        },
        invoiceTab: {
          ...getState().invoiceTab,
          selectedTab: selectedProduct.hasCasco ? "tab1" : "tab2",
        },
        policyTab: {
          ...getState().policyTab,
          selectedTab: selectedProduct.hasCasco ? "tab1" : "tab2",
        },
        progress: {
          cascoOfferCreated,
          mtplOfferCreated,
          cascoInvoiceCreated,
          mtplInvoiceCreated,
          cascoPolicyCreated,
          mtplPolicyCreated,
          mtplPolicyWithoutInvoice,
          cascoPolicyWithoutInvoice,
          policyWithoutInvoice,
        },
        showIncrease:
          data.inp_discount_p !== null && (data.inp_discount_p + "").length > 0
            ? false
            : true,
        showDiscount:
          data.inp_increase_p !== null && (data.inp_increase_p + "").length > 0
            ? false
            : true,
        showLicensePlate:
          data.offerRequestType === "F100056" &&
          (data.inp_vehicle_registration ===
            "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            data.inp_vehicle_registration ===
              "bcr.Registration_type_IN_ORDER_TO_MATRICULATE")
            ? false
            : true,
        avarie8:
          data.avarie8 === "true" || data.avarie8 === true ? true : false,
        // inp_vehicle_category: data.inp_vehicle_category
        //   ? taxonomyFlat["inp_vehicle_category"][data.inp_vehicle_category]
        //   : null,
        // inp_vehicle_subcategory: data.inp_vehicle_subcategory
        //   ? taxonomyFlat["inp_vehicle_subcategory"][
        //       data.inp_vehicle_subcategory
        //     ]
        //   : null,
        // inp_lessee_type: data.inp_lessee_type
        //   ? taxonomyFlat["inp_lessee_type"][data.inp_lessee_type]
        //   : null,
        selectedCascoInsurers: data.selectedCascoInsurers
          ? data.selectedCascoInsurers
          : selectedProduct.hasCasco
          ? ["omniasig", "asirom", "allianz", "uniqa", "generali", "groupama"]
          : null,
        selectedMtplInsurers: data.selectedMtplInsurers
          ? data.selectedMtplInsurers
          : selectedProduct.hasMtpl
          ? ["omniasig", "asirom", "allianz", "generali", "groupama", "grawe"]
          : null,
      });

      let titlePrefix = "";
      if (selectedProduct?.type === "generalBusiness") {
        titlePrefix = "BCR Leasing | F100056 | ";
      } else if (selectedProduct?.type === "casco") {
        titlePrefix = "BCR Leasing | " + offerRequestType + " | ";
      } else if (selectedProduct?.type === "cascoMtpl") {
        titlePrefix = "BCR Leasing | " + offerRequestType + " | ";
      } else if (selectedProduct?.type === "mtplRenewal") {
        titlePrefix = "BCR Leasing | " + offerRequestType + " | ";
      }

      onSuccess &&
        onSuccess(
          selectedProduct
            ? titlePrefix + t("bcr.product." + selectedProduct?.type)
            : "",
          data
        );
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const calculateCasco =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.calculateCasco(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const getVehicleInfo =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.getVehicleInfo(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const getCompanyInfo =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.getCompanyInfo(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const calculateCascoAndMtpl =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.calculateCascoAndMtpl(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const getOffer =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.getQuotes(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const invalidateOffer =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.invalidateOffer(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const generateCascoInvoice =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.generateCascoInvoice(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const issueCascoPolicy =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.issueCascoPolicy(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const issueCascoTotalPolicy =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.issueCascoTotalPolicy(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const issueCascoPartialPolicy =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.issueCascoPartialPolicy(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const generateMtplInvoice =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.generateMtplInvoice(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const issueMtplPolicy =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.issueMtplPolicy(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };
