import { useTheme } from "styled-components";
import { VSpace } from "components/Spacer";
import CheckboxField from "components/controls9/CheckboxField";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import TextInputField from "components/controls9/TextInputField";
import moment from "moment";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { TabProps } from "types/types";
import { resolverFn } from "utils/setYupLocale";
import { reverse_romanian_label } from "utils/types";
import { fieldToTab, getClasses, prepareFormItemsForSubmit } from "utils/utils";
import * as yup from "yup";
import TabCard from "../TabCard";
import { MessageBarType } from "office-ui-fabric-react";
import { CertificateFilled } from "@fluentui/react-icons";
import {
  Button,
  Spinner,
  makeStyles,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { toast } from "components/FluentToast";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const PolicyTab2 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { setValue, getValues, setError, clearErrors } = props.form;
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const [
    { romanianTranslations },
    { setNotificationMessage, clearAllNotifications },
  ] = useAppStore();

  const [
    { selectedProduct, progress, mainTab, policyTab },
    { issueMtplPolicy, setFormStoreValues },
  ] = formPageStore;
  const navigate = useNavigate();

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.mtplPolicyCreated;

  const theme = useTheme();
  const isDarkMode = theme.isDark;

  const classes = getClasses(theme, isDarkMode);
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  const cardFooter = (
    <>
      {progress.mtplPolicyCreated ? (
        <Button
          icon={
            isLoading ? (
              <Spinner size="tiny" />
            ) : (
              <CertificateFilled fontSize={16} />
            )
          }
          appearance="primary"
          disabled={disabledMap?.downloadMtplPolicy}
          name="downloadCascoPolicy"
          onClick={() => {
            window.open(getValues("pathToPolicyMtplPdf"), "_blank");
          }}
          // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToPolicyCascoPdf,'bcrcascopolicy');")}}
        >
          {t(reverse_romanian_label("Polita"))}
        </Button>
      ) : null}
      {!progress.mtplPolicyCreated ? (
        <>
          <Button
            icon={<CertificateFilled fontSize={16} />}
            appearance="primary"
            // isLoading={isLoading}
            name="generateMtplPolicy"
            // visible={visibleMap?.generateCascoPolicy}
            disabled={
              isLoading || disabledTab || disabledMap?.generateMtplPolicy
            }
            onClick={() => {
              props.form.clearErrors();
              clearAllNotifications();
              resolverFn(
                selectedProduct?.validationMap["policyTab2"](yup, t),
                getValues()
              ).then((res) => {
                if (Object.keys(res.errors).length > 0) {
                  toast.error(t("bcr.validationErrors.label"));
                  Object.keys(res.errors).forEach((key) => {
                    setError(key, res.errors[key]);
                    setNotificationMessage({
                      errors: [],
                      key: key,
                      messageBarType: MessageBarType.error,
                      fieldName: t("bcr." + key + ".label"),
                      handleNotificationClick: (e) => {
                        setFormStoreValues({
                          mainTab: { ...mainTab, selectedTab: "policy" },
                          policyTab: {
                            ...policyTab,
                            selectedTab: fieldToTab(key),
                          },
                        });
                      },
                      notify: {
                        label: "label",
                        type: "error",
                        notifyCode: "notifyCode",
                        notifyText: (
                          <>
                            <b>{t("bcr." + key + ".label")}</b>
                            <br />
                            {res.errors[key].message}
                          </>
                        ),
                      },
                    });
                  });
                } else {
                  setConfirmDialogOpen(true);
                }
              });
            }}
          >
            {t(reverse_romanian_label("Emiteti polita"))}
          </Button>
          <Dialog modalType="alert" open={confirmDialogOpen}>
            <DialogSurface>
              <DialogBody>
                <DialogTitle>
                  {t(reverse_romanian_label("Emiteti polita"))}
                </DialogTitle>
                <DialogContent>
                  {t(
                    reverse_romanian_label(
                      "A fost receptionata oferta semnata?"
                    )
                  )}
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button
                      disabled={isLoading}
                      appearance="secondary"
                      onClick={() => {
                        setConfirmDialogOpen(false);
                      }}
                    >
                      {t("greco.no")}
                    </Button>
                  </DialogTrigger>
                  <Button
                    icon={
                      isLoading ? (
                        <Spinner size="tiny" />
                      ) : (
                        <CertificateFilled fontSize={16} />
                      )
                    }
                    disabled={isLoading}
                    appearance="primary"
                    onClick={() => {
                      setValue("confirmationForMtplPolicyIssuing", true);
                      issueMtplPolicy(
                        {
                          appInfo: {
                            dataObj: {
                              ...prepareFormItemsForSubmit(
                                getValues(),
                                romanianTranslations
                              ),
                            },
                            resolvedProperties: {
                              title: "BCR Leasing",
                            },
                          },
                        },
                        (retVal) => {
                          if (retVal.pathToInvoiceMtplPdf) {
                            window.open(retVal.pathToPolicyMtplPdf, "_blank");
                          }
                          window.location.reload();
                        },
                        (err) => {
                          setConfirmDialogOpen(false);
                          setNotificationMessage({
                            errors: [],
                            key: "key",
                            messageBarType: MessageBarType.error,
                            fieldName: "fieldName",
                            handleNotificationClick: (e) => {},
                            notify: {
                              label: "label",
                              type: "error",
                              notifyCode: "notifyCode",
                              notifyText: (
                                <>
                                  <b>{"IssueMTPLPolicy"}</b>
                                  <br />
                                  {err.response.data.Message}
                                </>
                              ),
                            },
                          });
                        }
                      );
                    }}
                  >
                    {t("greco.yes")}
                  </Button>
                </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        </>
      ) : null}
    </>
  );
  if (!selectedProduct) return null;

  return (
    <>
      <TabCard footer={cardFooter}>
        <div className={styles.grid}>
          {/* <div className={styles.grid}>
           <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Decont"))}
              name="downloadMtplPolicy"
              visible={visibleMap?.downloadMtplPolicy}
              disabled={disabledTab || disabledMap?.downloadMtplPolicy}
              onClick={() => {
                window.open(getValues("pathToPolicyMtplPdf"), "_blank");
              }}
              // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToPolicyMtplPdf,'bcrmtplpolicy');")}}
            />
         </div> */}
          <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label("Data platii"))}
            {requiredMap.paymentDate ? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <DatePickerField
              isLoading={isLoading}
              form={props.form}
              name="paymentDate"
              label={reverse_romanian_label("Data platii")}
              disabled={disabledTab || disabledMap?.paymentDate}
              data-format="DD.MM.YYYY"
              data-dateFormat="DD.MM.YYYY"
              required={true}
              visible={visibleMap?.paymentDate}
              maxDate={moment().endOf("day").toDate()}
            />
          </div>
          {/* <div style={labelStyle(2, 1)}>
            {t(reverse_romanian_label("A fost receptionata oferta semnata?"))}
            {requiredMap.confirmationForMtplPolicyIssuing ? "*" : ""}
          </div>
          <div style={fieldStyle(2, 2)}>
            <CheckboxField
              isLoading={isLoading}
              form={props.form}
              name="confirmationForMtplPolicyIssuing"
              label={reverse_romanian_label(
                "A fost receptionata oferta semnata?"
              )}
              required={false}
              disabled={
                disabledTab || disabledMap?.confirmationForMtplPolicyIssuing
              }
              visible={visibleMap?.confirmationForMtplPolicyIssuing}

              // customConditional:"show = data.policyMtplId === ''"
            />
          </div> */}

          {/* <div style={labelStyle(1, 1)}>{t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}</div>
          <div style={fieldStyle(1, 2)}>
            <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Emiteti polita"))}
              name="issueMtplPolicy"
              visible={visibleMap?.issueMtplPolicy}
              disabled={disabledTab || disabledMap?.issueMtplPolicy}
              onClick={() => {
                issueMtplPolicy(
                  {
                    appInfo: {
                      dataObj: {
                        ...prepareFormItemsForSubmit(
                          getValues(),
                          romanianTranslations
                        ),
                      },
                      resolvedProperties: {
                        title: "BCR Leasing",
                      },
                    },
                  },
                  (retVal) => {
                    disableWhenPolicyMtplIdExists({
                      data: props.form.getValues(),
                      form: props.form,
                      formPageStore: pageFormStore,
                    });
                    // mtplPolicyErrorCheck();
                    const preparedRetVal = prepareFormReturnValue(retVal, t);
                    for (const key in preparedRetVal) {
                      setValue(key, preparedRetVal[key]);
                    }
                  }
                );
              }}
              // "action success script": "formioForm.checkConditions();"
            />
          </div> */}
        </div>
        {progress?.mtplPolicyCreated ? (
          <>
            <VSpace height={20} />
            <hr />
            <VSpace height={20} />
            <div className={styles.grid}>
              <div style={labelStyle(1, 1)}>
                {t(reverse_romanian_label("Serie polita"))}
                {requiredMap.policyMtplSeries ? "*" : ""}
              </div>
              <div style={fieldStyle(1, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="policyMtplSeries"
                  label={reverse_romanian_label("Serie polita")}
                  required={false}
                  disabled={disabledTab || disabledMap?.policyMtplSeries}
                  visible={visibleMap?.policyMtplSeries}
                />
              </div>
              <div style={labelStyle(2, 1)}>
                {t(reverse_romanian_label("Numar polita"))}
                {requiredMap.policyMtplId ? "*" : ""}
              </div>
              <div style={fieldStyle(2, 2)}>
                <TextInputField
                  isLoading={isLoading}
                  name="policyMtplId"
                  label={reverse_romanian_label("Numar polita")}
                  form={props.form}
                  required={false}
                  disabled={disabledTab || disabledMap?.policyMtplId}
                  visible={visibleMap?.policyMtplId}
                />
              </div>
              <div style={labelStyle(3, 1)}>
                {t(reverse_romanian_label("Prima"))}
                {requiredMap.policyMtplPremium ? "*" : ""}
              </div>
              <div style={fieldStyle(3, 2)}>
                <NumberInputField
                  isLoading={isLoading}
                  form={props.form}
                  name="policyMtplPremium"
                  label={reverse_romanian_label("Prima")}
                  required={false}
                  disabled={disabledTab || disabledMap?.policyMtplPremium}
                  isNegativeAllowed={false}
                  visible={visibleMap?.policyMtplPremium}
                  contentAfter={"RON"}
                />
              </div>
              <div style={labelStyle(4, 1)}>
                {t(reverse_romanian_label("Polita emisa la"))}
                {requiredMap.mtplPolicyIssuedAt ? "*" : ""}
              </div>
              <div style={fieldStyle(4, 2)}>
                <DatePickerField
                  isLoading={isLoading}
                  form={props.form}
                  name="mtplPolicyIssuedAt"
                  label={reverse_romanian_label("Polita emisa la")}
                  disabled={disabledTab || disabledMap?.mtplPolicyIssuedAt}
                  data-format="dd.MM.yyyy HH:mm:ss"
                  data-dateFormat="DD.MM.YYYY HH:mm"
                  required={false}
                  visible={visibleMap?.mtplPolicyIssuedAt}
                  includeTime={true}
                />
              </div>
              <div style={labelStyle(5, 1)}>
                {t(reverse_romanian_label("Polita emisa de"))}
                {requiredMap.mtplPolicyIssuedByName ? "*" : ""}
              </div>
              <div style={fieldStyle(5, 2)}>
                <TextInputField
                  isLoading={isLoading}
                  name="mtplPolicyIssuedByName"
                  label={reverse_romanian_label("Polita emisa de")}
                  form={props.form}
                  required={false}
                  disabled={disabledTab || disabledMap?.mtplPolicyIssuedByName}
                  visible={visibleMap?.mtplPolicyIssuedByName}
                />
              </div>
              <div style={labelStyle(6, 1)}>
                {t(reverse_romanian_label(""))}
                {requiredMap.htmlMtplPolicyErrorMessage ? "*" : ""}
              </div>
              <div style={fieldStyle(6, 2)}>
                {visibleMap.htmlMtplPolicyErrorMessage ? (
                  <div
                    data-key="htmlMtplPolicyErrorMessage"
                    data-customclass="errorText"
                  >
                    {getValues("htmlMtplPolicyErrorMessage")}
                  </div>
                ) : null}
                {/* <div style={labelStyle(1, 1)}>
            {t(reverse_romanian_label(""))}{requiredMap.? "*" : ""}
          </div>
          <div style={fieldStyle(1, 2)}>
            <MyDefaultButton
              isLoading={isLoading}
              text={t(reverse_romanian_label("Polita"))}
              name="downloadMtplPolicy"
              visible={visibleMap?.downloadMtplPolicy}
              disabled={disabledTab || disabledMap?.downloadMtplPolicy}
              onClick={() => {
                window.open(getValues("pathToPolicyMtplPdf"), "_blank");
              }}
              // onClick={() = {TogFormViewer.openFile(TogFormViewer.getAppInfo().dataObj.pathToPolicyMtplPdf,'bcrmtplpolicy');")}}
            />
         </div> */}
              </div>
            </div>
          </>
        ) : null}
      </TabCard>
    </>
  );
};

export default PolicyTab2;
