import moment from "moment";
import { setYupLocale } from "utils/setYupLocale";

export const calculateCascoSchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({
    inp_lessee_type: yup.mixed().nullable().required(),
    inp_vehicle_state: yup.mixed().nullable().required(),
    inp_policy_value: yup.string().nullable().required(),
    inp_vehicle_construction: yup.mixed().nullable().required(),
    inp_external_contractdate: yup
      .date()
      .required()
      .nullable()
      .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
        if (value && value.getFullYear() !== moment().year()) {
          return false;
        }
        return true;
      })
      .max(new Date(), t("greco.form.errors.date.future")),
    inp_vehicle_firstreg: yup.mixed().nullable().required(),
    inp_vehicle_category: yup.mixed().nullable().required(),
    inp_vehicle_brand: yup.mixed().nullable().required(),
    inp_vehicle_model: yup.string().nullable().required(),
    inp_vehicle_maximumgrossweight: numberValidation(yup, t, true, 1, 99999),
    inp_vehicle_places: numberValidation(yup, t, true, 0, 99),
    inp_vehicle_carusage: yup.mixed().nullable().required(),
    inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
    inp_policy_currency: yup.mixed().nullable().required(),
    inp_contract_type: yup.mixed().nullable().required(),

    // Added altghough not required by backend
    inp_vehicle_fuel: yup.mixed().nullable().required(),

    inp_discount_insurer: yup
      .mixed()
      .nullable()
      .when(["inp_increase_p", "inp_discount_p"], (field1, field2, schema) => {
        return field1 || field2 ? schema.required() : schema.notRequired();
      }),
  });
};

export const calculateCascoAndMtplSchema = (
  yup,
  t,
  offerRequestType,
  include_mtpl_offer
) => {
  setYupLocale(t);
  let obj: any = {
    comparisonType: yup.mixed().nullable().required(),

    inp_lessee_type: yup.mixed().nullable().required(),
    inp_vehicle_state: yup.mixed().nullable().required(),
    inp_vehicle_construction: yup.mixed().nullable().required(),
    inp_vehicle_firstreg: yup.mixed().nullable().required(),
    inp_vehicle_category: yup.mixed().nullable().required(),
    inp_vehicle_brand: yup.mixed().nullable().required(),
    inp_vehicle_model: yup.string().nullable().required(),
    inp_vehicle_maximumgrossweight: numberValidation(yup, t, true, 1, 99999),
    inp_vehicle_places: numberValidation(yup, t, true, 0, 99),
    inp_vehicle_carusage: yup.mixed().nullable().required(),

    inp_contract_type: yup.mixed().nullable().required(),

    // Added altghough not required by backend
    inp_vehicle_fuel: yup.mixed().nullable().required(),

    inp_lessee_street: yup.string().nullable().required(),
    inp_lessee_housenr: yup.string().nullable().required(),
    inp_lessee_city: yup.string().nullable().required(),
    inp_lessee_county: yup.mixed().nullable().required(),
    inp_lessee_mobile: yup.string().nullable().required(),
    inp_lessee_email: yup.string().nullable().required(),
    inp_vehicle_vin: yup.string().length(17).nullable().required(),
    inp_vehicle_kw: yup.string().nullable().required(),
    inp_vehicle_cubiccapacity: yup.string().nullable().required(),

    inp_lessee_firstname: yup.string().when("inp_lessee_type", {
      is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    inp_lessee_lastname: yup.string().when("inp_lessee_type", {
      is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    inp_lessee_compreg: yup.string().when("inp_lessee_type", {
      is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),

    inp_lessee_company_name: yup.string().when("inp_lessee_type", {
      is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(),
    }),
    inp_lessee_uid: yup.string().when("inp_lessee_type", {
      is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(),
    }),

    // inp_start_date,
    // inp_sicgroup,
    // inp_vehicle_id,
    // inp_vehicle_registration,
    // inp_licence_date,
    // inp_vehicle_licenseplate
  };
  if (include_mtpl_offer) {
    obj = {
      ...obj,

      inp_start_date: yup.date().required().nullable(),
      inp_sicgroup: yup.mixed().nullable().required(),
      inp_vehicle_id: yup.string().nullable().required(),
      inp_vehicle_registration: yup.mixed().nullable().required(),
      inp_licence_date: yup.date().when("inp_lessee_type", {
        is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
        then: yup.date().nullable().required(),
        otherwise: yup.date().nullable().notRequired(),
      }),
      inp_vehicle_licenseplate: yup.string().nullable().required(),
    };
  }
  if (offerRequestType !== "F100056") {
    obj = {
      ...obj,

      inp_policy_value: yup.string().nullable().required(),
      inp_external_contractdate: yup
        .date()
        .required()
        .nullable()
        .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
          if (value && value.getFullYear() !== moment().year()) {
            return false;
          }
          return true;
        })
        .max(new Date(), t("greco.form.errors.date.future")),
      inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
      inp_policy_currency: yup.mixed().nullable().required(),
    };
  }
  return yup.object().shape(obj);
};

export const getQuotesSchema = (yup, t, offerRequestType) => {
  setYupLocale(t);
  let obj: any = {
    inp_lessee_type: yup.mixed().nullable().required(),
    inp_vehicle_state: yup.mixed().nullable().required(),
    inp_vehicle_construction: yup.mixed().nullable().required(),
    inp_vehicle_firstreg: yup.mixed().nullable().required(),
    inp_vehicle_category: yup.mixed().nullable().required(),
    inp_vehicle_brand: yup.mixed().nullable().required(),
    inp_vehicle_model: yup.string().nullable().required(),
    // inp_vehicle_model: "Completati corect campul",
    inp_vehicle_maximumgrossweight: yup
      .number()
      .nullable()
      .min(1)
      .max(99999)
      .required(),
    inp_vehicle_places: yup.number().nullable().min(0).max(99).required(),
    inp_vehicle_carusage: yup.mixed().nullable().required(),
    inp_vehicle_fleet: yup.mixed().nullable().required(),
    inp_contract_type: yup.mixed().nullable().required(),

    inp_lessee_street: yup.string().nullable().required(),
    inp_lessee_housenr: yup.string().nullable().required(),
    inp_lessee_city: yup.string().nullable().required(),
    inp_lessee_county: yup.mixed().nullable().required(),
    inp_lessee_mobile: yup.string().nullable().required(),
    inp_lessee_email: yup.string().nullable().required(),
    inp_vehicle_vin: yup.string().length(17).nullable().required(),
    inp_vehicle_kw: yup.string().nullable().required(),
    inp_vehicle_cubiccapacity: yup.string().nullable().required(),

    // export const inp_lessee_type = {
    //   "Persoana fizica": "bcr.PersonType.CIVIL",
    //   "Persoana juridica": "bcr.PersonType.LEGAL",
    // };

    inp_lessee_firstname: yup.string().when("inp_lessee_type", {
      is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    inp_lessee_lastname: yup.string().when("inp_lessee_type", {
      is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    inp_lessee_compreg: yup.string().when("inp_lessee_type", {
      is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
  };
  if (offerRequestType !== "F100056") {
    obj = {
      ...obj,
      inp_policy_value: yup.string().nullable().required(),
      inp_policy_payment: yup.mixed().nullable().required(),
      inp_leasing_monthsinterval: yup
        .number()
        .nullable()
        .min(12)
        .max(84)
        .required(),
      inp_policy_currency: yup.mixed().nullable().required(),

      inp_start_date: yup.date().required().nullable(),
      inp_sicgroup: yup.mixed().nullable().required(),
      inp_vehicle_id: yup.string().nullable().required(),
      inp_vehicle_registration: yup.string().nullable().required(),
      inp_vehicle_licenseplate: yup.string().nullable().required(),

      inp_lessee_company_name: yup.string().when("inp_lessee_type", {
        is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),
      inp_lessee_uid: yup.string().when("inp_lessee_type", {
        is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),

      inp_licence_date: yup.date().when("inp_lessee_type", {
        is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
        then: yup.date().nullable().required(),
        otherwise: yup.date().nullable().notRequired(),
      }),
    };
  } else {
    obj = {
      ...obj,
      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
      inp_sicgroup: yup.mixed().nullable().required(),
      inp_vehicle_id: yup
        .string()
        .length(7)
        .nullable()
        .matches(/^[A-Za-z]\d+$/, t("bcr.validation.inp_vehicle_id"))
        .required(),
      inp_vehicle_registration: yup.mixed().nullable().required(),
      inp_licence_date: yup.date().nullable().required(),
      inp_vehicle_licenseplate: yup.string().nullable().required(),
      inp_lessee_compreg: yup.string().when("inp_lessee_type", {
        is: (field) => field && field?.value === "bcr.PersonType.CIVIL",
        then: yup
          .string()
          .nullable()
          .length(13)
          .matches(/^\d+$/, t("bcr.validation.inp_lessee_compreg"))
          .required(),
        otherwise: yup
          .string()
          .nullable()
          .length(13)
          .matches(/^\d+$/)
          .notRequired(),
      }),
    };
  }
  return yup.object().shape(obj);
};

export const useFormValidationSchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({
    inp_lessee_type: yup.mixed().nullable().required(),
    inp_vehicle_state: yup.mixed().nullable().required(),
    inp_policy_value: yup.number().nullable().required(),
    inp_external_contractdate: yup
      .date()
      .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
        if (value && value.getFullYear() !== moment().year()) {
          return false;
        }
        return true;
      }),
    inp_vehicle_construction: yup.mixed().nullable().required(),
    inp_vehicle_firstreg: yup.mixed().nullable().required(),
    inp_vehicle_category: yup.mixed().nullable().required(),
    inp_vehicle_brand: yup.mixed().nullable().required(),
    inp_vehicle_model: yup.string().nullable().required(),
    // inp_vehicle_model: "Completati corect campul",
    inp_vehicle_maximumgrossweight: yup
      .number()
      .nullable()
      .min(1)
      .max(99999)
      .required(),
    inp_vehicle_places: yup.number().nullable().min(0).max(99).required(),
    inp_vehicle_carusage: yup.mixed().nullable().required(),
    inp_policy_payment: yup.mixed().nullable().required(),
    inp_leasing_monthsinterval: yup
      .number()
      .nullable()
      .min(12)
      .max(84)
      .required(),
    inp_vehicle_fleet: yup.mixed().nullable().required(),
    inp_policy_currency: yup.mixed().nullable().required(),
    inp_contract_type: yup.mixed().nullable().required(),
    inp_vehicle_fuel: yup.mixed().nullable().required(),
    // inp_lessee_compreg: "Completati corect campul",
    // inp_caen: "Completati corect campul",
    // inp_lessee_email: "Completati corect campul",

    // inp_vehicle_vin: ? "if (isUndefinedOrNullOrEmpty('inp_vehicle_vin')) {   $('.easy-target').removeClass('warning-text-color');   valid = false; } else if (data.inp_vehicle_vin.length <17) {   $('.easy-target').addClass('warning-text-color');   valid = 'Seria de sasiu nu contine 17 caractere'; } else if (data.inp_vehicle_vin.length > 17) {   $('.easy-target').removeClass('warning-text-color');   valid = 'Completati corect campul'; } else  {   $('.easy-target').removeClass('warning-text-color');   valid = false; }",

    inp_start_date: yup
      .date()
      .test("valid-year", t("bcr.validation.dateInThePast"), function (value) {
        if (value && value < moment().startOf("day").toDate()) {
          return false;
        }
        return true;
      }),

    // ? "if (data.inp_start_date !== '' && new Date(data.inp_start_date) < moment().startOf('day')) {valid = 'Completati corect campul';} else {valid = false;}",

    // inp_vehicle_id: "Completati corect campul",
    // inp_driver_compreg: "Completati corect campul",
    inp_vehicle_kw: yup.number().nullable().min(0).max(99999).required(),
    inp_vehicle_cubiccapacity: yup
      .number()
      .nullable()
      .min(0)
      .max(99999)
      .required(),
    insurerCasco: yup.mixed().nullable().required(),
    leasingctrno: yup.date().nullable().required(),
    validFrom: yup.date().nullable().required(),
    // validFrom: ? "if (data.validFrom !== '' && new Date(data.validFrom) < moment().startOf('day')) {valid = 'Completati corect campul';} else {valid = false;}",
    deliveryType: yup.mixed().nullable().required(),
    inp_vehicle_vin2: yup.date().nullable().required(),
    // inp_vehicle_vin2: ? "if (isUndefinedOrNullOrEmpty('inp_vehicle_vin2')) {   $('.easy-target').removeClass('warning-text-color');   valid = false; } else if (data.inp_vehicle_vin2.length <17) {   $('.easy-target').addClass('warning-text-color');   valid = 'Seria de sasiu nu contine 17 caractere'; } else if (data.inp_vehicle_vin2.length > 17) {   $('.easy-target').removeClass('warning-text-color');   valid = 'Completati corect campul'; } else  {   $('.easy-target').removeClass('warning-text-color');   valid = false; }",
    insurerMtpl: yup.mixed().nullable().required(),
    months: yup.mixed().nullable().required(),
    paymentDate: yup.date().nullable().required(),
    // paymentDate: ? "if (data.paymentDate !== '' && data.inp_start_date !== '' && data.mtplInvoiceIssuedAt !== '' && (new Date(data.paymentDate) < new Date(data.mtplInvoiceIssuedAt.substring(0, data.mtplInvoiceIssuedAt.indexOf('T'))) || new Date(data.paymentDate) > new Date(data.inp_start_date) || new Date(data.paymentDate) > moment().endOf('day'))) {valid = 'Completati corect campul';} else {valid = false;}",
    mtpl_commission_broker_p: yup
      .number()
      .nullable()
      .min(0)
      .max(100)
      .required(),
  });
};

function numberValidation(
  yup: any,
  t: any,
  required: boolean,
  min: number | null,
  max: number | null
) {
  let retVal = yup
    .string()
    .nullable()
    .test("is-number", "Must be a number", (value) => {
      if (value === null || value === "") {
        return true;
      }
      // const germanNumberRegex = /^\d{1,3}(\.\d{3})*(,\d+)?$/;
      // if (!germanNumberRegex.test(value)) {
      //   return false;
      // }
      const germanLocale = "de-DE";
      const parsedNumber = Number(value); //.replaceAll(".", "").replace(",", "."));
      const formattedNumber = parsedNumber.toLocaleString(germanLocale);
      return !isNaN(parsedNumber); // && formattedNumber === value;
    })
    .transform((value) => (value === "" ? null : value));

  if (required) {
    retVal = retVal.required();
  }
  if (min) {
    retVal = retVal.test(
      "min",
      t("greco.form.errors.number.greaterorequal", { min }),
      (value) => {
        if (value === null) {
          return true;
        }
        const parsedNumber = Number(
          value //.replaceAll(".", "").replace(",", ".")
        );
        return parsedNumber >= min;
      }
    );
  }
  if (max) {
    retVal = retVal.test(
      "max",
      t("greco.form.errors.number.lessorequal", { max }),
      (value) => {
        if (value === null) {
          return true;
        }
        const parsedNumber = Number(
          value //.replaceAll(".", "").replace(",", ".")
        );
        return parsedNumber <= max;
      }
    );
  }
  return retVal;
}
