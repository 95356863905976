import React from "react";
import { useFormPageStore } from "store/FormPageStore";
import { reverse_romanian_label } from "utils/types";
import OfferTab1 from "./offer/OfferTab1";
import OfferTab2 from "./offer/OfferTab2";
import OfferTab3 from "./offer/OfferTab3";
import OfferTab4 from "./offer/OfferTab4";
import OfferTab5 from "./offer/OfferTab5";
import OfferTab6 from "./offer/OfferTab6";
import OfferTab7 from "./offer/OfferTab7";
import OfferTab8 from "./offer/OfferTab8";
import OfferTab9 from "./offer/OfferTab9";
import PolicyTab1 from "./policy/PolicyTab1";
import PolicyTab2 from "./policy/PolicyTab2";
import InvoiceTab1 from "./invoice/InvoiceTab1";
import InvoiceTab2 from "./invoice/InvoiceTab2";

const MainTabCard = ({ form, isLoading }) => {
  const [{ mainTab, offerTab, invoiceTab, policyTab }, {}] = useFormPageStore();
  return (
    <>
      {mainTab.selectedTab === "offer" ? (
        <>
          {offerTab.selectedTab === "tab1" ? (
            <OfferTab1 form={form} isLoading={isLoading} />
          ) : null}
          {offerTab.selectedTab === "tab2" ? (
            <OfferTab2 form={form} isLoading={isLoading} />
          ) : null}
          {/* {offerTab.selectedTab === "tab3" ? (
            <OfferTab3 form={form} isLoading={isLoading} />
          ) : null} */}
          {offerTab.selectedTab === "tab4" ? (
            <OfferTab4 form={form} isLoading={isLoading} />
          ) : null}
          {offerTab.selectedTab === "tab5" ? (
            <OfferTab5 form={form} isLoading={isLoading} />
          ) : null}
          {offerTab.selectedTab === "tab6" ? (
            <OfferTab6 form={form} isLoading={isLoading} />
          ) : null}
          {offerTab.selectedTab === "tab7" ? (
            <OfferTab7 form={form} isLoading={isLoading} />
          ) : null}
          {offerTab.selectedTab === "tab8" ? (
            <OfferTab8 form={form} isLoading={isLoading} />
          ) : null}
          {offerTab.selectedTab === "tab9" ? (
            <OfferTab9 form={form} isLoading={isLoading} />
          ) : null}
        </>
      ) : null}
      {mainTab.selectedTab === "invoice" ? (
        <>
          {invoiceTab.selectedTab === "tab1" ? (
            <InvoiceTab1 form={form} isLoading={isLoading} />
          ) : null}
          {invoiceTab.selectedTab === "tab2" ? (
            <InvoiceTab2 form={form} isLoading={isLoading} />
          ) : null}
        </>
      ) : null}
      {mainTab.selectedTab === "policy" ? (
        <>
          {policyTab.selectedTab === "tab1" ? (
            <PolicyTab1 form={form} isLoading={isLoading} />
          ) : null}
          {policyTab.selectedTab === "tab2" ? (
            <PolicyTab2 form={form} isLoading={isLoading} />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default MainTabCard;
