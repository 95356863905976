import React from "react";
import {
  ExcelExportColumn,
  ExcelExport,
} from "@progress/kendo-react-excel-export";
import { useTranslation } from "react-i18next";
import { ExpandedColumn } from "types/columns";
import { useListPageStore, useListPageStoreState } from "store/ListPageStore";
import { formatDateWithTime } from "utils/pdf";

type Props = {
  columns: ExpandedColumn[];
};

export const ExportExcelVehicles = React.forwardRef<ExcelExport, Props>(
  (props, ref) => {
    const { columns } = props;
    const { t } = useTranslation();
    // console.log("COLUMNS",columns);
    const actualColumns = columns
      .filter((c) => c.key !== "options")
      .filter((c) => c.isVisible);

    const { vehicleItems } = useListPageStoreState();
    return (
      <ExcelExport
        data={vehicleItems}
        ref={ref}
        fileName={`Oferta ${formatDateWithTime(new Date())}`}
      >
        {actualColumns.map((col) => {
          if (col.excel && col.excel.isVisible === false) return null;
          let width = col.width;
          if (col.excel && col.excel.width !== undefined) {
            width = col.excel.width;
          }
          return (
            <ExcelExportColumn
              key={col.key}
              field={col.key}
              title={t(col.labelKey)}
              cellOptions={{ format: "dd.MM.yyyy" }}
              width={width}
              headerCellOptions={{
                background: "#005aa1",
                color: "#fff",
                textAlign: col.align ?? ("center" as any),
              }}
            />
          );
        })}
      </ExcelExport>
    );
  }
);
