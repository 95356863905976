import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ListContextMenuIconButton } from "../../components/styled";
// import { routes } from '../../../config/routes';
import { OfferAzureSearch } from "../../types/types";
import * as API from "../../api/api";
import { useListPageStoreActions } from "store/ListPageStore";
import { useFormPageStoreActions } from "store/FormPageStore";
import { routes } from "config/routes";
import { determineProduct } from "types/products";

type Props = {
  offer?: OfferAzureSearch;
};

export const ContextButton = ({ offer }: Props) => {
  // console.log("OFFER",offer);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setListStoreValue } = useListPageStoreActions();
  const { setFormStoreValue } = useFormPageStoreActions();

  const onClickInvalidateOffer = async () => {
    setListStoreValue("invalidateOfferDialog", {
      isOpen: true,
      offer,
    });
    // const payload: any = {
    //   appInfo: {
    //     dataObj: {
    //       offerId: offer.offerId,
    //     },
    //   },
    // };
    // await API.invalidateOfferReq(payload);
  };

  const onClickRepeatOffer = async () => {
    const payload: any = {
      appInfo: {
        dataObj: {
          offerId: offer.offerId,
        },
      },
    };
    setFormStoreValue("repeatOfferPayload", payload);
    navigate(routes.formPageRepeatOffer.getPath);
  };

  const items = [
    {
      key: "invalidateOffer",
      text: t("bcr.offers.contextMenu.invalidate"),
      iconProps: { iconName: "ErrorBadge" },
      disabled:
        offer.status.toUpperCase().includes("DECONT") ||
        offer.status.toUpperCase().includes("POLITA"),
      onClick: onClickInvalidateOffer as any,
    },
    {
      key: "repeatOffer",
      text: t("bcr.offers.contextMenu.repeat"),
      iconProps: { iconName: "Refresh" },
      disabled: false,
      onClick: onClickRepeatOffer as any,
    },
  ];
  return (
    <ListContextMenuIconButton
      iconProps={{ iconName: "MoreVertical" }}
      menuIconProps={{ style: { display: "none" } }}
      menuProps={{
        items: items,
      }}
    />
  );
};
