import {
  createStore,
  createHook,
  createActionsHook,
  createStateHook,
} from "react-sweet-state";
import { defaults } from "react-sweet-state";
import { DataObj, LoadStatus } from "types/types";
import { reverse_romanian_label, taxonomy } from "utils/types";
import {
  calculateCasco,
  calculateCascoAndMtpl,
  duplicateOffer,
  generateCascoInvoice,
  generateMtplInvoice,
  getOffer,
  getVehicleInfo,
  getCompanyInfo,
  invalidateOffer,
  issueCascoPartialPolicy,
  issueCascoPolicy,
  issueCascoTotalPolicy,
  issueMtplPolicy,
  loadOffer,
  setStoreValue,
  setStoreValues,
} from "./FormPageStoreActions";
import {
  initialDisableMap,
  initialOffer,
  initialRequiredMap,
  initialVisibilityMap,
} from "./FormPageStoreInitialValues";
import { InsuranceProduct, PolicyProgress } from "types/products";
import {
  FormPageTab,
  initialInvoiceTab,
  initialMainTab,
  initialOfferTab,
  initialPolicyTab,
} from "types/formPageTabs";
defaults.devtools = true;

export type StoreState = {
  offer: DataObj;
  calculationInfo: DataObj;
  refreshFormItems: boolean;
  initialOffer: DataObj;
  visibleMap: any;
  disabledMap: { [key: string]: boolean };
  requiredMap: { [key: string]: boolean };
  offerLoadStatus: LoadStatus;
  dataLoadingStatus: LoadStatus;
  repeatOfferPayload: any;
  comparisonTypeOptions: {
    value: string;
    label: string;
  }[];
  contractTypeOptions: {
    value: string;
    label: string;
  }[];
  fuelTypeOptions: {
    value: string;
    label: string;
  }[];
  sicCodeOptions: {
    value: string;
    label: string;
  }[];
  vehicleRegistrationOptions: {
    value: string;
    label: string;
  }[];
  subCategories: {
    value: string;
    label: string;
  }[];
  cities: [];
  insurerCascoList: {
    value: string;
    label: string;
  }[];
  insurerMtplList: {
    value: string;
    label: string;
  }[];
  months: {
    value: string;
    label: string;
  }[];
  accordionCollapsedMap: { [key: string]: boolean };

  htmlErrorMessage: string;

  selectedProduct: InsuranceProduct;
  selectedCascoInsurers: string[];
  selectedMtplInsurers: string[];
  progress: PolicyProgress;
  // inp_lessee_type: string;

  // inp_vehicle_category: string;
  // inp_vehicle_subcategory: string;
  // inp_vehicle_subcategory_items: any[];
  // inp_contract_type_items: any[];
  // inp_vehicle_fuel_items: any[];
  // inp_vehicle_registration_items: any[];
  // inp_sicgroup_items: any[];
  // insurerCasco_items: any[];
  // insurerMtpl_items: any[];

  // avarie8: boolean;
  mainTab: FormPageTab;
  offerTab: FormPageTab;
  invoiceTab: FormPageTab;
  policyTab: FormPageTab;

  showDiscount: boolean;
  showIncrease: boolean;
  showLicensePlate: boolean;
  avarie8: boolean;
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  offer: initialOffer,
  calculationInfo: initialOffer,
  offerLoadStatus: "none",

  refreshFormItems: false,

  initialOffer: initialOffer,
  visibleMap: initialVisibilityMap,
  disabledMap: initialDisableMap,
  requiredMap: initialRequiredMap,

  selectedCascoInsurers: null,
  selectedMtplInsurers: null,

  repeatOfferPayload: null,

  comparisonTypeOptions: taxonomy["comparisonType"],
  contractTypeOptions: taxonomy["inp_contract_type"],
  subCategories: taxonomy["inp_vehicle_subcategory"],
  cities: [],
  fuelTypeOptions: taxonomy["inp_vehicle_fuel"],
  vehicleRegistrationOptions: taxonomy["inp_vehicle_registration"],
  sicCodeOptions: taxonomy["inp_sicgroup"],
  insurerCascoList: taxonomy["insurerCasco"],
  insurerMtplList: taxonomy["insurerMtpl"],
  months: taxonomy["months"],

  accordionCollapsedMap: {
    [reverse_romanian_label("Introducere date")]: false,
    [reverse_romanian_label("Clauze CASCO")]: true,
    [reverse_romanian_label("Utilizator")]: true,
    [reverse_romanian_label("Vehicul asigurat")]: true,
    [reverse_romanian_label("Informatii suplimentare RCA")]: true,
    [reverse_romanian_label("Oferta CASCO")]: true,
    [reverse_romanian_label("Oferta RCA (RON)")]: true,
  },
  htmlErrorMessage: null,

  selectedProduct: null,
  progress: {
    cascoOfferCreated: false,
    cascoInvoiceCreated: false,
    cascoPolicyCreated: false,
    mtplOfferCreated: false,
    mtplInvoiceCreated: false,
    mtplPolicyCreated: false,

    mtplPolicyWithoutInvoice: false,
    cascoPolicyWithoutInvoice: false,
    policyWithoutInvoice: false,
  },
  dataLoadingStatus: "none",

  // inp_lessee_type: null,
  // inp_vehicle_category: null,
  // inp_vehicle_subcategory: null,
  // inp_vehicle_subcategory_items: [],
  // inp_contract_type_items: [],
  // inp_vehicle_fuel_items: [],
  // inp_vehicle_registration_items: [],
  // inp_sicgroup_items: [],
  // insurerCasco_items: [],
  // insurerMtpl_items: [],

  // avarie8: false,
  mainTab: initialMainTab,
  offerTab: initialOfferTab,
  invoiceTab: initialInvoiceTab,
  policyTab: initialPolicyTab,

  showDiscount: true,
  showIncrease: true,
  showLicensePlate: true,
  avarie8: false,
};

// All the actions that mutate the store
const actions = {
  setFormStoreValue: setStoreValue,
  setFormStoreValues: setStoreValues,
  reset:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
  loadOffer: loadOffer,
  calculateCasco: calculateCasco,
  calculateCascoAndMtpl: calculateCascoAndMtpl,
  getOffer: getOffer,
  duplicateOffer: duplicateOffer,
  invalidateOffer: invalidateOffer,
  generateCascoInvoice: generateCascoInvoice,
  issueCascoPolicy: issueCascoPolicy,
  issueCascoTotalPolicy: issueCascoTotalPolicy,
  issueCascoPartialPolicy: issueCascoPartialPolicy,
  generateMtplInvoice: generateMtplInvoice,
  issueMtplPolicy: issueMtplPolicy,
  getVehicleInfo: getVehicleInfo,
  getCompanyInfo: getCompanyInfo,

  // loadOfferRequestType: (offerRequestType) => Promise<void>;
  // resetState: any;
  // repeatOfferActions: any;
};

const FormPageStore = createStore({
  initialState,
  actions,
  name: "FormPageStore",
});
export const useFormPageStore = createHook(FormPageStore);
export const useFormPageStoreState = createStateHook(FormPageStore);
export const useFormPageStoreActions = createActionsHook(FormPageStore);
