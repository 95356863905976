import { ExcelExport } from "@progress/kendo-react-excel-export";
import React from "react";
import ListPageNavigationFirstLine from "./ListPageNavigationFirstLine";
import ListPageNavigationSecondLine from "./ListPageNavigationSecondLine";

type Props = {
  refExcelExport: ExcelExport;
};

const ListPageNavigation = ({ refExcelExport }: Props) => {
  return (
    <>
      <ListPageNavigationFirstLine refExcelExport={refExcelExport} />
      <ListPageNavigationSecondLine />
    </>
  );
};

export default ListPageNavigation;
