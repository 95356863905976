import { Notification } from "types/types";
import { handleAxiosError } from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./AppStore";

export const setAppStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const loadAppInfoData = async ({ setState, getState }) => {
  if (getState().appInfoDataLoadStatus === "loading") return;
  try {
    setState({
      ...getState(),
      appInfoDataLoadStatus: "loading",
    });
    const res = await API.getOfferList({
      appInfo: {
        dataObj: {},
      },
    });
    setState({
      ...getState(),
      appInfoData: res.data.appInfo.dataObj,
      appInfoDataLoadStatus: "success",
    });
  } catch (error) {
    handleAxiosError(error);
    setState({
      ...getState(),
      appInfoDataLoadStatus: "error",
    });
  }
};

export const loadUADTaxonomy = async ({ setState, getState }) => {
  if (getState().taxonomyUadLoadStatus === "loading") return;
  try {
    setState({
      taxonomyUadLoadStatus: "loading",
    });
    const res = await API.getUADTaxonomy();
    setState({
      taxonomyUad: res.data,
      taxonomyUadLoadStatus: "success",
    });
  } catch (err) {
    handleAxiosError(err);
    setState({
      taxonomyUadLoadStatus: "error",
    });
  }
};

export const setNotificationMessage =
  (notificationMessage: Notification) =>
  async ({ setState, getState }) => {
    let prev = getState().notifications;

    const f = (prev) => {
      const alreadyExist = prev.some(
        (item) => item.key === notificationMessage.key
      );

      if (alreadyExist) {
        return prev;
      }

      return [
        ...prev,
        {
          errors: notificationMessage.errors,
          key: notificationMessage.key,
          fieldName: notificationMessage.fieldName,
          handleNotificationClick: notificationMessage.handleNotificationClick,
          messageBarType: notificationMessage.messageBarType,
          notify: notificationMessage.notify,
        },
      ];
    };
    setState({ notifications: f(prev) });
  };

export const filterDuplicates =
  (errors) =>
  async ({ setState, getState }) => {
    let prev = getState().notifications;
    prev = prev.filter((item) => Object.keys(errors).includes(item.fieldName));
    setState({ notifications: prev });
  };

export const removeNotification =
  (notificationKey) =>
  async ({ setState, getState }) => {
    let prev = getState().notifications;

    setState({
      notifications: prev.filter((item) => item.key !== notificationKey),
    });
  };

export const clearAllNotifications =
  () =>
  async ({ setState, getState }) => {
    setState({ notifications: [] });
  };

export const clearFormNotifications =
  () =>
  async ({ setState, getState }) => {
    let prev = getState().notifications;

    setState({ notifications: prev.filter((item) => !item.fieldName) });
  };

export const loadRomanianTranslations = async ({ setState, getState }) => {
  if (getState().appInfoDataLoadStatus === "loading") return;
  try {
    setState({
      ...getState(),
      romanianTranslationsLoadStatus: "loading",
    });
    const res = await API.getRomanianPropertyFile();
    setState({
      ...getState(),
      romanianTranslations: res.data,
      romanianTranslationsLoadStatus: "success",
    });
  } catch (error) {
    handleAxiosError(error);
    setState({
      ...getState(),
      romanianTranslationsLoadStatus: "error",
    });
  }
};
