import { useTheme } from "styled-components";
import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Text,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { BuildingRegular } from "@fluentui/react-icons";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import SelectField from "components/controls9/SelectField";
import TextInputField from "components/controls9/TextInputField";
import { MessageBarType } from "office-ui-fabric-react";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppStoreActions } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { resolverFn } from "utils/setYupLocale";
import { reverse_romanian_label, taxonomy } from "utils/types";
import {
  fieldToTab,
  getClasses,
  prepareFormReturnValue,
  setCityOptions,
} from "utils/utils";
import * as yup from "yup";
import { calculateCascoSchema } from "../useFormValidationSchema";
import { TabProps } from "types/types";
import TabCard from "../TabCard";
import CardFooterWithInfoCalculation from "./CardFooterWithInfoCalculation";
import AutomaticOperationButton from "components/AutomaticOperationButton";
import { toast } from "components/FluentToast";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const OfferTab6 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { setValue, getValues, setError, clearErrors, watch } = props.form;

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const [addressLine, setAddressLine] = React.useState(null);
  const [
    { selectedProduct, offerTab, mainTab, sicCodeOptions, progress, cities },
    { setFormStoreValue, getCompanyInfo, setFormStoreValues },
  ] = formPageStore;

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.cascoOfferCreated || progress.mtplOfferCreated;
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  watch((data, { name, type }) => {
    if (type === "change" && name === "inp_lessee_county") {
      setFormStoreValue("cities", setCityOptions(data.inp_lessee_county, t));
    }
  });
  const cardFooter = (
    <CardFooterWithInfoCalculation isLoading={isLoading} form={props.form}>
      <Button
        appearance="secondary"
        icon={<BuildingRegular fontSize={16} />}
        onClick={async () => {
          clearErrors();
          clearAllNotifications();
          resolverFn(
            selectedProduct.validationMap["offerTab6"](yup, t),
            getValues()
          ).then((res) => {
            if (Object.keys(res.errors).length > 0) {
              toast.error(t("bcr.validationErrors.label"));
              Object.keys(res.errors).forEach((key) => {
                setError(key, res.errors[key]);
                setNotificationMessage({
                  errors: [],
                  key: key,
                  messageBarType: MessageBarType.error,
                  fieldName: t("bcr." + key + ".label"),
                  handleNotificationClick: (e) => {
                    setFormStoreValues({
                      mainTab: { ...mainTab, selectedTab: "offer" },
                      offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
                    });
                  },
                  notify: {
                    label: "label",
                    type: "error",
                    notifyCode: "notifyCode",
                    notifyText: (
                      <>
                        <b>{t("bcr." + key + ".label")}</b>
                        <br />
                        {res.errors[key].message}
                      </>
                    ),
                  },
                });
              });
            } else {
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab: selectedProduct.hasMtpl ? "tab8" : "tab9",
                disabledTabs: offerTab.disabledTabs.filter(
                  (el) => el !== (selectedProduct.hasMtpl ? "tab8" : "tab9")
                ),
              });
            }
          });
        }}
      >
        {t("bcr.next.label")}
      </Button>
    </CardFooterWithInfoCalculation>
  );

  const addressLineStyle = addressLine
    ? {
        border: "4px dashed gray",
        padding: "20px",
        display: "flex",
        marginBottom: "10px",
      }
    : {};
  return (
    <>
      <TabCard footer={cardFooter}>
        <div className={styles.grid}>
          {getValues("inp_lessee_type") === "bcr.PersonType.LEGAL" ? (
            <>
              <div style={labelStyle(1, 1)}>
                {t(reverse_romanian_label("C.U.I"))}
                {requiredMap.inp_lessee_uid ? "*" : ""}
              </div>
              <div style={fieldStyle(1, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_lessee_uid"
                  label={reverse_romanian_label("C.U.I")}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_lessee_uid}
                  visible={visibleMap?.inp_lessee_uid}
                  contentAfter={
                    <AutomaticOperationButton
                      aria-label="Populate Company data"
                      onClick={(e) => {
                        if (
                          getValues("inp_lessee_uid") &&
                          getValues("inp_lessee_uid") !== ""
                        ) {
                          getCompanyInfo(
                            getValues("inp_lessee_uid"),
                            (retVal) => {
                              try {
                                const preparedRetVal: any =
                                  prepareFormReturnValue(retVal, t);
                                for (const key1 in retVal) {
                                  let found = false;
                                  for (const key2 in preparedRetVal) {
                                    if (key1 === key2) {
                                      found = true;
                                      break;
                                    }
                                  }
                                  if (!found) {
                                    setValue(key1, null);
                                  }
                                }

                                for (const key in preparedRetVal) {
                                  if (key === "inp_lessee_address_line") {
                                    setAddressLine(preparedRetVal[key]);
                                    continue;
                                  }
                                  if (key === "inp_lessee_county") {
                                    setFormStoreValues({
                                      cities: setCityOptions(
                                        preparedRetVal[key].value,
                                        t
                                      ),
                                    });
                                  }
                                  setValue(
                                    key,
                                    preparedRetVal[key]?.value
                                      ? preparedRetVal[key].value
                                      : preparedRetVal[key]
                                  );
                                }
                              } catch (e) {
                                console.log(e);
                              }
                            },
                            (err) => {
                              setNotificationMessage({
                                errors: [],
                                key: "key",
                                messageBarType: MessageBarType.error,
                                fieldName: "fieldName",
                                handleNotificationClick: (e) => {},
                                notify: {
                                  label: "label",
                                  type: "error",
                                  notifyCode: "notifyCode",
                                  notifyText: (
                                    <>
                                      <b>{"GetCompanyInfo"}</b>
                                      <br />
                                      {err.response.data.Message}
                                    </>
                                  ),
                                },
                              });
                            }
                          );
                        } else {
                          toast.error(t("greco.form.errors.required"));
                        }
                      }}
                    />
                  }
                />
              </div>

              <div style={labelStyle(2, 1)}>
                {t(reverse_romanian_label("Numele companiei"))}
                {requiredMap.inp_lessee_company_name ? "*" : ""}
              </div>
              <div style={fieldStyle(2, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_lessee_company_name"
                  label={reverse_romanian_label("Numele companiei")}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_lessee_company_name}
                  visible={visibleMap?.inp_lessee_company_name}
                />
              </div>

              <div style={labelStyle(3, 1)}>
                {t(reverse_romanian_label("Cod CAEN"))}
                {requiredMap.inp_caen ? "*" : ""}
              </div>
              <div style={fieldStyle(3, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_caen"
                  label={reverse_romanian_label("Cod CAEN")}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_caen}
                  visible={visibleMap?.inp_caen}
                />
              </div>
            </>
          ) : null}
          {getValues("inp_lessee_type") === "bcr.PersonType.CIVIL" ? (
            <>
              <div style={labelStyle(1, 1)}>
                {t(reverse_romanian_label("C.N.P"))}
                {requiredMap.inp_lessee_compreg ? "*" : ""}
              </div>
              <div style={fieldStyle(1, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_lessee_compreg"
                  label={reverse_romanian_label("C.N.P")}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_lessee_compreg}
                  visible={visibleMap?.inp_lessee_compreg}
                />
              </div>
              <div style={labelStyle(2, 1)}>
                {t(reverse_romanian_label("Nume"))}
                {requiredMap.inp_lessee_lastname ? "*" : ""}
              </div>
              <div style={fieldStyle(2, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_lessee_lastname"
                  label={reverse_romanian_label("Nume")}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_lessee_lastname}
                  visible={visibleMap?.inp_lessee_lastname}
                />
              </div>
              <div style={labelStyle(3, 1)}>
                {t(reverse_romanian_label("Prenume"))}
                {requiredMap.inp_lessee_firstname ? "*" : ""}
              </div>
              <div style={fieldStyle(3, 2)}>
                <TextInputField
                  form={props.form}
                  isLoading={isLoading}
                  name="inp_lessee_firstname"
                  label={reverse_romanian_label("Prenume")}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_lessee_firstname}
                  visible={visibleMap?.inp_lessee_firstname}
                />
              </div>

              {visibleMap?.inp_licence_date ? (
                <>
                  <div style={labelStyle(4, 1)}>
                    {t(reverse_romanian_label("Data permisului (pentru PF*)"))}
                    {requiredMap.inp_licence_date ? "*" : ""}
                  </div>
                  <div style={fieldStyle(4, 2)}>
                    <DatePickerField
                      isLoading={isLoading}
                      form={props.form}
                      name="inp_licence_date"
                      label={reverse_romanian_label(
                        "Data permisului (pentru PF*)"
                      )}
                      disabled={disabledTab || disabledMap?.inp_licence_date}
                      data-format="dd.MM.yyyy"
                      data-dateFormat="DD.MM.YYYY"
                      required={false}
                      visible={visibleMap?.inp_licence_date}
                    />
                  </div>
                </>
              ) : null}
            </>
          ) : null}

          {selectedProduct?.visibleMap.inp_sicgroup ? (
            <>
              <div style={labelStyle(5, 1)}>
                {t(reverse_romanian_label("Tipul societatii"))}
                {requiredMap.inp_sicgroup ? "*" : ""}
              </div>
              <div style={fieldStyle(5, 2)}>
                <SelectField
                  isLoading={isLoading}
                  form={props.form}
                  name="inp_sicgroup"
                  label={reverse_romanian_label("Tipul societatii")}
                  options={sicCodeOptions}
                  required={false}
                  disabled={disabledTab || disabledMap?.inp_sicgroup}
                  visible={visibleMap?.inp_sicgroup}

                  // refreshOn: inp_contract_type
                />
              </div>
            </>
          ) : null}

          <div style={labelStyle(6, 1)}>
            {t(reverse_romanian_label("Judet"))}
            {requiredMap.inp_lessee_county ? "*" : ""}
          </div>
          <div style={fieldStyle(6, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_lessee_county"
              label={reverse_romanian_label("Judet")}
              options={taxonomy["inp_lessee_county"] || []}
              required={false}
              disabled={disabledTab || disabledMap?.inp_lessee_county}
              visible={visibleMap?.inp_lessee_county}
            />
          </div>
          <div style={labelStyle(7, 1)}>
            {t(reverse_romanian_label("Oras"))}
            {requiredMap.inp_lessee_city ? "*" : ""}
          </div>
          <div style={fieldStyle(7, 2)}>
            <SelectField
              isLoading={isLoading}
              form={props.form}
              name="inp_lessee_city"
              label={reverse_romanian_label("Oras")}
              options={cities || []}
              required={false} //
              disabled={disabledTab || disabledMap?.inp_lessee_city}
              visible={visibleMap?.inp_lessee_city}

              // refreshOn: inp_vehicle_category
            />
          </div>
          <div style={{ ...fieldStyle(8, 2), ...addressLineStyle }}>
            <Text>{addressLine}</Text>
          </div>

          <div style={labelStyle(9, 1)}>
            {t(reverse_romanian_label("Strada"))}
            {requiredMap.inp_lessee_street ? "*" : ""}
          </div>
          <div style={fieldStyle(9, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_lessee_street"
              label={reverse_romanian_label("Strada")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_lessee_street}
              visible={visibleMap?.inp_lessee_street}
            />
          </div>
          <div style={labelStyle(10, 1)}>
            {t(reverse_romanian_label("Numar"))}
            {requiredMap.inp_lessee_housenr ? "*" : ""}
          </div>
          <div style={fieldStyle(10, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_lessee_housenr"
              label={reverse_romanian_label("Numar")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_lessee_housenr}
              visible={visibleMap?.inp_lessee_housenr}
            />
          </div>
          <div style={labelStyle(11, 1)}>
            {t(reverse_romanian_label("Bloc"))}
            {requiredMap.inp_lessee_building ? "*" : ""}
          </div>
          <div style={fieldStyle(11, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_lessee_building"
              label={reverse_romanian_label("Bloc")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_lessee_building}
              visible={visibleMap?.inp_lessee_building}
            />
          </div>
          <div style={labelStyle(12, 1)}>
            {t(reverse_romanian_label("Scara"))}
            {requiredMap.inp_lessee_entrance ? "*" : ""}
          </div>
          <div style={fieldStyle(12, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_lessee_entrance"
              label={reverse_romanian_label("Scara")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_lessee_entrance}
              visible={visibleMap?.inp_lessee_entrance}
            />
          </div>
          <div style={labelStyle(13, 1)}>
            {t(reverse_romanian_label("Apartament"))}
            {requiredMap.inp_lessee_door ? "*" : ""}
          </div>
          <div style={fieldStyle(13, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_lessee_door"
              label={reverse_romanian_label("Apartament")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_lessee_door}
              visible={visibleMap?.inp_lessee_door}
            />
          </div>
          <div style={labelStyle(14, 1)}>
            {t(reverse_romanian_label("Etaj"))}
            {requiredMap.inp_lessee_floor ? "*" : ""}
          </div>
          <div style={fieldStyle(14, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_lessee_floor"
              label={reverse_romanian_label("Etaj")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_lessee_floor}
              visible={visibleMap?.inp_lessee_floor}
            />
          </div>
          <div style={labelStyle(15, 1)}>
            {t(reverse_romanian_label("Mobil"))}
            {requiredMap.inp_lessee_mobile ? "*" : ""}
          </div>
          <div style={fieldStyle(15, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_lessee_mobile"
              label={reverse_romanian_label("Mobil")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_lessee_mobile}
              visible={visibleMap?.inp_lessee_mobile}
            />
          </div>
          <div style={labelStyle(16, 1)}>
            {t(reverse_romanian_label("E-mail"))}
            {requiredMap.inp_lessee_email ? "*" : ""}
          </div>
          <div style={fieldStyle(16, 2)}>
            <TextInputField
              form={props.form}
              isLoading={isLoading}
              name="inp_lessee_email"
              label={reverse_romanian_label("E-mail")}
              required={false}
              disabled={disabledTab || disabledMap?.inp_lessee_email}
              visible={visibleMap?.inp_lessee_email}
            />
          </div>
        </div>
      </TabCard>
    </>
  );
};

export default OfferTab6;
