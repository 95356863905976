import React from "react";
import App from "./App";
import { initializeIcons } from "@uifabric/icons";
import Axios from "axios";
import { mergeStyles } from "@fluentui/react";
import "./index.css";
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import ReactDOM from "react-dom/client";

import "../node_modules/@greco/services/dist/lib/main.css";
import "./i18n";
import "../node_modules/react-toastify/dist/ReactToastify.css";

// -- Auth
import { MSGraphAuth } from "./api/GraphService";
import { AccountInfo } from "@azure/msal-browser";
import { ProjectParams } from "./config/MSGraphConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

pdfMake.vfs = pdfFonts.pdfMake.vfs;

Axios.interceptors.request.use(async (config) => {
  let authResponse = await MSGraphAuth.getAccessToken(ProjectParams);
  // console.log("AUTH", authResponse);

  if (!authResponse) return config;
  if (config.url?.indexOf("thumbnailPhoto") !== -1) {
    return config;
  }
  config!.headers!.Authorization = `Bearer ${authResponse.accessToken}`;
  return config;
});

const isDev = process.env.NODE_ENV === "development";

const APP_URL = isDev ? "http://localhost:3000" : process.env.REACT_APP_APP_URL;

pdfMake.fonts = {
  SegoeUI: {
    normal: `${APP_URL}/fonts/SEGOEUI.ttf`,
    bold: `${APP_URL}/fonts/SEGOEUI.ttf`,
  },
  Remix: {
    normal: `${APP_URL}/fonts/remixicon.ttf`,
  },
  LineAwesome: {
    normal: `${APP_URL}/fonts/la-regular-400.ttf`,
  },
};

// Inject some global styles
mergeStyles({
  selectors: {
    ":global(body), :global(html), :global(#root)": {
      margin: 0,
      padding: 0,
      height: "100vh",
    },
  },
});

const startApplication = () => {
  initializeIcons();

  root.render(<App />);
};

MSGraphAuth.accountObj
  .then((accountInfo: AccountInfo | null) => {
    if (accountInfo) {
      startApplication();
    } else {
      MSGraphAuth.login();
    }
  })
  .catch((error: any) => {
    if (error === "There isn't any account detected.") {
      MSGraphAuth.login();
    } else {
      // TODO log error
    }
  });
