import { useTheme } from "styled-components";
import { Button, makeStyles } from "@fluentui/react-components";
import { BuildingRegular } from "@fluentui/react-icons";
import DatePickerField from "components/controls9/DatePickerField";
import NumberInputField from "components/controls9/NumberInputField";
import SelectField from "components/controls9/SelectField";
import TaxonomyField from "components/controls9/TaxonomyField";
import { MessageBarType } from "office-ui-fabric-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreActions, useAppStoreState } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { TabProps } from "types/types";
import { resolverFn } from "utils/setYupLocale";
import {
  inp_contract_type,
  inp_lessee_type,
  inp_sicgroup,
  reverse_romanian_label,
  taxonomy,
} from "utils/types";
import * as yup from "yup";
import TabCard from "../TabCard";
import { determineContractTypeOptions } from "types/products";
import { fieldToTab, setSicGroupOptions } from "utils/utils";
import CardFooterWithInfoCalculation from "./CardFooterWithInfoCalculation";
import moment from "moment";
import { toast } from "components/FluentToast";

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const OfferTab1 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { watch, getValues, setError, clearErrors, setValue } = props.form;
  const { appInfoData } = useAppStoreState();

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const [
    { selectedProduct, offerTab, progress, mainTab },
    { setFormStoreValue, setFormStoreValues },
  ] = formPageStore;

  const disabledTab = progress.cascoOfferCreated || progress.mtplOfferCreated;

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (type === "change") {
        if (name === "inp_lessee_type") {
          if (selectedProduct) {
            setFormStoreValue("selectedProduct", {
              ...selectedProduct,
              requiredMap: {
                ...selectedProduct.requiredMap,
                inp_licence_date:
                  data.inp_lessee_type === inp_lessee_type["Persoana fizica"]
                    ? true
                    : false,
              },
            });
          }

          if (data.inp_lessee_type === inp_lessee_type["Persoana fizica"]) {
            setValue("inp_lessee_company_name", null);
            setValue("inp_lessee_uid", null);
          } else if (
            data.inp_lessee_type === inp_lessee_type["Persoana juridica"]
          ) {
            setValue("inp_lessee_firstname", null);
            setValue("inp_lessee_lastname", null);
            setValue("inp_lessee_compreg", null);
          }
        }
        if (name === "inp_contract_type") {
          setFormStoreValue(
            "sicCodeOptions",
            setSicGroupOptions({ inp_contract_type: data.inp_contract_type }, t)
          );
          if (
            data?.inp_contract_type ===
              inp_contract_type["Contract de leasing financiar"] ||
            data?.inp_contract_type === "Contract de leasing financiar"
          ) {
            setValue(
              "inp_sicgroup",
              inp_sicgroup["intermediari financiari - leasing"]
            );
          }
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, selectedProduct]);

  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;

  const cardFooter = (
    <CardFooterWithInfoCalculation isLoading={isLoading} form={props.form}>
      <Button
        appearance="secondary"
        icon={<BuildingRegular fontSize={16} />}
        onClick={async () => {
          clearErrors();
          clearAllNotifications();

          resolverFn(
            selectedProduct.validationMap["offerTab1"](yup, t),
            getValues()
          ).then((res) => {
            if (Object.keys(res.errors).length > 0) {
              toast.error(t("bcr.validationErrors.label"));
              Object.keys(res.errors).forEach((key) => {
                setError(key, res.errors[key]);
                setNotificationMessage({
                  errors: [],
                  key: key,
                  messageBarType: MessageBarType.error,
                  fieldName: t("bcr." + key + ".label"),
                  handleNotificationClick: (e) => {
                    setFormStoreValues({
                      mainTab: { ...mainTab, selectedTab: "offer" },
                      offerTab: { ...offerTab, selectedTab: fieldToTab(key) },
                    });
                  },
                  notify: {
                    label: "label",
                    type: "error",
                    notifyCode: "notifyCode",
                    notifyText: (
                      <>
                        <b>{t("bcr." + key + ".label")}</b>
                        <br />
                        {res.errors[key].message}
                      </>
                    ),
                  },
                });
              });
            } else {
              setFormStoreValue("offerTab", {
                ...offerTab,
                selectedTab: "tab2",
                disabledTabs: offerTab.disabledTabs.filter(
                  (el) => el !== "tab2"
                ),
              });
              //setFormStoreValue("selectedOfferTab", "tab3");
            }
          });
        }}
      >
        {t("bcr.next.label")}
      </Button>
    </CardFooterWithInfoCalculation>
  );

  return (
    <>
      <TabCard footer={cardFooter}>
        {selectedProduct ? (
          <div className={styles.grid}>
            <div style={labelStyle(1, 1)}>
              {t(reverse_romanian_label("Tip persoana"))}
              {requiredMap.inp_lessee_type ? "*" : ""}
            </div>
            <div style={fieldStyle(1, 2)}>
              <TaxonomyField
                isLoading={isLoading}
                form={props.form}
                name="inp_lessee_type"
                label={reverse_romanian_label("Tip persoana")}
                taxonomyKey="inp_lessee_type"
                required={requiredMap?.inp_lessee_type}
                disabled={disabledTab || disabledMap?.inp_lessee_type}
                visible={visibleMap?.inp_lessee_type}
                onChange={(value) => {
                  //setFormStoreValue("inp_lessee_type", value);
                }}
              />
            </div>
            <div style={labelStyle(2, 1)}>
              {t(reverse_romanian_label("Tip contract"))}
              {requiredMap.inp_contract_type ? "*" : ""}
            </div>
            <div style={fieldStyle(2, 2)}>
              <SelectField
                isLoading={isLoading}
                form={props.form}
                name="inp_contract_type"
                label={reverse_romanian_label("Tip contract")}
                options={determineContractTypeOptions(
                  selectedProduct?.type,
                  getValues("offerRequestType"),
                  t
                )}
                deprecatedOptions={[
                  {
                    label: t(
                      "bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_OPERATIONAL"
                    ),
                    value:
                      "bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_OPERATIONAL",
                  },
                ]}
                required={requiredMap?.inp_contract_type}
                disabled={disabledTab || disabledMap?.inp_contract_type}
                visible={visibleMap?.inp_contract_type}
              />
            </div>
            {selectedProduct?.visibleMap.inp_policy_currency ? (
              <>
                <div style={labelStyle(3, 1)}>
                  {t(reverse_romanian_label("Valuta contract"))}
                  {requiredMap.inp_policy_currency ? "*" : ""}
                </div>
                <div style={fieldStyle(3, 2)}>
                  <SelectField
                    styles={props.styles}
                    isLoading={isLoading}
                    form={props.form}
                    name="inp_policy_currency"
                    label={reverse_romanian_label("Valuta contract")}
                    options={taxonomy["inp_policy_currency"] || []}
                    required={requiredMap?.inp_policy_currency}
                    disabled={disabledTab || disabledMap?.inp_policy_currency}
                    visible={visibleMap?.inp_policy_currency}
                    width="50%"
                  />
                </div>
              </>
            ) : null}
            {selectedProduct?.visibleMap.inp_policy_value ? (
              <>
                <div style={labelStyle(4, 1)}>
                  {t(reverse_romanian_label("Valoare ctr. leasing (+TVA)"))}
                  {requiredMap.inp_policy_value ? "*" : ""}
                </div>
                <div style={fieldStyle(4, 2)}>
                  <NumberInputField
                    label={reverse_romanian_label(
                      "Valoare ctr. leasing (+TVA)"
                    )}
                    name="inp_policy_value"
                    form={props.form}
                    required={requiredMap?.inp_policy_value}
                    disabled={disabledTab || disabledMap?.inp_policy_value}
                    visible={visibleMap?.inp_policy_value}
                    isLoading={isLoading}
                  />
                </div>
              </>
            ) : null}
            {selectedProduct?.visibleMap.inp_leasing_monthsinterval ? (
              <>
                <div style={labelStyle(5, 1)}>
                  {t(reverse_romanian_label("Perioada leasing (luni)"))}
                  {requiredMap.inp_leasing_monthsinterval ? "*" : ""}
                </div>
                <div style={fieldStyle(5, 2)}>
                  <NumberInputField
                    label={reverse_romanian_label("Perioada leasing (luni)")}
                    name="inp_leasing_monthsinterval"
                    form={props.form}
                    required={requiredMap?.inp_leasing_monthsinterval}
                    disabled={
                      disabledTab || disabledMap?.inp_leasing_monthsinterval
                    }
                    visible={visibleMap?.inp_leasing_monthsinterval}
                    isLoading={isLoading}
                  />
                </div>
              </>
            ) : null}
            {selectedProduct?.visibleMap.inp_external_contractdate ? (
              <>
                <div style={labelStyle(6, 1)}>
                  {t(reverse_romanian_label("Data contract leasing"))}
                  {requiredMap.inp_external_contractdate ? "*" : ""}
                </div>
                <div style={fieldStyle(6, 2)}>
                  <div style={{ display: "flex" }}>
                    <DatePickerField
                      isLoading={isLoading}
                      form={props.form}
                      name="inp_external_contractdate"
                      label={reverse_romanian_label("Data contract leasing")}
                      data-format="DD.MM.YYYY"
                      data-dateFormat="DD.MM.YYYY"
                      required={requiredMap?.inp_external_contractdate}
                      maxDate={moment().endOf("day").toDate()}
                      disabled={
                        disabledTab || disabledMap?.inp_external_contractdate
                      }
                      visible={visibleMap?.inp_external_contractdate}
                    />
                  </div>
                </div>
              </>
            ) : null}
            {selectedProduct?.visibleMap.inp_policy_payment ? (
              <>
                <div style={labelStyle(7, 1)}>
                  {t(reverse_romanian_label("Metoda de plata"))}
                  {requiredMap.inp_policy_payment ? "*" : ""}
                </div>
                <div style={fieldStyle(7, 2)}>
                  <SelectField
                    styles={props.styles}
                    isLoading={isLoading}
                    form={props.form}
                    name="inp_policy_payment"
                    label={reverse_romanian_label("Metoda de plata")}
                    options={taxonomy["inp_policy_payment"] || []}
                    required={requiredMap?.inp_policy_payment}
                    disabled={disabledTab || disabledMap?.inp_policy_payment}
                    visible={visibleMap?.inp_policy_payment}
                  />
                </div>
              </>
            ) : null}
            {selectedProduct?.hasMtpl ? (
              <>
                <div style={labelStyle(8, 1)}>
                  {t(reverse_romanian_label("Data inceput"))}
                  {requiredMap.inp_start_date ? "*" : ""}
                </div>
                <div style={fieldStyle(8, 2)}>
                  <DatePickerField
                    isLoading={isLoading}
                    form={props.form}
                    name="inp_start_date"
                    label={reverse_romanian_label("Data inceput")}
                    disabled={disabledTab || disabledMap?.inp_start_date}
                    data-format="DD.MM.YYYY"
                    data-dateFormat="DD.MM.YYYY"
                    required={false}
                    visible={visibleMap?.inp_start_date}
                    minDate={moment().startOf("day").toDate()}
                  />
                </div>
                {appInfoData.showButton === "both" ||
                appInfoData.showButton === "admin" ? (
                  <>
                    <div style={labelStyle(9, 1)}>
                      {t(reverse_romanian_label("Comision Broker"))}
                      {requiredMap.mtpl_commission_broker_p ? "*" : ""}
                    </div>
                    <div style={fieldStyle(9, 2)}>
                      <NumberInputField
                        isLoading={isLoading}
                        name="mtpl_commission_broker_p"
                        form={props.form}
                        label={reverse_romanian_label("Comision Broker")}
                        required={requiredMap?.mtpl_commission_broker_p}
                        disabled={
                          disabledTab || disabledMap?.mtpl_commission_broker_p
                        }
                        isFloat={true}
                        visible={visibleMap?.mtpl_commission_broker_p}

                        // customConditional:"show = (data.offerRequestType === 'F100004' || data.offerRequestType === 'F100056') && (data.highestRole === 'admin' || data.highestRole === 'manager')"
                      />
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        ) : null}
      </TabCard>
    </>
  );
};

export default OfferTab1;
