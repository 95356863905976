import i18next, { TFunction } from "i18next";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { format } from "date-fns";
import { ExpandedColumn } from "../types/columns";

// Saga za pdf
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-Italic.ttf",
  },
};

export const neutrals = {
  neutralLighterAlt: "#faf9f8",
  neutralTertiary: "#a19f9d",
  neutralTertiaryAlt: "#c8c6c4",
  neutralQuaternary: "#d2d0ce",
};

export const accent = {
  yellow: "#ffb900",
  red: "#d13438",
  redDark: "#a4262c",
  greenDark: "#004b1c",
  orangeLighter: "#ff8c00",
};

export const formatDateWithTime = (date?: Date) =>
  format(date, "yyyy_MM_dd_kk_mm_ss");

export const createPDFOffers = ({
  result,
  columns,
  //   taxonomy,
  t,
}: {
  result: any;
  columns: ExpandedColumn[];
  //   taxonomy: TaxonomyState;
  t: TFunction;
}) => {
  const actualColumns = columns.filter((c) => c.key !== "options");

  const docDefinition = {
    pageOrientation: "landscape",
    pageMargins: [20, 20, 20, 20],
    pageSize: {
      width:
        actualColumns.reduce((sum, c) => {
          if (c.pdf && c.pdf.width !== undefined) {
            return sum + c.pdf.width;
          }
          return sum + c.width;
        }, 0) * 1.1,
      height: 1200,
    },
    info: {
      title: `Oferta ${formatDateWithTime(new Date())}`,
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: actualColumns.map((c) => {
            if (c.pdf && c.pdf.width !== undefined) {
              return c.pdf.width;
            }
            return c.width;
          }),
          body: [
            actualColumns.map((c) => {
              if (c.pdf && c.pdf.label !== undefined) {
                return {
                  text: c.pdf.label,
                  style: "headerCell",
                };
              }
              return {
                text: t(c.labelKey),
                style: "headerCell",
              };
            }),
          ].concat(
            result.map((r) => {
              return actualColumns.map((c) => {
                const value = r[c.key];
                if (c.pdf && c.pdf.render) {
                  try {
                    return {
                      style: "bodyCell",
                      ...c.pdf.render({
                        offer: r,
                        // taxonomy,
                        t: t,
                      }),
                    };
                  } catch (err) {
                    console.log(err);
                  }
                }
                if (value === undefined || value === null) return "";
                return {
                  text: value,
                  style: "bodyCell",
                };
              });
            })
          ),
        },
      },
    ],
    styles: {
      headerCell: {
        fillColor: "#005aa1",
        color: "#fff",
        bold: true,
        alignment: "center",
      },
      bodyCell: {
        alignment: "center",
      },
    },
    defaultStyle: {
      font: "SegoeUI",
    },
  };
  return pdfMake.createPdf(docDefinition);
};
