export let COUNTIES_CITIES = {};
COUNTIES_CITIES["-"] = ["-"];

COUNTIES_CITIES["CONSTANTA"] = [
  "02.mai",
  "23.aug",
  "ABRUD",
  "ADAMCLISI",
  "AGIGEA",
  "ALBESTI",
  "ALIMAN",
  "ALMALAU",
  "AMZACEA",
  "ARSA",
  "BALTAGESTI",
  "BANEASA",
  "BARAGANU",
  "BIRUINTA",
  "BREBENI",
  "BUGEAC",
  "CALUGARENI",
  "CANLIA",
  "CAP AURORA",
  "CAPIDAVA",
  "CARVAN",
  "CASCIOARELE",
  "CASIAN",
  "CASICEA",
  "CASTELU",
  "CERCHEZU",
  "CERNAVODA",
  "CETATEA",
  "CHEIA",
  "CHIRNOGENI",
  "CIOBANITA",
  "CIOBANU",
  "CIOCARLIA",
  "CIOCARLIA DE SUS",
  "CLOSCA",
  "COBADIN",
  "COCHIRLENI",
  "COGEALAC",
  "COMANA",
  "CONACU",
  "CONSTANTA",
  "CORBU",
  "COROANA",
  "COSLUGEA",
  "COSTINESTI",
  "COTU VAII",
  "CRANGU",
  "CREDINTA",
  "CRISAN",
  "CRUCEA",
  "CUIUGIUC",
  "CUMPANA",
  "CURCANI",
  "CUZA VODA",
  "DARABANI",
  "DELENI",
  "DOBROMIR",
  "DOBROMIRU DIN DEAL",
  "DOROBANTU",
  "DROPIA",
  "DULCESTI",
  "DULGHERU",
  "DUMBRAVENI",
  "DUNAREA",
  "DUNARENI",
  "EFORIE NORD",
  "EFORIE SUD",
  "ESECHIOI",
  "FACLIA",
  "FANTANA MARE",
  "FANTANELE",
  "FAUREI",
  "FLORIILE",
  "FURNICA",
  "GALBIORI",
  "GALITA",
  "GARLICIU",
  "GARLITA",
  "GENERAL SCARISOREANU",
  "GHERGHINA",
  "GHINDARESTI",
  "GORUNI",
  "GRADINA",
  "GRANICERU",
  "GURA DOBROGEI",
  "HAGIENI",
  "HARSOVA",
  "HATEG",
  "HORIA",
  "INDEPENDENTA",
  "ION CORVIN",
  "ISTRIA",
  "IVRINEZU MARE",
  "IVRINEZU MIC",
  "IZVOARELE",
  "IZVORU MARE",
  "JUPITER",
  "LANURILE",
  "LAZU",
  "LESPEZI",
  "LIMANU",
  "LIPNITA",
  "LUMINA",
  "LUMINITA",
  "MAGURA",
  "MAMAIA",
  "MAMAIA-SAT",
  "MANGALIA",
  "MEDGIDIA",
  "MERENI",
  "MIHAI VITEAZU",
  "MIHAIL KOGALNICEANU",
  "MIORITA",
  "MIRCEA VODA",
  "MIREASA",
  "MIRISTEA",
  "MOSNENI",
  "MOVILA VERDE",
  "MOVILITA",
  "MURFATLAR",
  "NAVODARI",
  "NAZARCEA",
  "NEGRESTI",
  "NEGRU VODA",
  "NEGURENI",
  "NEPTUN",
  "NICOLAE BALCESCU",
  "NISIPARI",
  "NISTORESTI",
  "NUNTASI",
  "OITUZ",
  "OLIMP",
  "OLTENI",
  "OLTINA",
  "OSMANCEA",
  "OSTROV",
  "OVIDIU",
  "PADURENI",
  "PALAZU MARE",
  "PALAZU MIC",
  "PANTELIMON",
  "PANTELIMON DE JOS",
  "PECINEAGA",
  "PELINU",
  "PESTERA",
  "PETROSANI",
  "PIATRA",
  "PIETRENI",
  "PLOPENI",
  "POARTA ALBA",
  "POIANA",
  "POTARNICHEA",
  "RAMNICU DE JOS",
  "RAMNICU DE SUS",
  "RARISTEA",
  "RASOVA",
  "RAZOARELE",
  "REMUS OPREANU",
  "RUNCU",
  "SACELE",
  "SALIGNY",
  "SANATORIUL AGIGEA",
  "SARAIU",
  "SATU NOU",
  "SATURN",
  "SCHITU",
  "SEIMENI",
  "SEIMENII MICI",
  "SIBIOARA",
  "SILISTEA",
  "SIMINOC",
  "SINOIE",
  "SIPOTELE",
  "SIRIU",
  "STATIUNEA ZOOLOGICA MARINA AGIGEA",
  "STEFAN CEL MARE",
  "STEJARU",
  "STRAJA",
  "STRUNGA",
  "STUPINA",
  "TARGUSOR",
  "TARIVERDE",
  "TATARU",
  "TECHIRGHIOL",
  "TEPES VODA",
  "TIBRINU",
  "TICHILESTI",
  "TOPALU",
  "TOPRAISAR",
  "TORTOMAN",
  "TRAIAN",
  "TUDOR VLADIMIRESCU",
  "TUFANI",
  "TUZLA",
  "URLUIA",
  "VADU",
  "VADU OII",
  "VALCELELE",
  "VALEA DACILOR",
  "VALENI",
  "VALU LUI TRAIAN",
  "VAMA VECHE",
  "VANATORI",
  "VARTOP",
  "VENUS",
  "VETERANU",
  "VIILE",
  "VIISOARA",
  "VIROAGA",
  "VLAHII",
  "VULTURU",
  "ZORILE",
];
COUNTIES_CITIES["CARAS-SEVERIN"] = [
  "23.aug",
  "AGADICI",
  "ANINA",
  "APADIA",
  "ARMENIS",
  "ARSURI",
  "BAILE HERCULANE",
  "BANIA",
  "BARBOSU",
  "BARZ",
  "BARZA",
  "BAUTAR",
  "BAZIAS",
  "BELOBRESCA",
  "BERLISTE",
  "BERZASCA",
  "BERZOVIA",
  "BIGAR",
  "BINIS",
  "BOCSA",
  "BOGALTIN",
  "BOGODINT",
  "BOINA",
  "BOINITA",
  "BOJIA",
  "BOLVASNITA",
  "BORLOVA",
  "BORLOVENII NOI",
  "BORLOVENII VECHI",
  "BORUGI",
  "BOZOVICI",
  "BRADISORU DE JOS",
  "BRATOVA",
  "BREBU",
  "BREBU NOU",
  "BRESTELNIC",
  "BREZON",
  "BROSTENI",
  "BUCHIN",
  "BUCOSNITA",
  "BUCOVA",
  "CALINA",
  "CALNIC",
  "CAMENA",
  "CAMENITA",
  "CAMPIA",
  "CANICEA",
  "CARANSEBES",
  "CARASOVA",
  "CARBUNARI",
  "CARNECEA",
  "CARSA ROSIE",
  "CARSIE",
  "CAVARAN",
  "CICLENI",
  "CICLOVA MONTANA",
  "CICLOVA ROMANA",
  "CIORTEA",
  "CIRESA",
  "CIRESEL",
  "CIUCHICI",
  "CIUDANOVITA",
  "CIUTA",
  "CLOCOTICI",
  "COMORASTE",
  "COPACELE",
  "CORNEA",
  "CORNEREVA",
  "CORNISORU",
  "CORNUTEL",
  "CORONINI",
  "COSTIS",
  "COZIA",
  "COZLA",
  "CRACU ALMAJ",
  "CRACU MARE",
  "CRACU TEIULUI",
  "CRUSOVAT",
  "CRUSOVITA",
  "CUPTOARE",
  "CURMATURA",
  "DALBOSET",
  "DALCI",
  "DELINESTI",
  "DEZESTI",
  "DIVICI",
  "DOBRAIA",
  "DOCLIN",
  "DOGNECEA",
  "DOLINA",
  "DOMAN",
  "DOMASNEA",
  "DRENCOVA",
  "DRISTIE",
  "DULEU",
  "EFTIMIE MURGU",
  "ERSIG",
  "EZERIS",
  "FARLIUG",
  "FENES",
  "FIZES",
  "FOROTIC",
  "FRASINIS",
  "GARANA",
  "GARBOVAT",
  "GARLISTE",
  "GARNIC",
  "GHERTENIS",
  "GIURGIOVA",
  "GLIMBOCA",
  "GLOBU CRAIOVEI",
  "GLOBURAU",
  "GOLET",
  "GORNEA",
  "GORUIA",
  "GRADINARI",
  "GREONI",
  "GRUNI",
  "HORA MARE",
  "HORA MICA",
  "IABALCEA",
  "IABLANITA",
  "IAM",
  "IAZ",
  "IERTOF",
  "ILIDIA",
  "ILOVA",
  "INELET",
  "IZGAR",
  "IZVOR",
  "JITIN",
  "JUPA",
  "LAPUSNICEL",
  "LAPUSNICU MARE",
  "LESCOVITA",
  "LIBORAJDEA",
  "LINDENFELD",
  "LIUBCOVA",
  "LUCACEVAT",
  "LUNCA FLORII",
  "LUNCA ZAICII",
  "LUNCAVITA",
  "LUPAC",
  "MACESTI",
  "MACIOVA",
  "MACOVISTE",
  "MAGURA",
  "MAL",
  "MARGA",
  "MARILA",
  "MARTINOVAT",
  "MARU",
  "MATNICU MARE",
  "MAURENI",
  "MEHADIA",
  "MEHADICA",
  "MERCINA",
  "MESTEACAN",
  "MILCOVENI",
  "MOCERIS",
  "MOLDOVA NOUA",
  "MOLDOVA VECHE",
  "MOLDOVITA",
  "MONIOM",
  "NAIDAS",
  "NEGIUDIN",
  "NERMED",
  "NICOLINT",
  "OBITA",
  "OBREJA",
  "OCNA DE FIER",
  "OGASU PODULUI",
  "OHABA-MATNIC",
  "OHABITA",
  "ORAVITA",
  "OTELU ROSU",
  "PADINA MATEI",
  "PALTINIS",
  "PARNEAURA",
  "PARVOVA",
  "PATAS",
  "PECINISCA",
  "PESTERE",
  "PETNIC",
  "PETRILOVA",
  "PETROSNITA",
  "PLOPU",
  "PLUGOVA",
  "POGARA",
  "POGARA DE SUS",
  "POIANA",
  "POIANA LUNGA",
  "POIANA MARULUI",
  "POIENILE BOINEI",
  "POJEJENA",
  "PONEASCA",
  "POTOC",
  "PREVECIORI",
  "PRIGOR",
  "PRILIPET",
  "PRISACA",
  "PRISACINA",
  "PRISIAN",
  "PRISLOP",
  "PUTNA",
  "RACASDIA",
  "RACHITA",
  "RACHITOVA",
  "RADIMNA",
  "RAFNIC",
  "RAMNA",
  "RAVENSCA",
  "REMETEA-POGANICI",
  "RESITA",
  "RESITA MICA",
  "RUGI",
  "RUGINOSU",
  "RUSCA",
  "RUSCA MONTANA",
  "RUSCHITA",
  "RUSOVA NOUA",
  "RUSOVA VECHE",
  "RUSTIN",
  "SACU",
  "SADOVA NOUA",
  "SADOVA VECHE",
  "SALBAGELU NOU",
  "SASCA MONTANA",
  "SASCA ROMANA",
  "SAT BATRAN",
  "SCAIUS",
  "SCARISOARA",
  "SECASENI",
  "SECU",
  "SFANTA ELENA",
  "SICHEVITA",
  "SLATINA-NERA",
  "SLATINA-TIMIS",
  "SOCENI",
  "SOCOL",
  "SOCOLARI",
  "SOPOTU NOU",
  "SOPOTU VECHI",
  "SOSDEA",
  "STANCILOVA",
  "STEIERDORF",
  "STINAPARI",
  "STRENEAC",
  "STRUGASCA",
  "STUDENA",
  "SUB CRANG",
  "SUB MARGINE",
  "SUB PLAI",
  "SUMITA",
  "SURDUCU MARE",
  "SUSCA",
  "TARNOVA",
  "TATU",
  "TEREGOVA",
  "TEROVA",
  "TICVANIU MARE",
  "TICVANIU MIC",
  "TINCOVA",
  "TIROL",
  "TOPLA",
  "TOPLET",
  "TURNU RUIENI",
  "URCU",
  "VALEA BISTREI",
  "VALEA BOLVASNITA",
  "VALEA MARE",
  "VALEA MINISULUI",
  "VALEA OREVITA",
  "VALEA RACHITEI",
  "VALEA RAVENSCA",
  "VALEA ROSIE",
  "VALEA SICHEVITEI",
  "VALEA TIMISULUI",
  "VALEADENI",
  "VALEAPAI",
  "VALISOARA",
  "VALIUG",
  "VAMA MARGA",
  "VAR",
  "VARADIA",
  "VARCIOROVA",
  "VERENDIN",
  "VERMES",
  "VODNIC",
  "VOISLOVA",
  "VRANI",
  "VRANIUT",
  "ZAGUJENI",
  "ZANOGI",
  "ZANOU",
  "ZASLOANE",
  "ZAVOI",
  "ZBEGU",
  "ZERVESTI",
  "ZLAGNA",
  "ZLATITA",
  "ZMOGOTIN",
  "ZOINA",
  "ZORILE",
  "ZORLENCIOR",
  "ZORLENTU MARE",
];
COUNTIES_CITIES["MEHEDINTI"] = [
  "23.aug",
  "ADUNATII TEIULUI",
  "ALBULESTI",
  "ALMAJEL",
  "ALUNISUL",
  "ARGINESTI",
  "ARVATESTI",
  "AURORA",
  "BACLES",
  "BADITESTI",
  "BAHNA",
  "BAIA DE ARAMA",
  "BAIA NOUA",
  "BALA",
  "BALA DE SUS",
  "BALACITA",
  "BALOTA",
  "BALOTESTI",
  "BALTA",
  "BALTA VERDE",
  "BALTANELE",
  "BALUTA",
  "BALVANESTI",
  "BALVANESTII DE JOS",
  "BARAIACU",
  "BARDA",
  "BARLOGENI",
  "BATOTI",
  "BIBAN",
  "BISTRETU",
  "BISTRITA",
  "BLIDARU",
  "BOBAITA",
  "BOCENI",
  "BOROGEA",
  "BRAGLEASA",
  "BRANISTEA",
  "BRANZENI",
  "BRATESUL",
  "BRATILOVU",
  "BRATIVOESTI",
  "BREBINA",
  "BRETA",
  "BREZNICIOARA",
  "BREZNITA-MOTRU",
  "BREZNITA-OCOL",
  "BROSTENI",
  "BUCURA",
  "BUDANESTI",
  "BUICANI",
  "BUICESTI",
  "BUNOAICA",
  "BURILA MARE",
  "BURILA MICA",
  "BUSESTI",
  "BUTOIESTI",
  "CALINESTII DE JOS",
  "CALINESTII DE SUS",
  "CAMPU MARE",
  "CAPATANESTI",
  "CARAMIDARU",
  "CARCENI",
  "CARJEI",
  "CARSU",
  "CAZANESTI",
  "CEARANGU",
  "CELNATA",
  "CEPTURENI",
  "CERANGANUL",
  "CERNAIA",
  "CERNA-VIRF",
  "CERNETI",
  "CERVENITA",
  "CIOCHIUTA",
  "CIOROBORENI",
  "CIOVARNASANI",
  "CIRESU",
  "COADA CORNETULUI",
  "COCOROVA",
  "COLARET",
  "COLIBASI",
  "COMANDA",
  "COMANESTI",
  "COPACIOASA",
  "CORCOVA",
  "CORDUN",
  "CORLATEL",
  "CORZU",
  "COSOVAT",
  "COSTESTI",
  "COTOROAIA",
  "COZIA",
  "CRACU LUNG",
  "CRACU MUNTELUI",
  "CRAGUESTI",
  "CRAINICI",
  "CREMENEA",
  "CRIVINA",
  "CROICA",
  "CUJMIR",
  "CUJMIRU MIC",
  "DALBOCITA",
  "DALMA",
  "DANCEU",
  "DARVARI",
  "DEALU MARE",
  "DEDOVITA NOUA",
  "DEDOVITA VECHE",
  "DELENI",
  "DELURENI",
  "DEVESEL",
  "DOBRA",
  "DRAGHESTI",
  "DRAGOTESTI",
  "DRINCEA",
  "DROBETA-TURNU SEVERIN",
  "DUBOVA",
  "DUDASU",
  "DUDASU SCHELEI",
  "DUMBRAVA DE JOS",
  "DUMBRAVA DE MIJLOC",
  "DUMBRAVA DE SUS",
  "DUMBRAVITA",
  "DUNAREA MICA",
  "EIBENTHAL",
  "ERCEA",
  "ERGHEVITA",
  "ESELNITA",
  "FANTANA DOMNEASCA",
  "FANTANILE NEGRE",
  "FATA CREMENII",
  "FATA MOTRULUI",
  "FAUROAIA",
  "FIRIZU",
  "FLORESTI",
  "GARBOVATU DE JOS",
  "GARBOVATU DE SUS",
  "GARDANEASA",
  "GARDOAIA",
  "GARLA MARE",
  "GARNITA",
  "GEMENI",
  "GHELMEGIOAIA",
  "GHEORGHESTI",
  "GIURA",
  "GIURGIANI",
  "GOANTA",
  "GODEANU",
  "GOGOSU",
  "GOLINEASA",
  "GORNENTI",
  "GORNOVITA",
  "GOVODARVA",
  "GRECI",
  "GROZESTI",
  "GRUIA",
  "GURA MOTRULUI",
  "GURA VAII",
  "GUTU",
  "GVARDINITA",
  "HALANGA",
  "HIGIU",
  "HINOVA",
  "HOTARANI",
  "HURDUCESTI",
  "HUSNICIOARA",
  "IABLANITA",
  "IGIROASA",
  "ILOVAT",
  "ILOVITA",
  "ILOVU",
  "IMOASA",
  "ISVERNA",
  "IUPCA",
  "IZIMSA",
  "IZVOARELE",
  "IZVORALU",
  "IZVORALU DE JOS",
  "IZVORU ANESTILOR",
  "IZVORU BARZII",
  "IZVORU FRUMOS",
  "JIANA",
  "JIANA MARE",
  "JIANA VECHE",
  "JIDOSTITA",
  "JIGNITA",
  "JIROV",
  "JUGASTRU",
  "JUPANESTI",
  "LAC",
  "LAZU",
  "LIVEZI",
  "LIVEZILE",
  "LUDU",
  "LUMNIC",
  "LUNCA BANULUI",
  "LUNCSOARA",
  "LUPSA DE JOS",
  "LUPSA DE SUS",
  "MAGHERU",
  "MAGURELE",
  "MALARISCA",
  "MALOVAT",
  "MANU",
  "MARASESTI",
  "MARGA",
  "MARMANU",
  "MARU ROSU",
  "MENTI",
  "MERIS",
  "MIJARCA",
  "MOISESTI",
  "MOLANI",
  "MOSNENI",
  "MOTRULENI",
  "NADANOVA",
  "NEGOESTI",
  "NEGRESTI",
  "NEGRUSA",
  "NEVATU",
  "NICOLAE BALCESCU",
  "NOAPTESA",
  "OBARSIA DE CAMP",
  "OBARSIA-CLOSANI",
  "OHABA",
  "OLTEANCA",
  "OPRANESTI",
  "OPRISOR",
  "OREVITA MARE",
  "ORSOVA",
  "OSTROVU CORBULUI",
  "OSTROVU MARE",
  "PADINA MARE",
  "PADINA MICA",
  "PALTINISU",
  "PARLAGELE",
  "PARVULESTI",
  "PASARANI",
  "PATULELE",
  "PAUNESTI",
  "PAVAT",
  "PERI",
  "PESTEANA",
  "PESTENUTA",
  "PETRA",
  "PETRIS",
  "PISTRITA",
  "PLAI",
  "PLOPI",
  "PLUTA",
  "PODENI",
  "PODU GROSULUI",
  "POIANA",
  "POIANA GRUII",
  "PONOARELE",
  "POROINA",
  "POROINA MARE",
  "POROINITA",
  "POSTA VECHE",
  "PREJNA",
  "PRIBOIESTI",
  "PRISACEAUA",
  "PRISTOL",
  "PROITESTI",
  "PRUNARU",
  "PRUNISOR",
  "PUNGHINA",
  "PUSCASU",
  "PUTINEI",
  "RACOVA",
  "RADUTESTI",
  "RAICULESTI",
  "RASCOLESTI",
  "RECEA",
  "ROCSORENI",
  "ROGOVA",
  "ROSIA",
  "ROSIORI",
  "RUDINA",
  "RUNCUSORU",
  "RUPTURA",
  "SALATRUC",
  "SALCIA",
  "SARDANESTI",
  "SATU MARE",
  "SATU NOU",
  "SCAPAU",
  "SCHELA CLADOVEI",
  "SCHINTEIESTI",
  "SCHITU TOPOLNITEI",
  "SCORILA",
  "SELISTEA",
  "SELISTENI",
  "SELISTIUTA",
  "SEVERINESTI",
  "SFODEA",
  "SIMIAN",
  "SIPOTU",
  "SIROCA",
  "SISESTI",
  "SLASOMA",
  "SLATINICU MARE",
  "SLATINICU MIC",
  "SMADOVITA",
  "SOVARNA",
  "SPERLESTI",
  "STANCESTI",
  "STANESTI",
  "STANGACEAUA",
  "STEFAN ODOBLEJA",
  "STEJARU",
  "STIGNITA",
  "STIRCOVITA",
  "STREHAIA",
  "STROESTI",
  "STUDINA",
  "SUHARU",
  "SUSITA",
  "SVINITA",
  "TALAPANU",
  "TAMNA",
  "TANTARU",
  "TARSA",
  "TIGANASI",
  "TISMANA",
  "TITERLESTI",
  "TITIRIGI",
  "TRAIAN",
  "TURTABA",
  "VALEA ANILOR",
  "VALEA BUNA",
  "VALEA COPCII",
  "VALEA COSUSTEI",
  "VALEA MARCULUI",
  "VALEA PETRII",
  "VALEA TEIULUI",
  "VALEA URSULUI",
  "VANATORI",
  "VANJU MARE",
  "VANJULET",
  "VARODIA",
  "VIASU",
  "VIDIMIRESTI",
  "VISINA",
  "VLADAIA",
  "VLADASESTI",
  "VLADICA",
  "VOLOIAC",
  "VOLOICEL",
  "VRANCEA",
  "VRATA",
  "ZEGAIA",
  "ZEGUJANI",
];
COUNTIES_CITIES["ILFOV"] = [
  "01.dec",
  "AFUMATI",
  "ALUNISU",
  "BALACEANCA",
  "BALOTESTI",
  "BALTA NEAGRA",
  "BALTENI",
  "BERCENI",
  "BRAGADIRU",
  "BRANESTI",
  "BUCIUMENI",
  "BUDA",
  "BUFTEA",
  "BURIAS",
  "CACIULATI",
  "CALDARARU",
  "CATELU",
  "CERNICA",
  "CHIAJNA",
  "CHITILA",
  "CIOFLICENI",
  "CIOLPANI",
  "CIOROGARLA",
  "CLINCENI",
  "COPACENI",
  "CORBEANCA",
  "CORNETU",
  "COZIENI",
  "CREATA",
  "CRETESTI",
  "CRETULEASCA",
  "DARASTI-ILFOV",
  "DARVARI",
  "DASCALU",
  "DIMIENI",
  "DOBROESTI",
  "DOMNESTI",
  "DRAGOMIRESTI-DEAL",
  "DRAGOMIRESTI-VALE",
  "DUDU",
  "DUMBRAVENI",
  "DUMITRANA",
  "FUNDENI",
  "GAGU",
  "GANEASA",
  "GHERMANESTI",
  "GLINA",
  "GRADISTEA",
  "GRUIU",
  "ISLAZ",
  "IZVORANI",
  "JILAVA",
  "LIPIA",
  "LUPARIA",
  "MAGURELE",
  "MAINEASCA",
  "MANOLACHE",
  "MERII PETCHII",
  "MICSUNESTII MARI",
  "MICSUNESTII-MOARA",
  "MOARA DOMNEASCA",
  "MOARA VLASIEI",
  "MOGOSOAIA",
  "NUCI",
  "ODAILE",
  "OLTENI",
  "ORDOREANU",
  "OSTRATU",
  "OTOPENI",
  "PANTELIMON",
  "PASAREA",
  "PERIS",
  "PETRACHIOAIA",
  "PETRESTI",
  "PISCU",
  "PITEASCA",
  "POPESTI LEORDENI",
  "POSTA",
  "PRUNI",
  "ROSU",
  "RUDENI",
  "RUNCU",
  "SAFTICA",
  "SANTU FLORESTI",
  "SILISTEA SNAGOVULUI",
  "SINDRILITA",
  "SINTESTI",
  "SITARU",
  "SNAGOV",
  "STEFANESTII DE JOS",
  "STEFANESTII DE SUS",
  "SURLARI",
  "TAMASI",
  "TANCABESTI",
  "TANGANU",
  "TEGHES",
  "TUNARI",
  "VADU ANEI",
  "VANATORI",
  "VARTEJU",
  "VIDRA",
  "VLADICEASCA",
  "VOLUNTARI",
  "ZURBAUA",
];
COUNTIES_CITIES["VASLUI"] = [
  "01.dec",
  "ALBESTI",
  "ALBINA",
  "ALBITA",
  "ALEXANDRU VLAHUTA",
  "ARMASENI",
  "ARMASOAIA",
  "ARSITA",
  "ARSURA",
  "AVERESTI",
  "AVRAMESTI",
  "BABUSA",
  "BABUTA",
  "BACANI",
  "BACAOANI",
  "BACESTI",
  "BADEANA",
  "BAHNARI",
  "BAILE DRANCENI",
  "BALESTI",
  "BALTATENI",
  "BALTATI",
  "BALTENI",
  "BALTENI-DEAL",
  "BANCA",
  "BANCESTI",
  "BARBOSI",
  "BARLAD",
  "BARLALESTI",
  "BARTALUS-MOCANI",
  "BARTALUS-RAZESI",
  "BARZESTI",
  "BEJENESTI",
  "BELCESTI",
  "BELZENI",
  "BENESTI",
  "BEREASA",
  "BEREZENI",
  "BLAGESTI",
  "BLESCA",
  "BOBESTI",
  "BOGDANA",
  "BOGDANA-VOLOSENI",
  "BOGDANESTI",
  "BOGDANITA",
  "BOGESTI",
  "BOLATI",
  "BORODESTI",
  "BOTESTI",
  "BOTOAIA",
  "BOTOI",
  "BOUSORI",
  "BOZIA",
  "BRADESTI",
  "BRAHASOAIA",
  "BRODOC",
  "BROSCOSESTI",
  "BROSTENI",
  "BUDA",
  "BUDESTI",
  "BUDU CANTEMIR",
  "BUHAIESTI",
  "BULBOACA",
  "BUMBATA",
  "BUNESTI",
  "BURSUCI",
  "BUSCATA",
  "BUTUCARIA",
  "CALIMANESTI",
  "CALUGARENI",
  "CANTALARESTI",
  "CAPOTESTI",
  "CAPUSNENI",
  "CARJA",
  "CARJOANI",
  "CARTIBASI",
  "CAZANESTI",
  "CEPESTI",
  "CETATUIA",
  "CHERSACOSU",
  "CHETRESTI",
  "CHETROSU",
  "CHILIENI",
  "CHIRCESTI",
  "CHITCANI",
  "CHITOC",
  "CIOATELE",
  "CIOCANI",
  "CIOFENI",
  "CIORTOLOM",
  "CIUPERCA",
  "CODAESTI",
  "CODRENI",
  "CONDREA",
  "COPACEANA",
  "CORBU",
  "CORNI-ALBESTI",
  "COROBANESTI",
  "CORODESTI",
  "COROIESTI",
  "COROIESTII DE SUS",
  "COROIU",
  "COSCA",
  "COSESTI",
  "COSTESTI",
  "COTIC",
  "COZMESTI",
  "CRACIUNESTI",
  "CRANG",
  "CRANGU NOU",
  "CRASNA",
  "CRASNASENI",
  "CRETESTI",
  "CRETESTII DE SUS",
  "CRISTESTI",
  "CRIVESTI",
  "CUJBA",
  "CURSESTI-DEAL",
  "CURSESTI-VALE",
  "CURTENI",
  "DANESTI",
  "DAVIDESTI",
  "DEALU MARE",
  "DEALU SECARII",
  "DELEA",
  "DELENI",
  "DELESTI",
  "DINGA",
  "DOAGELE",
  "DOBROSLOVESTI",
  "DOCANEASA",
  "DOCANI",
  "DODESTI",
  "DRAGESTI",
  "DRAGOMANESTI",
  "DRAGOMIRESTI",
  "DRANCENI",
  "DRAXENI",
  "DRUJESTI",
  "DUDA",
  "DUMASCA",
  "DUMBRAVENI",
  "DUMESTI",
  "DUMESTII VECHI",
  "EMIL RACOVITA",
  "EPURENI",
  "FALCIU",
  "FANTANA BLANARULUI",
  "FANTANELE",
  "FASTACI",
  "FEDESTI",
  "FERESTI",
  "FLORENI",
  "FLORESTI",
  "FOCSA",
  "FOCSEASCA",
  "FRASINU",
  "FRUNTISENI",
  "FULGU",
  "FUNDATURA",
  "FUNDATURA MARE",
  "FUNDATURA MICA",
  "FUNDU VAII",
  "GAGESTI",
  "GALTESTI",
  "GANESTI",
  "GARA BANCA",
  "GARA DOCANEASA",
  "GARA ROSIESTI",
  "GARA TALASMAN",
  "GARCENI",
  "GARDESTI",
  "GAVANU",
  "GHERGHELEU",
  "GHERGHESTI",
  "GHERMANESTI",
  "GHICANI",
  "GHIREASCA",
  "GIURCANI",
  "GIURGESTI",
  "GLODENI",
  "GRAJDENI",
  "GRIVITA",
  "GRUMEZOAIA",
  "GUGESTI",
  "GURA ALBESTI",
  "GURA IDRICI",
  "GURA VAII",
  "GUSITEI",
  "HALARESTI",
  "HALTA DODESTI",
  "HARSOVA",
  "HARSOVENI",
  "HOCENI",
  "HORDILA",
  "HORDILESTI",
  "HORGA",
  "HOROIATA",
  "HREASCA",
  "HUC",
  "HUPCA",
  "HURDUGI",
  "HUSI",
  "IANA",
  "IAZ",
  "IBANESTI",
  "IDRICI",
  "IEZER",
  "IEZEREL",
  "IGESTI",
  "IVANESTI",
  "IVESTI",
  "JIGALIA",
  "LACU BABEI",
  "LALESTI",
  "LATESTI",
  "LAZA",
  "LAZU",
  "LEOSTI",
  "LIPOVAT",
  "LUNCA",
  "LUNCA BANULUI",
  "LUNCA VECHE",
  "LUPESTI",
  "MACRESTI",
  "MALAIESTI",
  "MALUSTENI",
  "MANASTIREA",
  "MANJESTI",
  "MANTU",
  "MANZATESTI",
  "MANZATI",
  "MARASENI",
  "MARASESTI",
  "MASCUREI",
  "MICLESTI",
  "MIHAIL KOGALNICEANU",
  "MIRCESTI",
  "MIRENI",
  "MITOC",
  "MOARA DOMNEASCA",
  "MOARA GRECILOR",
  "MORARENI",
  "MORENI",
  "MOVILENI",
  "MUNTENESTI",
  "MUNTENII DE JOS",
  "MUNTENII DE SUS",
  "MURGENI",
  "MUSATA",
  "NEGRESTI",
  "OBARSENI",
  "OBARSENII LINGURARI",
  "ODAIA BOGDANA",
  "ODAIA BURSUCANI",
  "OLTENESTI",
  "OPRISITA",
  "ORGOIESTI",
  "OSESTI",
  "OTELENI",
  "OTETOAIA",
  "PACURARESTI",
  "PADURENI",
  "PAHNESTI",
  "PALTINIS",
  "PARPANITA",
  "PARVESTI",
  "PEICANI",
  "PERIENI",
  "PIHNA",
  "PLOPENI",
  "PLOPI",
  "PLOPOASA",
  "PLOTONESTI",
  "POCHIDIA",
  "PODENI",
  "PODU OPRII",
  "PODU PETRIS",
  "POGANA",
  "POGANESTI",
  "POGONESTI",
  "POIANA",
  "POIANA LUI ALEXA",
  "POIANA PIETREI",
  "POIENESTI",
  "POIENESTI-DEAL",
  "POLOCIN",
  "POPENI",
  "POPESTI",
  "PORTARI",
  "POSTA ELAN",
  "PRIBESTI",
  "PROTOPOPESTI",
  "PUIESTI",
  "PUNGESTI",
  "PUNTISENI",
  "PUSCASI",
  "PUTU OLARULUI",
  "RACOVA",
  "RACOVITA",
  "RADAESTI",
  "RADENI",
  "RADESTI",
  "RADUCANI",
  "RADUIESTI",
  "RAFAILA",
  "RAIU",
  "RANCENI",
  "RANZESTI",
  "RAPSA",
  "RASCANI",
  "RASESTI",
  "RASNITA",
  "RATESU CUZEI",
  "REBRICEA",
  "RECEA",
  "REDIU",
  "REDIU GALIAN",
  "ROSIESTI",
  "ROSIORI",
  "ROTARI",
  "RUGARIA",
  "RUSCA",
  "RUSI",
  "SALCENI",
  "SALCIOARA",
  "SARATENI",
  "SARATU",
  "SARBI",
  "SASOVA",
  "SATU NOU",
  "SAUCA",
  "SCHINENI",
  "SCHINETEA",
  "SCHITU",
  "SECUIA",
  "SEMENEA",
  "SERBOTESTI",
  "SILISTEA",
  "SIMILA",
  "SIMILISOARA",
  "SIPENI",
  "SISCANI",
  "SLOBOZIA",
  "SOCI",
  "SOFIENI",
  "SOFRONESTI",
  "SOLESTI",
  "SOPARLENI",
  "STANCASENI",
  "STANILESTI",
  "STEFAN CEL MARE",
  "STEJARU",
  "STIOBORANI",
  "STOISESTI",
  "STRAMTURA-MITOC",
  "STROIESTI",
  "STUHULET",
  "SUCEVENI",
  "SULETEA",
  "SUSENI",
  "TABALAESTI",
  "TACUTA",
  "TALPIGENI",
  "TANACU",
  "TARZII",
  "TATARANI",
  "TATOMIRESTI",
  "TEISORU",
  "TELEJNA",
  "TIBANESTII BUHLII",
  "TIFU",
  "TODIRENI",
  "TODIRESTI",
  "TOMESTI",
  "TOMSA",
  "TOPORASTI",
  "TRESTIANA",
  "TROHAN",
  "TUFESTII DE JOS",
  "TULESTI",
  "TUNSESTI",
  "TUPILATI",
  "TUTCANI",
  "TUTOVA",
  "ULEA",
  "UNCESTI",
  "UNTESTI",
  "URDESTI",
  "URICARI",
  "URLATI",
  "URSOAIA",
  "VADURILE",
  "VALCELE",
  "VALEA GRECULUI",
  "VALEA LUI BOSIE",
  "VALEA LUI DARIE",
  "VALEA LUNGA",
  "VALEA LUPULUI",
  "VALEA MARE",
  "VALEA OANEI",
  "VALEA POPII",
  "VALEA SEACA",
  "VALEA SILISTEI",
  "VALEA TARGULUI",
  "VALENI",
  "VASLUI",
  "VERDES",
  "VETRISOAIA",
  "VIISOARA",
  "VILTOTESTI",
  "VINDEREI",
  "VINETESTI",
  "VISINARI",
  "VIZURENI",
  "VLADESTI",
  "VLADIA",
  "VOINESTI",
  "VOVRIESTI",
  "VULPASENI",
  "VULTURESTI",
  "VUTCANI",
  "ZAPODENI",
  "ZGURA",
  "ZIZINCA",
  "ZORLENI",
];
COUNTIES_CITIES["BIHOR"] = [
  "ABRAM",
  "ABRAMUT",
  "ADONI",
  "ALBESTI",
  "ALBIS",
  "ALESD",
  "ALMASU MARE",
  "ALMASU MIC",
  "ALPAREA",
  "ANT",
  "APATEU",
  "ARPASEL",
  "ASTILEU",
  "ATEAS",
  "AUSEU",
  "AVRAM IANCU",
  "BAILE FELIX",
  "BAITA",
  "BAITA-PLAI",
  "BALAIA",
  "BALC",
  "BALENI",
  "BALNACA",
  "BALNACA-GROSI",
  "BATAR",
  "BEIUS",
  "BEIUSELE",
  "BELEJENI",
  "BELFIR",
  "BERECHIU",
  "BETFIA",
  "BEZNEA",
  "BICACEL",
  "BICACI",
  "BIHARIA",
  "BIRTIN",
  "BISTRA",
  "BOGEI",
  "BOIANU MARE",
  "BOIU",
  "BOROD",
  "BOROZEL",
  "BORS",
  "BORSA",
  "BORUMLACA",
  "BORZ",
  "BOTEAN",
  "BRADET",
  "BRATCA",
  "BRATESTI",
  "BRIHENI",
  "BRUSTURI",
  "BUCIUM",
  "BUCUROAIA",
  "BUDOI",
  "BUDUREASA",
  "BUDUSLAU",
  "BULZ",
  "BUNTESTI",
  "BURDA",
  "BURZUC",
  "BUTANI",
  "CABESTI",
  "CACUCIU NOU",
  "CACUCIU VECHI",
  "CADEA",
  "CALACEA",
  "CALATANI",
  "CALATEA",
  "CALEA MARE",
  "CALUGARI",
  "CAMP",
  "CAMPANI",
  "CAMPANI DE POMEZEU",
  "CAMP-MOTI",
  "CAPALNA",
  "CARANDENI",
  "CARANZEL",
  "CARASAU",
  "CARPINET",
  "CAUACEU",
  "CAUASD",
  "CEFA",
  "CEICA",
  "CEISOARA",
  "CENALOS",
  "CETARIU",
  "CETEA",
  "CHERECHIU",
  "CHERESIG",
  "CHERIU",
  "CHESA",
  "CHESEREU",
  "CHET",
  "CHIJIC",
  "CHIOAG",
  "CHIRALEU",
  "CHIRIBIS",
  "CHISCAU",
  "CHISIRID",
  "CHISLAZ",
  "CHISTAG",
  "CIHEI",
  "CIOCAIA",
  "CIUHOI",
  "CIULESTI",
  "CIUMEGHIU",
  "CIUTELEC",
  "COCIUBA MARE",
  "COCIUBA MICA",
  "CODRISORU",
  "CODRU",
  "COHANI",
  "COLESTI",
  "COPACEL",
  "COPACENI",
  "CORBESTI",
  "CORBOAIA",
  "CORDAU",
  "CORNISESTI",
  "CORNITEL",
  "COSDENI",
  "COTIGLET",
  "CRANCESTI",
  "CRESTUR",
  "CRESUIA",
  "CRISTIORU DE JOS",
  "CRISTIORU DE SUS",
  "CUBULCUT",
  "CUCUCENI",
  "CUIESD",
  "CURATELE",
  "CURTUISENI",
  "CUSUIUS",
  "CUZAP",
  "DAMIS",
  "DELANI",
  "DERNA",
  "DERNISOARA",
  "DICANESTI",
  "DIJIR",
  "DIOSIG",
  "DOBRESTI",
  "DOBRICIONESTI",
  "DOLEA",
  "DRAGANESTI",
  "DRAGESTI",
  "DRAGOTENI",
  "DUMBRAVA",
  "DUMBRAVANI",
  "DUMBRAVITA",
  "DUMBRAVITA DE CODRU",
  "DUSESTI",
  "FANATE",
  "FANCICA",
  "FASCA",
  "FEGERNIC",
  "FEGERNICU NOU",
  "FELCHERIU",
  "FENERIS",
  "FERICE",
  "FINIS",
  "FIZIS",
  "FOGLAS",
  "FONAU",
  "FORAU",
  "FOROSIG",
  "FUGHIU",
  "GALASENI",
  "GALOSPETRU",
  "GEPIS",
  "GEPIU",
  "GHEGHIE",
  "GHENETEA",
  "GHIDA",
  "GHIGHISENI",
  "GHIORAC",
  "GINTA",
  "GIRISU DE CRIS",
  "GIRISU NEGRU",
  "GIULESTI",
  "GOILA",
  "GRADINARI",
  "GROSI",
  "GRUILUNG",
  "GURANI",
  "GURBEDIU",
  "GURBESTI",
  "HAIEU",
  "HARSESTI",
  "HAUCESTI",
  "HIDIS",
  "HIDISEL",
  "HIDISELU DE JOS",
  "HIDISELU DE SUS",
  "HINCHIRIS",
  "HODIS",
  "HODISEL",
  "HODOS",
  "HOLOD",
  "HOMOROG",
  "HOTAR",
  "HOTAREL",
  "HUSASAU DE CRIS",
  "HUSASAU DE TINCA",
  "HUTA",
  "HUTA VOIVOZI",
  "IANCA",
  "IANOSDA",
  "INAND",
  "INCESTI",
  "INEU",
  "IOANIS",
  "ITEU",
  "ITEU NOU",
  "IZBUC",
  "IZVOARELE",
  "JOSANI",
  "LACU SARAT",
  "LAZARENI",
  "LAZURI",
  "LAZURI DE BEIUS",
  "LEHECENI",
  "LELESTI",
  "LES",
  "LIVADA BEIUSULUI",
  "LIVADA DE BIHOR",
  "LORANTA",
  "LORAU",
  "LUGASU DE JOS",
  "LUGASU DE SUS",
  "LUNCA",
  "LUNCASPRIE",
  "LUNCSOARA",
  "LUPOAIA",
  "MADARAS",
  "MAGESTI",
  "MAGURA",
  "MARGHITA",
  "MARGINE",
  "MARTIHAZ",
  "MEZIAD",
  "MIERAG",
  "MIERLAU",
  "MIERSIG",
  "MIHAI BRAVU",
  "MIHELEU",
  "MISCA",
  "MIZIES",
  "MOTESTI",
  "MUNTENI",
  "NADAR",
  "NIMAIESTI",
  "NIUVED",
  "NOJORID",
  "NUCET",
  "OGESTI",
  "OLCEA",
  "OLOSIG",
  "ORADEA",
  "ORTITEAG",
  "ORVISELE",
  "OSAND",
  "OSORHEI",
  "OTOMANI",
  "PACALESTI",
  "PADUREA NEAGRA",
  "PADURENI",
  "PAGAIA",
  "PALEU",
  "PALOTA",
  "PANTASESTI",
  "PARHIDA",
  "PAULESTI",
  "PAUSA",
  "PESTERE",
  "PESTIS",
  "PETID",
  "PETRANI",
  "PETREASA",
  "PETREU",
  "PETRILENI",
  "PICLEU",
  "PIETROASA",
  "POCIOVELISTE",
  "POCLUSA DE BARCAU",
  "POCLUSA DE BEIUS",
  "POCOLA",
  "POIANA",
  "POIANA TASAD",
  "POIENII DE JOS",
  "POIENII DE SUS",
  "POIETARI",
  "POMEZEU",
  "PONOARA",
  "POPESTI",
  "POSOLOACA",
  "PRISACA",
  "RABAGANI",
  "RACAS",
  "RAPA",
  "REGHEA",
  "REMETEA",
  "REMETI",
  "RIENI",
  "ROGOZ",
  "ROHANI",
  "ROIT",
  "RONTAU",
  "ROSIA",
  "ROSIORI",
  "ROTARESTI",
  "RUGEA",
  "SABOLCIU",
  "SACA",
  "SACADAT",
  "SACALASAU",
  "SACALASAU NOU",
  "SACUENI",
  "SALACEA",
  "SALARD",
  "SALDABAGIU DE BARCAU",
  "SALDABAGIU DE MUNTE",
  "SALDABAGIU MIC",
  "SALISTE",
  "SALISTE DE BEIUS",
  "SALISTE DE POMEZEU",
  "SALISTE DE VASCAU",
  "SALONTA",
  "SAMBATA",
  "SANIOB",
  "SANLAZAR",
  "SANMARTIN",
  "SANMARTIN DE BEIUS",
  "SANNICOLAU DE BEIUS",
  "SANNICOLAU DE MUNTE",
  "SANNICOLAU ROMAN",
  "SANTANDREI",
  "SANTAUL MARE",
  "SANTAUL MIC",
  "SANTELEC",
  "SANTIMREU",
  "SANTION",
  "SARAND",
  "SARBESTI",
  "SARBI",
  "SARCAU",
  "SARSIG",
  "SATU BARBA",
  "SATU NOU",
  "SAUAIEU",
  "SAUCANI",
  "SAUD",
  "SEBIS",
  "SEGHISTE",
  "SERANI",
  "SERGHIS",
  "SFARNAS",
  "SIGHISTEL",
  "SILINDRU",
  "SIMIAN",
  "SINTEU",
  "SISTEREA",
  "SITANI",
  "SITITELEC",
  "SOCET",
  "SOHODOL",
  "SOIMI",
  "SOIMUS",
  "SPINUS",
  "SPINUS DE POMEZEU",
  "STANCESTI",
  "STEI",
  "STRACOS",
  "SUBPIATRA",
  "SUDRIGIU",
  "SUIUG",
  "SUMUGIU",
  "SUNCUIS",
  "SUNCUIUS",
  "SUPLACU DE BARCAU",
  "SUPLACU DE TINCA",
  "SURDUC",
  "SURDUCEL",
  "SUSTIU",
  "SUSTUROGI",
  "TALPE",
  "TALPOS",
  "TAMASDA",
  "TAMASEU",
  "TARCAIA",
  "TARCAITA",
  "TARCEA",
  "TARGUSOR",
  "TARIAN",
  "TASAD",
  "TAUT",
  "TAUTELEC",
  "TAUTEU",
  "TELEAC",
  "TELECHIU",
  "TETCHEA",
  "TIGANESTII DE BEIUS",
  "TIGANESTII DE CRIS",
  "TILEAGD",
  "TILECUS",
  "TINAUD",
  "TINCA",
  "TOBOLIU",
  "TOMNATIC",
  "TOPA DE CRIS",
  "TOPA DE JOS",
  "TOPA DE SUS",
  "TOPESTI",
  "TOTORENI",
  "TRIA",
  "TULCA",
  "UCURIS",
  "UILEACU DE BEIUS",
  "UILEACU DE CRIS",
  "UILEACU DE MUNTE",
  "URSAD",
  "URVIND",
  "URVIS DE BEIUS",
  "VADU CRISULUI",
  "VAIDA",
  "VALANI DE POMEZEU",
  "VALANII DE BEIUS",
  "VALCELELE",
  "VALEA CERULUI",
  "VALEA CRISULUI",
  "VALEA DE JOS",
  "VALEA DE SUS",
  "VALEA LUI MIHAI",
  "VALEA MARE DE CODRU",
  "VALEA MARE DE CRIS",
  "VALEA TIRNEI",
  "VARASAU",
  "VARASENI",
  "VARCIOROG",
  "VARVIZ",
  "VARZARI",
  "VARZARII DE JOS",
  "VARZARII DE SUS",
  "VASAD",
  "VASCAU",
  "VIISOARA",
  "VINTERE",
  "VOIVOZI",
  "ZAVOIU",
  "ZECE HOTARE",
];
COUNTIES_CITIES["ALBA"] = [
  "ABRUD",
  "ABRUD-SAT",
  "ACHIMETESTI",
  "ACMARIU",
  "AIUD",
  "AIUDUL DE SUS",
  "ALBA IULIA",
  "ALBAC",
  "ALECUS",
  "ALMASU DE MIJLOC",
  "ALMASU MARE",
  "AMPOITA",
  "ANGHELESTI",
  "ARIESENI",
  "ARONESTI",
  "ARTI",
  "ASINIP",
  "AVRAM IANCU",
  "AVRAMESTI",
  "BABA",
  "BACAINTI",
  "BADAI",
  "BAGAU",
  "BAI",
  "BAIA DE ARIES",
  "BALCACIU",
  "BALESTI",
  "BALESTI-CATUN",
  "BALMOSESTI",
  "BALOMIRU DE CAMP",
  "BARABANT",
  "BARASTI",
  "BARBESTI",
  "BARDESTI",
  "BARLESTI",
  "BARLESTI-CATUN",
  "BARSANA",
  "BARZAN",
  "BARZOGANI",
  "BAZESTI",
  "BELDIU",
  "BENIC",
  "BERGHIN",
  "BETA",
  "BIDIGESTI",
  "BIHARIA",
  "BIIA",
  "BILANESTI",
  "BISERICANI",
  "BISTRA",
  "BLAJ",
  "BLANDIANA",
  "BLIDESTI",
  "BOBARESTI",
  "BOCESTI",
  "BOCSITURA",
  "BODESTI",
  "BODRESTI",
  "BOGDANESTI",
  "BOGLESTI",
  "BOLDESTI",
  "BOLOVANESTI",
  "BONCESTI",
  "BORDESTII POIENI",
  "BORLESTI",
  "BOTANI",
  "BOTESTI",
  "BOZ",
  "BRADEANA",
  "BRADESTI",
  "BRADET",
  "BRAZESTI",
  "BUBESTI",
  "BUCERDEA GRANOASA",
  "BUCERDEA VANOASA",
  "BUCIUM",
  "BUCIUM-SAT",
  "BUCURU",
  "BUDAIESTI",
  "BUDENI",
  "BULBUC",
  "BUNINGINEA",
  "BUNTA",
  "BURZESTI",
  "BURZONESTI",
  "BUTESTI",
  "CALENE",
  "CALNIC",
  "CALUGARESTI",
  "CAMPENI",
  "CAMPU GOBLII",
  "CAPALNA",
  "CAPALNA DE JOS",
  "CAPTALAN",
  "CAPU DEALULUI",
  "CAPUD",
  "CARASTI",
  "CARPEN",
  "CARPENII DE SUS",
  "CARPINIS",
  "CARTULESTI",
  "CASA DE PIATRA",
  "CASOAIA",
  "CENADE",
  "CERBU",
  "CERGAU MARE",
  "CERGAU MIC",
  "CERTEGE",
  "CERU-BACAINTI",
  "CETATEA DE BALTA",
  "CETEA",
  "CHEIA",
  "CHEILE CIBULUI",
  "CHELETENI",
  "CIB",
  "CICARD",
  "CICAU",
  "CINDESTI",
  "CIOARA DE SUS",
  "CIOCASU",
  "CIONESTI",
  "CISTEI",
  "CISTEIU DE MURES",
  "CIUCULESTI",
  "CIUGUD",
  "CIUGUDU DE JOS",
  "CIUGUDU DE SUS",
  "CIUGUZEL",
  "CIULDESTI",
  "CIUMBRUD",
  "CIURULEASA",
  "COASTA HENTII",
  "COASTA VASCULUI",
  "COBLES",
  "COCESTI",
  "COCOSESTI",
  "COJOCANI",
  "COLESENI",
  "COLIBI",
  "COLTESTI",
  "COPAND",
  "CORNA",
  "CORNU",
  "COROIESTI",
  "CORTESTI",
  "COSLARIU",
  "COSLARIU NOU",
  "COSTESTI",
  "COTORASTI",
  "CRACIUNELU DE JOS",
  "CRACIUNELU DE SUS",
  "CRAIVA",
  "CRETESTI",
  "CRICAU",
  "CRISENI",
  "CRISTESTI",
  "CUCUTA",
  "CUGIR",
  "CULDESTI",
  "CUNTA",
  "CURATURI",
  "CURMATURA",
  "CURPENI",
  "CUT",
  "DAIA ROMANA",
  "DAMBURENI",
  "DANDUT",
  "DARLESTI",
  "DAROAIA",
  "DEAL",
  "DEALU BAJULUI",
  "DEALU BISTRII",
  "DEALU CAPSEI",
  "DEALU CASELOR",
  "DEALU CRISULUI",
  "DEALU DOSTATULUI",
  "DEALU FERULUI",
  "DEALU FRUMOS",
  "DEALU GEOAGIULUI",
  "DEALU GOIESTI",
  "DEALU LAMASOI",
  "DEALU MUNTELUI",
  "DEALU ORDINCUSII",
  "DEALU ROATEI",
  "DECEA",
  "DELENI-OBIRSIE",
  "DEONCESTI",
  "DEVE",
  "DILIMANI",
  "DOBRA",
  "DOBRESTI",
  "DOBROT",
  "DOGARESTI",
  "DOLESTI",
  "DOPTAU",
  "DOS",
  "DOSTAT",
  "DOSU LUNCII",
  "DOSU VASESTI",
  "DRAGOIESTI-LUNCA",
  "DRAMBAR",
  "DRASOV",
  "DRIC",
  "DUDUIENI",
  "DUMACESTI",
  "DUMBRAVA",
  "DUMBRAVITA",
  "DUMESTI",
  "DUMITRA",
  "DUPA DEAL",
  "DUPA PLESE",
  "DURASTI",
  "FAGET",
  "FAGETU DE JOS",
  "FAGETU DE SUS",
  "FANTINELE",
  "FARAU",
  "FATA",
  "FATA ABRUDULUI",
  "FATA CRISTESEI",
  "FATA LAPUSULUI",
  "FATA PIETRII",
  "FATA-LAZESTI",
  "FEISA",
  "FENES",
  "FERESTI",
  "FERICET",
  "FETENI",
  "FICARESTI",
  "FLITESTI",
  "FLORESTI",
  "FURDUIESTI",
  "GABUD",
  "GALATI",
  "GALBENA",
  "GALDA DE JOS",
  "GALDA DE SUS",
  "GALTIU",
  "GAMBAS",
  "GANESTI",
  "GARBOVA",
  "GARBOVA DE JOS",
  "GARBOVA DE SUS",
  "GARBOVITA",
  "GARDA DE SUS",
  "GARDA SEACA",
  "GARDA-BARBULESTI",
  "GARDE",
  "GEAMANA",
  "GEOAGIU DE SUS",
  "GEOGEL",
  "GEOMAL",
  "GHEDULESTI",
  "GHETARI",
  "GHIONCANI",
  "GHIRBOM",
  "GIURGIUT",
  "GLIGORESTI",
  "GLOD",
  "GLOGOVET",
  "GOASELE",
  "GOIESTI",
  "GOJEIESTI",
  "GORGAN",
  "GROSI",
  "GURA ARIESULUI",
  "GURA CORNEI",
  "GURA CUTULUI",
  "GURA IZBITEI",
  "GURA ROSIEI",
  "GURA SOHODOL",
  "HADARAU",
  "HAIDUCESTI",
  "HANASESTI",
  "HAPRIA",
  "HARASTI",
  "HATEGANA",
  "HELERESTI",
  "HELESTI",
  "HENIG",
  "HERIA",
  "HOANCA",
  "HODISESTI",
  "HODOBANA",
  "HOLOBANI",
  "HOPARTA",
  "HOREA",
  "HUDRICESTI",
  "HUZARESTI",
  "IACOBESTI",
  "IBRU",
  "ICLOD",
  "IGHIEL",
  "IGHIU",
  "IGNATESTI",
  "ILIESTI",
  "INCESTI",
  "INOC",
  "INTREGALDE",
  "INURI",
  "ISCA",
  "IVANIS",
  "IZBICIOARA",
  "IZBITA",
  "IZLAZ",
  "IZVOARELE",
  "IZVORU AMPOIULUI",
  "JEFLESTI",
  "JIDOSTINA",
  "JIDVEI",
  "JOJEI",
  "JOLDISESTI",
  "JURCUIESTI",
  "LANCRAM",
  "LAZ",
  "LAZESTI",
  "LAZURI",
  "LEHESTI",
  "LEORINT",
  "LESPEZEA",
  "LIMBA",
  "LIPAIA",
  "LIVEZILE",
  "LODROMAN",
  "LOMAN",
  "LOPADEA NOUA",
  "LOPADEA VECHE",
  "LUMINESTI",
  "LUNCA",
  "LUNCA AMPOITEI",
  "LUNCA BISERICII",
  "LUNCA DE JOS",
  "LUNCA GOIESTI",
  "LUNCA LARGA",
  "LUNCA MERILOR",
  "LUNCA METESULUI",
  "LUNCA MURESULUI",
  "LUNCA TARNAVEI",
  "LUNCA VESESTI",
  "LUPAIESTI",
  "LUPSA",
  "LUPSENI",
  "LUPU",
  "LUPULESTI",
  "MACARESTI",
  "MAGHIERAT",
  "MAGINA",
  "MAGURA",
  "MAHACENI",
  "MAMALIGANI",
  "MANARADE",
  "MANASTIRE",
  "MANCESTI",
  "MARGAIA",
  "MARGINENI",
  "MARINESTI",
  "MARTESTI",
  "MARTINIE",
  "MATACINA",
  "MATEI",
  "MATISESTI",
  "MEDRESTI",
  "MEDVES",
  "MERETEU",
  "MESCREAC",
  "MESENTEA",
  "METES",
  "MICESTI",
  "MICOSLACA",
  "MIHAIESTI",
  "MIHALT",
  "MIHOESTI",
  "MIRASLAU",
  "MODOLESTI",
  "MOGOS",
  "MORARESTI",
  "MORCANESTI",
  "MOTORASTI",
  "MUGESTI",
  "MUNCELU",
  "MUNESTI",
  "MUNTARI",
  "MUNUNA",
  "MUSCA",
  "NADASTIA",
  "NAMAS",
  "NAPAIESTI",
  "NECRILESTI",
  "NECSESTI",
  "NEGESTI",
  "NEGRESTI",
  "NELEGESTI",
  "NEMESI",
  "NICORESTI",
  "NICULESTI",
  "NOSLAC",
  "NOVACESTI",
  "OARDA",
  "OBREJA",
  "OCNA MURES",
  "OCNISOARA",
  "OCOALE",
  "OCOLIS",
  "ODVEREM",
  "OHABA",
  "OIDESTI",
  "OIEJDEA",
  "OLTENI",
  "ONCESTI",
  "ORASTI",
  "ORGESTI",
  "ORMENIS",
  "PACLISA",
  "PADURE",
  "PADUREA",
  "PAGIDA",
  "PANADE",
  "PANCA",
  "PANTESTI",
  "PARAU GRUIULUI",
  "PARAU LUI MIHAI",
  "PARAU-CARBUNARI",
  "PASTESTI",
  "PATRAHAITESTI",
  "PATRANGENI",
  "PATRUSESTI",
  "PATRUTESTI",
  "PELES",
  "PERJESTI",
  "PESTE VALEA BISTRII",
  "PETELCA",
  "PETELEI",
  "PETREASA",
  "PETRENI",
  "PETRESTI",
  "PETRISAT",
  "PIANU DE JOS",
  "PIANU DE SUS",
  "PIRITA",
  "PITARCESTI",
  "PITIGA",
  "PLAI",
  "PLAIURI",
  "PLESCUTA",
  "PLESESTI",
  "PLESI",
  "PLISTI",
  "PODU LUI PAUL",
  "PODURI",
  "PODURI-BRICESTI",
  "POIANA",
  "POIANA AIUDULUI",
  "POIANA AMPOIULUI",
  "POIANA GALDEI",
  "POIANA URSULUI",
  "POIANA VADULUI",
  "POIENI",
  "POIENILE-MOGOS",
  "POIENITA",
  "POIU",
  "PONOR",
  "PONOREL",
  "POPESTI",
  "POPESTII DE JOS",
  "POPESTII DE SUS",
  "POSAGA DE JOS",
  "POSAGA DE SUS",
  "POSOGANI",
  "POTIONCI",
  "PRELUCA",
  "PRESACA AMPOIULUI",
  "PUIULETESTI",
  "PURCARETI",
  "PUSELESTI",
  "RACATAU",
  "RACHIS",
  "RACHITA",
  "RADESTI",
  "RAHAU",
  "RAICANI",
  "RAMET",
  "RATITIS",
  "RAVICESTI",
  "RAZBOIENI-CETATE",
  "RECIU",
  "REMETEA",
  "RIMETEA",
  "ROBESTI",
  "ROGOZ",
  "ROSESTI",
  "ROSIA DE SECAS",
  "ROSIA MONTANA",
  "RUNC",
  "RUNCURI",
  "RUSESTI",
  "RUSI",
  "SAGAGEA",
  "SALAGESTI",
  "SALCIUA DE JOS",
  "SALCIUA DE SUS",
  "SALISTEA",
  "SALISTEA-DEAL",
  "SANBENEDIC",
  "SANCEL",
  "SANCRAI",
  "SANMICLAUS",
  "SANTAMARIE",
  "SANTIMBRU",
  "SARACSAU",
  "SARD",
  "SARTAS",
  "SASA",
  "SASCIORI",
  "SCARISOARA",
  "SCOARTA",
  "SEBES",
  "SEBESEL",
  "SEBISESTI",
  "SECASEL",
  "SEGAJ",
  "SEUSA",
  "SFARCEA",
  "SFOARTEA",
  "SIBOT",
  "SICOIESTI",
  "SILEA",
  "SILIVAS",
  "SIMOCESTI",
  "SIMULESTI",
  "SNIDE",
  "SOAL",
  "SOHARU",
  "SOHODOL",
  "SOICESTI",
  "SOIMUS",
  "SONA",
  "SORLITA",
  "SPALNACA",
  "SPATAC",
  "SPRING",
  "STALNISOARA",
  "STANA DE MURES",
  "STANESTI",
  "STAUINI",
  "STEFANCA",
  "STEI-ARIESENI",
  "STERTESTI",
  "STIULETI",
  "STRAJA",
  "STREMT",
  "STRUNGARI",
  "STURU",
  "SUB PIATRA",
  "SUCESTI",
  "SUGAG",
  "SURDESTI",
  "SUSENI",
  "TAMBORESTI",
  "TARANESTI",
  "TARINA",
  "TARSA",
  "TARSA-PLAI",
  "TARTARIA",
  "TATARLAUA",
  "TAU",
  "TAU BISTRA",
  "TAUNI",
  "TAUTI",
  "TECSESTI",
  "TEIU",
  "TEIUS",
  "TELEAC",
  "TELNA",
  "TIBRU",
  "TIFRA",
  "TIUR",
  "TOCI",
  "TOLACESTI",
  "TOMESTI",
  "TOMNATEC",
  "TOMUSESTI",
  "TOMUTESTI",
  "TONEA",
  "TOTESTI",
  "TOTOI",
  "TRAMPOIELE",
  "TRANCESTI",
  "TRIFESTI",
  "TRISORESTI",
  "TURDAS",
  "UIOARA DE JOS",
  "UIOARA DE SUS",
  "UNGUREI",
  "UNIREA",
  "URDES",
  "VADU MOTILOR",
  "VAI",
  "VALCANEASA",
  "VALCEA",
  "VALCESTI",
  "VALE IN JOS",
  "VALEA ABRUZEL",
  "VALEA ALBA",
  "VALEA BARLUTESTI",
  "VALEA BARNII",
  "VALEA BISTRII",
  "VALEA BUCURULUI",
  "VALEA CASELOR",
  "VALEA CERBULUI",
  "VALEA CIUCIULUI",
  "VALEA COCESTI",
  "VALEA FAGETULUI",
  "VALEA GIOGESTI",
  "VALEA GOBLII",
  "VALEA HOLHORII",
  "VALEA INZELULUI",
  "VALEA LARGA",
  "VALEA LUI MIHAI",
  "VALEA LUNGA",
  "VALEA LUPSII",
  "VALEA MACIULUI",
  "VALEA MANASTIRII",
  "VALEA MARE",
  "VALEA MICA",
  "VALEA MLACII",
  "VALEA MORII",
  "VALEA NEGRILESII",
  "VALEA POIENII",
  "VALEA SASULUI",
  "VALEA SESII",
  "VALEA TUPILOR",
  "VALEA UTULUI",
  "VALEA UZEI",
  "VALEA VERDE",
  "VALEA VINTULUI",
  "VALENI",
  "VALISOARA",
  "VALTORI",
  "VAMA SEACA",
  "VANVUCESTI",
  "VARSI",
  "VARSII MARI",
  "VARSII MICI",
  "VARSI-RONTU",
  "VARTANESTI",
  "VARTOP",
  "VASESTI",
  "VERDESTI",
  "VESEUS",
  "VEZA",
  "VIDOLM",
  "VIDRA",
  "VIDRISOARA",
  "VIEZURI",
  "VINEREA",
  "VINGARD",
  "VINTA",
  "VINTU DE JOS",
  "VLADESTI",
  "VLADOSESTI",
  "VULCAN",
  "VURPAR",
  "ZAGRIS",
  "ZANZESTI",
  "ZARIES",
  "ZLATNA",
];
COUNTIES_CITIES["HUNEDOARA"] = [
  "ABUCEA",
  "ALMASEL",
  "ALMAS-SALISTE",
  "ALMASU MIC",
  "ALMASU MIC DE MUNTE",
  "ALMASU SEC",
  "ALUN",
  "ANINOASA",
  "ARANIES",
  "ARCHIA",
  "ARDEU",
  "AUREL VLAICU",
  "BACEA",
  "BACIA",
  "BACIIA",
  "BAIA DE CRIS",
  "BAIESTI",
  "BAITA",
  "BALATA",
  "BALDOVIN",
  "BALOMIR",
  "BALSA",
  "BANITA",
  "BANPOTOC",
  "BARASTII HATEGULUI",
  "BARASTII ILIEI",
  "BARBURA",
  "BARCEA MARE",
  "BARCEA MICA",
  "BARSAU",
  "BARU",
  "BARZA",
  "BASARABASA",
  "BASTEA",
  "BATALAR",
  "BATIZ",
  "BATRANA",
  "BEJAN",
  "BEJAN-TARNAVITA",
  "BERCU",
  "BERIU",
  "BIRTIN",
  "BLAJENI",
  "BLAJENI-VULCAN",
  "BOBAIA",
  "BOBALNA",
  "BOCSA MARE",
  "BOCSA MICA",
  "BOHOLT",
  "BOIA BARZII",
  "BOITA",
  "BOIU",
  "BOIU DE JOS",
  "BOIU DE SUS",
  "BOS",
  "BOSOROD",
  "BOZ",
  "BOZES",
  "BRAD",
  "BRADATEL",
  "BRANISCA",
  "BRASEU",
  "BRAZI",
  "BRAZNIC",
  "BREAZOVA",
  "BRETEA MURESANA",
  "BRETEA ROMANA",
  "BRETEA STREIULUI",
  "BRETELIN",
  "BROTUNA",
  "BUCES",
  "BUCES-VULCAN",
  "BUCIUM",
  "BUCIUM-ORLEA",
  "BUCURESCI",
  "BUJORU",
  "BULZESTII DE JOS",
  "BULZESTII DE SUS",
  "BUNESTI",
  "BUNILA",
  "BURJUC",
  "CABESTI",
  "CAINELU DE JOS",
  "CAINELU DE SUS",
  "CALAN",
  "CALANU MIC",
  "CAMPA",
  "CAMPU LUI NEAG",
  "CAMPURI DE SUS",
  "CAMPURI-SURDUC",
  "CAOI",
  "CARACI",
  "CARASTAU",
  "CARJITI",
  "CARMAZANESTI",
  "CARNESTI",
  "CARPINIS",
  "CASTAU",
  "CAZANESTI",
  "CERBAL",
  "CERBIA",
  "CERISOR",
  "CERNISOARA FLORESE",
  "CERTEJU DE JOS",
  "CERTEJU DE SUS",
  "CHERGHES",
  "CHIMINDIA",
  "CHISCADAGA",
  "CHITID",
  "CIGMAU",
  "CINCIS-CERNA",
  "CIOCLOVINA",
  "CIOPEIA",
  "CIULA MARE",
  "CIULA MICA",
  "CIULPAZ",
  "CIUMITA",
  "CIUNGANI",
  "CIUNGU MARE",
  "CLOPOTIVA",
  "COAJA",
  "COPACI",
  "COROIESTI",
  "COSESTI",
  "COSTESTI",
  "COSTESTI-DEAL",
  "COVRAGIU",
  "COZIA",
  "CRACIUNESTI",
  "CRAGUIS",
  "CRIS",
  "CRISAN",
  "CRISCIOR",
  "CRISTUR",
  "CRIVA",
  "CRIVADIA",
  "CUCUIS",
  "CUIES",
  "CURECHIU",
  "CURPENII SILVASULUI",
  "CUTIN",
  "DABACA",
  "DALJA MARE",
  "DALJA MICA",
  "DANCU MARE",
  "DANCU MIC",
  "DANULESTI",
  "DEALU BABII",
  "DEALU MARE",
  "DEALU MIC",
  "DELENI",
  "DENSUS",
  "DEVA",
  "DOBRA",
  "DOBROT",
  "DRAGU-BRAD",
  "DUDESTI",
  "DUMBRAVA",
  "DUMBRAVA DE JOS",
  "DUMBRAVA DE SUS",
  "DUMBRAVITA",
  "DUMESTI",
  "DUPAPIATRA",
  "FAGETEL",
  "FANTANA",
  "FARCADIN",
  "FATA ROSIE",
  "FEDERI",
  "FEREGI",
  "FINTOAG",
  "FIZES",
  "FIZESTI",
  "FOLT",
  "FORNADIA",
  "FURCSOARA",
  "GALATI",
  "GALBINA",
  "GANTAGA",
  "GELMAR",
  "GENERAL BERTHELOT",
  "GEOAGIU",
  "GEOAGIU-BAI",
  "GHELARI",
  "GIALACUTA",
  "GIURGESTI",
  "GLODGHILESTI",
  "GODINESTI",
  "GOLES",
  "GOTESTI",
  "GOTHATEA",
  "GOVAJDIA",
  "GRADISTEA DE MUNTE",
  "GRID",
  "GRIND",
  "GROHOT",
  "GROHOTELE",
  "GROS",
  "GROSURI",
  "GURA BORDULUI",
  "GURASADA",
  "HARAU",
  "HARTAGANI",
  "HASDAT",
  "HASDAU",
  "HATAGEL",
  "HATEG",
  "HEREPEIA",
  "HOBITA",
  "HOBITA-GRADISTE",
  "HOLDEA",
  "HOMOROD",
  "HONDOL",
  "HUNEDOARA",
  "ILIA",
  "ISCRONI",
  "IZVOARELE",
  "JELEDINTI",
  "JIET",
  "JIU-PAROSENI",
  "JOSANI",
  "LAPUGIU DE JOS",
  "LAPUGIU DE SUS",
  "LAPUSNIC",
  "LASAU",
  "LEAUT",
  "LELESE",
  "LESNIC",
  "LIVADA",
  "LIVADIA",
  "LIVEZI",
  "LUDESTII DE JOS",
  "LUDESTII DE SUS",
  "LUNCA",
  "LUNCA CERNII DE JOS",
  "LUNCA CERNII DE SUS",
  "LUNCANI",
  "LUNCOIU DE JOS",
  "LUNCOIU DE SUS",
  "LUNCSOARA",
  "LUPENI",
  "MACEU",
  "MADA",
  "MAGURA",
  "MAGURA-TOPLITA",
  "MAGURENI",
  "MALAIESTI",
  "MANERAU",
  "MARTINESTI",
  "MERIA",
  "MERISOR",
  "MERISORU DE MUNTE",
  "MERMEZEU-VALENI",
  "MESTEACAN",
  "MICANESTI",
  "MIHAIESTI",
  "MIHAILENI",
  "MINTIA",
  "MOSORU",
  "MUNCELU MARE",
  "MUNCELU MIC",
  "NADASTIA DE JOS",
  "NADASTIA DE SUS",
  "NALATVAD",
  "NANDRU",
  "NEGOIU",
  "NOJAG",
  "NUCSOARA",
  "OBARSA",
  "OCISOR",
  "OCIU",
  "OCOLISU MARE",
  "OCOLISU MIC",
  "OHABA",
  "OHABA DE SUB PIATRA",
  "OHABA STRAIULUI",
  "OHABA-PONOR",
  "OHABA-SIBISEL",
  "OPRISESTI",
  "ORASTIE",
  "ORASTIOARA DE JOS",
  "ORASTIOARA DE SUS",
  "ORMINDEA",
  "OSTROV",
  "OSTROVEL",
  "OSTROVU MIC",
  "PACLISA",
  "PANC",
  "PANC-SALISTE",
  "PAROS",
  "PAUCINESTI",
  "PAULESTI",
  "PAULIS",
  "PESTEANA",
  "PESTENITA",
  "PESTERA",
  "PESTISU MARE",
  "PESTISU MIC",
  "PETRENI",
  "PETRESTI",
  "PETRILA",
  "PETROS",
  "PETROSANI",
  "PIATRA",
  "PISCHINTI",
  "PLAI",
  "PLOP",
  "PLOPI",
  "PODELE",
  "POGANESTI",
  "POIANA",
  "POIANA RACHITELII",
  "POIENI",
  "POIENITA",
  "POIENITA TOMII",
  "POIENITA VOINII",
  "POJOGA",
  "PONOR",
  "POPESTI",
  "POTINGANI",
  "PRAVALENI",
  "PRICAZ",
  "PRIHODISTE",
  "PUI",
  "RACASTIA",
  "RACHITAUA",
  "RACHITOVA",
  "RADULESTI",
  "RAPAS",
  "RAPOLTEL",
  "RAPOLTU MARE",
  "RASCOALA",
  "RAU ALB",
  "RAU BARBAT",
  "RAU DE MORI",
  "RAU MIC",
  "REEA",
  "RENGHET",
  "RET",
  "RIBICIOARA",
  "RIBITA",
  "RISCA",
  "RISCULITA",
  "ROMOS",
  "ROMOSEL",
  "ROSCANI",
  "ROSIA",
  "ROVINA",
  "RUDA",
  "RUDA-BRAD",
  "RUNCSOR",
  "RUNCU MARE",
  "RUNCU MIC",
  "RUSESTI",
  "RUSI",
  "RUSOR",
  "SACAMAS",
  "SACARAMB",
  "SACEL",
  "SALASU DE JOS",
  "SALASU DE SUS",
  "SALATRUC",
  "SALCIVA",
  "SALISTE",
  "SALISTIOARA",
  "SANCRAI",
  "SANPETRU",
  "SANTAMARIA DE PIATRA",
  "SANTAMARIA-ORLEA",
  "SANTANDREI",
  "SANTUHALM",
  "SARBI",
  "SARMIZEGETUSA",
  "SAULESTI",
  "SERECA",
  "SEREL",
  "SESURI",
  "SIBISEL",
  "SILVASU DE JOS",
  "SILVASU DE SUS",
  "SIMERIA",
  "SIMERIA VECHE",
  "SLATINIOARA",
  "SOCET",
  "SOHODOL",
  "SOIMUS",
  "SPINI",
  "STANCESTI",
  "STANCESTI-OHABA",
  "STANCULESTI",
  "STANIJA",
  "STAUINI",
  "STEI",
  "STEIA",
  "STEJAREL",
  "STOIENEASA",
  "STREI",
  "STREI-SACEL",
  "STREISANGEORGIU",
  "STRETEA",
  "SUBCETATE",
  "SULIGHETE",
  "SUSENI",
  "TAMASASA",
  "TAMASESTI",
  "TAMPA",
  "TARATEL",
  "TARNAVA",
  "TARNAVA DE CRIS",
  "TARNAVITA",
  "TARNITA",
  "TARSA",
  "TATARASTI",
  "TATARASTII DE CRIS",
  "TEBEA",
  "TECHEREU",
  "TEIU",
  "TELIUCU INFERIOR",
  "TELIUCU SUPERIOR",
  "TICERA",
  "TIRICI",
  "TISA",
  "TIULESTI",
  "TOMESTI",
  "TOMNATEC",
  "TOPLITA",
  "TOPLITA MURESULUI",
  "TOTESTI",
  "TOTIA",
  "TRESTIA",
  "TURDAS",
  "TURMAS",
  "TUSTEA",
  "UIBARESTI",
  "ULIES",
  "ULM",
  "UNCIUC",
  "URIC",
  "URICANI",
  "UROI",
  "URSICI",
  "VADU",
  "VADU DOBRII",
  "VAIDEI",
  "VALARI",
  "VALCELE",
  "VALCELELE BUNE",
  "VALCELUTA",
  "VALEA",
  "VALEA ARSULUI",
  "VALEA BABII",
  "VALEA BRADULUI",
  "VALEA DALJII",
  "VALEA DE BRAZI",
  "VALEA LUNGA",
  "VALEA LUPULUI",
  "VALEA MARE DE CRIS",
  "VALEA NANDRULUI",
  "VALEA POIENII",
  "VALEA SANGEORGIULUI",
  "VALENI",
  "VALIOARA",
  "VALISOARA",
  "VARMAGA",
  "VATA DE JOS",
  "VATA DE SUS",
  "VETEL",
  "VICA",
  "VISCA",
  "VOIA",
  "VORTA",
  "VULCAN",
  "ZAM",
  "ZAVOI",
  "ZDRAPTI",
  "ZEICANI",
];
COUNTIES_CITIES["MURES"] = [
  "ABUD",
  "ABUS",
  "ACATARI",
  "ADAMUS",
  "ADRIAN",
  "ADRIANU MARE",
  "ADRIANU MIC",
  "AGRISTEU",
  "ALBESTI",
  "ALUNIS",
  "ANDRENEASA",
  "ANGOFA",
  "APALINA",
  "APOLD",
  "ARCHITA",
  "ARSITA",
  "ATINTIS",
  "AUREL VLAICU",
  "AVRAMESTI",
  "BAGACIU",
  "BAHNEA",
  "BAITA",
  "BALA",
  "BALAUSERI",
  "BALDA",
  "BAND",
  "BARA",
  "BARBOSI",
  "BARDESTI",
  "BARLIBAS",
  "BARLIBASOAIA",
  "BARZA",
  "BATOS",
  "BEDENI",
  "BEICA DE JOS",
  "BEICA DE SUS",
  "BERENI",
  "BERGHIA",
  "BERNADEA",
  "BEU",
  "BEZID",
  "BEZIDU NOU",
  "BICASU",
  "BICHIS",
  "BISTRA MURESULUI",
  "BLIDIREASA",
  "BOBOHALMA",
  "BOGATA",
  "BOIU",
  "BOLINTINENI",
  "BOLOGAIA",
  "BORD",
  "BORDOSIU",
  "BORZIA",
  "BOTEI",
  "BOTEZ",
  "BOTORCA",
  "BOZED",
  "BOZENI",
  "BRADETELU",
  "BRANCOVENESTI",
  "BREAZA",
  "BUDIU MIC",
  "BUJOR",
  "BUJOR-HODAIE",
  "CACIULATA",
  "CACUCIU",
  "CALIMANESTI",
  "CALUGARENI",
  "CALUSERI",
  "CAMPENITA",
  "CAMPU CETATII",
  "CANDU",
  "CAPALNA DE SUS",
  "CAPETI",
  "CAPUSU DE CAMPIE",
  "CASVA",
  "CECALACA",
  "CEIE",
  "CERGHID",
  "CERGHIZEL",
  "CEUAS",
  "CEUASU DE CAMPIE",
  "CHENDU",
  "CHETANI",
  "CHIBED",
  "CHIHERU DE JOS",
  "CHIHERU DE SUS",
  "CHINARI",
  "CHINCIUS",
  "CHIRILEU",
  "CHISALITA",
  "CIBA",
  "CIBU",
  "CINTA",
  "CIOARGA",
  "CIOBOTANI",
  "CIPAIENI",
  "CIPAU",
  "CIRETEA",
  "CIRHAGAU",
  "CIULEA",
  "CIURGAU",
  "CLOASTERF",
  "COASTA GRINDULUI",
  "COASTA MARE",
  "COMORI",
  "CORBESTI",
  "CORDOS",
  "CORNESTI",
  "COROI",
  "COROISANMARTIN",
  "CORUNCA",
  "COTORINAU",
  "COTUS",
  "COZMA",
  "CRACIUNESTI",
  "CRAIESTI",
  "CRIS",
  "CRISTESTI",
  "CUCERDEA",
  "CUCI",
  "CUIESD",
  "CULPIU",
  "CUND",
  "CURETE",
  "CURTENI",
  "CUSTELNIC",
  "DAIA",
  "DALU",
  "DAMBAU",
  "DAMBU",
  "DAMIENI",
  "DANES",
  "DATASENI",
  "DEAG",
  "DEAJ",
  "DEDA",
  "DEDRAD",
  "DELENI",
  "DELENII",
  "DILEU NOU",
  "DILEU VECHI",
  "DOBRA",
  "DRACULEA BANDULUI",
  "DROJDII",
  "DUBISTEA DE PADURE",
  "DULCEA",
  "DUMBRAVA",
  "DUMBRAVIOARA",
  "DUMITRENI",
  "DUMITRESTII",
  "DUPA DEAL",
  "ERCEA",
  "EREMIENI",
  "EREMITU",
  "ERNEI",
  "FANATELE CAPUSULUI",
  "FANATELE MADARASULUI",
  "FANATELE SOCOLULUI",
  "FANTANA BABII",
  "FANTANELE",
  "FARAGAU",
  "FELEAG",
  "FILEA",
  "FILITELNIC",
  "FILPISU MARE",
  "FILPISU MIC",
  "FITCAU",
  "FOI",
  "FRUNZENI",
  "FUNDATURA",
  "FUNDOAIA",
  "GAIESTI",
  "GALAOAIA",
  "GALATENI",
  "GALESTI",
  "GAMBUT",
  "GANESTI",
  "GAURA SANGERULUI",
  "GHEJA",
  "GHEORGHE DOJA",
  "GHIDASTEU",
  "GHINDARI",
  "GHINESTI",
  "GIULUS",
  "GIURGIS",
  "GLAJARIE",
  "GLODENI",
  "GOGAN",
  "GORENI",
  "GORNESTI",
  "GRADINI",
  "GRAUSORUL",
  "GREBENISU DE CAMPIE",
  "GRINDENI",
  "GROAPA RADAII",
  "GRUISOR",
  "GURGHIU",
  "HABIC",
  "HADARENI",
  "HAGAU",
  "HARANGLAB",
  "HARTAU",
  "HEREPEA",
  "HERGHELIA",
  "HETIUR",
  "HODAC",
  "HODAIA",
  "HODOSA",
  "IARA DE MURES",
  "IBANESTI",
  "IBANESTI-PADURE",
  "ICLAND",
  "ICLANDU MARE",
  "ICLANZEL",
  "IDECIU DE JOS",
  "IDECIU DE SUS",
  "IDICEL",
  "IDICEL-PADURE",
  "IDICIU",
  "IDRIFAIA",
  "IERNUT",
  "IERNUTENI",
  "IHOD",
  "ILIENI",
  "ILIESI",
  "ILIOARA",
  "IOD",
  "ISLA",
  "ISTAN-TAU",
  "ISTIHAZA",
  "IVANESTI",
  "JABENITA",
  "JACODU",
  "JACU",
  "LAPUSNA",
  "LARGA",
  "LASCUD",
  "LASLAU MARE",
  "LASLAU MIC",
  "LAURENI",
  "LECHINCIOARA",
  "LECHINTA",
  "LEFAIA",
  "LENIS",
  "LEORDENI",
  "LEORINTA",
  "LEORINTA-SAULIA",
  "LEPINDEA",
  "LINT",
  "LIVEZENI",
  "LOGIG",
  "LOTU",
  "LUDUS",
  "LUIERIU",
  "LUNCA",
  "LUNCA BRADULUI",
  "LUNCA MURESULUI",
  "MACICASESTI",
  "MADARAS",
  "MADARASENI",
  "MAGHERANI",
  "MAGHERUS",
  "MAIA",
  "MAIAD",
  "MAIORESTI",
  "MALAESTI",
  "MALDAOCI",
  "MALEA",
  "MARASESTI",
  "MARCULENI",
  "MATRICI",
  "MERISOR",
  "MERISORU",
  "MESTERA",
  "MICA",
  "MIERCUREA NIRAJULUI",
  "MIHAI VITEAZU",
  "MIHESU DE CAMPIE",
  "MILASEL",
  "MIRIGIOAIA",
  "MITRESTI",
  "MOARA DE JOS",
  "MOGOAIA",
  "MOISA",
  "MORARENI",
  "MORESTI",
  "MORUT",
  "MOSUNI",
  "MURA MARE",
  "MURA MICA",
  "MURENI",
  "MURESENI",
  "MURGESTI",
  "NADASA",
  "NADES",
  "NANDRA",
  "NAZNA",
  "NEAGRA",
  "NEAUA",
  "NEGRENII DE CAMPIE",
  "NICOLESTI",
  "NIMA MILASELULUI",
  "NIMA RACIULUI",
  "OARBA DE MURES",
  "OBARSIE",
  "ODRIHEI",
  "OGRA",
  "ONUCA",
  "ORMENIS",
  "OROIU",
  "OROSIA",
  "ORSOVA",
  "ORSOVA-PADURE",
  "OZD",
  "PACURENI",
  "PADUREA",
  "PADURENI",
  "PAINGENI",
  "PANET",
  "PAPIU ILARIAN",
  "PARAU CRUCII",
  "PARAU MARE",
  "PASARENI",
  "PAUCISOARA",
  "PAULOAIA",
  "PERIS",
  "PETEA",
  "PETELEA",
  "PETRILACA",
  "PETRILACA DE MURES",
  "PIETRIS",
  "PIPEA",
  "POARTA",
  "PODURI",
  "POGACEAUA",
  "POIENITA",
  "PORUMBAC",
  "PORUMBENI",
  "PRIPOARE",
  "PUSTA",
  "RACAMET",
  "RACIU",
  "RANTA",
  "RAPA DE JOS",
  "RASTOLITA",
  "RAZOARE",
  "RECEA",
  "REGHIN",
  "REMETEA",
  "RIGMANI",
  "RORA",
  "ROSIORI",
  "ROTENI",
  "ROUA",
  "RUSII-MUNTI",
  "SABED",
  "SACADAT",
  "SACALU DE PADURE",
  "SACARENI",
  "SAES",
  "SALARD",
  "SALASURI",
  "SALCUD",
  "SAMBRIAS",
  "SANCRAIU DE MURES",
  "SANDRU",
  "SANGEORGIU DE CAMPIE",
  "SANGEORGIU DE MURES",
  "SANGEORGIU DE PADURE",
  "SANGER",
  "SANGERU DE PADURE",
  "SANIACOB",
  "SANISOR",
  "SANMARGHITA",
  "SANMARTINU DE CAMPIE",
  "SANMIHAI DE PADURE",
  "SANPAUL",
  "SANPETRU DE CAMPIE",
  "SANSIMION",
  "SANTANA DE MURES",
  "SANTIOANA",
  "SANTIOANA DE MURES",
  "SANTU",
  "SANVASII",
  "SAPARTOC",
  "SARATENI",
  "SARDU NIRAJULUI",
  "SARMASEL",
  "SARMASEL-GARA",
  "SARMASU",
  "SASCHIZ",
  "SATU NOU",
  "SAULIA",
  "SAULITA",
  "SAUSA",
  "SCURTA",
  "SEBES",
  "SELEUS (Danes)",
  "SELEUS (Zagar)",
  "SENEREUS",
  "SERBENI",
  "SEUCA",
  "SEULIA DE MURES",
  "SFANTU GHEORGHE",
  "SICELE",
  "SIGHISOARA",
  "SILEA NIRAJULUI",
  "SINCAI",
  "SINCAI-FANATE",
  "SOARD",
  "SOCOLU DE CAMPIE",
  "SOIMUS",
  "SOLOCMA",
  "SOLOVASTRU",
  "SOMOSTELNIC",
  "SOROMICLEA",
  "SOVATA",
  "STANCENI",
  "STEFANCA",
  "STEFANEACA",
  "STEJARENII",
  "STEJERIS",
  "SUB PADURE",
  "SUPLAC",
  "SUSENI",
  "SUVEICA",
  "TABLASENI",
  "TAMPA",
  "TARGU MURES",
  "TARNAVENI",
  "TAU",
  "TAURENI",
  "TELEAC",
  "TIGMANDRU",
  "TIPTELNIC",
  "TIREU",
  "TIRIMIA",
  "TIRIMIOARA",
  "TISIEU",
  "TITIANA",
  "TOACA",
  "TOFALAU",
  "TOLDAL",
  "TONCIU",
  "TOPA",
  "TORBA",
  "TREI SATE",
  "TROITA",
  "TUSINU",
  "UILA",
  "ULIES",
  "UNGHENI",
  "URICEA",
  "URISIU DE JOS",
  "URISIU DE SUS",
  "URSOAIA",
  "VADAS",
  "VADU",
  "VAIDACUTA",
  "VAIDEIU",
  "VALEA",
  "VALEA ALBESTIULUI",
  "VALEA DAII",
  "VALEA FRATIEI",
  "VALEA GLODULUI",
  "VALEA ICLANDULUI",
  "VALEA IZVOARELOR",
  "VALEA LARGA",
  "VALEA MARE",
  "VALEA PADURII",
  "VALEA RECE",
  "VALEA SANMARTINULUI",
  "VALEA SANPETRULUI",
  "VALEA SAPARTOCULUI",
  "VALEA SASULUI",
  "VALEA SEACA",
  "VALEA SURII",
  "VALEA ULIESULUI",
  "VALEA UNGURULUI",
  "VALEA URIESULUI",
  "VALENI",
  "VALENII",
  "VALENII DE MURES",
  "VALISOARA",
  "VALURENI",
  "VANATORI",
  "VARGATA",
  "VATAVA",
  "VENCHI",
  "VETA",
  "VETCA",
  "VIDRASAU",
  "VIFOROASA",
  "VIILOR",
  "VIISOARA",
  "VISINELU",
  "VOINICENI",
  "VOIVODENI",
  "VULCAN",
  "ZAGAR",
  "ZAPODEA",
  "ZAU DE CAMPIE",
  "ZIMTI",
];
COUNTIES_CITIES["SATU-MARE"] = [
  "ACAS",
  "ACIUA",
  "ADRIAN",
  "ADY ENDRE",
  "AGRIS",
  "ALICENI",
  "ALIZA",
  "AMATI",
  "AMBUD",
  "ANDRID",
  "APA",
  "APATEU",
  "ARDUD",
  "ARDUD-VII",
  "ATEA",
  "BABA NOVAC",
  "BABASESTI",
  "BABESTI",
  "BABTA",
  "BARSAU DE JOS",
  "BARSAU DE SUS",
  "BATARCI",
  "BECHENI",
  "BELTIUG",
  "BERCU",
  "BERCU NOU",
  "BEREA",
  "BERINDAN",
  "BERVENI",
  "BICAU",
  "BIXAD",
  "BLAJA",
  "BOCICAU",
  "BOGDAND",
  "BOGHIS",
  "BOINESTI",
  "BOLDA",
  "BORLESTI",
  "BOTIZ",
  "CALINESTI-OAS",
  "CAMARZANA",
  "CAMIN",
  "CAPLENI",
  "CARASEU",
  "CAREI",
  "CAUAS",
  "CEAN",
  "CEHAL",
  "CEHALUT",
  "CERTEZE",
  "CHEGEA",
  "CHEREUSA",
  "CHILIA",
  "CHISAU",
  "CIDREAG",
  "CIG",
  "CIONCHESTI",
  "CIUMESTI",
  "CIUPERCENI",
  "COCA",
  "COMLAUSA",
  "COROD",
  "CORUND",
  "CRAIDOROLT",
  "CRISENI",
  "CRUCISOR",
  "CUCU",
  "CULCIU MARE",
  "CULCIU MIC",
  "CUTA",
  "DACIA",
  "DARA",
  "DECEBAL",
  "DINDESTI",
  "DINDESTIU MIC",
  "DOBA",
  "DOBOLT",
  "DOBRA",
  "DOMANESTI",
  "DOROLT",
  "DRAGUSENI",
  "DUMBRAVA",
  "ERIU SANCRAI",
  "ETENI",
  "FOIENI",
  "GANAS",
  "GELU",
  "GERAUSA",
  "GHENCI",
  "GHERTA MARE",
  "GHERTA MICA",
  "GHILESTI",
  "GHILVACI",
  "GHIRISA",
  "GHIROLT",
  "GIOROCUTA",
  "GIUNGI",
  "GIURTELECU HODODULUI",
  "HALMEU",
  "HALMEU VII",
  "HODISA",
  "HODOD",
  "HOMORODU DE JOS",
  "HOMORODU DE MIJLOC",
  "HOMORODU DE SUS",
  "HOREA",
  "HOTOAN",
  "HRIP",
  "HUREZU MARE",
  "HUTA CERTEZE",
  "IANCULESTI",
  "IEGHERISTE",
  "IOJIB",
  "IRINA",
  "ISTRAU",
  "LAZURI",
  "LECHINTA",
  "LELEI",
  "LIPAU",
  "LIVADA",
  "LIVADA MICA",
  "LUCACENI",
  "LUNA",
  "LUNCA APEI",
  "MADARAS",
  "MARIUS",
  "MARNA NOUA",
  "MARTINESTI",
  "MEDIES RATURI",
  "MEDIES VII",
  "MEDIESU AURIT",
  "MEDISA",
  "MESTEACAN",
  "MICULA",
  "MICULA NOUA",
  "MIHAIENI",
  "MOFTINU MARE",
  "MOFTINU MIC",
  "MOISENI",
  "NADISU HODODULUI",
  "NECOPOI",
  "NEGRESTI-OAS",
  "NISIPENI",
  "NOROIENI",
  "OAR",
  "ODOREU",
  "ORASU NOU",
  "ORASU NOU-VII",
  "ORBAU",
  "PASUNEA MARE",
  "PAULESTI",
  "PAULIAN",
  "PELES",
  "PELISOR",
  "PETEA",
  "PETIN",
  "PETRESTI",
  "PIR",
  "PIRU NOU",
  "PISCARI",
  "PISCOLT",
  "POIANA CODRULUI",
  "POMI",
  "PORTITA",
  "PORUMBESTI",
  "POTAU",
  "PRILOG",
  "PRILOG VII",
  "RACOVA",
  "RACSA",
  "RACSA VII",
  "RADULESTI",
  "RATESTI",
  "RATIU",
  "REMETEA OASULUI",
  "RESIGHEA",
  "ROMANESTI",
  "ROSIORI",
  "RUSENI",
  "SACASENI",
  "SAI",
  "SANDRA",
  "SANISLAU",
  "SANMICLAUS",
  "SANTAU",
  "SARATURA",
  "SARAUAD",
  "SARVAZEL",
  "SATMAREL",
  "SATU MARE",
  "SATU MIC",
  "SAUCA",
  "SCARISOARA NOUA",
  "SECHERESA",
  "SER",
  "SILVAS",
  "SIRLAU",
  "SOCOND",
  "SOCONZEL",
  "SOLDUBA",
  "SOMESENI",
  "STANA",
  "SUDURAU",
  "SUPURU DE JOS",
  "SUPURU DE SUS",
  "TAMASENI",
  "TARNA MARE",
  "TARSOLT",
  "TASNAD",
  "TATARESTI",
  "TEGHEA",
  "TEREBESTI",
  "TIREAC",
  "TIREAM",
  "TRAIAN",
  "TRIP",
  "TUR",
  "TURT",
  "TURT BAI",
  "TURULUNG",
  "TURULUNG VII",
  "UNIMAT",
  "URZICENI",
  "URZICENI PADURE",
  "VAGAS",
  "VALEA MORII",
  "VALEA SEACA",
  "VALEA VINULUI",
  "VAMA",
  "VANATORESTI",
  "VETIS",
  "VEZENDIU",
  "VIILE SATU MARE",
  "VIISOARA",
];
COUNTIES_CITIES["SIBIU"] = [
  "ACILIU",
  "AGARBICIU",
  "AGNITA",
  "ALAMOR",
  "ALBI",
  "ALMA",
  "ALMA VII",
  "ALTINA",
  "AMNAS",
  "APOLDU DE JOS",
  "APOLDU DE SUS",
  "APOS",
  "ARMENI",
  "ARPASU DE JOS",
  "ARPASU DE SUS",
  "ATEL",
  "AVRIG",
  "AXENTE SEVER",
  "BARGHIS",
  "BAZNA",
  "BENESTI",
  "BIERTAN",
  "BLAJEL",
  "BOARTA",
  "BOGATU ROMAN",
  "BOIAN",
  "BOITA",
  "BRADENI",
  "BRADU",
  "BRATEIU",
  "BROSTENI",
  "BRUIU",
  "BUIA",
  "BUNGARD",
  "BUZD",
  "CARTA",
  "CARTISOARA",
  "CASOLT",
  "CHESLER",
  "CHIRPAR",
  "CISNADIE",
  "CISNADIOARA",
  "COLONIA TALMACIU",
  "COLONIA TARNAVA",
  "COLUN",
  "COPSA MARE",
  "COPSA MICA",
  "CORNATEL",
  "COVES",
  "CRINT",
  "CRISTIAN",
  "CURCIU",
  "DAIA",
  "DARLOS",
  "DEALU FRUMOS",
  "DOBARCA",
  "DUMBRAVENI",
  "DUPUS",
  "ERNEA",
  "FANTANELE",
  "FLORESTI",
  "FOFELDEA",
  "GALES",
  "GHERDEAL",
  "GHIJASA DE JOS",
  "GHIJASA DE SUS",
  "GIACAS",
  "GLAMBOACA",
  "GURA RAULUI",
  "GUSU",
  "HAMBA",
  "HASAG",
  "HOGHILAG",
  "HOSMAN",
  "IACOBENI",
  "IGHISU NOU",
  "IGHISU VECHI",
  "ILIMBAV",
  "JINA",
  "LASLEA",
  "LAZARET",
  "LOAMNES",
  "LOTRIOARA",
  "LUDOS",
  "MAG",
  "MALANCRAV",
  "MANDRA",
  "MARPOD",
  "MARSA",
  "MEDIAS",
  "MERGHINDEAL",
  "METIS",
  "MICASASA",
  "MIERCUREA SIBIULUI",
  "MIGHINDOALA",
  "MIHAILENI",
  "MOARDAS",
  "MOHU",
  "MOSNA",
  "MOTIS",
  "MOVILE",
  "NEMSA",
  "NETUS",
  "NOCRICH",
  "NOISTAT",
  "NOU",
  "NOU SASESC",
  "NOUL ROMAN",
  "NUCET",
  "OCNA SIBIULUI",
  "ORLAT",
  "PADURENI",
  "PALTIN",
  "PALTINIS",
  "PAUCA",
  "PAUCEA",
  "PELISOR",
  "PETIS",
  "POIANA SIBIULUI",
  "POIENITA",
  "POPLACA",
  "PORUMBACU DE JOS",
  "PORUMBACU DE SUS",
  "PRESACA",
  "PRISLOP",
  "PROD",
  "RACOVITA",
  "RASINARI",
  "RAU SADULUI",
  "RAVASEL",
  "RETIS",
  "RICHIS",
  "ROANDOLA",
  "ROD",
  "ROMANESTI",
  "ROSIA",
  "RUJA",
  "RUSCIORI",
  "RUSI",
  "SACADATE",
  "SACEL",
  "SADINCA",
  "SADU",
  "SALCAU",
  "SALISTE",
  "SANGATIN",
  "SARATA",
  "SAROS PE TARNAVE",
  "SASAUS",
  "SCOREIU",
  "SEBESU DE JOS",
  "SEBESU DE SUS",
  "SEICA MARE",
  "SEICA MICA",
  "SELIMBAR",
  "SIBIEL",
  "SIBIU",
  "SLIMNIC",
  "SMIG",
  "SOALA",
  "SOMARTIN",
  "SOROSTIN",
  "STEJARISU",
  "STENEA",
  "SURA MARE",
  "SURA MICA",
  "TALMACEL",
  "TALMACIU",
  "TAPU",
  "TARNAVA",
  "TELINE",
  "TICHINDEAL",
  "TILISCA",
  "TOPARCEA",
  "TURNU ROSU",
  "VALCHID",
  "VALE",
  "VALEA LUNGA",
  "VALEA VIILOR",
  "VALENI",
  "VARD",
  "VECERD",
  "VELT",
  "VESEUD",
  "VESTEM",
  "VURPAR",
  "ZLAGNA",
];
COUNTIES_CITIES["ARAD"] = [
  "ACIUTA",
  "ADEA",
  "AGRISU MARE",
  "AGRISU MIC",
  "ALDESTI",
  "ALMAS",
  "ALUNIS",
  "ANDREI SAGUNA",
  "APATEU",
  "ARAD",
  "ARANEAG",
  "ARCHIS",
  "AVRAM IANCU (Cernei)",
  "AVRAM IANCU (Varfurile)",
  "BACAU DE MIJLOC",
  "BAIA",
  "BANESTI",
  "BARATCA",
  "BARSA",
  "BARZAVA",
  "BARZESTI",
  "BATA",
  "BATUTA",
  "BELIU",
  "BELOTINT",
  "BENESTI",
  "BERECHIU",
  "BERINDIA",
  "BIRCHIS",
  "BOCHIA",
  "BOCSIG",
  "BODESTI",
  "BODROGU NOU",
  "BODROGU VECHI",
  "BONTESTI",
  "BOTFEI",
  "BRAZII",
  "BRUSTURI",
  "BRUZNIC",
  "BUCEAVA-SOIMUS",
  "BUDESTI",
  "BUHANI",
  "BULCI",
  "BUTENI",
  "CALUGARENI",
  "CAMNA",
  "CAPALNAS",
  "CAPORAL ALEXA",
  "CAPRIOARA",
  "CAPRUTA",
  "CARAND",
  "CERMEI",
  "CHELMAC",
  "CHERELUS",
  "CHESINT",
  "CHIER",
  "CHISINDIA",
  "CHISINEU-CRIS",
  "CHISLACA",
  "CICIR",
  "CIL",
  "CINTEI",
  "CIUNTESTI",
  "CLADOVA",
  "CLIT",
  "COCIUBA",
  "COMANESTI",
  "CONOP",
  "CORBESTI",
  "COROI",
  "COVASINT",
  "CRAIVA",
  "CRISTESTI",
  "CROCNA",
  "CRUCENI",
  "CUIAS",
  "CUIED",
  "CURTICI",
  "CUVESDIA",
  "CUVIN",
  "DEZNA",
  "DIECI",
  "DONCENI",
  "DORGOS",
  "DOROBANTI",
  "DRAUT",
  "DUD",
  "DULCELE",
  "DUMBRAVA",
  "DUMBRAVITA",
  "FANTANELE",
  "FELNAC",
  "FENIS",
  "FIRITEAZ",
  "FISCUT",
  "FRUMUSENI",
  "GALSA",
  "GHIOROC",
  "GRANICERI",
  "GROSENI",
  "GROSI",
  "GROSII NOI",
  "GURA VAII",
  "GURAHONT",
  "GURBA",
  "HALALIS",
  "HALMAGEL",
  "HALMAGIU",
  "HASMAS",
  "HODIS",
  "HONTISOR",
  "HORIA",
  "HUNEDOARA TIMISANA",
  "IACOBINI",
  "IERCOSENI",
  "IERMATA",
  "IERMATA NEAGRA",
  "IGNESTI",
  "ILTEU",
  "INEU",
  "IONESTI",
  "IOSAS",
  "IRATOSU",
  "JOIA MARE",
  "JULITA",
  "LABASINT",
  "LALASINT",
  "LAZ",
  "LAZURI",
  "LEASA",
  "LESTIOARA",
  "LIPOVA",
  "LIVADA",
  "LUGUZAU",
  "LUNCSOARA",
  "LUPESTI",
  "MACEA",
  "MADERAT",
  "MADRIGESTI",
  "MAGULICEA",
  "MAILAT",
  "MANASTUR",
  "MANDRULOC",
  "MANERAU",
  "MARAUS",
  "MASCA",
  "MERMESTI",
  "MILOVA",
  "MINEAD",
  "MINIS",
  "MINISEL",
  "MINISU DE SUS",
  "MISCA",
  "MOCREA",
  "MONEASA",
  "MONOROSTIA",
  "MORODA",
  "MOTIORI",
  "MUNAR",
  "MUSTESTI",
  "NADAB",
  "NADALBESTI",
  "NADAS",
  "NADLAC",
  "NEAGRA",
  "NERMIS",
  "NEUDORF",
  "NICOLAE BALCESCU",
  "OBARSIA",
  "ODVOS",
  "OLARI",
  "OSTROV",
  "PAIUSENI",
  "PANCOTA",
  "PARNESTI",
  "PATARS",
  "PAULIN",
  "PAULIS",
  "PECICA",
  "PEREGU MARE",
  "PEREGU MIC",
  "PESCARI",
  "PETRIS",
  "PILU",
  "PLESCUTA",
  "POIANA",
  "PRUNISOR",
  "RADESTI",
  "RADNA",
  "RANUSA",
  "RAPSIG",
  "REVETIS",
  "ROGOZ DE BELIU",
  "ROSIA",
  "ROSIA NOUA",
  "ROSTOCI",
  "SAGU",
  "SALAJENI",
  "SAMBATENI",
  "SANLEANI",
  "SANMARTIN",
  "SANPAUL",
  "SANPETRU GERMAN",
  "SANTANA",
  "SARBI",
  "SATU MARE",
  "SATU MIC",
  "SATU NOU",
  "SAVARSIN",
  "SEBIS",
  "SECACI",
  "SECAS",
  "SECUSIGIU",
  "SEDERHAT",
  "SEITIN",
  "SELEUS",
  "SELISTE",
  "SELISTEA",
  "SEMLAC",
  "SEPREUS",
  "SIAD",
  "SICLAU",
  "SICULA",
  "SILINDIA",
  "SIMAND",
  "SINTEA MARE",
  "SINTEA MICA",
  "SIRIA",
  "SISTAROVAT",
  "SLATINA DE CRIS",
  "SLATINA DE MURES",
  "SOCODOR",
  "SOFRONEA",
  "SOIMOS",
  "SOMOSCHES",
  "STEJAR",
  "STOINESTI",
  "SUSAG",
  "SUSANI",
  "TAGADAU",
  "TALAGIU",
  "TALMACI",
  "TARMURE",
  "TARNAVITA",
  "TARNOVA",
  "TAUT",
  "TELA",
  "TEMESESTI",
  "TIPAR",
  "TISA",
  "TISA NOUA",
  "TOC",
  "TOHESTI",
  "TROAS",
  "TURNU",
  "URVISU DE BELIU",
  "USUSAU",
  "VALEA MARE",
  "VANATORI",
  "VARADIA DE MURES",
  "VARFURILE",
  "VARIASU MARE",
  "VARIASU MIC",
  "VARNITA",
  "VARSAND",
  "VASILE GOLDIS",
  "VASOAIA",
  "VIDRA",
  "VINGA",
  "VIRISMORT",
  "VLADIMIRESCU",
  "VOIVODENI",
  "ZABALT",
  "ZABRANI",
  "ZADARENI",
  "ZARAND",
  "ZERIND",
  "ZERINDU MIC",
  "ZIMANDCUZ",
  "ZIMANDU NOU",
  "ZIMBRU",
];
COUNTIES_CITIES["BRASOV"] = [
  "ACRIS",
  "APATA",
  "ARINI",
  "AUGUSTIN",
  "BARCUT",
  "BECLEAN",
  "BEIA",
  "BERIVOI",
  "BOD",
  "BOGATA OLTEANA",
  "BOHOLT",
  "BRAN",
  "BRASOV",
  "BREAZA",
  "BUCIUM",
  "BUDILA",
  "BUNESTI",
  "BUZAIEL",
  "CALBOR",
  "CARPINIS",
  "CATA",
  "CHEIA",
  "CINCSOR",
  "CINCU",
  "COBOR",
  "CODLEA",
  "COLONIA 1 MAI",
  "COLONIA BOD",
  "COLONIA RECONSTRUCTIA",
  "COMANA DE JOS",
  "COMANA DE SUS",
  "COPACEL",
  "CORBI",
  "CRIHALMA",
  "CRISTIAN",
  "CRIT",
  "CRIZBAV",
  "CUCIULATA",
  "CUTUS",
  "DACIA",
  "DAISOARA",
  "DALGHIU",
  "DEJANI",
  "DOPCA",
  "DRAGUS",
  "DRAUSENI",
  "DRIDIF",
  "DRUMUL CARULUI",
  "DUMBRAVITA",
  "FAGARAS",
  "FANTANA",
  "FELDIOARA",
  "FELMER",
  "FISER",
  "FUNDATA",
  "FUNDATICA",
  "GHIMBAV",
  "GRANARI",
  "GRID",
  "GURA VAII",
  "HALCHIU",
  "HALMEAG",
  "HARMAN",
  "HARSENI",
  "HOGHIZ",
  "HOLBAV",
  "HOMOROD",
  "HUREZ",
  "IASI",
  "ILENI",
  "IONESTI",
  "JIBERT",
  "JIMBOR",
  "LISA",
  "LOVNIC",
  "LUDISOR",
  "LUNCA CALNICULUI",
  "LUPSA",
  "LUTA",
  "MAGURA",
  "MAIERUS",
  "MALINIS",
  "MANDRA",
  "MARGINENI",
  "MATEIAS",
  "MERCHEASA",
  "MESENDORF",
  "MOIECIU DE JOS",
  "MOIECIU DE SUS",
  "OHABA",
  "OLTET",
  "ORMENIS",
  "PALOS",
  "PALTIN",
  "PARAU",
  "PARAUL RECE",
  "PERSANI",
  "PESTERA",
  "PODU OLTULUI",
  "POIANA BRASOV",
  "POIANA MARULUI",
  "POJORTA",
  "PREDEAL",
  "PREDELUT",
  "PREJMER",
  "PURCARENI",
  "RACOS",
  "RASNOV",
  "RAUSOR",
  "RECEA",
  "ROADES",
  "RODBAV",
  "ROTBAV",
  "RUCAR",
  "RUPEA",
  "SACELE",
  "SAMBATA DE SUS",
  "SANPETRU",
  "SASCIORI",
  "SATU NOU",
  "SAVASTRENI",
  "SEBES",
  "SELISTAT",
  "SERCAIA",
  "SERCAITA",
  "SIMBATA DE JOS",
  "SIMON",
  "SINCA NOUA",
  "SINCA VECHE",
  "SIRNEA",
  "SOARS",
  "SOHODOL",
  "SONA",
  "STATIUNEA CLIMATERICA SIMBATA",
  "STUPINII PREJMERULUI",
  "TARLUNGENI",
  "TELIU",
  "TICUSU NOU",
  "TICUSU VECHI",
  "TIMISU DE JOS",
  "TIMISU DE SUS",
  "TOARCLA",
  "TODERITA",
  "TOHANU NOU",
  "UCEA DE JOS",
  "UCEA DE SUS",
  "UNGRA",
  "VAD",
  "VALCEA",
  "VALENI",
  "VAMA BUZAULUI",
  "VENETIA DE JOS",
  "VENETIA DE SUS",
  "VICTORIA",
  "VISCRI",
  "VISTEA DE JOS",
  "VISTEA DE SUS",
  "VISTISOARA",
  "VLADENI",
  "VOILA",
  "VOIVODENI",
  "VULCAN",
  "ZARNESTI",
  "ZIZIN",
];
COUNTIES_CITIES["SALAJ"] = [
  "ADALIN",
  "AGHIRES",
  "AGRIJ",
  "ALEUS",
  "ALMASU",
  "ALUNIS",
  "ARCHID",
  "BABENI",
  "BABIU",
  "BADACIN",
  "BADON",
  "BAICA",
  "BALAN",
  "BAN",
  "BANISOR",
  "BARSA",
  "BARSAU MARE",
  "BENESAT",
  "BERCEA",
  "BEZDED",
  "BIC",
  "BILGHEZ",
  "BIRSAUTA",
  "BIUSA",
  "BIZUSA BAI",
  "BOBOTA",
  "BOCSA",
  "BOCSITA",
  "BODIA",
  "BOGDANA",
  "BOGHIS",
  "BORLA",
  "BORZA",
  "BOZIES",
  "BOZNA",
  "BRAGLEZ",
  "BREBI",
  "BRUSTURI",
  "BUCIUMI",
  "BULGARI",
  "BUZAS",
  "CALACEA",
  "CAMAR",
  "CAMPIA",
  "CAPALNA",
  "CARASTELEC",
  "CEACA",
  "CEHEI",
  "CEHU SILVANIEI",
  "CERISA",
  "CERNUC",
  "CHECHIS",
  "CHENDREA",
  "CHENDREMAL",
  "CHEUD",
  "CHICHISA",
  "CHIESD",
  "CHILIOARA",
  "CHIZENI",
  "CIGLEAN",
  "CIOCMANI",
  "CIULA",
  "CIUMARNA",
  "CIURENI",
  "CIZER",
  "CLIT",
  "COLONIA SIGHETU SILVANIEI",
  "CORMENIS",
  "COSEIU",
  "COSNICIU DE JOS",
  "COSNICIU DE SUS",
  "COZLA",
  "CRASNA",
  "CREACA",
  "CRISENI",
  "CRISTELEC",
  "CRISTOLT",
  "CRISTOLTEL",
  "CRISTUR CRISENI",
  "CUBLESU",
  "CUCEU",
  "CUCIULAT",
  "CUTIS",
  "CUZAPLAC",
  "DABACENI",
  "DEJA",
  "DELENI",
  "DERSIDA",
  "DIOSOD",
  "DOBA",
  "DOBRIN",
  "DOBROCINA",
  "DOH",
  "DOLHENI",
  "DOLU",
  "DOMNIN",
  "DRAGU",
  "DRIGHIU",
  "DUMUSLAU",
  "FABRICA",
  "FAGETU",
  "FALCUSA",
  "FANTANELE",
  "FANTANELE RUS",
  "FETINDIA",
  "FILDU DE JOS",
  "FILDU DE MIJLOC",
  "FILDU DE SUS",
  "FIRMINIS",
  "FIZES",
  "FODORA",
  "FRANCENII DE PIATRA",
  "FUFEZ",
  "GALASENI",
  "GALGAU",
  "GALGAU ALMASULUI",
  "GALPAIA",
  "GARBOU",
  "GARCEIU",
  "GIURTELECU SIMLEULUI",
  "GLOD",
  "GOSTILA",
  "GURA VLADESEI",
  "GURUSLAU",
  "HALMASD",
  "HASMAS",
  "HERECLEAN",
  "HIDA",
  "HOROATU CEHULUI",
  "HOROATU CRASNEI",
  "HUREZ",
  "HUSENI",
  "HUSIA",
  "HUTA",
  "IAZ",
  "ILEANDA",
  "ILISUA",
  "INAU",
  "IP",
  "JAC",
  "JEBUCU",
  "JIBOU",
  "LAZURI",
  "LEMNIU",
  "LESMIR",
  "LETCA",
  "LOMPIRT",
  "LOZNA",
  "LUMINISU",
  "LUPOAIA",
  "MAERISTE",
  "MAGURA",
  "MAL",
  "MALADIA",
  "MALENI",
  "MARCA",
  "MARCA HUTA",
  "MARIN",
  "MESESENII DE JOS",
  "MESESENII DE SUS",
  "MESTEACANU",
  "MIERTA",
  "MILUANI",
  "MINEU",
  "MIRSID",
  "MOIAD",
  "MOIGRAD-POROLISSUM",
  "MOTIS",
  "MUNCEL",
  "NADIS",
  "NAIMON",
  "NAPRADEA",
  "NEGRENI",
  "NOTIG",
  "NUSFALAU",
  "PADURENI",
  "PADURIS",
  "PANIC",
  "PAUSA",
  "PECEIU",
  "PERICEI",
  "PERICEIU MIC",
  "PERII VADULUI",
  "PETRINDU",
  "PETRINZEL",
  "PIROSA",
  "PLESCA",
  "PLOPIS",
  "POARTA SALAJULUI",
  "PODISU",
  "POIANA BLENCHII",
  "POIANA MAGURA",
  "POIANA ONTII",
  "POIENITA",
  "POPENI",
  "POPTELEAC",
  "PORT",
  "PRELUCI",
  "PREOTEASA",
  "PRIA",
  "PRODANESTI",
  "PURCARET",
  "PUSTA",
  "RACAS",
  "RASTOCI",
  "RASTOLT",
  "RASTOLTU DESERT",
  "RATIN",
  "RATOVEI",
  "RECEA",
  "RECEA MICA",
  "ROGNA",
  "ROMANASI",
  "ROMITA",
  "RONA",
  "RUGINOASA",
  "RUS",
  "SAG",
  "SALAJENI",
  "SALATIG",
  "SAMSUD",
  "SANCRAIU ALMASULUI",
  "SANCRAIU SILVANIEI",
  "SANGEORGIU DE MESES",
  "SANMIHAIU ALMASULUI",
  "SANPETRU ALMASULUI",
  "SANTA MARIA",
  "SARBI",
  "SARMASAG",
  "SASA",
  "SEREDEIU",
  "SFARAS",
  "SICI",
  "SIGHETU SILVANIEI",
  "SIMISNA",
  "SIMLEU SILVANIEI",
  "SOIMUS",
  "SOIMUSENI",
  "SOLOMON",
  "SOLONA",
  "SOMES GURUSLAU",
  "SOMES-ODORHEI",
  "STANA",
  "STARCIU",
  "STOBORU",
  "STUPINI",
  "SUB CETATE",
  "SUMAL",
  "SURDUC",
  "SUTORU",
  "TAMASA",
  "TARMURE",
  "TAUDU",
  "TESTIOARA",
  "TETISU",
  "TIHAU",
  "TOPLITA",
  "TRANIS",
  "TRESTIA",
  "TREZNEA",
  "TURBUTA",
  "TUSA",
  "UGRUTIU",
  "UILEACU SIMLEULUI",
  "ULCIUG",
  "VADURELE",
  "VALCAU DE JOS",
  "VALCAU DE SUS",
  "VALEA CIURENILOR",
  "VALEA HRANEI",
  "VALEA LESULUI",
  "VALEA LOZNEI",
  "VALEA LUNGA",
  "VALEA POMILOR",
  "VALENI",
  "VALISOARA",
  "VAR",
  "VARSOLT",
  "VARTESCA",
  "VERVEGHIU",
  "VIILE JACULUI",
  "VOIVODENI",
  "ZALAU",
  "ZALHA",
  "ZALNOC",
  "ZAUAN",
  "ZAUAN BAI",
  "ZIMBOR",
];
COUNTIES_CITIES["GALATI"] = [
  "ADAM",
  "ALDESTI",
  "BALABANESTI",
  "BALASESTI",
  "BALENI",
  "BALINTESTI",
  "BALTARETI",
  "BANEASA",
  "BARCEA",
  "BERESTI",
  "BERESTI-MERIA",
  "BLANZI",
  "BRAHASESTI",
  "BRANESTI",
  "BRANISTEA",
  "BRATULESTI",
  "BUCESTI",
  "BUCIUMENI",
  "BURSUCANI",
  "CALMATUI",
  "CARAPCESTI",
  "CARLOMANESTI",
  "CAUIESTI",
  "CAVADINESTI",
  "CERTESTI",
  "CHIRAFTEI",
  "CIORASTI",
  "CISMELE",
  "CIURESTI",
  "CIURESTII NOI",
  "COASTA LUPEI",
  "COMANESTI",
  "CONDREA",
  "CORCIOVENI",
  "CORNI",
  "COROD",
  "COSITENI",
  "COSMESTI",
  "COSMESTII-VALE",
  "COSTACHE NEGRI",
  "COSTI",
  "COTOROAIA",
  "CRAIESTI",
  "CRANGENI",
  "CRUCEANU",
  "CUCA",
  "CUDALBI",
  "CUZA VODA",
  "DOBRINESTI",
  "DRAGANESTI",
  "DRAGUSENI",
  "FANTANELE",
  "FANTANI",
  "FARTANESTI",
  "FOLTESTI",
  "FRUMUSITA",
  "FRUNZEASCA",
  "FUNDEANU",
  "FUNDENI",
  "FUNDENII NOI",
  "FURCENII NOI",
  "FURCENII VECHI",
  "GALATI",
  "GANESTI",
  "GARA BERHECI",
  "GARA GHIDIGENI",
  "GARBOVAT",
  "GEFU",
  "GHIDIGENI",
  "GHINGHESTI",
  "GOHOR",
  "GRIVITA",
  "GROZAVESTI",
  "GURA GARBOVATULUI",
  "HANTESTI",
  "HANU CONACHI",
  "HUSTIU",
  "IJDILENI",
  "INDEPENDENTA",
  "IONASESTI",
  "IREASCA",
  "IVESTI",
  "IZVOARELE",
  "JORASTI",
  "LIESTI",
  "LOZOVA",
  "LUNCA",
  "LUNGESTI",
  "LUNGOCI",
  "LUPELE",
  "MACISENI",
  "MALU ALB",
  "MALURENI",
  "MANDRESTI",
  "MASTACANI",
  "MATCA",
  "MIHAIL KOGALNICEANU",
  "MOSCU",
  "MOVILENI",
  "MUNTENI",
  "NAMOLOASA",
  "NAMOLOASA SAT",
  "NARTESTI",
  "NEGREA",
  "NEGRILESTI",
  "NICOPOLE",
  "NICORESTI",
  "OANCEA",
  "ODAIA MANOLACHE",
  "ONCIU",
  "PECHEA",
  "PISCU",
  "PISCU CORBULUI",
  "PLESA",
  "PLEVNA",
  "PODOLENI",
  "POIANA",
  "POSTA",
  "PRIPONESTI",
  "PRIPONESTII DE JOS",
  "PRODANESTI",
  "PUPEZENI",
  "PURICANI",
  "RADESTI",
  "REDIU",
  "ROGOJENI",
  "ROSCANI",
  "SALCIA",
  "SARBI",
  "SASENI",
  "SATU NOU",
  "SCANTEIESTI",
  "SCHELA",
  "SENDRENI",
  "SERBESTII VECHI",
  "SILISTEA",
  "SIPOTE",
  "SIVITA",
  "SLIVNA",
  "SLOBOZIA BLANEASA",
  "SLOBOZIA CONACHI",
  "SLOBOZIA CORNI",
  "SLOBOZIA OANCEA",
  "SMARDAN",
  "SMULTI",
  "STIETESTI",
  "STOICANI",
  "SUCEVENI",
  "SUHURLUI",
  "TALPIGI",
  "TAMAOANI",
  "TAPLAU",
  "TARGU BUJOR",
  "TATARCA",
  "TECUCELU SEC",
  "TECUCI",
  "TEPU",
  "TEPU DE SUS",
  "TIGANESTI",
  "TOFLEA",
  "TORCESTI",
  "TRAIAN",
  "TUDOR VLADIMIRESCU",
  "TULUCESTI",
  "UMBRARESTI",
  "UMBRARESTI-DEAL",
  "UNGURENI",
  "URLESTI",
  "VADENI",
  "VALEA MARULUI",
  "VAMES",
  "VANATORI",
  "VARLEZI",
  "VASILE ALECSANDRI",
  "VIILE",
  "VISINA",
  "VIZURESTI",
  "VLADESTI",
  "ZARNESTI",
  "ZIMBRU",
];
COUNTIES_CITIES["DAMBOVITA"] = [
  "ADANCA",
  "ALUNIS",
  "ALUNISU",
  "ANINOASA",
  "ANINOSANI",
  "BACESTI",
  "BADENI",
  "BADULESTI",
  "BALANESTI",
  "BALDANA",
  "BALENI-ROMANI",
  "BALENI-SARBI",
  "BALTENI",
  "BANESTI",
  "BARACENI",
  "BARBUCEANU",
  "BARBULETU",
  "BECHINESTI",
  "BELA",
  "BEREVOESTI",
  "BEZDEAD",
  "BILCIURESTI",
  "BLIDARI",
  "BOBOCI",
  "BOLOVANI",
  "BOTENI",
  "BRADATEL",
  "BRANCOVEANU",
  "BRANESTI",
  "BRANISTEA",
  "BRATESTII DE JOS",
  "BREBU",
  "BREZOAELE",
  "BREZOAIA",
  "BROSTENI",
  "BUCIUMENI",
  "BUCSANI",
  "BUJOREANCA",
  "BUMBUIA",
  "BUNGETU",
  "BURDUCA",
  "BUTIMANU",
  "BUTOIU DE JOS",
  "BUTOIU DE SUS",
  "CALUGARENI",
  "CANDESTI DEAL",
  "CANDESTI-VALE",
  "CAPRIORU",
  "CAPSUNA",
  "CAPU COASTEI",
  "CARLANESTI",
  "CATANELE",
  "CATUNU",
  "CAZACI",
  "CETATUIA",
  "CHIRCA",
  "CIOCANARI",
  "CIOCANESTI",
  "COADA IZVORULUI",
  "COBIUTA",
  "COCANI",
  "COJASCA",
  "COJOCARU",
  "COJOIU",
  "COLACU",
  "COLANU",
  "COLIBASI",
  "COMISANI",
  "CONTESTI",
  "COPACENI",
  "CORBII MARI",
  "CORNATELU",
  "CORNESTI",
  "CORNI",
  "COSTESTI",
  "COSTESTII DIN DEAL",
  "COSTESTII DIN VALE",
  "COSTISATA",
  "CRACIUNESTI",
  "CRANGASI",
  "CRANGURILE DE JOS",
  "CRANGURILE DE SUS",
  "CRETU",
  "CRETULESTI",
  "CREVEDIA",
  "CRICOVU DULCE",
  "CRISTEASCA",
  "CRIVATU",
  "CROITORI",
  "CROVU",
  "CUCUTENI",
  "CUPARU",
  "CUZA VODA",
  "DAMBOVICIOARA",
  "DARMANESTI",
  "DARZA",
  "DEALU FRUMOS",
  "DEALU MARE",
  "DECINDEA",
  "DECINDENI",
  "DIACONESTI",
  "DIMOIU",
  "DOBRA",
  "DOBRESTI",
  "DOICESTI",
  "DOSPINESTI",
  "DRAGAESTI PAMANTENI",
  "DRAGAESTI UNGURENI",
  "DRAGODANA",
  "DRAGODANESTI",
  "DRAGOMIRESTI",
  "DUMBRAVA",
  "DUPA DEAL",
  "FAGETU",
  "FANTANELE",
  "FERESTRE",
  "FETENI",
  "FIENI",
  "FIERBINTI",
  "FINTA MARE",
  "FINTA VECHE",
  "FRASIN DEAL",
  "FRASIN VALE",
  "FRASINU",
  "FUSEA",
  "GAESTI",
  "GAMANESTI",
  "GARLENI",
  "GEANGOESTI",
  "GEMENEA BRATULESTI",
  "GHEBOAIA",
  "GHEBOIENI",
  "GHERGANI",
  "GHERGHESTI",
  "GHERGHITESTI",
  "GHIMPATI",
  "GHINESTI",
  "GHIRDOVENI",
  "GLOD",
  "GLODENI",
  "GLOGOVEANU",
  "GORGOTA",
  "GRECI",
  "GROZAVESTI",
  "GULIA",
  "GURA BARBULETULUI",
  "GURA FOII",
  "GURA OCNITEI",
  "GURA SUTII",
  "GURA VULCANEI",
  "GUSOIU",
  "HABENI",
  "HAGIOAICA",
  "HANU LUI PALA",
  "HELESTEU",
  "HODARASTI",
  "HULUBESTI",
  "I. L. CARAGIALE",
  "IAZU",
  "IBRIANU",
  "IEDERA DE JOS",
  "IEDERA DE SUS",
  "ILFOVENI",
  "IONESTI",
  "IZVOARELE",
  "IZVORU",
  "JUGURENI",
  "LACULETE",
  "LACULETE-GARA",
  "LAZURI",
  "LIVEZILE",
  "LUCIANCA",
  "LUCIENI",
  "LUDESTI",
  "LUNCA",
  "LUNGULETU",
  "MAGURA",
  "MALU CU FLORI",
  "MALU MIERII",
  "MALURILE",
  "MANASTIOARA",
  "MANASTIREA",
  "MANESTI",
  "MANGA",
  "MARCESTI",
  "MARGINENII DE SUS",
  "MARUNTISU",
  "MATASARU",
  "MATRACA",
  "MAVRODIN",
  "MEISOARE",
  "MERENI",
  "MERII",
  "MERISORU",
  "MESTEACAN",
  "MICLOSANII MARI",
  "MICLOSANII MICI",
  "MICULESTI",
  "MIJA",
  "MILOSARI",
  "MINJINA",
  "MIRCEA VODA",
  "MISLEA",
  "MIULESTI",
  "MOARA DIN GROAPA",
  "MOARA NOUA",
  "MOGOSANI",
  "MOGOSESTI",
  "MORENI",
  "MOROENI",
  "MORTENI",
  "MOSIA MICA",
  "MOTAIENI",
  "MOVILA",
  "MUSCEL",
  "NEAJLOVU",
  "NICOLAESTI",
  "NICULESTI",
  "NISIPURILE",
  "NUCET",
  "OCHIURI",
  "OCNITA",
  "ODAIA TURCULUI",
  "ODOBESTI",
  "OLTENI",
  "ONCESTI",
  "OREASCA",
  "PADURENI",
  "PATROAIA DEAL",
  "PATROAIA VALE",
  "PERSINARI",
  "PETRESTI",
  "PIATRA",
  "PICIOR DE MUNTE",
  "PIETRARI",
  "PIETROSITA",
  "PITARU",
  "PLOPU",
  "PODU CORBENCII",
  "PODU CRISTINII",
  "PODU RIZII",
  "POIANA",
  "POIENITA",
  "POROINICA",
  "POSTARNACU",
  "POTLOGENI VALE",
  "POTLOGENI-DEAL",
  "POTLOGI",
  "POTOCELU",
  "PRIBOIU",
  "PRISEACA",
  "PRODULESTI",
  "PUCHENI",
  "PUCIOASA",
  "PUCIOASA-SAT",
  "PUNTEA DE GRECI",
  "PUTU CU SALCIE",
  "RACARI",
  "RACIU",
  "RACOVITA",
  "RAGU",
  "RANCACIOV",
  "RASCAETI",
  "RATESTI",
  "RATOAIA",
  "RAU ALB DE JOS",
  "RAU ALB DE SUS",
  "RAZVAD",
  "ROMANESTI",
  "RUNCU",
  "SABIESTI",
  "SACUENI",
  "SALCIOARA",
  "SALCUTA",
  "SAMURCASI",
  "SARU",
  "SATENI",
  "SATU NOU",
  "SAVESTI",
  "SCHEIU DE JOS",
  "SCHEIU DE SUS",
  "SCHELA",
  "SELARU",
  "SERBANEASA",
  "SERDANU",
  "SILISTEA",
  "SIPOT",
  "SLOBOZIA",
  "SLOBOZIA MOARA",
  "SOTANGA",
  "SPERIETENI",
  "STANESTI",
  "STATESTI",
  "STAVROPOLIA",
  "STRAOSTI",
  "STRATONESTI",
  "STUBEIE TISA",
  "SUDULENI",
  "SULTANU",
  "SUSENI SOCETU",
  "SUTA SEACA",
  "SUVITA",
  "TARGOVISTE",
  "TARTASESTI",
  "TATARANI",
  "TEIS",
  "TELESTI",
  "TETCOIU",
  "TITU",
  "TOCULESTI",
  "TOMSANI",
  "TUNARI",
  "UDRESTI",
  "ULIESTI",
  "ULMETU",
  "ULMI",
  "UNGURENI",
  "URSEIU",
  "URZICEANCA",
  "VACARESTI",
  "VADU STANCHII",
  "VALEA",
  "VALEA CASELOR",
  "VALEA DADEI",
  "VALEA LARGA",
  "VALEA LEURZII",
  "VALEA LUI DAN",
  "VALEA LUNGA GORGOTA",
  "VALEA LUNGA OGREA",
  "VALEA LUNGA-CRICOV",
  "VALEA MARE",
  "VALEA MORII",
  "VALEA VOIVOZILOR",
  "VALENI-DAMBOVITA",
  "VARFURENI",
  "VARFURI",
  "VIFORATA",
  "VIISOARA",
  "VISINA",
  "VISINESTI",
  "VIZURESTI",
  "VLADENI",
  "VLASCENI",
  "VOIA",
  "VOINESTI",
  "VULCANA DE SUS",
  "VULCANA-BAI",
  "VULCANA-PANDELE",
  "VULTUREANCA",
  "ZAVOIU",
  "ZIDURILE",
];
COUNTIES_CITIES["DOLJ"] = [
  "ADANCATA",
  "AFUMATI",
  "ALBESTI",
  "ALMAJ",
  "ALMAJEL",
  "AMARASTI",
  "AMARASTII DE JOS",
  "AMARASTII DE SUS",
  "APELE VII",
  "ARGETOAIA",
  "BADOSI",
  "BAILESTI",
  "BALASAN",
  "BALOTA DE JOS",
  "BALOTA DE SUS",
  "BALTA",
  "BALTA VERDE",
  "BARANU",
  "BARBOI",
  "BARCA",
  "BASARABI",
  "BASCOV",
  "BAZDANA",
  "BECHET",
  "BEHARCA",
  "BELCINU",
  "BELOT",
  "BENESTI",
  "BERBESU",
  "BISTRET",
  "BISTRETU NOU",
  "BOBEANU",
  "BODAIESTI",
  "BODAIESTII DE SUS",
  "BOGEA",
  "BOJOIU",
  "BOOVENI",
  "BOTOSESTI-PAIA",
  "BOURENI",
  "BRABETI",
  "BRABOVA",
  "BRADESTI",
  "BRADESTII BATRINI",
  "BRALOSTITA",
  "BRANDUSA",
  "BRANISTE",
  "BRATOVOESTI",
  "BREASTA",
  "BUCICANI",
  "BUCOVAT",
  "BUCOVICIOR",
  "BUJOR",
  "BULZESTI",
  "BUSTENI",
  "BUSU",
  "BUSULETU",
  "BUZDUC",
  "CACIULATESTI",
  "CACIULATU",
  "CALAFAT",
  "CALARASI",
  "CALINESTI",
  "CALOPAR",
  "CALUGAREI",
  "CAMPENI",
  "CARAIMAN",
  "CARAULA",
  "CARCEA",
  "CARLIGEI",
  "CARNA",
  "CARPEN",
  "CARSTOVANI",
  "CARUIA",
  "CASTRANOVA",
  "CASTRELE TRAIANE",
  "CATANE",
  "CATANELE NOI",
  "CELARU",
  "CERAT",
  "CERNAT",
  "CERNATESTI",
  "CERNELE",
  "CERNELELE DE SUS",
  "CETATE",
  "CETATUIA",
  "CHIASU",
  "CIOCANELE",
  "CIOCANESTI",
  "CIOROIASI",
  "CIOROIU NOU",
  "CIUPERCENII NOI",
  "CIUPERCENII VECHI",
  "CIUTURA",
  "CLEANOV",
  "COMANICEA",
  "COMOSTENI",
  "CORLATE",
  "CORNETU",
  "CORNITA",
  "CORNU",
  "COSERENI",
  "COSOVENI",
  "COTOFENII DIN DOS",
  "COTOFENII DIN FATA",
  "COTU",
  "COVEI",
  "CRAIOVA",
  "CRIVA",
  "CROVNA",
  "CURMATURA",
  "DABULENI",
  "DANETI",
  "DELENI",
  "DESA",
  "DESNATUI",
  "DIOSTI",
  "DOBRESTI",
  "DOBRIDOR",
  "DOBROMIRA",
  "DOBROTESTI",
  "DOMNU TUDOR",
  "DRAGOAIA",
  "DRAGOTESTI",
  "DRANIC",
  "DUDOVICESTI",
  "DUTULESTI",
  "FACAI",
  "FAGET",
  "FANTANI",
  "FANTINELE",
  "FARCAS",
  "FILARET",
  "FILIASI",
  "FLORAN",
  "FLORESTI",
  "FOISOR",
  "FRASIN",
  "FRATILA",
  "FRATOSTITA",
  "GABRU",
  "GAIA",
  "GALICEA MARE",
  "GALICIUICA",
  "GANGIOVA",
  "GARLESTI",
  "GEBLESTI",
  "GEOROCEL",
  "GEOROCU MARE",
  "GHERCESTI",
  "GHIDICI",
  "GHINDENI",
  "GHIZDAVESTI",
  "GIGHERA",
  "GIUBEGA",
  "GIURGITA",
  "GODENI",
  "GOGOSESTI",
  "GOGOSITA",
  "GOGOSU",
  "GOICEA",
  "GOIESTI",
  "GOLENTI",
  "GOLFIN",
  "GOLUMBELU",
  "GOLUMBU",
  "GRADISTEA",
  "GRECESTI",
  "GROPANELE",
  "GRUITA",
  "GUBAUCEA",
  "GURA RACULUI",
  "GURA VAII",
  "HOREZU POENARI",
  "HUNIA",
  "INFRATIREA",
  "INTORSURA",
  "IORDACHESTI",
  "ISALNITA",
  "ITALIENI",
  "IZVOARE",
  "IZVOR",
  "IZVORUL RECE",
  "JIENI",
  "JIUL",
  "LACRITA MARE",
  "LACRITA MICA",
  "LANGA",
  "LAZU",
  "LEAMNA DE JOS",
  "LEAMNA DE SUS",
  "LEORDOASA",
  "LESILE",
  "LEU",
  "LIPOVU",
  "LIPOVU DE SUS",
  "LISTEAVA",
  "LIVEZI",
  "LOCUSTENI",
  "LUNCSORU",
  "MACESU DE JOS",
  "MACESU DE SUS",
  "MAGLAVIT",
  "MALAESTI",
  "MALAICA",
  "MALU MARE",
  "MALUMNIC",
  "MARACINELE",
  "MAROTINU DE JOS",
  "MAROTINU DE SUS",
  "MARSANI",
  "MARZA",
  "MELINESTI",
  "METEU",
  "MIHAITA",
  "MILESTI",
  "MILOVAN",
  "MISCHII",
  "MLECANESTI",
  "MOFLENI",
  "MOFLESTI",
  "MOGOSESTI",
  "MORENI",
  "MOSNA",
  "MOSNENI",
  "MOTATEI",
  "MOTATEI GARA",
  "MOTOCI",
  "MUERENI",
  "MUIERUSU",
  "MURGASI",
  "MURTA",
  "NEDEIA",
  "NEGOI",
  "NEGOIESTI",
  "NISIPURI",
  "NISTOI",
  "NOVAC",
  "OBEDIN",
  "OCOLNA",
  "ODOLENI",
  "OHABA",
  "ORODEL",
  "OSTROVENI",
  "PADEA",
  "PALILULA",
  "PANAGHIA",
  "PERENI",
  "PERISOR",
  "PICATURILE",
  "PIELESTI",
  "PIETROAIA",
  "PIORESTI",
  "PIRIA",
  "PISCANI",
  "PISCU LUNG",
  "PISCU NOU",
  "PISCU SADOVEI",
  "PISCU VECHI",
  "PISCULET",
  "PLENITA",
  "PLESOI",
  "PLOPSOR",
  "PLOPU AMARASTI",
  "PLOSCA",
  "PLOSTINA",
  "PODARI",
  "POIANA FANTINII",
  "POIANA MARE",
  "POIENILE",
  "POMETESTI",
  "POPANZALESTI",
  "POPEASA",
  "POPESTI",
  "POPOVENI",
  "POTMELTU",
  "PRAPOR",
  "PREAJBA",
  "PREAJBA DE JOS",
  "PREAJBA DE PADURE",
  "PREDESTI",
  "PREDESTII MICI",
  "PREJOI",
  "PRUNET",
  "PUTINEI",
  "PUTURI",
  "RACARII DE JOS",
  "RACARII DE SUS",
  "RACHITA DE JOS",
  "RACHITA DE SUS",
  "RACOVITA",
  "RADOMIR",
  "RADOVAN",
  "RASNICU BATRIN",
  "RASNICU OGHIAN",
  "RAST",
  "ROBANESTII DE JOS",
  "ROBANESTII DE SUS",
  "ROJISTE",
  "ROMANESTI",
  "ROSIENI",
  "ROVINE",
  "RUPTURILE",
  "SADOVA",
  "SALCIA",
  "SALCUTA",
  "SALISTE",
  "SAPATA",
  "SARATA",
  "SARBATOAREA",
  "SARSCA",
  "SCAESTI",
  "SCHITU",
  "SEACA DE CAMP",
  "SEACA DE PADURE",
  "SECU",
  "SECUI",
  "SECULESTI",
  "SEGARCEA",
  "SEGLET",
  "SFARCEA",
  "SILISTEA CRUCII",
  "SIMNICU DE JOS",
  "SIMNICU DE SUS",
  "SITOAIA",
  "SMADOVICIOARA DE SECU",
  "SMARDAN",
  "SOCENI",
  "SOPOT",
  "SORENI",
  "SPINENI",
  "STEFANEL",
  "STIUBEI",
  "STOICESTI",
  "SUHARU",
  "SUMANDRA",
  "TALPAS",
  "TAMBURESTI",
  "TANDARA",
  "TARTAL",
  "TATOMIRESTI",
  "TEASC",
  "TEASCU DIN DEAL",
  "TEIU",
  "TENCANAU",
  "TERPEZITA",
  "TESLUI",
  "TIRNAVA",
  "TIU",
  "TOCENI",
  "TUGLUI",
  "TUNARII NOI",
  "TUNARII VECHI",
  "UNGURENI",
  "UNGURENII MICI",
  "UNIREA",
  "URDINITA",
  "URECHESTI",
  "URIENI",
  "URSOAIA",
  "URZICA MARE",
  "URZICUTA",
  "USCACI",
  "VALEA FINTINILOR",
  "VALEA LUI PATRU",
  "VALEA LUNGULUI",
  "VALEA MARE",
  "VALEA MUIERII DE JOS",
  "VALEA STANCIULUI",
  "VARTOP",
  "VARVOR",
  "VARVORU DE JOS",
  "VELA",
  "VELENI",
  "VELESTI",
  "VERBICIOARA",
  "VERBITA",
  "VIISOARA",
  "VIISOARA MOSNENI",
  "VLADIMIR",
  "VOITA",
  "ZANOAGA",
  "ZAVAL",
  "ZLATARI",
  "ZVORSCA",
];
COUNTIES_CITIES["IALOMITA"] = [
  "ADANCATA",
  "ALBESTI",
  "ALEXENI",
  "AMARA",
  "AMARA NOUA",
  "ANDRASESTI",
  "ARMASESTI",
  "AXINTELE",
  "BALACIU",
  "BARBATESCU",
  "BARBULESTI",
  "BARCANESTI",
  "BATALURI",
  "BITINA-PAMANTENI",
  "BITINA-UNGURENI",
  "BORA",
  "BORANESTI",
  "BORDUSANI",
  "BORDUSELU",
  "BOTENI",
  "BRAZII",
  "BROSTENI",
  "BUCSA",
  "BUCU",
  "BUESTI",
  "BULIGA",
  "BUTOIU",
  "CATRUNESTI",
  "CAZANESTI",
  "CEGANI",
  "CHIROIU-PAMANTENI",
  "CHIROIU-SATU NOU",
  "CHIROIU-UNGURENI",
  "CIOCARLIA",
  "CIOCHINA",
  "CIULNITA",
  "COCORA",
  "COLELIA",
  "CONDEESTI",
  "COPUZU",
  "COSAMBESTI",
  "COSERENI",
  "COTORCA",
  "CRASANII DE JOS",
  "CRASANII DE SUS",
  "CRUNTI",
  "DRAGOESTI",
  "DRIDU",
  "DRIDU-SNAGOV",
  "DUMITRESTI",
  "FACAENI",
  "FETESTI",
  "FETESTI-GARA",
  "FIERBINTII DE JOS",
  "FIERBINTII DE SUS",
  "FIERBINTI-TARG",
  "FRATILESTI",
  "FUNDATA",
  "GARBOVI",
  "GHEORGHE DOJA",
  "GHEORGHE LAZAR",
  "GIMBASANI",
  "GIURGENI",
  "GRECII DE JOS",
  "GRINDASI",
  "GRINDU",
  "GRIVITA",
  "GURA IALOMITEI",
  "GURA VAII",
  "HAGIENI",
  "HAGIESTI",
  "HORIA",
  "IAZU",
  "ION GHICA",
  "ION ROATA",
  "IVANESTI",
  "JILAVELE",
  "LACUSTENI",
  "LILIECI",
  "LIVEDEA",
  "LUCIU",
  "MAIA",
  "MALTEZI",
  "MALU",
  "MALU ROSU",
  "MANASIA",
  "MARCULESTI",
  "MARSILIENI",
  "MIHAIL KOGALNICEANU",
  "MILOSESTI",
  "MIRCEA CEL BATRAN",
  "MISLEANU",
  "MOLDOVENI",
  "MOVILA",
  "MOVILEANCA",
  "MOVILITA",
  "MUNTENI-BUZAU",
  "MURGEANCA",
  "NENISORI",
  "NICOLESTI",
  "OGRADA",
  "ORBOESTI",
  "OREZU",
  "PALTINISU",
  "PATRU FRATI",
  "PERIETI",
  "PIERSICA",
  "PLATONESTI",
  "POIANA",
  "PROGRESU",
  "RASI",
  "RASIMNICEA",
  "RETEZATU",
  "REVIGA",
  "ROSIORI",
  "ROVINE",
  "SALCIOARA",
  "SARATENI",
  "SAVENI",
  "SCANTEIA",
  "SFANTU GHEORGHE",
  "SINESTI",
  "SINTESTI",
  "SLATIOARELE",
  "SLOBOZIA",
  "SLOBOZIA NOUA",
  "SMIRNA",
  "STEJARU",
  "STELNICA",
  "SUDITI",
  "TANDAREI",
  "TOVARASIA",
  "TRAIAN",
  "URZICENI",
  "VALEA BISERICII",
  "VALEA CIORII",
  "VALEA MACRISULUI",
  "VLADENI",
  "VLASCA",
];
COUNTIES_CITIES["SUCEAVA"] = [
  "ADANCATA",
  "ANTOCENI",
  "ARBORE",
  "ARGEL",
  "ARGESTRU",
  "ARGHIRA",
  "ARTARI",
  "BADEUTI",
  "BADRAGI",
  "BAHNA ARIN",
  "BAIA",
  "BAINET",
  "BAISESTI",
  "BALACEANA",
  "BALCAUTI",
  "BALINESTI",
  "BANCESTI",
  "BANESTI",
  "BARASTI",
  "BASARABI",
  "BENIA",
  "BERCHISESTI",
  "BERESTI",
  "BILCA",
  "BIVOLARIA",
  "BOBEICA",
  "BODNARENI",
  "BOGATA",
  "BOGDANESTI",
  "BOROAIA",
  "BOSANCI",
  "BOTESTI",
  "BOTOS",
  "BOTOSANA",
  "BOTOSANITA MARE",
  "BOTOSANITA MICA",
  "BOTUS",
  "BOTUSEL",
  "BOURA",
  "BRADATEL",
  "BRAIESTI",
  "BRANISTEA",
  "BRASCA",
  "BREAZA",
  "BREAZA DE SUS",
  "BRODINA",
  "BRODINA DE JOS",
  "BROSTENI",
  "BUCSOAIA",
  "BUDA",
  "BUDENI",
  "BULAI",
  "BUNESTI",
  "BURLA",
  "BURSUCENI",
  "CACICA",
  "CAJVANA",
  "CALAFINDESTI",
  "CALINESTI",
  "CALINESTI-VASILACHE",
  "CALUGARENI",
  "CAMARZANI",
  "CAMPULUNG MOLDOVENESC",
  "CAPU CAMPULUI",
  "CAPU CODRULUI",
  "CARLIBABA",
  "CARLIBABA NOUA",
  "CASOI",
  "CATRINARI",
  "CHILISENI",
  "CHIRIL",
  "CIOCANESTI",
  "CIOMARTAN",
  "CIPRIAN PORUMBESCU",
  "CIUMARNA",
  "CIUMULESTI",
  "CLIMAUTI",
  "CLIT",
  "CODRU",
  "COJOCARENI",
  "COJOCI",
  "COLACU",
  "COMANESTI",
  "CORLATA",
  "CORNI",
  "CORNU LUNCII",
  "COROCAIESTI",
  "CORPACI",
  "COSNA",
  "COSTANA",
  "COSTILEVA",
  "COSTISA",
  "COTARGASI",
  "COTU BAII",
  "COTU DOBEI",
  "COVERCA",
  "COZANESTI",
  "CRUCEA",
  "CUMPARATURA",
  "CUNUNSCHI",
  "DANILA",
  "DARMANESTI",
  "DARMOXA",
  "DEALU",
  "DEALU FLORENI",
  "DEIA",
  "DELENI",
  "DELNITA",
  "DELUT",
  "DEMACUSA",
  "DOLHASCA",
  "DOLHESTII MICI",
  "DOLHESTII-MARI",
  "DORNA CANDRENILOR",
  "DORNA-ARINI",
  "DORNESTI",
  "DORNISOARA",
  "DOROTEIA",
  "DRAGOIASA",
  "DRAGOIESTI",
  "DRAGOMIRNA",
  "DRAGOSA",
  "DRAGUSENI",
  "DUBIUSCA",
  "DUMBRAVA",
  "DUMBRAVENI",
  "DUMBRAVITA",
  "EHRESTE",
  "FALCAU",
  "FALTICENI",
  "FANTANA MARE",
  "FANTANELE",
  "FETESTI",
  "FLORINTA",
  "FORASTI",
  "FRASIN",
  "FRATAUTII NOI",
  "FRATAUTII VECHI",
  "FRUMOASA",
  "FRUMOSU",
  "FUNDU MOLDOVEI",
  "GAINESTI",
  "GALANESTI",
  "GARA",
  "GARA LEU",
  "GEMENEA",
  "GHEORGHITENI",
  "GIULESTI",
  "GIURGESTI",
  "GLODU",
  "GRAMESTI",
  "GRANICESTI",
  "GRIGORESTI",
  "GROAPA VLADICHII",
  "GROPENI",
  "GULIA",
  "GURA HAITII",
  "GURA HUMORULUI",
  "GURA PUTNEI",
  "GURA SOLCII",
  "HALEASA",
  "HANCEA",
  "HANTESTI",
  "HARTOP",
  "HERLA",
  "HOLDA",
  "HOLDITA",
  "HORODNIC DE JOS",
  "HORODNIC DE SUS",
  "HORODNICENI",
  "HREATCA",
  "HUMORENI",
  "HURJUIENI",
  "HUSI",
  "IACOBENI",
  "IACOBESTI",
  "IASLOVAT",
  "IAZ",
  "IEDU",
  "IESLE",
  "ILISESTI",
  "IONEASA",
  "IPOTESTI",
  "IZVOARELE SUCEVEI",
  "JACOTA",
  "JAHALIA",
  "LAMASENI",
  "LEUCUSESTI",
  "LIPOVENI",
  "LISAURA",
  "LITENI",
  "LUCACESTI",
  "LUNCUSOARA",
  "LUNGENI",
  "LUPCINA",
  "MAGURA",
  "MAIDAN",
  "MALINI",
  "MANASTIOARA",
  "MANASTIREA HUMORULUI",
  "MANEUTI",
  "MANOLEA",
  "MARGINEA",
  "MARITEI",
  "MARITEIA MICA",
  "MAZANAESTI",
  "MERENI",
  "MERESTI",
  "MESTECANIS",
  "MESTECENI",
  "MIHAIESTI",
  "MIHOVENI",
  "MILISAUTI",
  "MIRONU",
  "MITOCASI",
  "MITOCU DRAGOMIRNEI",
  "MOARA CARP",
  "MOARA NICA",
  "MOISA",
  "MOLDOVA-SULITA",
  "MOLDOVITA",
  "MOLID",
  "MOVILENI",
  "MUSENITA",
  "NEAGRA",
  "NEAGRA SARULUI",
  "NEGOSTINA",
  "NEGRILEASA",
  "NICANI",
  "NIGOTESTI",
  "NISIPITU",
  "NOROCU",
  "OBCINA",
  "ONICENI",
  "ORTOAIA",
  "OSOI",
  "OSTRA",
  "PADURENI",
  "PAISENI",
  "PALTIN",
  "PALTINIS",
  "PALTINOASA",
  "PALTINU",
  "PANACI",
  "PARAIE",
  "PARAU NEGREI",
  "PARHAUTI",
  "PARTESTII DE JOS",
  "PARTESTII DE SUS",
  "PATRAUTI",
  "PETIA",
  "PIETROASA",
  "PILUGANI",
  "PLAI",
  "PLAIU SARULUI",
  "PLAVALARI",
  "PLESA",
  "PLESESTI",
  "PLOPENI",
  "PLUTONITA",
  "POCOLENI",
  "PODENI",
  "PODU COSNEI",
  "POIANA",
  "POIANA MARULUI",
  "POIANA MICULUI",
  "POIANA NEGRII",
  "POIANA STAMPEI",
  "POIENARI",
  "POIENI-SOLCA",
  "POIENI-SUCEAVA",
  "POJORATA",
  "PRALENI",
  "PRAXIA",
  "PRELIPCA",
  "PREUTESTI",
  "PRISACA DORNEI",
  "PROBOTA",
  "PUTNA",
  "RACOVA",
  "RADASENI",
  "RADAUTI",
  "RASCA",
  "RAUTENI",
  "REUSENI",
  "ROMANESTI (Cosna)",
  "ROMANESTI (Granicesti)",
  "ROSCANI",
  "ROSIORI",
  "ROSU",
  "ROTOPANESTI",
  "ROTUNDA",
  "RUDESTI",
  "RUNCU",
  "RUSCA",
  "RUSI",
  "RUSII-MANASTIOARA",
  "SACUTA",
  "SADAU",
  "SADOVA",
  "SALAGENI",
  "SALCEA",
  "SARGHIESTI",
  "SARISOR",
  "SARISORU MARE",
  "SARU BUCOVINEI",
  "SARUL DORNEI",
  "SASCA MARE",
  "SASCA MICA",
  "SASCA NOUA",
  "SATU MARE",
  "SCHEIA",
  "SECURICENI",
  "SERBANESTI",
  "SERBAUTI",
  "SES",
  "SESURI",
  "SFANTU ILIE",
  "SILISTEA",
  "SILISTEA NOUA",
  "SIMINICEA",
  "SINCA",
  "SIRET",
  "SLATINA",
  "SLATIOARA",
  "SLOBOZIA",
  "SLOBOZIA SUCEVEI",
  "SMIDA UNGURENILOR",
  "SOLCA",
  "SOLDANESTI",
  "SOLONET",
  "SOLONETU NOU",
  "SPATARESTI",
  "STAMATE",
  "STANCA",
  "STANCUTA",
  "STIRBAT",
  "STRAJA",
  "STRAMTURA",
  "STROIESTI",
  "STULPICANI",
  "SUCEAVA",
  "SUCEVITA",
  "SUNATORI",
  "TARNA MARE",
  "TARNICIOARA",
  "TATARU",
  "TAUTESTI",
  "TESNA",
  "TIBAU",
  "TIBENI",
  "TISAUTI",
  "TODIRENI",
  "TODIRESTI",
  "TOLESTI",
  "TREI MOVILE",
  "UDESTI",
  "UIDESTI",
  "ULMA",
  "UNCESTI",
  "VADU MOLDOVEI",
  "VADU NEGRILESEI",
  "VALCELELE",
  "VALEA BANCULUI",
  "VALEA BOUREI",
  "VALEA GLODULUI",
  "VALEA MOLDOVEI",
  "VALEA POIENEI",
  "VALEA PUTNEI",
  "VALEA STANEI",
  "VALENI-STANISOARA",
  "VAMA",
  "VARATEC",
  "VARFU DEALULUI",
  "VARVATA",
  "VASCAUTI",
  "VATRA DORNEI",
  "VATRA MOLDOVITEI",
  "VERBIA",
  "VERCICANI",
  "VERESTI",
  "VICOVU DE JOS",
  "VICOVU DE SUS",
  "VICSANI",
  "VOIEVODEASA",
  "VOITINEL",
  "VOLOVAT",
  "VORNICENII MARI",
  "VORNICENII MICI",
  "VORONET",
  "VULTURESTI",
  "ZAHARESTI",
  "ZALOMESTRA",
  "ZAMOSTEA",
  "ZVORISTEA",
];
COUNTIES_CITIES["BOTOSANI"] = [
  "ADASENI",
  "AGAFTON",
  "ALBA",
  "ALBESTI",
  "ARBOREA",
  "AUREL VLAICU",
  "AVRAM IANCU",
  "AVRAMENI",
  "BABICENI",
  "BADARAI",
  "BADIUTI",
  "BAICENI",
  "BAISA",
  "BAJURA",
  "BALINTI",
  "BALTA ARSA",
  "BALUSENI",
  "BALUSENII NOI",
  "BARANCA",
  "BARSANESTI",
  "BASEU",
  "BATRANESTI",
  "BELCEA",
  "BERZA",
  "BIVOLARI",
  "BLANDESTI",
  "BOBULESTI",
  "BODEASA",
  "BOGDANESTI",
  "BOHOGHINA",
  "BOLD",
  "BOROLEA",
  "BORZESTI",
  "BOSCOTENI",
  "BOTOSANI",
  "BOZIENI",
  "BRAESTI",
  "BRATENI",
  "BREHUIESTI",
  "BROSCAUTI",
  "BROSTENI",
  "BUCECEA",
  "BUDA",
  "BUHACENI",
  "BUIMACENI",
  "BURLA",
  "BURLESTI",
  "BUZENI",
  "CALARASI",
  "CALINESTI",
  "CALUGARENI",
  "CALUGARENII NOI",
  "CAMPENI",
  "CANDESTI",
  "CARAIMAN",
  "CARASA",
  "CATAMARESTI",
  "CATAMARESTI-DEAL",
  "CERBU",
  "CERCHEJENI",
  "CERNESTI",
  "CERVICESTI",
  "CERVICESTI-DEAL",
  "CHELIS",
  "CHISCARENI",
  "CHITOVENI",
  "CINGHINIIA",
  "CIRITEI",
  "CISMANESTI",
  "CISMEA",
  "CODRENI",
  "CONCESTI",
  "COPALAU",
  "CORDARENI",
  "CORJAUTI",
  "CORLATENI",
  "CORNI",
  "COSTESTI",
  "COSTINESTI",
  "COSTIUGENI",
  "COSULA",
  "COSULENI",
  "COTARGACI",
  "COTU",
  "COTU MICULINTI",
  "COTUSCA",
  "CRASNALEUCA",
  "CRISTESTI",
  "CRISTINESTI",
  "CUCORANI",
  "CUCUTENI",
  "CURTESTI",
  "CUZA VODA",
  "CUZLAU",
  "DACIA",
  "DAMIDENI",
  "DAMILENI",
  "DANGENI",
  "DARABANI",
  "DAVIDOAIA",
  "DEALU CRUCII",
  "DEALU MARE",
  "DERSCA",
  "DIMACHENI",
  "DIMITRIE CANTEMIR",
  "DOBARCENI",
  "DOBRINAUTI-HAPAI",
  "DOINA",
  "DOLINA",
  "DOROBANTI",
  "DOROHOI",
  "DRACSANI",
  "DRAGALINA",
  "DRAGUSENI",
  "DRAXINI",
  "DRISLEA",
  "DUMBRAVITA",
  "DUMENI",
  "DURNESTI",
  "ESANCA",
  "FLAMANZI",
  "FLONDORA",
  "FLORESTI",
  "FRUMUSICA",
  "FUNDU HERTII",
  "GALBENI",
  "GARBENI",
  "GARBESTI",
  "GEORGE COSBUC",
  "GEORGE ENESCU",
  "GHIRENI",
  "GORBANESTI",
  "GOROVEI",
  "GRIVITA",
  "GURANDA",
  "HANESTI",
  "HAVARNA",
  "HILISEU-CLOSCA",
  "HILISEU-CRISAN",
  "HILISEU-HORIA",
  "HLIPICENI",
  "HORIA",
  "HORLACENI",
  "HORODISTEA",
  "HRISCANI",
  "HUDESTI",
  "HUDUM",
  "HULUB",
  "HULUBESTI",
  "HUTANI",
  "IACOBENI",
  "IBANEASA",
  "IBANESTI",
  "ICHIMENI",
  "ICUSENI",
  "IEZER",
  "ILISENI",
  "IONASENI",
  "IORGA",
  "IPOTESTI",
  "IURESTI",
  "IZVOARE",
  "JIJIA",
  "JOLDESTI",
  "LEHNESTI",
  "LEORDA",
  "LIBERTATEA",
  "LISMANITA",
  "LISNA",
  "LIVADA",
  "LIVENI",
  "LOTURI",
  "LOTURI ENESCU",
  "LOZNA",
  "LUNCA",
  "LUPARIA",
  "MAGHERA",
  "MANASTIREA DOAMNEI",
  "MANASTIRENI",
  "MANDRESTI",
  "MANOLEASA",
  "MANOLEASA-PRUT",
  "MANOLESTI",
  "MASCATENI",
  "MATEIENI",
  "MESTEACAN",
  "MIHAI EMINESCU",
  "MIHAI VITEAZU",
  "MIHAIL KOGALNICEANU",
  "MIHAILENI",
  "MIHALASENI",
  "MILEANCA",
  "MILETIN",
  "MIORCANI",
  "MIRON COSTIN",
  "MITOC",
  "MLENAUTI",
  "MOARA JORII",
  "MOVILA RUPTA",
  "MOVILENI",
  "MURGUTA",
  "NASTASE",
  "NEGRENI",
  "NEGRESTI",
  "NICHITENI",
  "NICOLAE BALCESCU",
  "NICSENI",
  "NICULCEA",
  "ONEAGA",
  "ORASENI-DEAL",
  "ORASENI-VALE",
  "OROFTIANA",
  "PADURENI",
  "PALTINIS",
  "PANAITOAIA",
  "PARAU NEGRU",
  "PASATENI",
  "PAUN",
  "PETRICANI",
  "PLESANI",
  "PLEVNA",
  "PLOPENII MARI",
  "PLOPENII MICI",
  "PODENI",
  "PODRIGA",
  "POGORASTI",
  "POIANA",
  "POMARLA",
  "POPENI",
  "POPOAIA",
  "PRAJENI",
  "PRISACANI",
  "PROGRESUL",
  "PUSTOAIA",
  "PUTURENI",
  "RACHITI",
  "RACOVAT",
  "RADAUTI-PRUT",
  "RADENI",
  "RANGHILESTI",
  "RANGHILESTI-DEAL",
  "RASCA",
  "RAUSENI",
  "RECIA-VERBIA",
  "REDIU",
  "RIPICENI",
  "RIPICENII VECHI",
  "ROGOJESTI",
  "ROMA",
  "ROMANESTI",
  "ROMANESTI-VALE",
  "ROSIORI",
  "SADOVENI",
  "SANTA MARE",
  "SARAFINESTI",
  "SARATA",
  "SARATA-BASARAB",
  "SARATA-DRAGUSENI",
  "SARBI",
  "SAT NOU",
  "SAUCENITA",
  "SAVENI",
  "SCHIT-ORASENI",
  "SCUTARI",
  "SELISTEA",
  "SENDRENI",
  "SENDRICENI",
  "SERPENITA",
  "SILISCANI",
  "SILISTEA",
  "SLOBOZIA",
  "SLOBOZIA HANESTI",
  "SLOBOZIA SILISCANI",
  "SMARDAN",
  "SOCRUJENI",
  "SOLDANESTI",
  "SOROCENI",
  "STANCA",
  "STANCESTI",
  "STAUCENI",
  "STEFANESTI",
  "STEFANESTI-SAT",
  "STIUBIENI",
  "STOLNICENI",
  "STORESTI",
  "STRAHOTIN",
  "STRATENI",
  "STROIESTI",
  "SUHARAU",
  "SULITA",
  "SUPITCA",
  "TALPA",
  "TATARASENI",
  "TAUTESTI",
  "TIMUS",
  "TOCILENI",
  "TODIRENI",
  "TRUSESTI",
  "TUDOR VLADIMIRESCU",
  "TUDORA",
  "UNGURENI",
  "UNGUROAIA",
  "UNTENI",
  "VACULESTI",
  "VALEA GRAJDULUI",
  "VANATORI",
  "VARFU CAMPULUI",
  "VATRA",
  "VICOLENI",
  "VICTORIA",
  "VIFORENI",
  "VIISOARA",
  "VIISOARA MICA",
  "VILCELELE",
  "VITCANI",
  "VLADENI",
  "VLADENI-DEAL",
  "VLASINESTI",
  "VORNICENI",
  "VORONA",
  "VORONA MARE",
  "VORONA-TEODORU",
  "VULTURENI",
  "ZAHORENI",
  "ZAICESTI",
  "ZLATUNOAIA",
  "ZOITANI",
];
COUNTIES_CITIES["VRANCEA"] = [
  "ADJUD",
  "ADJUDU VECHI",
  "ALEXANDRU VLAHUTA",
  "ANDREIASU DE JOS",
  "ANDREIASU DE SUS",
  "ANGHELESTI",
  "ARGEA",
  "ARMENI",
  "ARSITA",
  "ARVA",
  "BAHNELE",
  "BALANESTI",
  "BALESTI",
  "BALTA RATEI",
  "BARSESTI",
  "BATCARI",
  "BATINESTI",
  "BECIU",
  "BELCIUGELE",
  "BICESTII DE JOS",
  "BICESTII DE SUS",
  "BICHESTI",
  "BILIESTI",
  "BIZIGHESTI",
  "BLIDARI",
  "BODESTI",
  "BOGHESTI",
  "BOGHESTII DE SUS",
  "BOGZA",
  "BOLOTESTI",
  "BONTESTI",
  "BORDEASCA NOUA",
  "BORDEASCA VECHE",
  "BORDESTI",
  "BORDESTII DE JOS",
  "BOTARLAU",
  "BRADACESTI",
  "BRADETU",
  "BROSTENI",
  "BUDA",
  "BUDESTI",
  "BURCA",
  "BURCIOAIA",
  "CAIATA",
  "CALIENII NOI",
  "CALIENII VECHI",
  "CALIMANEASA",
  "CALIMANESTI",
  "CAMPINEANCA",
  "CAMPURI",
  "CANDESTI",
  "CARLIGELE",
  "CARSOCHESTI-CORABITA",
  "CATAUTI",
  "CEARDAC",
  "CERBU",
  "CHIOJDENI",
  "CHIRICANI",
  "CHITCANI",
  "CIOLANESTI",
  "CIORANI",
  "CIORASTI",
  "CIUSLEA",
  "CLIPICESTI",
  "COCOSARI",
  "CODRESTI",
  "COLACU",
  "COPACESTI",
  "CORBITA",
  "CORNETU",
  "COROTENI",
  "COSTISA",
  "COSTISA DE SUS",
  "COTESTI",
  "COVRAG",
  "COZA",
  "CRUCEA DE JOS",
  "CRUCEA DE SUS",
  "DALHAUTI",
  "DEALU CUCULUI",
  "DEALU SARII",
  "DEALUL LUNG",
  "DIOCHETI-REDIU",
  "DOAGA",
  "DOMNESTI-SAT",
  "DOMNESTI-TARG",
  "DRAGOSLOVENI",
  "DUMBRAVENI",
  "DUMITRESTI",
  "DUMITRESTII DE SUS",
  "DUMITRESTII-FATA",
  "DUPA MAGURA",
  "FAGETU",
  "FARAOANELE",
  "FARCAS",
  "FAUREI",
  "FELDIOARA",
  "FETESTI",
  "FETIG",
  "FITIONESTI",
  "FOCSANI",
  "FRECATEI",
  "GAGESTI",
  "GALBENI",
  "GALOIESTI",
  "GAROAFA",
  "GHEBARI",
  "GHIMICESTI",
  "GOGOIU",
  "GOLESTI",
  "GOLESTII DE SUS",
  "GOLOGANU",
  "GRESU",
  "GROAPA TUFEI",
  "GUGESTI",
  "GURA CALITEI",
  "GURA VAII",
  "HANGULESTI",
  "HARET",
  "HAULISCA",
  "HOLBANESTI",
  "HOMOCEA",
  "HOTARU",
  "IGESTI",
  "IRESTI",
  "IUGANI",
  "IVANCESTI",
  "IZVOARELE",
  "JARISTEA",
  "JGHEABURI",
  "JILISTE",
  "JITIA",
  "JITIA DE JOS",
  "JORASTI",
  "LACU LUI BABAN",
  "LAMOTESTI",
  "LARGASENI",
  "LASTUNI",
  "LEPSA",
  "LESPEZI",
  "LIESTI",
  "LIVADA",
  "LIVEZILE",
  "LOJNITA",
  "LUNCILE",
  "LUPOAIA",
  "MAGURA",
  "MAHRIU",
  "MAICANESTI",
  "MALURI",
  "MANASTIOARA",
  "MANDRESTI-MOLDOVA",
  "MANDRESTI-MUNTENI",
  "MARACINI",
  "MARASESTI",
  "MARASTI",
  "MARTINESTI",
  "MATACINA",
  "MERA",
  "MESTEACANU",
  "MIHALCENI",
  "MILCOVEL",
  "MILCOVUL",
  "MIRCESTII NOI",
  "MIRCESTII VECHI",
  "MODRUZENI",
  "MORARESTI",
  "MOTNAU",
  "MOVILITA",
  "MUNCEI",
  "MUNCELU",
  "NANESTI",
  "NARUJA",
  "NECULELE",
  "NEGRILESTI",
  "NEICU",
  "NEREJU",
  "NEREJU MIC",
  "NISTORESTI",
  "OBREJITA",
  "OCHESESTI",
  "ODOBASCA",
  "ODOBESTI",
  "OLARENI",
  "OLESESTI",
  "OLTENI",
  "OREAVU",
  "PADURENI",
  "PALTIN",
  "PANCIU",
  "PATRASCANI",
  "PAULESTI",
  "PAUNESTI",
  "PAVALARI",
  "PETREANU",
  "PETRESTI",
  "PIETROASA",
  "PISCU RADULUI",
  "PISCU REGHIULUI",
  "PITULUSA",
  "PLACINTENI",
  "PLESESTI",
  "PLOPU",
  "PLOSCUTENI",
  "PLOSTINA",
  "PODU LACULUI",
  "PODU NARUJEI",
  "PODU SCHIOPULUI",
  "PODU STOICA",
  "PODURI",
  "PODURILE",
  "POENILE",
  "POIANA",
  "POIANA CRISTEI",
  "POIANA STOICHII",
  "POIENITA",
  "POPESTI",
  "PRAHUDA",
  "PRECISTANU",
  "PRISACA",
  "PRISECANI",
  "PUFESTI",
  "PUTNA",
  "RACHITASU",
  "RACHITOSU",
  "RACOASA",
  "RADACINESTI",
  "RADULESTI",
  "RAIUTI",
  "RAMNICEANCA",
  "RAMNICENI",
  "RASCA",
  "RASTOACA",
  "REBEGARI",
  "REGHIU",
  "REPEDEA",
  "ROMANESTI",
  "ROSCARI",
  "ROSIOARA",
  "ROTILESTII MARI",
  "ROTILESTII MICI",
  "RUCARENI",
  "RUGET",
  "RUGINESTI",
  "SAHASTRU",
  "SALCIA NOUA",
  "SALCIA VECHE",
  "SARBI",
  "SATU NOU",
  "SCAFARI",
  "SCANTEIA",
  "SECIU",
  "SERBANESTI",
  "SERBESTI",
  "SIHLEA",
  "SIMINOC",
  "SINDRILARI",
  "SIRETU",
  "SISCANI",
  "SLIMNIC",
  "SLOBOZIA BOTESTI",
  "SLOBOZIA BRADULUI",
  "SLOBOZIA CIORASTI",
  "SOTARCARI",
  "SPATAREASA",
  "SPINESTI",
  "SPULBER",
  "STRAJESCU",
  "STRAOANE",
  "STUPINA",
  "SURAIA",
  "TABUCESTI",
  "TAMBOESTI",
  "TANASARI",
  "TANASOAIA",
  "TARATU",
  "TATARANU",
  "TATARU",
  "TEPA",
  "TERCHESTI",
  "TICHIRIS",
  "TIFESTI",
  "TINOASA",
  "TIPAU",
  "TISITA",
  "TITILA",
  "TOJANII DE JOS",
  "TOJANII DE SUS",
  "TOPESTI",
  "TRESTIA",
  "TRESTIENI",
  "TROTUSANU",
  "TULBUREA",
  "TULNICI",
  "TUTU",
  "UNGURENI",
  "UNIREA",
  "URECHESTI",
  "URSOAIA",
  "VADU ROSCA",
  "VAJAITOAREA",
  "VALCANI",
  "VALCELE",
  "VALCELELE",
  "VALEA BECIULUI",
  "VALEA COTESTI",
  "VALEA MICA",
  "VALEA MILCOVULUI",
  "VALEA NEAGRA",
  "VALEA SARII",
  "VALENI",
  "VANATORI",
  "VARNITA",
  "VARSATURA",
  "VARTESCOIU",
  "VERDEA",
  "VETRESTI-HERASTRAU",
  "VIDRA",
  "VIISOARA",
  "VINTILEASCA",
  "VITANESTI",
  "VITANESTII DE SUB MAGURA",
  "VIZANTEA MANASTIREASCA",
  "VIZANTEA RAZASEASCA",
  "VLADNICU DE JOS",
  "VLADNICU DE SUS",
  "VOETIN",
  "VOLOSCANI",
  "VRANCIOAIA",
  "VULCANEASA",
  "VULTURU",
];
COUNTIES_CITIES["NEAMT"] = [
  "ADJUDENI",
  "AGAPIA",
  "AGARCIA",
  "Alexandru cel Bun",
  "ALMAS",
  "ARAMESTI",
  "ARAMOAIA",
  "ARDELUTA",
  "AVERESTI",
  "BAHNA",
  "BAHNA MARE",
  "BAHNISOARA",
  "BALANESTI",
  "BALTATESTI",
  "BALUSESTI",
  "BANEASA",
  "BARATCA",
  "BARCANESTI",
  "BARGAUANI",
  "BARJOVENI",
  "BARNADU",
  "BARTICESTI",
  "BASTA",
  "BATRANESTI",
  "BETESTI",
  "BICAZ",
  "BICAZ-CHEI",
  "BICAZU ARDELEAN",
  "BIRA",
  "BISERICANI",
  "BISTRICIOARA",
  "BISTRITA",
  "BLEBEA",
  "BOBOIESTI",
  "BODESTI",
  "BODESTII DE JOS",
  "BOGHICEA",
  "BOGZESTI",
  "BOISTEA",
  "BORCA",
  "BORDEA",
  "BORLESTI",
  "BORNIS",
  "BORSENI",
  "BOTESTI",
  "BOZIENI",
  "BOZIENII DE SUS",
  "BRADU",
  "BRASAUTI",
  "BRATES",
  "BREAZA",
  "BRITCANI",
  "BROSTENI",
  "BRUSTURI",
  "BUCIUM",
  "BUDESTI",
  "BUHALNITA",
  "BUHONCA",
  "BUNGHI",
  "BURUIENESTI",
  "BUSMEI",
  "BUTNARESTI",
  "CACIULESTI",
  "CALUGARENI",
  "CANDESTI",
  "CAPSA",
  "CARLIG",
  "CARLIGI",
  "CASARIA",
  "CAUSENI",
  "CAZACI",
  "CEAHLAU",
  "CERTIENI",
  "CHICEREA",
  "CHILIA",
  "CHILII",
  "CHINTINICI",
  "CHIRITENI",
  "CIORNEI",
  "CIRITEI",
  "CIUREA",
  "CLIMESTI",
  "CORDUN",
  "CORHANA",
  "CORNI",
  "COSTISA",
  "COTU VAMES",
  "CRACAOANI",
  "CRACAUL NEGRU",
  "CRAIESTI",
  "CUCI",
  "CUEJDIU",
  "CURECHISTEA",
  "CUT",
  "DAMUC",
  "DANESTI",
  "DARLOAIA",
  "DAVID",
  "DAVIDENI",
  "DELENI",
  "DOAMNA",
  "DOBRENI",
  "DOCHIA",
  "DODENI",
  "DOINA",
  "DOLHESTI",
  "DOLJESTI",
  "DORNESTI",
  "DRAGANESTI",
  "DRAGOMIRESTI",
  "DRAGOVA",
  "DREPTU",
  "DULCESTI",
  "DUMBRAVA",
  "DUMBRAVA ROSIE",
  "DUMBRAVA-DEAL",
  "DUSESTI",
  "FARCASA",
  "FAUREI",
  "FILIOARA",
  "FRUMOSU",
  "FRUNZENI",
  "GADINTI",
  "GALU",
  "GARCINA",
  "GHELAIESTI",
  "GHERAESTI",
  "GHERAESTII NOI",
  "GHERMAN",
  "GHIDION",
  "GHIGOIESTI",
  "GHINDAOANI",
  "GIROV",
  "GIULESTI",
  "GIURGENI",
  "GORUN",
  "GOSMANI",
  "GRINTIES",
  "GROSI",
  "GROZAVESTI",
  "GRUMAZESTI",
  "GURA VAII",
  "HAMZOAIA",
  "HANGU",
  "HANUL ANCUTEI",
  "HARTESTI",
  "HARTOP",
  "HLAPESTI",
  "HOCIUNGI",
  "HOISESTI",
  "HOLM",
  "HOMICENI",
  "HORIA",
  "HUISUREZ",
  "HUMULESTI",
  "HUMULESTII NOI",
  "ICUSESTI",
  "INGARESTI",
  "ION CREANGA",
  "ITRINESTI",
  "IUCSA",
  "IVANES",
  "IZVOARE",
  "IZVORU",
  "IZVORU MUNTELUI",
  "IZVORUL ALB",
  "LEGHIN",
  "LILIAC",
  "LINSESTI",
  "LUMINIS",
  "LUNCA MOLDOVEI",
  "LUTCA",
  "MADEI",
  "MAGAZIA",
  "MANASTIREA NEAMT",
  "MANOAIA",
  "MARGINENI",
  "MARMURENI",
  "MASTACAN",
  "MESTEACAN",
  "MICSUNESTI",
  "MIRON COSTIN",
  "MITOCU BALAN",
  "MOLDOVENI",
  "MORENI",
  "MUNCELU",
  "MUNCELU DE JOS",
  "MUNTENI",
  "NEAGRA",
  "NECHIT",
  "NEGRESTI",
  "NEGRITESTI",
  "NEGULESTI",
  "NEMTISOR",
  "NETEZI",
  "NISIPORESTI",
  "NISTRIA",
  "OANTU",
  "OGLINZI",
  "ONICENI",
  "ORTASTI",
  "OSLOBENI",
  "PADURENI",
  "PANCESTI",
  "PANGARACIOR",
  "PANGARATI",
  "PARAUL CARJEI",
  "PARAUL FAGULUI",
  "PARAUL MARE",
  "PARAUL PANTEI",
  "PASTRAVENI",
  "PATALIGENI",
  "PATRICHENI",
  "PETRICANI",
  "PETRU VODA",
  "PIATRA NEAMT",
  "PIATRA SOIMULUI",
  "PIETROSU",
  "PILDESTI",
  "PIPIRIG",
  "PLAIESU",
  "PLUGARI",
  "PLUTON",
  "PODOLENI",
  "POIANA",
  "POIANA CRACAOANI",
  "POIANA HUMEI",
  "POIANA LARGULUI",
  "POIANA TEIULUI",
  "POIENARI",
  "POIENI",
  "POIENILE OANCEI",
  "POLOBOC",
  "POPESTI",
  "POTOCI",
  "PRAJESTI",
  "PRELUCA",
  "PREUTESTI",
  "PUSTIETA",
  "RADENI",
  "RASCA",
  "RAUCESTI",
  "RAZBOIENI",
  "RAZBOIENII DE JOS",
  "RECEA",
  "REDIU",
  "ROCNA",
  "ROMAN",
  "ROMANI",
  "ROSENI",
  "ROSIORI",
  "ROTUNDA",
  "ROZNOV",
  "RUGINESTI",
  "RUGINOASA",
  "RUSENI",
  "SABAOANI",
  "SABASA",
  "SACALENI",
  "SACALUSESTI",
  "SAGNA",
  "SARATA",
  "SAVESTI",
  "SAVINESTI",
  "SCARICICA",
  "SCHITU TARCAU",
  "SECU",
  "SECUIENI",
  "SECUIENII NOI",
  "SILISTEA",
  "SIMIONESTI",
  "SLOBOZIA",
  "SOCEA",
  "SOCI",
  "SOIMARESTI",
  "SOLCA",
  "SOVOAIA",
  "SPIESTI",
  "SPIRIDONESTI",
  "STANCA",
  "STANITA",
  "STEFAN CEL MARE",
  "STEJARU",
  "STRAJA",
  "TABARA",
  "TALPA",
  "TALPALAI",
  "TAMASENI",
  "TARCAU",
  "TARDENII MICI",
  "TARGU NEAMT",
  "TARPESTI",
  "TARZIA",
  "TASCA",
  "TAZLAU",
  "TELEC",
  "TETCANI",
  "TIBUCANI",
  "TIBUCANII DE JOS",
  "TICOS",
  "TICOS-FLOAREA",
  "TIMISESTI",
  "TODIRENI",
  "TOLICI",
  "TOPOLICENI",
  "TOPOLITA",
  "TOTOIESTI",
  "TRAIAN",
  "TREI FANTANI",
  "TRIFESTI",
  "TUPILATI",
  "TURTURESTI",
  "TUTCANII DIN DEAL",
  "TUTCANII DIN VALE",
  "UNCESTI",
  "UNGHENI",
  "UNGHI",
  "URECHENI",
  "VAD",
  "VADURELE",
  "VADURI",
  "VALEA ALBA",
  "VALEA ARINI",
  "VALEA ENEI",
  "VALEA MARE",
  "VALEA SEACA",
  "VALEA URSULUI",
  "VALENI",
  "VANATORI-NEAMT",
  "VARATEC",
  "VEJA",
  "VERSESTI",
  "VLADICENI",
  "VLADNICELE",
  "VULPASESTI",
  "ZANESTI",
  "ZVORANESTI",
];
COUNTIES_CITIES["ARGES"] = [
  "ADUNATI",
  "AFRIMESTI",
  "ALBESTI",
  "ALBESTII PAMANTENI",
  "ALBESTII UNGURENI",
  "ALBOTA",
  "ALBOTELE",
  "ALUNIS",
  "ALUNISU",
  "ANGHINESTI",
  "ANINOASA",
  "AREFU",
  "ARGESANI",
  "ARGESELU",
  "BABANA",
  "BABAROAGA",
  "BACESTI",
  "BADENI",
  "BADESTI",
  "BADICEA",
  "BADILA",
  "BADISLAVA",
  "BADULESTI",
  "BAICULESTI",
  "BAILA",
  "BAJANESTI",
  "BAJESTI",
  "BALABANI",
  "BALILESTI",
  "BALOTEASCA",
  "BALTATA",
  "BALTENI",
  "BANARESTI",
  "BANICESTI",
  "BANTAU",
  "BARANESTI",
  "BARASTI",
  "BARBALANI",
  "BARBALATESTI",
  "BARBATESTI",
  "BARLA",
  "BARLOGU",
  "BARLOI",
  "BARSESTII DE JOS",
  "BARSESTII DE SUS",
  "BARZESTI",
  "BASCOV",
  "BASCOVELE",
  "BATRANI",
  "BECULESTI",
  "BELETI",
  "BEREVOESTI",
  "BERINDESTI",
  "BILCESTI",
  "BLAJU",
  "BLEJANI",
  "BOGATI",
  "BOLCULESTI",
  "BOLOVANESTI",
  "BORDEIENI",
  "BORLESTI",
  "BOROBANESTI",
  "BOROVINESTI",
  "BOTARCANI",
  "BOTENI",
  "BOTESTI",
  "BRABETI",
  "BRADETU",
  "BRADU",
  "BRADULET",
  "BRAILENI",
  "BRANISTEA",
  "BRANZARI",
  "BRATEASCA",
  "BRATESTI",
  "BRATIA",
  "BROSTENI",
  "BUCOV",
  "BUCSENESTI",
  "BUCSENESTI-LOTASI",
  "BUDEASA MARE",
  "BUDEASA MICA",
  "BUDISTENI",
  "BUGHEA DE JOS",
  "BUGHEA DE SUS",
  "BUJOI",
  "BUJORENI",
  "BUMBUENI",
  "BUNESTI",
  "BURDEA",
  "BURDESTI",
  "BURETESTI",
  "BURLUSI",
  "BURNESTI",
  "BUTA",
  "BUZOESTI",
  "CALDARARU",
  "CALINESTI",
  "CALOTESTI",
  "CAMPULUNG",
  "CANDESTI",
  "CAPATANENII PAMANTENI",
  "CAPATANENII UNGURENI",
  "CAPU PISCULUI",
  "CARCESTI",
  "CARCIUMARESTI",
  "CARPENIS",
  "CARSTIENI",
  "CATANE",
  "CATANELE",
  "CATEASCA",
  "CATUNASI",
  "CEAURESTI",
  "CEAUSESTI",
  "CEPARI",
  "CEPARII PAMANTENI",
  "CEPARII UNGURENI",
  "CERBU",
  "CERBURENI",
  "CERSANI",
  "CETATENI",
  "CHILII",
  "CHIRITESTI",
  "CHITANI",
  "CHITESTI",
  "CICANESTI",
  "CIESTI",
  "CIOBANESTI",
  "CIOBANI",
  "CIOCANAI",
  "CIOCANESTI",
  "CIOCANU",
  "CIOCESTI",
  "CIOFRANGENI",
  "CIOLCESTI",
  "CIOMAGESTI",
  "CIRESU",
  "CISMEA",
  "CIULNITA",
  "CIUPA-MANCIULESCU",
  "CIURESTI",
  "CLUCEREASA",
  "COCENESTI",
  "COCHINESTI",
  "COCU",
  "COLIBASI",
  "COLNIC",
  "COLTU",
  "CONTESTI",
  "CORBENI",
  "CORBI",
  "CORBSORI",
  "CORNATEL",
  "COSACI",
  "COSERI",
  "COSESTI",
  "COSTESTI",
  "COSTESTI-VASLAN",
  "COSTITA",
  "COTEASCA",
  "COTENESTI",
  "COTESTI",
  "COTMEANA",
  "COTMENITA",
  "COTU",
  "COTU MALULUI",
  "CRAMPOTANI",
  "CRINTESTI",
  "CRIVATU",
  "CRUCISOARA",
  "CUCA",
  "CUNGREA",
  "CURTEA DE ARGES",
  "CURTEANCA",
  "DAMBOVICIOARA",
  "DARMANESTI",
  "DAVIDESTI",
  "DEAGU DE JOS",
  "DEAGU DE SUS",
  "DEALU",
  "DEALU BISERICII",
  "DEALU BRADULUI",
  "DEALU FRUMOS",
  "DEALU OBEJDEANULUI",
  "DEALU ORASULUI",
  "DEALU PADURII",
  "DEALU TOLCESII",
  "DEALU VIILOR",
  "DEDULESTI",
  "DICONESTI",
  "DINCANI",
  "DINCULESTI",
  "DOBLEA",
  "DOBRESTI",
  "DOBROGOSTEA",
  "DOBROTU",
  "DOGARI",
  "DOMNESTI",
  "DRAGANU-OLTENI",
  "DRAGHESCU",
  "DRAGHICESTI",
  "DRAGHICI",
  "DRAGOLESTI",
  "DRAGOSLAVELE",
  "DUMBRAVA",
  "DUMBRAVESTI",
  "DUMIRESTI",
  "ENCULESTI",
  "FACALETESTI",
  "FAGETU",
  "FALFANI",
  "FATA",
  "FEDELESOIU",
  "FINTINEA",
  "FLORIENI",
  "FRATESTI",
  "FRATICI",
  "FURDUESTI",
  "FURESTI",
  "FURNICOSI",
  "GAINUSA",
  "GALASESTI",
  "GALCESTI",
  "GALESESTI",
  "GALESU",
  "GALETEANU",
  "GAMACESTI",
  "GANESTI",
  "GARDINESTI",
  "GAUJANI",
  "GEAMANA",
  "GIUCLANI",
  "GLAMBOCATA",
  "GLAMBOCATA-DEAL",
  "GLAMBOCEL",
  "GLAMBOCELU",
  "GLAVACIOC",
  "GLIGANU DE JOS",
  "GLIGANU DE SUS",
  "GLIMBOCU",
  "GLODU",
  "GODENI",
  "GOIA",
  "GOLEASCA",
  "GOLESTI",
  "GORANESTI",
  "GORANI",
  "GORGANU",
  "GREABAN",
  "GREABANU",
  "GROSANI",
  "GROSI",
  "GRUIU",
  "GURA PRAVAT",
  "GURA VAII",
  "HARSESTI",
  "HARTIESTI",
  "HINTESTI",
  "HULUBA",
  "HUMELE",
  "IANCULESTI",
  "IOANICESTI",
  "IONESTI",
  "IZBASESTI",
  "IZVORANI",
  "IZVORU",
  "IZVORU DE JOS",
  "IZVORU DE SUS",
  "JGHEABURI",
  "JUGUR",
  "JUPANESTI",
  "LACENI",
  "LACURILE",
  "LAICAI",
  "LANGESTI",
  "LAPUSANI",
  "LAUNELE DE SUS",
  "LAZARESTI",
  "LEICESTI",
  "LENTEA",
  "LEORDENI",
  "LERESTI",
  "LESILE",
  "LESPEZI",
  "LINTESTI",
  "LIPIA",
  "LIVEZENI",
  "LOTURI",
  "LUCIENI",
  "LUMINILE",
  "LUNCA",
  "LUNCA CORBULUI",
  "LUNCA GIRTII",
  "LUNGANI",
  "LUNGULESTI",
  "LUPUENI",
  "MACAI",
  "MALU",
  "MALU VANAT",
  "MALURENI",
  "MANCIOIU",
  "MANDRA",
  "MANESTI",
  "MANICESTI",
  "MARACINENI",
  "MARES",
  "MARGHIA DE JOS",
  "MARGHIA DE SUS",
  "MARTALOGI",
  "MARTESTI",
  "MATAU",
  "MAVRODOLU",
  "MERISANI",
  "METOFU",
  "MICA",
  "MICESTI",
  "MIERCANI",
  "MIHAESTI",
  "MIOARELE",
  "MIOVENI",
  "MIROSI",
  "MOARA MOCANULUI",
  "MOGOSESTI",
  "MORARESTI",
  "MORASTI",
  "MOSOAIA",
  "MOSTENI-GRECI",
  "MOZACENI",
  "MOZACENII-VALE",
  "MOZACU",
  "MUSATESTI",
  "MUSCEL",
  "MUSTATESTI",
  "NAMAESTI",
  "NEGESTI",
  "NEGRASI",
  "NEGRENI",
  "NEGRESTI",
  "NEJLOVELU",
  "NIGRISOARA",
  "NOAPTES",
  "NUCSOARA",
  "OARJA",
  "ODAENI",
  "OESTII PAMANTENI",
  "OESTII UNGURENI",
  "OGREZEA",
  "OPRESTI",
  "ORODEL",
  "OTELU",
  "PACIOIU",
  "PADURENI",
  "PADURETI",
  "PADUROIU DIN DEAL",
  "PADUROIU DIN VALE",
  "PALANGA",
  "PALTENU",
  "PARASCHIVESTI",
  "PARVU ROSU",
  "PATULENI",
  "PAULEASCA",
  "PAULENI",
  "PAUNESTI",
  "PETRESTI",
  "PIATRA",
  "PIELESTI",
  "PIETROASA",
  "PIETROSANI",
  "PISCANI",
  "PITESTI",
  "PITIGAIA",
  "PITOI",
  "PODENI",
  "PODISORU",
  "PODU BROSTENI",
  "PODU DAMBOVITEI",
  "PODURI",
  "POIANA LACULUI",
  "POIENAREI",
  "POIENARI",
  "POIENITA",
  "POJORATA",
  "POPESTI",
  "PRIBOAIA",
  "PRIBOIENI",
  "PRISEACA",
  "PRISLOPU MARE",
  "PRISLOPU MIC",
  "PRODANI",
  "PROSIA",
  "PURCARENI",
  "PUTINA",
  "RACA",
  "RACHITELE DE JOS",
  "RACHITELE DE SUS",
  "RACOVITA",
  "RADESTI",
  "RADU NEGRU",
  "RADUTESTI",
  "RAJLETU-GOVORA",
  "RANCACIOV",
  "RATESTI",
  "RATOI",
  "RECEA",
  "REDEA",
  "RETEVOIESTI",
  "ROBAIA",
  "ROCIU",
  "ROGOJINA",
  "ROMANA",
  "ROTUNDA",
  "RUCAR",
  "RUDENI",
  "RUGINOASA",
  "SALATRUCU",
  "SALISTEA",
  "SAMAILA",
  "SAMARA",
  "SANDULESTI",
  "SAPUNARI",
  "SATIC",
  "SATU NOU",
  "SBOGHITESTI",
  "SCHEAU",
  "SCHITU GOLESTI",
  "SCHITU SCOICESTI",
  "SCHITU-MATEI",
  "SELAREASCA",
  "SELARI",
  "SENDRULESTI",
  "SERBANESTI",
  "SERBOENI",
  "SILISTEA",
  "SILISTENI",
  "SINESTI",
  "SLAMNESTI",
  "SLANIC",
  "SLATINA",
  "SLATIOARELE",
  "SLOBOZIA",
  "SMEI",
  "SMEURA",
  "SPIRIDONI",
  "STALPENI",
  "STANESTI",
  "STANICEI",
  "STARCI",
  "STEFAN CEL MARE",
  "STEFANESTI",
  "STEFANESTII NOI",
  "STEJARI",
  "STOENESTI",
  "STOLNICI",
  "STRAMBENI",
  "STROESTI",
  "SUICI",
  "SURDULESTI",
  "SUSENI",
  "SUSLANESTI",
  "TEIU",
  "TEODORESTI",
  "TIGANESTI",
  "TIGVENI",
  "TITESTI",
  "TOMSANCA",
  "TOMULESTI",
  "TOPLITA",
  "TOPOLOVENI",
  "TURBUREA",
  "TURCESTI",
  "TUTANA",
  "TUTULESTI",
  "UDA",
  "UDENI-ZAVOI",
  "UIASCA",
  "ULENI",
  "ULITA",
  "UNGHENI",
  "UNGURENI",
  "URECHESTI",
  "URLUCEA",
  "URLUENI",
  "URLUIESTI",
  "URSOAIA",
  "VACAREA",
  "VALCELELE",
  "VALEA BADENILOR",
  "VALEA BRADULUI",
  "VALEA BRAZILOR",
  "VALEA CALULUI",
  "VALEA CETATUIA",
  "VALEA CORBULUI",
  "VALEA CUCII",
  "VALEA DANULUI",
  "VALEA FAURULUI",
  "VALEA HOTARULUI",
  "VALEA IASULUI",
  "VALEA INDARAT",
  "VALEA LUI ENACHE",
  "VALEA LUI MAS",
  "VALEA MAGUREI",
  "VALEA MANASTIRII",
  "VALEA MARE",
  "VALEA MARE PRAVAT",
  "VALEA MARE-BRATIA",
  "VALEA MARE-PODGORIA",
  "VALEA MARULUI",
  "VALEA MUSCELULUI",
  "VALEA NANDRII",
  "VALEA NENII",
  "VALEA PECHII",
  "VALEA POPII",
  "VALEA RIZII",
  "VALEA RUMANESTILOR",
  "VALEA SILISTII",
  "VALEA STANII",
  "VALEA ULEIULUI",
  "VALEA URSULUI",
  "VALENI",
  "VALENI-PODGORIA",
  "VALSANESTI",
  "VARLOVENI",
  "VARSESTI",
  "VARZAROAIA",
  "VARZARU",
  "VATA",
  "VEDEA",
  "VERNESTI",
  "VETISOARA",
  "VIISOARA",
  "VLADESTI",
  "VLADUTA",
  "VLASCUTA",
  "VOINESTI",
  "VONIGEASA",
  "VOROVENI",
  "VRANESTI",
  "VULPESTI",
  "VULTURESTI",
  "ZAMFIRESTI",
  "ZARNESTI",
  "ZAVOI",
  "ZGRIPCESTI",
  "ZIDURILE",
  "ZIGONENI",
  "ZUVELCATI",
];
COUNTIES_CITIES["PRAHOVA"] = [
  "ADUNATI",
  "ALBESTI-MURU",
  "ALBESTI-PALEOLOGU",
  "ALBINARI",
  "ALUNIS",
  "ANTOFILOAIA",
  "APOSTOLACHE",
  "ARICESTII RAHTIVANI",
  "ARICESTII ZELETIN",
  "ARIONESTII NOI",
  "ARIONESTII VECHI",
  "ARVA",
  "AZUGA",
  "BABA ANA",
  "BAICOI",
  "BALTA DOAMNEI",
  "BALTESTI",
  "BALTITA",
  "BANESTI",
  "BARA",
  "BARAITARU",
  "BARCANESTI",
  "BARZILA",
  "BATESTI",
  "BATRANI",
  "BELCIUG",
  "BERCENI",
  "BERTEA",
  "BIGHILIN",
  "BLEJOI",
  "BOBICESTI",
  "BOBOCI",
  "BOBOLIA",
  "BODESTI",
  "BOGDANESTI",
  "BOLDESTI",
  "BOLDESTI-SCAENI",
  "BORDENII MARI",
  "BORDENII MICI",
  "BOSILCESTI",
  "BOZIENI",
  "BRADET",
  "BRATASANCA",
  "BRATESTI",
  "BRAZII DE JOS",
  "BRAZII DE SUS",
  "BREAZA",
  "BREAZA DE JOS",
  "BREAZA DE SUS",
  "BREBU",
  "BREBU MANASTIREI",
  "BREBU MEGIESESC",
  "BUCHILASI",
  "BUCOV",
  "BUDA",
  "BUGHEA DE JOS",
  "BUGNEA DE SUS",
  "BUSTENARI",
  "BUSTENI",
  "BUTUCI",
  "BUZOTA",
  "CALINESTI",
  "CALUGARENI",
  "CAMPINA",
  "CAP ROSU",
  "CARBUNARI",
  "CARBUNESTI",
  "CARJARI",
  "CARTIERUL DAMBU",
  "CATINA",
  "CATUNU",
  "CEPTURA DE JOS",
  "CEPTURA DE SUS",
  "CERASU",
  "CERNESTI",
  "CHEIA",
  "CHERBA",
  "CHESNOIU",
  "CHICIURENI",
  "CHIOJDEANCA",
  "CHIRITESTI",
  "CHITORANI",
  "CIOCENI",
  "CIOCRAC",
  "CIORANI",
  "CIORANII DE JOS",
  "CIORANII DE SUS",
  "CIRESANU",
  "CIUPELNITA",
  "COADA IZVORULUI",
  "COADA MALULUI",
  "COCEANA",
  "COCORASTII CAPLII",
  "COCORASTII COLT",
  "COCORASTII GRIND",
  "COCORASTII MISLII",
  "COCOSESTI",
  "COLCEAG",
  "COLTU DE JOS",
  "COMARNIC",
  "CONDURATU",
  "CORLATESTI",
  "CORNU DE JOS",
  "CORNU DE SUS",
  "COSERELE",
  "COSLEGI",
  "COSMINA DE JOS",
  "COSMINA DE SUS",
  "COSTENI",
  "COTOFENESTI",
  "CRANGURILE",
  "CRIVINA",
  "CUIB",
  "CURCUBEU",
  "CURMATURA",
  "DARVARI",
  "DIMBU",
  "DITESTI",
  "DOBROTA",
  "DOFTANA",
  "DRAGANEASA",
  "DRAGANESTI",
  "DRAGHICESTI",
  "DRAJNA DE JOS",
  "DRAJNA DE SUS",
  "DUMBRAVA",
  "DUMBRAVESTI",
  "EZENI",
  "FACAIENI",
  "FAGET",
  "FAGETU",
  "FANARI",
  "FANTANELE",
  "FEFELEI",
  "FILIPESTII DE PADURE",
  "FILIPESTII DE TARG",
  "FLORESTI",
  "FRASINET",
  "FULGA DE JOS",
  "FULGA DE SUS",
  "FUNDENI",
  "GAGENI",
  "GALMEIA",
  "GAVANEL",
  "GHEABA",
  "GHERGHITA",
  "GHIGHIU",
  "GHINOAICA",
  "GHIOCEL",
  "GHIOLDUM",
  "GHIOSESTI",
  "GLOD",
  "GOGA",
  "GOGEASCA",
  "GORGOTA",
  "GORNET",
  "GORNET-CRICOV",
  "GORUNA",
  "GRADISTEA",
  "GRESIA",
  "GROSANI",
  "GURA BELIEI",
  "GURA CRIVATULUI",
  "GURA VADULUI",
  "GURA VITIOAREI",
  "HABUD",
  "HARSA",
  "HATCARAU",
  "HOMORACIU",
  "IAZU",
  "INDEPENDENTA",
  "INOTESTI",
  "IORDACHEANU",
  "IRIMESTI",
  "IZESTI",
  "IZVOARELE",
  "IZVORU",
  "JERCALAI",
  "JUGURENI",
  "LACU TURCULUI",
  "LAPOS",
  "LAPOSEL",
  "LILIESTI",
  "LIPANESTI",
  "LIVADEA",
  "LOLOIASCA",
  "LOPATNITA",
  "LUNCA MARE",
  "LUNCA PRAHOVEI",
  "LUTU ROSU",
  "MAGULA",
  "MAGURA",
  "MAGURELE",
  "MAGURENI",
  "MALAESTII DE JOS",
  "MALAESTII DE SUS",
  "MALAIESTI",
  "MALAMUC",
  "MALU ROSU",
  "MALU VANAT",
  "MANASTIREA SUZANA",
  "MANECIU-PAMANTENI",
  "MANECIU-UNGURENI",
  "MANESTI",
  "MARGINEA PADURII",
  "MARGINENII DE JOS",
  "MARLOGEA",
  "MARUNTIS",
  "MATITA",
  "MEHEDINTA",
  "MELICESTI",
  "MERDEALA",
  "MERI",
  "MINIERI",
  "MIRESU MARE",
  "MIRESU MIC",
  "MIROSLAVESTI",
  "MISLEA",
  "MIZIL",
  "MOARA",
  "MOARA DOMNEASCA",
  "MOARA NOUA",
  "MOCESTI",
  "NEDELEA",
  "NEGOIESTI",
  "NEVESTEASCA",
  "NISIPOASA",
  "NISTORESTI",
  "NOVACESTI",
  "NUCET",
  "NUCSOARA DE JOS",
  "NUCSOARA DE SUS",
  "OCINA DE JOS",
  "OCINA DE SUS",
  "ODAILE",
  "OGRETIN",
  "OLARI",
  "OLARII VECHI",
  "OLOGENI",
  "OLTENI",
  "ORZOAIA DE JOS",
  "ORZOAIA DE SUS",
  "OSTROVU",
  "PACURETI",
  "PACURI",
  "PALANCA",
  "PANTAZI",
  "PAREPA-RUSANI",
  "PAULESTI",
  "PAULESTII NOI",
  "PERSUNARI",
  "PIATRA",
  "PIATRA MICA",
  "PIETRICEAUA",
  "PIETRICICA",
  "PIETRISU",
  "PIETROSANI",
  "PIORESTI",
  "PISCULESTI",
  "PITIGOI",
  "PLAI",
  "PLAIETU",
  "PLAIU",
  "PLAIU CIMPINEI",
  "PLAIU CORNULUI",
  "PLAVIA",
  "PLEASA",
  "PLOIESTI",
  "PLOIESTIORI",
  "PLOPENI",
  "PLOPU",
  "PODENII NOI",
  "PODENII VECHI",
  "PODGORIA",
  "PODU CHEII",
  "PODU CORBULUI",
  "PODU LUI GALBEN",
  "PODU LUNG",
  "PODU URSULUI",
  "PODU VADULUI",
  "PODU VALENI",
  "PODURILE",
  "POIANA",
  "POIANA CAMPINA",
  "POIANA COPACENI",
  "POIANA MARE",
  "POIANA MIERLEI",
  "POIANA TAPULUI",
  "POIANA TRESTIEI",
  "POIANA VARBILAU",
  "POIENARII APOSTOLI",
  "POIENARII BURCHII",
  "POIENARII RALI",
  "POIENARII VECHI",
  "POIENILE",
  "POPESTI",
  "POSADA",
  "POSESTII UNGURENI",
  "POSESTII-PAMANTENI",
  "POTIGRAFU",
  "PRAJANI",
  "PREDEAL",
  "PREDESTI",
  "PRISEACA",
  "PROVITA DE JOS",
  "PROVITA DE SUS",
  "PUCHENII MARI",
  "PUCHENII MICI",
  "PUCHENII MOSNENI",
  "PUSCASI",
  "RACHIERI",
  "RADILA",
  "RAFOV",
  "RAGMAN",
  "RAHOVA",
  "ROMANESTI",
  "ROTAREA",
  "ROTARI",
  "SALCIA",
  "SALCIILE",
  "SALCIOARA",
  "SANGERU",
  "SARARI",
  "SARATEL",
  "SARCA",
  "SATU DE SUS",
  "SATU NOU",
  "SATUCU",
  "SCHELA",
  "SCHIAU",
  "SCHIULESTI",
  "SCORTENI",
  "SCURTESTI",
  "SECARIA",
  "SECIU",
  "SECIURI",
  "SFACARU",
  "SFARLEANCA",
  "SICRITA",
  "SILISTEA",
  "SILISTEA DEALULUI",
  "SINAIA",
  "SIPOTU",
  "SIRNA",
  "SLANIC",
  "SLAVU",
  "SLON",
  "SOIMARI",
  "SOIMESTI",
  "SOTRILE",
  "STANCESTI",
  "STARCHIOJD",
  "STEFESTI",
  "STEJARU",
  "STOENESTI",
  "STRAOSTI",
  "STREJNICU",
  "STUBEIU",
  "SURANI",
  "SURDESTI",
  "TALEA",
  "TANTARENI",
  "TARCULESTI",
  "TARGSORU NOU",
  "TARGSORU VECHI",
  "TARICENI",
  "TARLESTI",
  "TARSORENI",
  "TATARAI",
  "TATARANI",
  "TATARU",
  "TEISANI",
  "TELEGA",
  "TESILA",
  "TINOSU",
  "TINTEA",
  "TIPARESTI",
  "TISA",
  "TOHANI",
  "TOMSANI",
  "TONTESTI",
  "TRAISTENI",
  "TRENU",
  "TRESTIENII DE JOS",
  "TRESTIENII DE SUS",
  "TRESTIOARA",
  "TUFANI",
  "TUFENI",
  "TULBUREA",
  "TULBUREA VALENI",
  "UDRESTI",
  "ULMI",
  "UNGURENI",
  "URLATI",
  "URLETA",
  "VADU PARULUI",
  "VADU SAPAT",
  "VALCANESTI",
  "VALCELELE",
  "VALEA ANEI",
  "VALEA BOBULUI",
  "VALEA BORULUI",
  "VALEA BRADETULUI",
  "VALEA BRADULUI",
  "VALEA CALUGAREASCA",
  "VALEA CRANGULUI",
  "VALEA CRICOVULUI",
  "VALEA CUCULUI",
  "VALEA DULCE",
  "VALEA LARGA",
  "VALEA LESPEZII",
  "VALEA MANTEI",
  "VALEA MIEILOR",
  "VALEA NICOVANI",
  "VALEA NUCETULUI",
  "VALEA OPRII",
  "VALEA ORLEI",
  "VALEA PIETREI",
  "VALEA PLOPULUI",
  "VALEA POIENII",
  "VALEA POPII",
  "VALEA SCHEILOR",
  "VALEA SCREZII",
  "VALEA SEACA",
  "VALEA SEMAN",
  "VALEA STALPULUI",
  "VALEA STUPINII",
  "VALEA TARSEI",
  "VALEA TOCII",
  "VALEA UNGHIULUI",
  "VALEA URLOII",
  "VALEA URSOII",
  "VALENII DE MUNTE",
  "VARBILA",
  "VARBILAU",
  "VARFURILE",
  "VARNITA",
  "VISTIERU",
  "VITIOARA DE SUS",
  "ZAHANAUA",
  "ZALHANAUA",
  "ZAMBROAIA",
  "ZAMFIRA",
  "ZANOAGA",
  "ZMEURET",
];
COUNTIES_CITIES["GIURGIU"] = [
  "ADUNATII-COPACENI",
  "ANGHELESTI",
  "BACU",
  "BALANU",
  "BANEASA",
  "BANESTI",
  "BILA",
  "BOLINTIN-DEAL",
  "BOLINTIN-VALE",
  "BRANISTARI",
  "BRANISTEA",
  "BUCSANI",
  "BUDENI",
  "BULBUCATA",
  "BUTURUGENI",
  "CALUGARENI",
  "CAMINEASCA",
  "CAMPURELU",
  "CARAPANCEA",
  "CARPENISU",
  "CARTOJANI",
  "CASCIOARELE",
  "CETATEA",
  "CETATUIA",
  "CHIRCULESTI",
  "CHIRIACU",
  "CLEJANI",
  "COLIBASI",
  "COMANA",
  "COMASCA",
  "COPACIU",
  "CORBEANCA",
  "COSOBA",
  "COTENI",
  "CRANGURI",
  "CREVEDIA MARE",
  "CREVEDIA MICA",
  "CRIVINA",
  "CRUCEA DE PIATRA",
  "CUCURUZU",
  "CUPELE",
  "DAIA",
  "DARASTI-VLASCA",
  "DEALU",
  "DIMITRIE CANTEMIR",
  "DOBRENI",
  "DRAGANEASCA",
  "DRAGANESCU",
  "DRAGHICEANU",
  "FACAU",
  "FALASTOACA",
  "FLORESTI",
  "FRASINU",
  "FRATESTI",
  "GAISEANCA",
  "GAISENI",
  "GAUJANI",
  "GHIMPATI",
  "GHIONEA",
  "GHIZDARU",
  "GIURGIU",
  "GOGOSARI",
  "GOLEASCA",
  "GORNENI",
  "GOSTINARI",
  "GOSTINU",
  "GRADINARI",
  "GRADISTEA",
  "GREACA",
  "HERASTI",
  "HOBAIA",
  "HODIVOAIA",
  "HOTARELE",
  "HULUBESTI",
  "IANCULESTI",
  "ICOANA",
  "IEPURESTI",
  "ISVOARELE",
  "IZVOARELE",
  "IZVORU",
  "JOITA",
  "LETCA NOUA",
  "LETCA VECHE",
  "MALU",
  "MALU SPART",
  "MARSA",
  "MIHAI BRAVU",
  "MIHAI VODA",
  "MIHAILESTI",
  "MILCOVATU",
  "MILOSESTI",
  "MIRAU",
  "MIRONESTI",
  "MOGOSESTI",
  "MOSTENI",
  "NAIPU",
  "NEAJLOV",
  "NOVACI",
  "OBEDENI",
  "OGREZENI",
  "OINACU",
  "ONCESTI",
  "PADURENI",
  "PALANCA",
  "PETRU RARES",
  "PIETRELE",
  "PIETRISU",
  "PLOPSORU",
  "PODISOR",
  "PODU DOAMNEI",
  "PODU ILFOVATULUI",
  "PODU POPA NAE",
  "POENARI",
  "POIANA LUI STANGA",
  "POPESTI",
  "POSTA",
  "PRIBOIU",
  "PRUNDU",
  "PUIENI",
  "PUTINEIU",
  "PUTU GRECI",
  "RADU VODA",
  "RALESTI",
  "RASUCENI",
  "REMUS",
  "ROATA DE JOS",
  "ROATA MICA",
  "SABARENI",
  "SADINA",
  "SATU NOU",
  "SCHITU",
  "SFANTU GHEORGHE",
  "SINGURENI",
  "SLOBOZIA",
  "STALPU",
  "STANESTI",
  "STEJARU",
  "STEREA",
  "STOENESTI",
  "SUSENI",
  "TANTAVA",
  "TEISORI",
  "TEIUSU",
  "TOMULESTI",
  "TOPORU",
  "TRESTIENI",
  "UIESTI",
  "ULMI",
  "UZUNU",
  "VADU LAT",
  "VALCELE",
  "VALEA BUJORULUI",
  "VALEA DRAGULUI",
  "VALEA PLOPILOR",
  "VALTER MARACINEANU",
  "VANATORII MARI",
  "VANATORII MICI",
  "VARASTI",
  "VARLAAM",
  "VEDEA",
  "VIERU",
  "VLAD TEPES",
  "VLASIN",
  "ZADARICIU",
  "ZBOIU",
  "ZORILE",
];
COUNTIES_CITIES["VALCEA"] = [
  "AFANATA",
  "ALDESTI",
  "ALUNU",
  "AMARASTI",
  "ANDREIESTI",
  "ANINOASA",
  "ARANGHEL",
  "ARICIOAIA",
  "ARMASESTI",
  "ARSANCA",
  "AURESTI",
  "AVRAMESTI",
  "BABENI",
  "BABENI-OLTETU",
  "BABUESTI",
  "BADENI",
  "BAIASA",
  "BAIASU",
  "Baile Govora",
  "Baile Olanesti",
  "BAJENARI",
  "BALACIU",
  "BALCESTI",
  "BALOMIREASA",
  "BALOTA",
  "BALSOARA",
  "BALTATENI",
  "BALTENI",
  "BALUTOAIA",
  "BANESTI",
  "BANTESTI",
  "BARBARIGENI",
  "BARBATESTI",
  "BARBUCENI",
  "BARCANELE",
  "BARCANESTI",
  "BARLOGU",
  "BAROIU",
  "BARSESTI",
  "BARSOIU",
  "BARZA",
  "BARZESTI",
  "BATASANI",
  "BECSANI",
  "BENESTI",
  "BERBESTI",
  "BERCIOIU",
  "BERESTI",
  "BERISLAVESTI",
  "BISTRITA",
  "BLANOIU",
  "BLEJANI",
  "BLIDARI",
  "BOCSA",
  "BODESTI",
  "BOGDANESTI",
  "BOISOARA",
  "BONCIU",
  "BONDOCI",
  "BOROSESTI",
  "BOTORANI",
  "BRADISOR",
  "BRADU-CLOCOTICI",
  "BRATIA DIN DEAL",
  "BRATIA DIN VALE",
  "BRATOVESTI",
  "BREZOI",
  "BROSTENI",
  "BUCIUMENI",
  "BUCSANI",
  "BUDA",
  "BUDELE",
  "BUDESTI",
  "BUDURASTI",
  "BUJORENI",
  "BULAGEI",
  "BULETA",
  "BUMBUESTI",
  "BUNESTI",
  "BUNGETANI",
  "BURDALESTI",
  "BUTANU",
  "BUZDUGAN",
  "CACIULATA",
  "CAINENII MARI",
  "CAINENII MICI",
  "CALIGI",
  "CALIMANESTI",
  "CALINA",
  "CALINESTI",
  "CAPU DEALULUI",
  "CARCADIESTI",
  "CARLOGANI",
  "CARSTANESTI",
  "CASA VECHE",
  "CATETU",
  "CAZANESTI",
  "CERETU",
  "CERMEGESTI",
  "CERNA",
  "CERNELELE",
  "CERNISOARA",
  "CETATEAUA",
  "CHEIA",
  "CHERASTI",
  "CHICENI",
  "CHIRCULESTI",
  "CHIRICESTI",
  "CIOBOTI",
  "CIOCALTEI",
  "CIOCANARI",
  "CIOPONESTI",
  "CIORASTI",
  "CIORTESTI",
  "CIRESU",
  "CIRESUL",
  "CIUCHETI",
  "CIUMAGI",
  "CIUNGETU",
  "CIUTESTI",
  "COASTA",
  "COASTA CERBULUI",
  "COASTA MARE",
  "COCORU",
  "COLELIA",
  "COLTESTI",
  "COMANCA",
  "CONDOIESTI",
  "CONTEA",
  "COPACELU",
  "COPACENI",
  "CORBII DIN VALE",
  "CORBU",
  "CORNET",
  "COSANI",
  "COSOTA",
  "COSTESTI",
  "CRANGU",
  "CREMENARI",
  "CRETENI",
  "CUCESTI",
  "CUCI",
  "CUCOIU",
  "CUCULESTI",
  "CUENI",
  "CURATURILE",
  "CURTEA",
  "DAESTI",
  "DAMTENI",
  "DANCAI",
  "DANGESTI",
  "DEALU ALUNIS",
  "DEALU BISERICII",
  "DEALU CORNI",
  "DEALU GLAMEIA",
  "DEALU LAUNELE",
  "DEALU MALULUI",
  "DEALU MARE",
  "DEALU SCHEIULUI",
  "DEALU VALENI",
  "DEJOI",
  "DELENI",
  "DELURENI",
  "DEZROBITI",
  "DIACONESTI",
  "DIANU",
  "DICULESTI",
  "DIMULESTI",
  "DOBRESTI",
  "DOBRICEA",
  "DOBRICENI",
  "DOBRUSA",
  "DOSU RAULUI",
  "DOZESTI",
  "DRAGANESTI",
  "DRAGANU",
  "DRAGASANI",
  "DRAGIOIU",
  "DRAGOESTI",
  "DRAGULESTI",
  "DUMBRAVA",
  "DUMBRAVESTI",
  "FACAI",
  "FARTATESTI",
  "FAURESTI",
  "FEDELESOIU",
  "FETENI",
  "FIRESTI",
  "FIRIJBA",
  "FISCALIA",
  "FOLESTII DE JOS",
  "FOLESTII DE SUS",
  "FOTESTI",
  "FRANCESTI",
  "FRANCESTI-COASTA",
  "FRASINA",
  "FUMURENI",
  "FUNDATURA",
  "GAGENI",
  "GAINESTI",
  "GALICEA",
  "GALTOFANI",
  "GANESTI",
  "GANTULEI",
  "GARNICET",
  "GARNICETU",
  "GATEJESTI",
  "GAUJANI",
  "GAVANESTI",
  "GEAMANA",
  "GENUNENI",
  "GHINDARI",
  "GHIOBESTI",
  "GHIOROIU",
  "GIBESTI",
  "GINERICA",
  "GIULESTI",
  "GIULESTII DE SUS",
  "GIURGIUVENI",
  "GIUROIU",
  "GLAVILE",
  "GLODU",
  "GOLOTRENI",
  "GORANU",
  "GORUNESTI",
  "GOVORA",
  "GRADISTEA",
  "GREBLESTI",
  "GRECI",
  "GROPENI",
  "GROSI",
  "GRUIERI",
  "GRUIU",
  "GRUIU LUPULUI",
  "GUGUIANCA",
  "GURA CRUCILOR",
  "GURA SUHASULUI",
  "GURA VAII",
  "GURGUIATA",
  "GURISOARA",
  "GUSOENI",
  "GUSOIANCA",
  "HERASTI",
  "HOREZU",
  "HOTARASA",
  "HOTARELE",
  "HOTAROAIA",
  "IACOVILE",
  "IFRIMESTI",
  "IGOIU",
  "ILACIU",
  "IONESTI",
  "IRIMESTI",
  "IZBASESTI",
  "IZVORASU",
  "IZVORU",
  "IZVORU RECE",
  "JAROSTEA",
  "JIBLEA NOUA",
  "JIBLEA VECHE",
  "LACUSTENI",
  "LACUSTENII DE JOS",
  "LACUSTENII DE SUS",
  "LADESTI",
  "LALOSU",
  "LAUNELE DE JOS",
  "LESPEZI",
  "LINIA",
  "LINIA DEALULUI",
  "LINIA HANULUI",
  "LINIA PE VALE",
  "LIVADIA",
  "LIVEZI",
  "LUNGESTI",
  "LUPOAIA",
  "LUPUIESTI",
  "MACIUCENI",
  "MADULARI",
  "MAGURA",
  "MAGURENI",
  "MALAIA",
  "MALDARESTI",
  "MALDARESTII DE JOS",
  "MALU",
  "MALU ALB",
  "MALU VARTOP",
  "MAMU",
  "MANAILESTI",
  "MANEASA",
  "MANGURENI",
  "MANICEA",
  "MARCEA",
  "MARCUSU",
  "MARGINENI",
  "MARICESTI",
  "MARITA",
  "MATEESTI",
  "MAZARARU",
  "MAZILI",
  "MECEA",
  "MEIENI",
  "MERESESTI",
  "MIEREA",
  "MIHAESTI",
  "MIJATI",
  "MIJLOCU",
  "MILCOIU",
  "MILESTI",
  "MILOSTEA",
  "MIRESTI",
  "MITROFANI",
  "MLACENI",
  "MODOIA",
  "MOGESTI",
  "MOGOSESTI",
  "MOLOGESTI",
  "MOSOROASA",
  "MOSTENI",
  "MRENESTI",
  "MUEREASCA",
  "MUEREASCA DE SUS",
  "MUNTENI",
  "NEGHINESTI",
  "NEGRAIA",
  "NEGRENI",
  "NEGRULESTI",
  "NEMOIU",
  "NENCIULESTI",
  "NETESTI",
  "NICOLESTI",
  "NISIPI",
  "OBARSIA",
  "OBISLAVU",
  "OBOGENI",
  "OBROCESTI",
  "Ocnele Mari",
  "OCNITA",
  "OCRACU",
  "OLANESTI",
  "OLANU",
  "OLTEANCA",
  "OLTENI",
  "OLTETANI",
  "OLTETU",
  "OPATESTI",
  "ORLESTI",
  "OSTROVENI",
  "OTESANI",
  "OTETELISU",
  "OVESELU",
  "PADINA",
  "PADURETU",
  "PALANGA",
  "PARAIENII DE JOS",
  "PARAIENII DE MIJLOC",
  "PARAIENII DE SUS",
  "PARAUSANI",
  "PASAREI",
  "PASCOAIA",
  "PASCULESTI",
  "PATESTI",
  "PAUSA",
  "PAUSESTI",
  "PAUSESTI-MAGLASI",
  "PAUSESTI-OTASAU",
  "PERISANI",
  "PERTESTI",
  "PESCEANA",
  "PIETRARI",
  "PIETRARII DE SUS",
  "PIETRENI",
  "PIETRISU",
  "PIETROASA",
  "PISCU MARE",
  "PISCU PIETREI",
  "PISCU SCOARTEI",
  "PLEASA",
  "PLESESTI",
  "PLESOIU",
  "PODENI",
  "POENARI",
  "POENITA",
  "POIANA",
  "POIENARI",
  "POJOGI-CERNA",
  "POPESTI",
  "PORTARESTI",
  "PRAJILA",
  "PREDESTI",
  "PREOTESTI",
  "PRIBA",
  "PRILOAGE",
  "PRIPOARA",
  "PRIPOARE",
  "PRIPORU",
  "PROCOPOAIA",
  "PRODANESTI",
  "PROIENI",
  "PRUNDENI",
  "RACOVITA",
  "RACU",
  "RADACINESTI",
  "RAMESTI",
  "RAMNICU VALCEA",
  "RAPA CARAMIZII",
  "RAPANESTI",
  "RATALESTI",
  "RAU VADULUI",
  "RAURENI",
  "ROBAIA",
  "ROBESTI",
  "ROESTI",
  "ROMANESTI",
  "ROMANI",
  "ROMANII DE JOS",
  "ROMANII DE SUS",
  "ROSIA",
  "ROSIILE",
  "ROSIOARA",
  "ROSOVENI",
  "ROTARASTI",
  "RUDA",
  "RUGETU",
  "RUNCU",
  "RUSANESTI",
  "SAIOCI",
  "SALATRUCEL",
  "SALISTEA",
  "SANBOTIN",
  "SARBI",
  "SARSANESTI",
  "SARULESTI",
  "SASCIOARA",
  "SATU POIENI",
  "SCAIOSI",
  "SCARISOARA",
  "SCAUENI",
  "SCHITU",
  "SCORUSU",
  "SCUNDU",
  "SEACA",
  "SECIU",
  "SERBANEASA",
  "SERBANESTI",
  "SILEA",
  "SINESTI",
  "SIRINEASA",
  "SLATIOARA",
  "SLATIOARELE",
  "SLAVITESTI",
  "SNAMANA",
  "SOLICESTI",
  "SOTANI",
  "SPARLENI",
  "SPINU",
  "STANCULESTI",
  "STANESTI",
  "STANESTI-LUNCA",
  "STANOMIRU",
  "STEFANESTI",
  "STIRBESTI",
  "STOENESTI",
  "STOICANESTI",
  "STOICULESTI",
  "STOILESTI",
  "STOLNICENI",
  "STRACHINESTI",
  "STREMINOASA",
  "STROESTI",
  "STUPAREI",
  "SUB DEAL",
  "SUIESTI",
  "SURDOIU",
  "SURICARU",
  "SURPATELE",
  "SURPATI",
  "SUSANI",
  "SUSENI",
  "SUTA",
  "SUTESTI",
  "TANASESTI",
  "TANISLAVI",
  "TARGU GANGULESTI",
  "TATARANI",
  "TEICA",
  "TEIU",
  "TEIUL",
  "TEIUSU",
  "TELECHESTI",
  "TEPESTI",
  "TEPSENARI",
  "TETOIU",
  "TIGHINA",
  "TINA",
  "TISA",
  "TITESTI",
  "TITIRECI",
  "TOMSANI",
  "TROIAN",
  "TRUNDIN",
  "TUFANII",
  "TULEI-CAMPENI",
  "TURBUREA",
  "TURCESTI",
  "TUTULESTI",
  "TUTURU",
  "UDRESTI",
  "ULMETEL",
  "ULMETU",
  "URSANI",
  "URSI",
  "URSOAIA",
  "URZICA",
  "USUREI",
  "VAIDEENI",
  "VALEA ALUNISULUI",
  "VALEA BABEI",
  "VALEA BALCEASCA",
  "VALEA CASELOR",
  "VALEA CHEII",
  "VALEA GRADISTEI",
  "VALEA LUI STAN",
  "VALEA LUNGA",
  "VALEA MACESULUI",
  "VALEA MARE",
  "VALEA RAULUI",
  "VALEA SCHEIULUI",
  "VALEA URSULUI",
  "VALEA VALENI",
  "VALEA VIEI",
  "VALENI",
  "VARATECI",
  "VARATICA",
  "VARATICI",
  "VARLENI",
  "VATASESTI",
  "VERDEA",
  "VETELU",
  "VIISOARA",
  "VLADESTI",
  "VLADUCENI",
  "VLADULESTI",
  "VOICESTI",
  "VOICESTII DIN VALE",
  "VOICULEASA",
  "VOINEASA",
  "VOINESITA",
  "VULPUESTI",
  "ZARNENI",
  "ZARNESTI",
  "ZATRENI",
  "ZATRENII DE SUS",
  "ZAVIDENI",
  "ZAVOIENI",
  "ZGUBEA",
  "ZLATAREI",
  "ZMEURATU",
];
COUNTIES_CITIES["OLT"] = [
  "AFUMATI",
  "ALBESTI",
  "ALIMANESTI",
  "ALUNISU",
  "ARCESTI",
  "ARCESTI COT",
  "ARVATEASCA",
  "BABICIU",
  "BACEA",
  "BAGESTI",
  "BALANESTI",
  "BALDOVINESTI",
  "BALEASA",
  "BALS",
  "BALTATI",
  "BALTENI",
  "BARASTI",
  "BARASTII DE CEPTURI",
  "BARASTII DE VEDE",
  "BARBALAI",
  "BARCA",
  "BARCANESTI",
  "BARSESTII DE SUS",
  "BARZA",
  "BATARENI",
  "BATIA",
  "BECHET",
  "BECULESTI",
  "BELGUN",
  "BERIA DE JOS",
  "BERIA DE SUS",
  "BERINDEI",
  "BIRCII",
  "BISTRITA NOUA",
  "BLAJ",
  "BOBICESTI",
  "BOBU",
  "BONDREA",
  "BOROESTI",
  "BRANCOVENI",
  "BRANET",
  "BRASTAVATU",
  "BREBENI",
  "BROSTENI",
  "BUCINISU",
  "BUCINISU MIC",
  "BUICESTI",
  "BULIMANU",
  "BURDULESTI",
  "BUSCA",
  "BUTA",
  "BUTOI",
  "BUZESTI",
  "CALINESTI",
  "CALUI",
  "CAMPU MARE",
  "CAMPU PARULUI",
  "CANDELESTI",
  "CARACAL",
  "CARLOGANI",
  "CARSTANI",
  "CATANELE",
  "CAZANESTI",
  "CEPARI",
  "CEPESTI",
  "CERBENI",
  "CEZIENI",
  "CHELBESTI",
  "CHERLESTII DIN DEAL",
  "CHERLESTII MOSTENI",
  "CHILIA",
  "CHILII",
  "CHINTESTI",
  "CHITEASCA",
  "CILIENI",
  "CIOCANESTI",
  "CIOFLANU",
  "CIORACA",
  "CIOROIASU",
  "CIOROIU",
  "CIREASOV",
  "CIURESTI",
  "COCORASTI",
  "COJGAREI",
  "COLIBASI",
  "COLONESTI",
  "COMANCA",
  "COMANESTI",
  "COMANI",
  "COMANITA",
  "CONSTANTINESTI",
  "CORABIA",
  "CORBENI",
  "CORBU",
  "CORLATESTI",
  "CORNATELU",
  "COTEANA",
  "COTENI",
  "COTORBESTI",
  "CRACIUNEI",
  "CRAMPOIA",
  "CRETI",
  "CRIVA DE JOS",
  "CRIVA DE SUS",
  "CRUSOVU",
  "CUCUETI",
  "CUNGREA",
  "CURTISOARA",
  "CUZA VODA",
  "DAMBURILE",
  "DANEASA",
  "DAVIDESTI",
  "DEJESTI",
  "DELENI",
  "DEVESELU",
  "DIENCI",
  "DOANCA",
  "DOBA",
  "DOBRETU",
  "DOBRICENI",
  "DOBROSLOVENI",
  "DOBROTEASA",
  "DOBROTINET",
  "DOBRUN",
  "DONESTI",
  "DRAGANESTI-OLT",
  "DRAGHICENI",
  "DRANOVATU",
  "DUMITRESTI",
  "ENOSESTI",
  "FAGETELU",
  "FALCOIU",
  "FARCASELE",
  "FARCASU DE JOS",
  "FLORU",
  "FRASINET GARA",
  "FRASINETU",
  "FRUNZARU",
  "GANEASA",
  "GARCOV",
  "GAVANESTI",
  "GHIMPATI",
  "GHIMPETENI",
  "GHIMPETENII NOI",
  "GHIOSANI",
  "GIUVARASTI",
  "GOSTAVATU",
  "GOVORA",
  "GRADINARI",
  "GRADINILE",
  "GRADISTEA",
  "GRECI",
  "GREERESTI",
  "GROJDIBODU",
  "GROPSANI",
  "GROZAVESTI",
  "GRUIU",
  "GUBANDRU",
  "GUESTI",
  "GURA CALUIU",
  "GURA PADINII",
  "HOREZU",
  "HOTARANI",
  "HOTARU",
  "IANCA",
  "IANCA NOUA",
  "IANCU JIANU",
  "IBANESTI",
  "ICOANA",
  "IONICESTI",
  "IPOTESTI",
  "ISACI",
  "IZBICENI",
  "IZVOARELE",
  "IZVORU",
  "JIENI",
  "JITARU",
  "LAUNELE",
  "LAZARESTI",
  "LELEASCA",
  "LEOTESTI",
  "LIICENI",
  "LINIA DIN VALE",
  "LISA",
  "LUNCA",
  "MAGURA",
  "MALU ROSU",
  "MAMURA",
  "MANDRA",
  "MANULESTI",
  "MARDALE",
  "MARGARITESTI",
  "MARGHENI",
  "MARGINENI SLOBOZIA",
  "MARUNTEI",
  "MERENI",
  "MIERLESTII DE SUS",
  "MIERLICESTI",
  "MIESTI",
  "MIHAESTI",
  "MIHAILESTI POPESTI",
  "MILCOVENI",
  "MILCOVU DIN DEAL",
  "MILCOVU DIN VALE",
  "MIRCESTI",
  "MIRILA",
  "MOGOSESTI",
  "MOMAIU",
  "MORUNESTI",
  "MORUNGLAV",
  "MOSTENI",
  "MOTOESTI",
  "MOVILENI",
  "NAVARGENI",
  "NEGRENI",
  "NICOLAE TITULESCU",
  "OBARSIA",
  "OBARSIA NOUA",
  "OBOGA",
  "OCIOGI",
  "OLARI",
  "OLTISORU",
  "OPORELU",
  "OPTASANI",
  "OPTASI",
  "ORLEA",
  "ORLEA NOUA",
  "OSICA DE JOS",
  "OSICA DE SUS",
  "OSTROV",
  "OTESTII DE JOS",
  "OTESTII DE SUS",
  "PARSCOVENI",
  "PERETU",
  "PERIETI",
  "PESCARESTI",
  "PESTRA",
  "PETCULESTI",
  "PIATRA",
  "PIATRA-OLT",
  "PIELCANI",
  "PIETRIS",
  "PIETRISU",
  "PISCANI",
  "PLAVICEANCA",
  "PLAVICENI",
  "PLESOIU",
  "PLOPSORELU",
  "POBORU",
  "POGANU",
  "POIANA",
  "POIANA MARE",
  "POPESTI",
  "POTCOAVA",
  "POTCOAVA FALCOENI",
  "POTELU",
  "POTLOGENI",
  "POTOPINU",
  "PREOTESTI",
  "PRISACA",
  "PRISEACA",
  "PROASPETI",
  "PROFA",
  "RACOVITA",
  "RADESTI",
  "RADOMIRESTI",
  "RAITIU",
  "RECEA",
  "REDEA",
  "REDISOARA",
  "RESCA",
  "RESCUTA",
  "ROMANA",
  "ROSIENII MARI",
  "ROSIENII MICI",
  "ROTUNDA",
  "RUDARI",
  "RUNCU MARE",
  "RUSANESTI",
  "RUSANESTII DE SUS",
  "RUSCIORI",
  "SALCIA",
  "SALTANESTI",
  "SAMBURESTI",
  "SATU NOU",
  "SCARISOARA",
  "SCHITU",
  "SCHITU DELENI",
  "SCHITU DIN DEAL",
  "SCHITU DIN VALE",
  "SCORBURA",
  "SCORNICESTI",
  "SEACA",
  "SERBANESTI",
  "SERBANESTII DE SUS",
  "SIMNICENI",
  "SINESTI",
  "SLATINA",
  "SLATIOARA",
  "SLAVENI",
  "SOPARLITA",
  "SPATARU",
  "SPINENI",
  "SPRANCENATA",
  "STANULEASA",
  "STAVARU",
  "STEFAN CEL MARE",
  "STEJARU",
  "STOBORASTI",
  "STOENESTI",
  "STOICANESTI",
  "STREJESTI",
  "STREJESTII DE SUS",
  "STRUGURELU",
  "STUDINA",
  "STUDINITA",
  "STUPINA",
  "SUICA",
  "SURPENI",
  "TABACI",
  "TABONU",
  "TATULESTI",
  "TEIS",
  "TEIUS",
  "TEIUSU",
  "TESLUI",
  "TIA MARE",
  "TIRISNEAG",
  "TOMENI",
  "TONESTI",
  "TOPANA",
  "TRAIAN",
  "TREPTENI",
  "TRUFINESTI",
  "TUDOR VLADIMIRESCU",
  "TUFARU",
  "TUFENI",
  "TURIA",
  "ULMET",
  "ULMI",
  "UNGURENI",
  "URIA",
  "URSA",
  "URSI",
  "URSOAIA",
  "URZICA",
  "VADASTRA",
  "VADASTRITA",
  "VALCELE",
  "VALCELELE DE SUS",
  "VALEA FETEI",
  "VALEA LUI ALB",
  "VALEA MARE",
  "VALEA MERILOR",
  "VALEA SATULUI",
  "VALEA SOARELUI",
  "VALENI",
  "VANESTI",
  "VARTOPU",
  "VERGULEASA",
  "VINETI",
  "VISINA",
  "VISINA NOUA",
  "VITANESTI",
  "VITOMIRESTI",
  "VLADILA",
  "VLADILA NOUA",
  "VLADULENI",
  "VLAICI",
  "VLANGARESTI",
  "VOINEASA",
  "VULPENI",
  "VULPESTI",
  "VULTURESTI",
  "ZANOAGA",
  "ZORLEASCA",
];
COUNTIES_CITIES["CLUJ"] = [
  "AGARBICIU",
  "AGHIRESU",
  "AGHIRESU-FABRICI",
  "AGRIS",
  "AITON",
  "ALUNIS",
  "ALUNISU",
  "ANDICI",
  "ANTAS",
  "APAHIDA",
  "ARDEOVA",
  "ARGHISU",
  "ARUNCUTA",
  "ASCHILEU MARE",
  "ASCHILEU MIC",
  "BABDIU",
  "BABUTIU",
  "BACIU",
  "BADENI",
  "BADESTI",
  "BAGACIU",
  "BAGARA",
  "BAISOARA",
  "BAITA",
  "BALCESTI",
  "BARAI",
  "BARLEA",
  "BATIN",
  "BEDECIU",
  "BELIS",
  "BERCHIESU",
  "BERINDU",
  "BICA",
  "BICALATU",
  "BLIDARESTI",
  "BOBALNA",
  "BOCIU",
  "BODROG",
  "BOGATA",
  "BOGATA DE JOS",
  "BOGATA DE SUS",
  "BOIAN",
  "BOJ-CATUN",
  "BOJU",
  "BOLDUT",
  "BOLOGA",
  "BONT",
  "BONTIDA",
  "BORSA",
  "BORSA-CATUN",
  "BORSA-CRESTAIA",
  "BORZESTI",
  "BOTENI",
  "BRAISORU",
  "BUCEA",
  "BUNESTI",
  "BURU",
  "BUTENI",
  "BUZA",
  "CACOVA IERII",
  "CAIANU",
  "CAIANU MIC",
  "CAIANU-VAMA",
  "CALARASI",
  "CALARASI-GARA",
  "CALATA",
  "CALATELE",
  "CALNA",
  "CAMARASU",
  "CAMPENESTI",
  "CAMPIA TURZII",
  "CAPRIOARA",
  "CAPUSU MARE",
  "CAPUSU MIC",
  "CARA",
  "CASEIU",
  "CASELE MICESTI",
  "CATALINA",
  "CATCAU",
  "CATINA",
  "CEABA",
  "CEANU MARE",
  "CEANU MIC",
  "CERBESTI",
  "CERC",
  "CETAN",
  "CHEIA",
  "CHESAU",
  "CHIDEA",
  "CHINTENI",
  "CHIRIS",
  "CHIUIESTI",
  "CIUBANCA",
  "CIUBANCUTA",
  "CIUCEA",
  "CIULENI",
  "CIUMAFAIA",
  "CIURGAU",
  "CIURILA",
  "CLAPA",
  "CLUJ-NAPOCA",
  "COASTA",
  "CODOR",
  "COJOCNA",
  "COLONIA",
  "COMORITA",
  "COMSESTI",
  "COPACENI",
  "COPLEAN",
  "COPRU",
  "CORNENI",
  "CORNESTI",
  "CORPADEA",
  "CORUSU",
  "CRAESTI",
  "CRAIRAT",
  "CREMENEA",
  "CRISENI",
  "CRISTOREL",
  "CUBLESU SOMESAN",
  "CURTUIUSU DEJULUI",
  "CUSTURA",
  "CUTCA",
  "CUZDRIOARA",
  "DABACA",
  "DAMBU MARE",
  "DAMBURILE",
  "DANCU",
  "DANGAU MARE",
  "DANGAU MIC",
  "DARJA",
  "DAROT",
  "DEALU BOTII",
  "DEALU MARE",
  "DEALU NEGRU",
  "DEJ",
  "DELENI",
  "DEUSU",
  "DEZMIR",
  "DIVICIORII MARI",
  "DIVICIORII MICI",
  "DOMOSU",
  "DORNA",
  "DOROLTU",
  "DOSU BRICII",
  "DOSU NAPULUI",
  "DRETEA",
  "DUMBRAVA",
  "ELCIU",
  "ESCU",
  "FAGETU IERII",
  "FALCA",
  "FANATE",
  "FAURENI",
  "FEIURDENI",
  "FELDIOARA",
  "FELEACU",
  "FILEA DE JOS",
  "FILEA DE SUS",
  "FINCIU",
  "FINISEL",
  "FIZESU GHERLII",
  "FLORESTI",
  "FODORA",
  "FRASINET",
  "FRATA",
  "FUNDATURA",
  "GADALIN",
  "GARBAU",
  "GARBAU DEJULUI",
  "GEACA",
  "GHEORGHIENI",
  "GHERLA",
  "GHIRISU ROMAN",
  "GHIROLT",
  "GILAU",
  "GIULA",
  "GIURCUTA DE JOS",
  "GIURCUTA DE SUS",
  "GLIGORESTI",
  "GUGA",
  "HAGAU",
  "HASDATE",
  "HODAI BOIAN",
  "HODAIE",
  "HODISU",
  "HORLACEA",
  "HUCI",
  "HUEDIN",
  "HUTA",
  "IACOBENI",
  "IARA",
  "ICLOD",
  "ICLOZEL",
  "IGRITIA",
  "INUCU",
  "IURIU DE CAMPIE",
  "IZVORU CRISULUI",
  "JICHISU DE JOS",
  "JICHISU DE SUS",
  "JUC-HERGHELIE",
  "JUCU DE MIJLOC",
  "JUCU DE SUS",
  "JURCA",
  "LACU",
  "LAPUSTESTI",
  "LEGHIA",
  "LEGII",
  "LEURDA",
  "LITA",
  "LITENI",
  "LIVADA",
  "LOBODAS",
  "LUJERDIU",
  "LUNA",
  "LUNA DE JOS",
  "LUNA DE SUS",
  "LUNCA BONTULUI",
  "LUNCA VISAGULUI",
  "LUNCANI",
  "LUNGESTI",
  "MACAU",
  "MACICASU",
  "MAGOAJA",
  "MAGURA IERII",
  "MAGURI",
  "MAGURI-RACATAU",
  "MAHAL",
  "MAIA",
  "MANASTIREA",
  "MANASTIRENI",
  "MANASTUREL",
  "MANASTURU ROMANESC",
  "MARCESTI",
  "MARGAU",
  "MARISEL",
  "MARTINESTI",
  "MASCA",
  "MERA",
  "MICA",
  "MICESTI",
  "MIHAI VITEAZU",
  "MIHAIESTI",
  "MINTIU GHERLII",
  "MOARA DE PADURE",
  "MOCIU",
  "MOLDOVENESTI",
  "MORAU",
  "MORISTI",
  "MORLACA",
  "MORTESTI",
  "MUNCEL",
  "MUNTELE BAISORII",
  "MUNTELE BOCULUI",
  "MUNTELE CACOVEI",
  "MUNTELE FILII",
  "MUNTELE RECE",
  "MUNTELE SACELULUI",
  "MURESENII DE CAMPIE",
  "NADASELU",
  "NADASU",
  "NAOIU",
  "NASAL",
  "NEARSOVA",
  "NEGRENI",
  "NICULA",
  "NIMA",
  "NIRES",
  "OAS",
  "OCNA DEJULUI",
  "OCOLISEL",
  "OLARIU",
  "ORMAN",
  "OSOI",
  "OSORHEL",
  "PADUREA IACOBENI",
  "PADURENI",
  "PADURENII",
  "PAGLISA",
  "PALATCA",
  "PANICENI",
  "PANTICEU",
  "PATA",
  "PESTERA",
  "PETEA",
  "PETRESTI",
  "PETRESTII DE JOS",
  "PETRESTII DE MIJLOC",
  "PETRESTII DE SUS",
  "PIETROASA",
  "PINTIC",
  "PLAIESTI",
  "PLAIURI",
  "PLOPI",
  "PLOSCOS",
  "PODENI",
  "POIANA FRATII",
  "POIANA HOREA",
  "POIENI",
  "POPESTI",
  "PRELUCELE",
  "PRUNENI",
  "PRUNI",
  "PRUNIS",
  "PUINI",
  "PUSTUTA",
  "RACHITELE",
  "RADAIA",
  "RASCRUCI",
  "RAZBUNENI",
  "RAZOARE",
  "RECEA-CRISTUR",
  "REDIU",
  "RISCA",
  "ROGOJEL",
  "ROSIENI",
  "ROTUNDA",
  "RUGASESTI",
  "SACALAIA",
  "SACEL",
  "SACUIEU",
  "SALATIU",
  "SALATRUC",
  "SALICEA",
  "SALISCA",
  "SALISTE",
  "SALISTEA NOUA",
  "SALISTEA VECHE",
  "SAMBOIENI",
  "SAMBOLENI",
  "SANCRAIU",
  "SANDULESTI",
  "SANMARGHITA",
  "SANMARTIN",
  "SANNICOARA",
  "SANPAUL",
  "SANTEJUDE",
  "SANTEJUDE VALE",
  "SANTIOANA",
  "SARADIS",
  "SARATA",
  "SARDU",
  "SATU LUNG",
  "SAULA",
  "SAVA",
  "SAVADISLA",
  "SCRIND-FRASINET",
  "SIC",
  "SICFA",
  "SIGAU",
  "SILIVAS",
  "SMIDA",
  "SOIMENI",
  "SOMCUTU MIC",
  "SOMESU CALD",
  "SOMESU RECE",
  "SOPORU DE CAMPIE",
  "STARCU",
  "STEJERIS",
  "STOIANA",
  "STOLNA",
  "STRAJA",
  "STRAMBU",
  "STRUCUT",
  "SUARAS",
  "SUATU",
  "SUB COASTA",
  "SUCEAGU",
  "SUCUTARD",
  "SUMURDUCU",
  "SURDUC",
  "SUTU",
  "TAGA",
  "TARGUSOR",
  "TARPIU",
  "TAUSENI",
  "TAUTI",
  "TICU",
  "TICU-COLONIE",
  "TIOCU DE JOS",
  "TIOCU DE SUS",
  "TIOLTIUR",
  "TOPA MICA",
  "TRANISU",
  "TRITENII DE JOS",
  "TRITENII DE SUS",
  "TRITENII-HOTAR",
  "TURDA",
  "TUREA",
  "TURENI",
  "TURMASI",
  "UNGURAS",
  "URCA",
  "URISOR",
  "VAD",
  "VAIDA-CAMARAS",
  "VALCELE",
  "VALCELELE",
  "VALE",
  "VALEA AGRISULUI",
  "VALEA CALDA",
  "VALEA CASEIELULUI",
  "VALEA CIRESOII",
  "VALEA DRAGANULUI",
  "VALEA FLORILOR",
  "VALEA GARBOULUI",
  "VALEA GROSILOR",
  "VALEA IERII",
  "VALEA LUI CATI",
  "VALEA LUI OPRIS",
  "VALEA LUNCII",
  "VALEA UNGURASULUI",
  "VALEA VADULUI",
  "VALENI",
  "VALISOARA",
  "VANATORI",
  "VECHEA",
  "VIISOARA",
  "VISAGU",
  "VISEA",
  "VISTEA",
  "VLAHA",
  "VULTURENI",
  "ZORENII DE VALE",
];
COUNTIES_CITIES["BACAU"] = [
  "AGAS",
  "ALBELE",
  "ANTOHESTI",
  "APA ASAU",
  "ARDEOANI",
  "ARINI",
  "ASAU",
  "BACAU",
  "BACIOIU",
  "BACLESTI",
  "BAHNA",
  "BAHNASENI",
  "BAIMAC",
  "BALAIA",
  "BALANEASA",
  "BALANESTI",
  "BALCA",
  "BALCANI",
  "BALOTESTI",
  "BALTATA",
  "BALUSA",
  "BANCA",
  "BARATI",
  "BARBOASA",
  "BARCANA",
  "BARNA",
  "BARNESTI",
  "BARSANESTI",
  "BARTASESTI",
  "BARZULESTI",
  "BASASTI",
  "BAZGA",
  "BELCIUNEASA",
  "BELEGHET",
  "BENESTI",
  "BERBINCENI",
  "BERESTI",
  "BERESTI-BISTRITA",
  "BERESTI-TAZLAU",
  "BERZUNTI",
  "BIBIRESTI",
  "BIJGHIR",
  "BLAGA",
  "BLAGESTI",
  "BLIDARI",
  "BOANTA",
  "BOBOS",
  "BODEASA",
  "BOGATA",
  "BOGDAN VODA",
  "BOGDANA",
  "BOGDANESTI",
  "BOISTEA",
  "BOISTEA DE JOS",
  "BOLATAU",
  "BOLOVANIS",
  "BORSANI",
  "BORZESTI",
  "BOSIA",
  "BOSOTENI",
  "BOSTANESTI",
  "BOTA",
  "BOTESTI",
  "BRAD",
  "BRATESTI",
  "BRATILA",
  "BRUSTUROASA",
  "BUCHILA",
  "BUCIUMI",
  "BUCSA",
  "BUCSESTI",
  "BUDA",
  "BUDESTI",
  "BUHOCEL",
  "BUHOCI",
  "BUHUSI",
  "BURDUSACI",
  "BURUIENIS",
  "BURUIENISU DE SUS",
  "CABESTI",
  "CADARESTI",
  "CAIUTI",
  "CALAPODESTI",
  "CALCAI",
  "CALINESTI",
  "CALINI",
  "CALUGARENI",
  "CAMENCA",
  "CAMPENI",
  "CAPATA",
  "CAPOTESTI",
  "CARACLAU",
  "CARLIGI",
  "CASIN",
  "CAUIA",
  "CERDAC",
  "CERNU",
  "CETATUIA",
  "CHETRENI",
  "CHETRIS",
  "CHICEREA",
  "CHILIA BENEI",
  "CHITICENI",
  "CIOBANUS",
  "CIRESOAIA",
  "CIUCANI",
  "CIUGHES",
  "CIUMASI",
  "CIUTURESTI",
  "CLEJA",
  "CLIMESTI",
  "COCIU",
  "COLONESTI",
  "COMAN",
  "COMANESTI",
  "CONTESTI",
  "CORBASCA",
  "CORNATEL",
  "CORNATELU",
  "CORNESTI",
  "CORNET",
  "CORNII DE JOS",
  "CORNII DE SUS",
  "COSNEA",
  "COSTEI",
  "COTENI",
  "COTOFANESTI",
  "COTU GROSULUI",
  "COTUMBA",
  "CRAIESTI",
  "CRIHAN",
  "CUCHINIS",
  "CUCOVA",
  "CUCUIETI",
  "CURITA",
  "DADESTI",
  "DAMIENESTI",
  "DANAILA",
  "DARMANEASCA",
  "DARMANESTI",
  "DEALU MORII",
  "DEALU PERJULUI",
  "DEALU-MARE",
  "DELENI",
  "DIACONESTI",
  "DIENET",
  "DOFTEANA",
  "DORNENI",
  "DOROFEI",
  "DOSPINESTI",
  "DRAGESTI",
  "DRAGOMIR",
  "DRAGUGESTI",
  "DRAGUSANI",
  "DUMBRAVA",
  "ENACHESTI",
  "FAGET",
  "FAGETEL",
  "FAGETU DE SUS",
  "FAGHIENI",
  "FANTANELE",
  "FARAOANI",
  "FARCASA",
  "FERESTRAU-OITUZ",
  "FICHITESTI",
  "FILIPENI",
  "FILIPESTI",
  "FLORESTI",
  "FRUMOASA",
  "FRUMUSELU",
  "FRUNTESTI",
  "FULGERIS",
  "FUNDATURA",
  "FUNDATURA RACHITOASA",
  "FUNDENI",
  "FUNDOAIA",
  "FUNDU RACACIUNI",
  "FUNDU TUTOVEI",
  "FUNDU VAII",
  "FURNICARI",
  "GAICEANA",
  "GALBENI",
  "GALERI",
  "GARLA ANEI",
  "GARLENI",
  "GARLENII DE SUS",
  "GAZARIE",
  "GHEORGHE DOJA",
  "GHERDANA",
  "GHILAVESTI",
  "GHIMES",
  "GHIONOAIA",
  "GIOSENI",
  "GISTENI",
  "GIURGENI",
  "GIURGIOANA",
  "GLAVANESTI",
  "GLODISOARELE",
  "GODINESTII DE JOS",
  "GODINESTII DE SUS",
  "GOIOASA",
  "GORGHESTI",
  "GRADESTI",
  "GRIGORENI",
  "GURA CRAIESTI",
  "GURA VAII",
  "GUTINAS",
  "HAGHIAC",
  "HAINEALA",
  "HALMACIOAIA",
  "HANGANESTI",
  "HANTA",
  "HARLESTI",
  "HELEGIU",
  "HELTIU",
  "HEMEIUS",
  "HEMIENI",
  "HERTIOANA DE JOS",
  "HERTIOANA-RAZESI",
  "HIRJA",
  "HOLT",
  "HORGESTI",
  "HURUIESTI",
  "HUTU",
  "IAZ",
  "ILIESI",
  "ITCANI",
  "ITESTI",
  "IZVORU BERHECIULUI",
  "LAPOS",
  "LARGA",
  "LARGUTA",
  "LEHANCEA",
  "LEONTINESTI",
  "LESPEZI",
  "LETEA VECHE",
  "LICHITISENI",
  "LILIECI",
  "LIPOVA",
  "LIVEZI",
  "LUDASI",
  "LUIZI-CALUGARA",
  "LUNCA ASAU",
  "LUNCA DOCHIEI",
  "LUNCANI",
  "LUPESTI",
  "MAGAZIA",
  "MAGIRESTI",
  "MAGLA",
  "MAGURA",
  "MALOSU",
  "MANASTIREA CASIN",
  "MARASCU",
  "MARASTI",
  "MARCESTI",
  "MARGINEA",
  "MARGINENI",
  "MARVILA",
  "MATEIESTI",
  "MEDELENI",
  "MILESTII DE JOS",
  "MILESTII DE SUS",
  "MISIHANESTI",
  "MOINESTI",
  "MOTOC",
  "MOTOCESTI",
  "MOTOSENI",
  "MOVILITA",
  "MUNCELU",
  "NADISA",
  "NANESTI",
  "NASTASENI",
  "NAZARIOAIA",
  "NEGOIESTI",
  "NEGRENI",
  "NEGRI",
  "NEGULESTI",
  "NEGUSENI",
  "NICOLAE BALCESCU",
  "NICORESTI",
  "OBARSIA",
  "OCHENI",
  "ODOBESTI",
  "OITUZ",
  "ONCESTI",
  "ONCESTII VECHI",
  "ONESTI",
  "ONISCANI",
  "OPRISESTI",
  "ORASA",
  "ORBENI",
  "OSEBITI",
  "OTELESTI",
  "PADURENI",
  "PAGUBENI",
  "PAJISTEA",
  "PALANCA",
  "PALTINATA",
  "PALTINIS",
  "PANCESTI",
  "PARAU BOGHII",
  "PARAVA",
  "PARGARESTI",
  "PARINCEA",
  "PARJOL",
  "PERCHIU",
  "PETRESTI",
  "PETRICICA",
  "PIRVULESTI",
  "PLOPANA",
  "PLOPU",
  "PODEI",
  "PODIS",
  "PODU TURCULUI",
  "PODURI",
  "POGLET",
  "POIANA",
  "POIANA NEGUSTORULUI",
  "POIANA SARATA",
  "POIENI",
  "POPENI",
  "POPESTI",
  "POPOIU",
  "PRADAIS",
  "PRAJA",
  "PRAJESTI",
  "PRAJOAIA",
  "PRALEA",
  "PRELUCI",
  "PRISACA",
  "PROHOZESTI",
  "PUSTIANA",
  "PUTINI",
  "PUTREDENI",
  "RACACIUNI",
  "RACATAU DE JOS",
  "RACATAU-RAZESI",
  "RACAUTI",
  "RACHITIS",
  "RACHITISU",
  "RACHITOASA",
  "RACOVA",
  "RACUSANA",
  "RADEANA",
  "RADOAIA",
  "RADOMIRESTI",
  "RASTOACA",
  "RAZESU",
  "RECEA",
  "REPRIVAT",
  "ROGOAZA",
  "ROMANESTI",
  "ROSIORI",
  "ROTARIA",
  "RUNCU",
  "RUSENII DE SUS",
  "RUSENII RAZESI",
  "RUSI-CIUTEA",
  "SALATRUC",
  "SANDULENI",
  "SARATA",
  "SASCUT",
  "SASCUT-SAT",
  "SATU NOU",
  "SAUCESTI",
  "SCARIGA",
  "SCARISOARA",
  "SCHINENI",
  "SCHITU FRUMOASA",
  "SCORTENI",
  "SCURTA",
  "SCUTARU",
  "SEACA",
  "SECUIENI",
  "SENDRESTI",
  "SERBESTI",
  "SERPENI",
  "SESURI",
  "SIRBI",
  "SIRETU",
  "SLANIC-MOLDOVA",
  "SLOBOZIA",
  "SLOBOZIA NOUA",
  "SOCI",
  "SOHODOL",
  "SOHODOR",
  "SOLONT",
  "SOMUSCA",
  "SPRIA",
  "STANESTI",
  "STANISESTI",
  "STEFAN CEL MARE",
  "STEFAN VODA",
  "STEJARU",
  "STRAJA",
  "STRAMINOASA",
  "STRUGARI",
  "STUFU",
  "SULTA",
  "SURINA",
  "TAMASI",
  "TAMASOAIA",
  "TARDENII MARI",
  "TARGU OCNA",
  "TARGU TROTUS",
  "TARHAUSI",
  "TARITA",
  "TARNITA",
  "TATARASTI",
  "TAULA",
  "TAVADARESTI",
  "TEIUS",
  "TEMELIA",
  "TEPOAIA",
  "TESCANI",
  "TIGANESTI",
  "TIGIRA",
  "TISA",
  "TISA-SILVESTRI",
  "TOCHILEA",
  "TOMOZIA",
  "TRAIAN",
  "TREBES",
  "TURLUIANU",
  "TUTA",
  "UNGURENI",
  "URECHESTI",
  "URSOAIA",
  "VALCELE",
  "VALEA ARINILOR",
  "VALEA BOTULUI",
  "VALEA BUDULUI",
  "VALEA CASELOR",
  "VALEA FINATULUI",
  "VALEA HOGEI",
  "VALEA LUI ION",
  "VALEA LUPULUI",
  "VALEA MARE",
  "VALEA MARULUI",
  "VALEA MERILOR",
  "VALEA MICA",
  "VALEA MOSNEAGULUI",
  "VALEA NACULUI",
  "VALEA SALCIEI",
  "VALEA SEACA",
  "VALEA SOSII",
  "VALENI",
  "VERMESTI",
  "VERSESTI",
  "VIFORENI",
  "VIISOARA",
  "VLADNIC",
  "VRANCENI",
  "VULTURENI",
  "ZAPODIA",
  "ZEMES",
  "ZLATARI",
];
COUNTIES_CITIES["BRAILA"] = [
  "AGAUA",
  "ALBINA",
  "ARICIU",
  "BALDOVINESTI",
  "BANDOIU",
  "BARAGANUL",
  "BATOGU",
  "BERLESTI",
  "BERTESTII DE JOS",
  "BERTESTII DE SUS",
  "BOARCA",
  "BORDEI VERDE",
  "BRADEANCA",
  "BRAILA",
  "BRATESU VECHI",
  "BUMBACARI",
  "CAINENI-BAI",
  "CALDARUSA",
  "CAZASU",
  "CHICHINETU",
  "CHIOIBASESTI",
  "CHISCANI",
  "CIOCILE",
  "CIRESU",
  "CISTIA",
  "COLTEA",
  "COMANEASCA",
  "CONSTANTIN GABRIELESCU",
  "CONSTANTINESTI",
  "CORBENI",
  "CORBU NOU",
  "CORBU VECHI",
  "COTU LUNG",
  "COTU MIHALEA",
  "CUSTURA",
  "CUZA VODA",
  "DEDULESTI",
  "DESIRATI",
  "DROGU",
  "DUDESCU",
  "DUDESTI",
  "ESNA",
  "FAUREI",
  "FAUREI SAT",
  "FILIPESTI",
  "FILIU",
  "FLORICA",
  "FRECATEI",
  "GALBENU",
  "GARA IANCA",
  "GAVANI",
  "GEMENELE",
  "GRADISTEA",
  "GROPENI",
  "GULIANCA",
  "GURA CALMATUI",
  "GURA GIRLUTEI",
  "GURGUIETI",
  "HORIA",
  "IANCA",
  "IBRIANU",
  "INSURATEI",
  "IONESTI",
  "JIRLAU",
  "JUGUREANU",
  "LACU REZII",
  "LACU SARAT",
  "LANURILE",
  "LATINU",
  "LISCOTEANCA",
  "MAGURENI",
  "MARALOIU",
  "MARASU",
  "MARTACESTI",
  "MARU ROSU",
  "MAXINENI",
  "MIHAI BRAVU",
  "MIHAIL KOGALNICEANU",
  "MIRCEA VODA",
  "MOROTESTI",
  "MOVILA MIRESII",
  "MUCHEA",
  "NEDEICU",
  "NICOLAE BALCESCU",
  "NICOLESTI",
  "OANCEA",
  "ODAIENI",
  "OLANEASCA",
  "OPRISENESTI",
  "PANTECANI",
  "PERISORU",
  "PIETROIU",
  "PITULATI",
  "PLASOIU",
  "PLOPI",
  "PLOPU",
  "POLIZESTI",
  "PRIBEAGU",
  "RACOVITA",
  "RAMNICELU",
  "ROMANU",
  "ROSIORI",
  "SALCIA",
  "SALCIA TUDOR",
  "SATUC",
  "SCARLATESTI",
  "SCORTARU NOU",
  "SCORTARU VECHI",
  "SIHLEANU",
  "SILISTEA",
  "SILISTRARU",
  "SPIRU HARET",
  "STANCA",
  "STANCUTA",
  "STOIENESTI",
  "SURDILA-GAISEANCA",
  "SURDILA-GRECI",
  "SUTESTI",
  "TACAU",
  "TARLELE FILIU",
  "TATARU",
  "TEPES VODA",
  "TICHILESTI",
  "TITCOV",
  "TRAIAN",
  "TUDOR VLADIMIRESCU",
  "TUFESTI",
  "ULMU",
  "UNIREA",
  "URLEASCA",
  "VADENI",
  "VALEA CALMATUIULUI",
  "VALEA CANEPII",
  "VAMESU",
  "VARSATURA",
  "VICTORIA",
  "VISANI",
  "VIZIRU",
  "VOINESTI",
  "VULTURENI",
  "ZAMFIRESTI",
  "ZAVOAIA",
];
COUNTIES_CITIES["TULCEA"] = [
  "AGIGHIOL",
  "ALBA",
  "ARDEALU",
  "ATMAGEA",
  "BABADAG",
  "BAIA",
  "BALABANCEA",
  "BALTENII DE JOS",
  "BALTENII DE SUS",
  "BEIDAUD",
  "BESTEPE",
  "C.A. ROSETTI",
  "CALFA",
  "CAMENA",
  "CAPRIOARA",
  "CARAORMAN",
  "CARCALIU",
  "CARDON",
  "CARJELARI",
  "CASIMCEA",
  "CASLITA",
  "CATALOI",
  "CAUGAGIA",
  "CEAMURLIA DE JOS",
  "CEAMURLIA DE SUS",
  "CEATALCHIOI",
  "CERBU",
  "CERNA",
  "CHILIA VECHE",
  "CISMEAUA NOUA",
  "CIUCUROVA",
  "CLOSCA",
  "COLINA",
  "CORUGEA",
  "CRISAN",
  "DAENI",
  "DOROBANTU",
  "DUNAVATU DE JOS",
  "DUNAVATU DE SUS",
  "ENISALA",
  "FAGARASU NOU",
  "FANTANA MARE",
  "FANTANA OILOR",
  "FLORESTI",
  "FRECATEI",
  "GARVAN",
  "GENERAL PRAPORGESCU",
  "GORGOVA",
  "GRECI",
  "GRINDU",
  "HAIDAR",
  "HAMCEARCA",
  "HORIA",
  "I.C.BRATIANU",
  "IAZURILE",
  "ILGANII DE JOS",
  "ILGANII DE SUS",
  "ISACCEA",
  "IULIA",
  "IZVOARELE",
  "JIJILA",
  "JURILOVCA",
  "LASTUNI",
  "LETEA",
  "LUMINITA",
  "LUNCA",
  "LUNCAVITA",
  "MACIN",
  "MAGURELE",
  "MAHMUDIA",
  "MALCOCI",
  "MALIUC",
  "MESTERU",
  "MIHAI BRAVU",
  "MIHAIL KOGALNICEANU",
  "MILA 23",
  "MINA ALTAN TEPE",
  "MINERI",
  "MIRCEA VODA",
  "MURIGHIOL",
  "NALBANT",
  "NEATARNAREA",
  "NICOLAE BALCESCU",
  "NICULITEL",
  "NIFON",
  "NUFARU",
  "OSTROV",
  "OSTROVU TATARU",
  "PANDURU",
  "PARCHES",
  "PARDINA",
  "PARTIZANI",
  "PATLAGEANCA",
  "PECENEAGA",
  "PERIPRAVA",
  "PIATRA",
  "PLAURU",
  "PLOPUL",
  "POSTA",
  "RACHELU",
  "RAHMAN",
  "RANDUNICA",
  "RAZBOIENI",
  "REVARSAREA",
  "SABANGIA",
  "SALCENI",
  "SALCIOARA",
  "SAMBATA NOUA",
  "SARICHIOI",
  "SARIGHIOL DE DEAL",
  "SARINASUF",
  "SATU NOU",
  "SFANTU GHEORGHE",
  "SFISTOFCA",
  "SLAVA CERCHEZA",
  "SLAVA RUSA",
  "SMARDAN",
  "SOMOVA",
  "STANCA",
  "STEJARU",
  "SULINA",
  "TATANIR",
  "TELITA",
  "TICHILESTI",
  "TOPOLOG",
  "TRAIAN",
  "TRESTENIC",
  "TUDOR VLADIMIRESCU",
  "TULCEA",
  "TURCOAIA",
  "TURDA",
  "UZLINA",
  "VACARENI",
  "VALEA NUCARILOR",
  "VALEA TEILOR",
  "VASILE ALECSANDRI",
  "VICTORIA",
  "VISINA",
  "VISTERNA",
  "VULTURU",
  "ZEBIL",
];
COUNTIES_CITIES["BISTRITA-NASAUD"] = [
  "AGRIES",
  "AGRIESEL",
  "AGRISU DE JOS",
  "AGRISU DE SUS",
  "ALBESTII BISTRITEI",
  "ALUNISUL",
  "ANIES",
  "APATIU",
  "ARCALIA",
  "ARCHIUD",
  "ARDAN",
  "ARSITA",
  "BARLA",
  "BATA",
  "BECLEAN",
  "BEUDIU",
  "BICHIGIU",
  "BIDIU",
  "BISTRITA",
  "BISTRITA BARGAULUI",
  "BLAJENII DE JOS",
  "BLAJENII DE SUS",
  "BORLEASA",
  "BOZIES",
  "BRANISTEA",
  "BRATENI",
  "BREAZA",
  "BRETEA",
  "BUDACU DE JOS",
  "BUDACU DE SUS",
  "BUDESTI",
  "BUDESTI-FINATE",
  "BUDURLENI",
  "BUDUS",
  "BUNGARD",
  "BUZA CATUN",
  "CAIANU MARE",
  "CAIANU MIC",
  "CAILA",
  "CAMP",
  "CEPARI",
  "CHETIU",
  "CHINTELNIC",
  "CHIOCHIS",
  "CHIRALES",
  "CHIUZA",
  "CICEU-CORABIA",
  "CICEU-GIURGESTI",
  "CICEU-MIHAIESTI",
  "CICEU-POIENI",
  "CIOSA",
  "CIREASI",
  "CIRESOAIA",
  "COASTA",
  "COCIU",
  "COLDAU",
  "COLIBITA",
  "COMLOD",
  "CORMAIA",
  "CORVINESTI",
  "COSBUC",
  "COSERIU",
  "CRAINIMAT",
  "CRISTESTII CICEULUI",
  "CRISTUR-SIEU",
  "CUSMA",
  "DEALU STEFANITEI",
  "DELURENI",
  "DIPSA",
  "DOBRIC",
  "DOBRICEL",
  "DOMNESTI",
  "DOROLEA",
  "DRAGA",
  "DUMBRAVA",
  "DUMBRAVENI",
  "DUMBRAVITA",
  "DUMITRA",
  "DUMITRITA",
  "DUPA DEAL",
  "ENCIU",
  "FANATE",
  "FANATELE SILIVASULUI",
  "FANTANELE",
  "FANTANITA",
  "FELDRU",
  "FELEAC",
  "FIAD",
  "FIGA",
  "FLORESTI",
  "GALATII BISTRITEI",
  "GERSA I",
  "GERSA II",
  "GHEMES",
  "GHINDA",
  "GLEDIN",
  "HALMASAU",
  "HASMASU CICEULUI",
  "HERINA",
  "HIREAN",
  "ILISUA",
  "ILVA MARE",
  "ILVA MICA",
  "IVANEASA",
  "JEICA",
  "JELNA",
  "JIMBOR",
  "JOSENII BARGAULUI",
  "LA CURTE",
  "LECHINTA",
  "LELESTI",
  "LESU",
  "LIVEZILE",
  "LIVIU REBREANU",
  "LUNCA",
  "LUNCA BORLESEI",
  "LUNCA ILVEI",
  "LUNCA LESULUI",
  "LUNCA SATEASCA",
  "LUSCA",
  "MAGURA ILVEI",
  "MAGURELE",
  "MAIERU",
  "MALIN",
  "MALUT",
  "MANIC",
  "MARISELU",
  "MATEI",
  "MICESTII DE CAMPIE",
  "MIJLOCENII BARGAULUI",
  "MILAS",
  "MINTIU",
  "MIRES",
  "MITITEI",
  "MOCOD",
  "MOGOSENI",
  "MOLISET",
  "MONARIU",
  "MONOR",
  "MORUT",
  "MURESENII BARGAULUI",
  "NASAUD",
  "NEGRILESTI",
  "NEPOS",
  "NETENI",
  "NIMIGEA DE JOS",
  "NIMIGEA DE SUS",
  "NUSENI",
  "OARZINA",
  "OCNITA",
  "ORHEIU BISTRITEI",
  "OROSFAIA",
  "PALTINEASA",
  "PARVA",
  "PERISOR",
  "PETRIS",
  "PIATRA",
  "PIATRA FANTANELE",
  "PINTICU",
  "PODENII",
  "PODEREI",
  "PODIREI",
  "POIANA ILVEI",
  "POIENILE ZAGREI",
  "PORUMBENII",
  "POSMUS",
  "PRUNDU BARGAULUI",
  "PURCARETE",
  "RACATESU",
  "RAGLA",
  "REBRA",
  "REBRISOARA",
  "RETEAG",
  "RODNA",
  "ROMULI",
  "RUNCU SALVEI",
  "RUSTIOR",
  "RUSU BARGAULUI",
  "RUSU DE JOS",
  "RUSU DE SUS",
  "SALCUTA",
  "SALVA",
  "SANGEORZ-BAI",
  "SANGEORZU NOU",
  "SANIACOB",
  "SANMIHAIU DE CAMPIE",
  "SANNICOARA",
  "SANT",
  "SANTIOANA",
  "SARATA",
  "SARATEL",
  "SASARM",
  "SATU NOU",
  "SCOABE",
  "SEBIS",
  "SENDROAIA",
  "SESURI SPERMEZEU-VALE",
  "SIEU",
  "SIEU-MAGHERUS",
  "SIEU-ODORHEI",
  "SIEU-SFANTU",
  "SIEUT",
  "SIGMIR",
  "SILIVASU DE CAMPIE",
  "SIMIONESTI",
  "SINTEREAG",
  "SINTEREAG-GARA",
  "SIRIOARA",
  "SITA",
  "SLATINITA",
  "SOIMUS",
  "SOPTERIU",
  "SPERMEZEU",
  "STRAMBA",
  "STRUGURENI",
  "STUPINI",
  "SUPLAI",
  "SUSENII BARGAULUI",
  "TAGSORU",
  "TAGU",
  "TARLISUA",
  "TARPIU",
  "TAURE",
  "TEACA",
  "TELCISOR",
  "TELCIU",
  "TENTEA",
  "TIGAU",
  "TIHA BARGAULUI",
  "TONCIU",
  "TUREAC",
  "UNIREA",
  "URIU",
  "URMENIS",
  "VALEA",
  "VALEA BORCUTULUI",
  "VALEA MAGHERUSULUI",
  "VALEA MARE",
  "VALEA POENII",
  "VALEA VINULUI",
  "VERMES",
  "VIILE TECII",
  "VIISOARA",
  "VISUIA",
  "VITA",
  "ZAGRA",
  "ZORENI",
];
COUNTIES_CITIES["COVASNA"] = [
  "AITA MARE",
  "AITA MEDIE",
  "AITA SEACA",
  "ALBIS",
  "ALUNGENI",
  "ANGHELUS",
  "ANINOASA",
  "ARACI",
  "ARCUS",
  "ARIUSD",
  "BACEL",
  "BARAOLT",
  "BARCANI",
  "BATANII MARI",
  "BATANII MICI",
  "BELANI",
  "BELIN",
  "BELIN-VALE",
  "BIBORTENI",
  "BICFALAU",
  "BITA",
  "BIXAD",
  "BODOC",
  "BODOS",
  "BOROSNEU MARE",
  "BOROSNEU MIC",
  "BRADET",
  "BRADUT",
  "BRATES",
  "BRETCU",
  "CALNIC",
  "CAPENI",
  "CARPINENII",
  "CASINU MIC",
  "CATALINA",
  "CERNAT",
  "CHICHIS",
  "CHILIENI",
  "CHIURUS",
  "COMANDAU",
  "COSENI",
  "COVASNA",
  "CRASNA",
  "DALNIC",
  "DOBARLAU",
  "DOBOLII DE JOS",
  "DOBOLII DE SUS",
  "DOBOSENI",
  "ESTELNIC",
  "FILIA",
  "FLOROAIA",
  "FOTOS",
  "GHELINTA",
  "GHIDFALAU",
  "HAGHIG",
  "HARALE",
  "HATUICA",
  "HERCULIAN",
  "HETEA",
  "HILIB",
  "IARAS",
  "ICAFALAU",
  "ILIENI",
  "IMENI",
  "INTORSURA BUZAULUI",
  "LADAUTI",
  "LEMNIA",
  "LET",
  "LISNAU",
  "LISNAU-VALE",
  "LUNCA MARCUSULUI",
  "LUNCA OZUNULUI",
  "LUNGA",
  "LUTOASA",
  "MAGHERUS",
  "MALNAS",
  "MALNAS-BAI",
  "MARCUS",
  "MARCUSA",
  "MARTANUS",
  "MARTINENI",
  "MERENI",
  "MERISOR",
  "MICFALAU",
  "MICLOSOARA",
  "MOACSA",
  "OITUZ",
  "OJDULA",
  "OLTENI",
  "OZUN",
  "OZUNCA-BAI",
  "PACHIA",
  "PADURENI",
  "PAPAUTI",
  "PETENI",
  "PETRICENI",
  "POIAN",
  "RACOSUL DE SUS",
  "RECI",
  "SACIOVA",
  "SANCRAIU",
  "SANTIONLUNCA",
  "SANZIENI",
  "SARAMAS",
  "SCRADOASA",
  "SFANTU GHEORGHE",
  "SITA BUZAULUI",
  "SURCEA",
  "TALISOARA",
  "TAMASFALAU",
  "TARGU SECUIESC",
  "TELECHIA",
  "TUFALAU",
  "TURIA",
  "VALCELE",
  "VALEA CRISULUI",
  "VALEA DOBARLAULUI",
  "VALEA MARE",
  "VALEA MICA",
  "VALEA SCURTA",
  "VALEA SEACA",
  "VALEA ZALANULUI",
  "VARGHIS",
  "ZABALA",
  "ZABRATAU",
  "ZAGON",
  "ZALAN",
  "ZOLTAN",
];
COUNTIES_CITIES["GORJ"] = [
  "ALBENI",
  "ALEXENI",
  "ALIMPESTI",
  "ALUNIS",
  "ANDREESTI",
  "ANINISU DIN DEAL",
  "ANINISU DIN VALE",
  "ANINOASA",
  "APA NEAGRA",
  "ARCANI",
  "ARJOCI",
  "ARPADIA",
  "ARSENI",
  "ARSURI",
  "ARTANU",
  "BACESTI",
  "BADESTI",
  "BAIA DE FIER",
  "BALACESTI",
  "BALANESTI",
  "BALANI",
  "BALCESTI",
  "BALESTI",
  "BALOSANI",
  "BALTA",
  "BALTENI",
  "BALTISOARA",
  "BANIU",
  "BARBATESTI",
  "BARCACIU",
  "BARSESTI",
  "BARZA",
  "BARZEIU",
  "BARZEIU DE GILORT",
  "BAZAVANI",
  "BECHENI",
  "BENGESTI",
  "BERCESTI",
  "BERLESTI",
  "BIBESTI",
  "BIBULESTI",
  "BIRCII",
  "BLAHNITA DE JOS",
  "BLAHNITA DE SUS",
  "BLIDARI",
  "BOBAIA",
  "BOBOIESTI",
  "BOBU",
  "BOCA",
  "BOHOREL",
  "BOIA",
  "BOJINU",
  "BOLBOASA",
  "BOLBOCESTI",
  "BOLBOSI",
  "BORASCU",
  "BOROSTENI",
  "BOTOROGI",
  "BRADET",
  "BRADETEL",
  "BRADICENI",
  "BRANESTI",
  "BRATESTI",
  "BRATUIA",
  "BREBENEI",
  "BROSTENI",
  "BROSTENII DE SUS",
  "BUCSANA",
  "BUCUREASA",
  "BUDIENI",
  "BUDUHALA",
  "BULBUCENI",
  "BUMBESTI-JIU",
  "BUMBESTI-PITIC",
  "BURLANI",
  "BUSTUCHIN",
  "BUSUIOCI",
  "BUZESTI",
  "CALAPARU",
  "CALCESTI",
  "CALESTI",
  "CALNIC",
  "CALNICU DE SUS",
  "CALUGAREASA",
  "CALUGARENI",
  "CAMPOFENI",
  "CAMPU MARE",
  "CAMUIESTI",
  "CANEPESTI",
  "CAPRENI",
  "CAPU DEALULUI",
  "CARBESTI",
  "CARBUNESTI-SAT",
  "CARCIU",
  "CARLIGEI",
  "CARPINIS",
  "CARTIU",
  "CATUNELE",
  "CEAURU",
  "CELEI",
  "CEPLEA",
  "CERATU DE COPACIOASA",
  "CERNA SAT",
  "CERNADIA",
  "CETATEA",
  "CHICIORA",
  "CHILIU",
  "CIOCADIA",
  "CIONTI",
  "CIORARI",
  "CIUPERCENI",
  "CIUPERCENII DE OLTET",
  "CLESNESTI",
  "CLOSANI",
  "COCORENI",
  "COCOROVA",
  "COJANI",
  "COJMANESTI",
  "COLIBASI",
  "COLTESTI",
  "CONDEIESTI",
  "COPACIOASA",
  "CORNESTI",
  "CORNETU",
  "COROBAI",
  "CORSORU",
  "COSTENI",
  "COSTESTI",
  "COVRIGI",
  "CRASNA",
  "CRASNA DIN DEAL",
  "CRETESTI",
  "CROICI",
  "CRUSET",
  "CURPEN",
  "CURSARU",
  "CURTEANA",
  "CURTISOARA",
  "DAMBOVA",
  "DANCIULESTI",
  "DANESTI",
  "DEALU LEULUI",
  "DEALU POMILOR",
  "DEALU SPIREI",
  "DEALU VIEI",
  "DEALU VIILOR",
  "DELENI",
  "DIDILESTI",
  "DOBRANA",
  "DOBRITA",
  "DOLCESTI",
  "DOSENI",
  "DRAGOENI",
  "DRAGOIESTI",
  "DRAGOTESTI",
  "DRAGUTESTI",
  "DUCULESTI",
  "DUMBRAVENI",
  "FANTANELE",
  "FARCASESTI",
  "FARCASESTI MOSNENI",
  "FLORESTENI",
  "FLORESTI",
  "FRANCESTI",
  "FRASIN",
  "FRATESTI",
  "FRUMUSEI",
  "FRUNZA",
  "GALCESTI",
  "GALESOAIA",
  "GAMANI",
  "GARBOVU",
  "GAVANESTI",
  "GILORTU",
  "GLODENI",
  "GLOGOVA",
  "GODINESTI",
  "GORNACEL",
  "GORNOVITA",
  "GROSEREA",
  "GRUI",
  "GURA MENTI",
  "GURA SUSITEI",
  "GURENI",
  "HAIESTI",
  "HALANGESTI",
  "HIRISESTI",
  "HOBITA",
  "HODOREASCA",
  "HORASTI",
  "HOREZU",
  "HOTAROASA",
  "HUREZANI",
  "IASI-GORJ",
  "IEZURENI",
  "IGIROSU",
  "ILIESTI",
  "INSURATEI",
  "IONESTI",
  "IORMANESTI",
  "ISVARNA",
  "IZVOARELE",
  "JERISTEA",
  "JILTU",
  "JUPANESTI",
  "LARGA",
  "LAZARESTI",
  "LAZURI",
  "LELESTI",
  "LEURDA",
  "LICURICI",
  "LIHULESTI",
  "LINTEA",
  "LOGRESTI MOSTENI",
  "LUPOAIA",
  "LUPOITA",
  "MACESU",
  "MAGHERESTI",
  "MAIAG",
  "MARINESTI",
  "MARU",
  "MATASARI",
  "MAZAROI",
  "MENTII DIN DOS",
  "MERFULESTI",
  "MICLOSU",
  "MICULESTI",
  "MIELUSEI",
  "MIEREA",
  "MIERICEAUA",
  "MILUTA",
  "MIROSLOVENI",
  "MOGOSANI",
  "MOI",
  "MOTORGI",
  "MOTRU",
  "MOTRU SEC",
  "MURGESTI",
  "MURGILESTI",
  "MUSCULESTI",
  "MUSETESTI",
  "NAMETE",
  "NEGOIESTI",
  "NEGOMIR",
  "NEGRENI",
  "NISTORESTI",
  "NOVACI",
  "NUCETU",
  "OBARSIA",
  "OBREJA",
  "OHABA",
  "OHABA JIU",
  "OLARI",
  "OLTEANU",
  "ORZESTI",
  "ORZU",
  "PADES",
  "PAISANI",
  "PAJISTELE",
  "PALTINU",
  "PARAU DE PRIPOR",
  "PARAU DE VALE",
  "PARAU VIU",
  "PARIU",
  "PARIU BOIA",
  "PARVULESTI",
  "PEGENI",
  "PESTEANA DE JOS",
  "PESTEANA JIU",
  "PESTEANA VULCAN",
  "PESTISANI",
  "PETRACHEI",
  "PETRESTI",
  "PICU",
  "PIEPTANI",
  "PINOASA",
  "PISCOIU",
  "PISCURI",
  "PISTESTII DIN DEAL",
  "PLESA",
  "PLOPSORU",
  "PLOPU",
  "PLOSTINA",
  "POCIOVALISTEA",
  "POCRUIA",
  "POIANA",
  "POIANA SECIURI",
  "POIENARI",
  "POIENITA",
  "POJARU",
  "POJOGENI",
  "POLATA",
  "POLOVRAGI",
  "POPESTI",
  "POPESTI STEJARI",
  "PREAJBA MARE",
  "PRIGORIA",
  "PRIPORU",
  "PRUNESTI",
  "PURCARU",
  "RACHITI",
  "RACI",
  "RACOTI",
  "RACOVITA",
  "RADINESTI",
  "RADOSI",
  "RAPA",
  "RASOVA",
  "RASOVITA",
  "RATEZ",
  "ROGOJEL",
  "ROGOJENI",
  "ROMANESTI",
  "ROSIA DE AMARADIA",
  "ROSIA JIU",
  "ROSIUTA",
  "ROVINARI",
  "RUGET",
  "RUGI",
  "RUNCU",
  "RUNCUREL",
  "SACELU",
  "SAMARINESTI",
  "SAMBOTIN",
  "SANATESTI",
  "SARBESTI",
  "SARDANESTI",
  "SASA",
  "SATU NOU",
  "SAULESTI",
  "SCHELA",
  "SCOARTA",
  "SCORUSU",
  "SCRADA",
  "SCURTU",
  "SEACA",
  "SECIURILE",
  "SEUCA",
  "SIACU",
  "SIPOTU",
  "SITESTI",
  "SITOAIA",
  "SLAMNESTI",
  "SLAVUTA",
  "SLIVILESTI",
  "SLOBOZIA",
  "SOCU",
  "SOHODOL",
  "SOMANESTI",
  "SPAHII",
  "STANCESTI",
  "STANCESTI LARGA",
  "STANESTI",
  "STEFANESTI",
  "STEIC",
  "STEJARI",
  "STEJARU",
  "STEJEREI",
  "STERPOAIA",
  "STIUCANI",
  "STOINA",
  "STOLOJANI",
  "STRAMBA JIU",
  "STRAMBA VULCAN",
  "STRAMTU",
  "STROIESTI",
  "SURA",
  "SUSENI",
  "TALPASESTI",
  "TALVESTI",
  "TAMASESTI",
  "TANTARENI",
  "TARCULESTI",
  "TARGU CARBUNESTI",
  "TARGU JIU",
  "TARGU LOGRESTI",
  "TEHOMIR",
  "TELESTI",
  "TETILA",
  "TICLENI",
  "TIMISENI",
  "TIRIOI",
  "TISMANA",
  "TOIAGA",
  "TOPESTI",
  "TOTEA",
  "TOTEA DE HUREZANI",
  "TRESTIOARA",
  "TROCANI",
  "TURBUREA",
  "TURCENI",
  "TURCINESTI",
  "ULMET",
  "UNGURENI",
  "URDA DE JOS",
  "URDA DE SUS",
  "URDARI",
  "URECHESTI",
  "URSATI",
  "URSOAIA",
  "VACAREA",
  "VAGIULESTI",
  "VAIDEI",
  "VAIENI",
  "VALARI",
  "VALCEAUA",
  "VALCELE",
  "VALEA",
  "VALEA BISERICII",
  "VALEA CU APA",
  "VALEA DESULUI",
  "VALEA MANASTIRII",
  "VALEA MARE",
  "VALEA MICA",
  "VALEA MOTRULUI",
  "VALEA PERILOR",
  "VALEA POIENII",
  "VALEA POJARULUI",
  "VALEA RACILOR",
  "VALEA VIEI",
  "VALENI",
  "VALUTA",
  "VANATA",
  "VART",
  "VARTOPU",
  "VIDIN",
  "VIERSANI",
  "VLADIMIR",
  "VLADULENI",
  "VOINIGESTI",
  "VOITESTII DE VALE",
  "VOITESTII DIN DEAL",
  "ZAICOIU",
  "ZORLESTI",
  "ZORZILA",
];
COUNTIES_CITIES["TELEORMAN"] = [
  "ALBENI",
  "ALBESTI",
  "ALEXANDRIA",
  "ANTONESTI",
  "BABAITA",
  "BACALESTI",
  "BACIU",
  "BADULEASA",
  "BALACI",
  "BALDOVINESTI",
  "BALTA SARATA",
  "BANEASA",
  "BANOV",
  "BARSESTII DE JOS",
  "BASCOVENI",
  "BECIU",
  "BEIU",
  "BELCIUG",
  "BEUCA",
  "BLEJESTI",
  "BOGDANA",
  "BOTOROAGA",
  "BRAGADIRU",
  "BRANCENI",
  "BRANCOVEANCA",
  "BRATASANI",
  "BRATCOVU",
  "BRATESTI",
  "BREBINA",
  "BROSTEANCA",
  "BUJORENI",
  "BUJORU",
  "BURDENI",
  "BUTCULESTI",
  "BUTESTI",
  "BUZESCU",
  "CALINESTI",
  "CALMATUIU",
  "CALMATUIU DE SUS",
  "CALOMFIRESTI",
  "CALUGARU",
  "CARAVANETI",
  "CARLOMANU",
  "CATUNU",
  "CERNETU",
  "CERVENIA",
  "CETATEA",
  "Ciolanesti",
  "CIOLANESTII DIN DEAL",
  "CIOLANESTII DIN VALE",
  "CIUPERCENI",
  "CIURARI",
  "CIURARI-DEAL",
  "CLANITA",
  "COMOARA",
  "CONTESTI",
  "COPACEANCA",
  "COSMESTI",
  "COSOAIA",
  "COSOTENI",
  "CRANGENI",
  "CRANGU",
  "CREVENICU",
  "CUCUETI",
  "DARVAS",
  "DEPARATI",
  "DIDESTI",
  "DOBRENI",
  "DOBROTESTI",
  "DOROBANTU",
  "DRACEA",
  "DRACESTI",
  "DRACSANI",
  "DRACSENEI",
  "DRAGANESTI DE VEDE",
  "DRAGANESTI-VLASCA",
  "DRAGHINESTI",
  "DUDU",
  "DULCEANCA",
  "DULCENI",
  "FANTANELE",
  "FLORICA",
  "FRASINET",
  "FRUMOASA",
  "FURCULESTI",
  "GALATENI",
  "GARAGAU",
  "GARDESTI",
  "GRADISTEANCA",
  "GRATIA",
  "GRESIA",
  "GURUIENI",
  "INSURATEI",
  "IONASCU",
  "ISLAZ",
  "IZVOARELE",
  "LACENI",
  "LADA",
  "LICURICIU",
  "LINIA COSTII",
  "LISA",
  "LITA",
  "LUDANEASCA",
  "LUNCA",
  "MAGURA",
  "MAGURA CU LILIAC",
  "MALDAENI",
  "MARITA",
  "MARZANESTI",
  "MAVRODIN",
  "MERENII DE JOS",
  "MERENII DE SUS",
  "MERI",
  "MERISANI",
  "MOLDOVENI",
  "MOSTENI",
  "NANOV",
  "NASTURELU",
  "NAVODARI",
  "NECSESTI",
  "NEGRENI",
  "NEGRENII DE SUS",
  "NEGRENII-OSEBITI",
  "NEGRILESTI",
  "NENCIULESTI",
  "NICOLAE BALCESCU",
  "OBARTU",
  "ODOBEASCA",
  "OLTEANCA",
  "OLTENI",
  "ORBEASCA DE JOS",
  "ORBEASCA DE SUS",
  "PARU ROTUND",
  "PAULEASCA",
  "PERETU",
  "PERII BROSTENI",
  "PIATRA",
  "PIETROSANI",
  "PLEASOV",
  "PLOPI",
  "PLOPII-SLAVITESTI",
  "PLOSCA",
  "POENI",
  "POIANA",
  "POROSCHIA",
  "PREAJBA",
  "PRUNARU",
  "PRUNDU",
  "PURANI",
  "PURANII DE SUS",
  "PUTINEIU",
  "RADOIESTI-DEAL",
  "RADOIESTI-VALE",
  "RADULESTI",
  "RASMIRESTI",
  "ROSIORI DE VEDE",
  "ROTARESTI",
  "SACENI",
  "SAELELE",
  "SALCIA",
  "SARBENI",
  "SARBENII DE JOS",
  "SATU NOU",
  "SATUL VECHI",
  "SCHITU POIENARI",
  "SCRIOASTEA",
  "SCURTU MARE",
  "SCURTU-SLAVESTI",
  "SEACA",
  "SECARA",
  "SEGARCEA-DEAL",
  "SEGARCEA-VALE",
  "SERICU",
  "SFINTESTI",
  "SILISTEA",
  "SILISTEA MICA",
  "SILISTEA-GUMESTI",
  "SLAVESTI",
  "SLOBOZIA MANDRA",
  "SMARDAN",
  "SMARDIOASA",
  "SOCETU",
  "SOIMU",
  "SPATAREI",
  "STEFENI",
  "STEJARU",
  "STOROBANEASA",
  "SUHAIA",
  "TALPA POSTEI",
  "TALPA-BASCOVENI",
  "TALPA-OGRAZILE",
  "TARNAVA",
  "TATARASTII DE JOS",
  "TATARASTII DE SUS",
  "TAVARLAU",
  "TECUCI",
  "TELEORMANU",
  "TIGANESTI",
  "TRAIAN",
  "TRIVALEA-MOSTENI",
  "TROIANUL",
  "TUDOR VLADIMIRESCU",
  "TUNARI",
  "TURNU MAGURELE",
  "UDA-CLOCOCIOV",
  "UDA-PACIUREA",
  "UDENI",
  "UDUPU",
  "ULMENI",
  "URLUIU",
  "VACARESTI",
  "VACENI",
  "VALEA CIRESULUI",
  "VALEA PARULUI",
  "VALEA POSTEI",
  "VANATORI",
  "VARTOAPELE DE JOS",
  "VARTOAPELE DE SUS",
  "VATASI",
  "VATRA",
  "VEDEA",
  "VIDELE",
  "VIILE",
  "VIISOARA",
  "VITANESTI",
  "VOIEVODA",
  "ZAMBREASCA",
  "ZIMNICEA",
  "ZIMNICELE",
  "ZLATA",
];
COUNTIES_CITIES["BUZAU"] = [
  "ALBESTI",
  "ALDENI",
  "ALEXANDRU ODOBESCU",
  "ALICENI",
  "ALUNIS",
  "AMARA",
  "AMARU",
  "ANINI",
  "ARBANASI",
  "ARCANU",
  "BABENI",
  "BACENI",
  "BADENI",
  "BADILA",
  "BAESTI",
  "BAILE",
  "BAJANI",
  "BALACEANU",
  "BALAIA",
  "BALANESTI",
  "BALHACU",
  "BALTA ALBA",
  "BALTA TOCILA",
  "BALTAGARI",
  "BALTARETI",
  "BALTENI",
  "BANITA",
  "BARASTI",
  "BARBUNCESTI",
  "BASCA CHIOJDULUI",
  "BASCA ROZILEI",
  "BASCENII DE JOS",
  "BASCENII DE SUS",
  "BATOGU",
  "BECENI",
  "BECIU",
  "BEGU",
  "BEILIC",
  "BENTU",
  "BERCA",
  "BERCESTI",
  "BESLII",
  "BISOCA",
  "BLAJANI",
  "BOBOC",
  "BODINESTI",
  "BOLDU",
  "BORDUSANI",
  "BOZIORU",
  "BRADEANCA",
  "BRADEANU",
  "BRAESTI",
  "BRAGAREASA",
  "BRATILESTI",
  "BREAZA",
  "BREBU",
  "BUDA",
  "BUDA CRACIUNESTI",
  "BUDESTI",
  "BUDISTENI",
  "BUDREA",
  "BUDUILE",
  "BUSTEA",
  "BUZAU",
  "C.A. ROSETTI",
  "CALDARASTI",
  "CALDARUSA",
  "CALDARUSEANCA",
  "CALEA CHIOJDULUI",
  "CALTESTI",
  "CALTUNA",
  "CALVINI",
  "CAMPENI",
  "CAMPULUNGEANCA",
  "CANDESTI",
  "CANESTI",
  "CAPATANESTI",
  "CAPU SATULUI",
  "CARAGELE",
  "CARATNAU DE JOS",
  "CARATNAU DE SUS",
  "CARLIGU MARE",
  "CARLIGU MIC",
  "CARLOMANESTI",
  "CARPINISTEA",
  "CASOCA",
  "CASOTA",
  "CATIASU",
  "CATINA",
  "CEAIRU",
  "CERNATESTI",
  "CHILIILE",
  "CHIOJDU",
  "CHIPERU",
  "CHIRLESTI",
  "CILIBIA",
  "CIOBANOAIA",
  "CIOCANESTI",
  "CIORANCA",
  "CIRESU",
  "CISLAU",
  "CIUTA",
  "CLONDIRU",
  "CLONDIRU DE SUS",
  "COCA-ANTIMIRESTI",
  "COCA-NICULESTI",
  "COCHIRLEANCA",
  "COCIRCENI",
  "COCONARI",
  "COJANU",
  "COLIBASI",
  "COLTANENI",
  "COLTENI",
  "COLTI",
  "COLTII DE JOS",
  "COLTU PIETRII",
  "COMISOAIA",
  "CORBU",
  "CORNEANU",
  "COSTESTI",
  "COSTIENI",
  "COSTOMIRU",
  "COTATCU",
  "COTORCA",
  "COTU CIORII",
  "COZIENI",
  "CRANG",
  "CREVELESTI",
  "CUCULEASA",
  "CURCANESTI",
  "CURMATURA",
  "DALMA",
  "DAMBROCA",
  "DANULESTI",
  "DARA",
  "DASCALESTI",
  "DEALUL VIEI",
  "DEDULESTI",
  "DELENI",
  "DOBRILESTI",
  "DOGARI",
  "DULBANU",
  "FANTANELE",
  "FATA LUI NAN",
  "FINTESTI",
  "FISICI",
  "FLORESTI",
  "FLORICA",
  "FOCSANEI",
  "FOTIN",
  "FRASINET",
  "FULGA",
  "FUNDATA",
  "FUNDATURILE",
  "FUNDENI",
  "FURTUNESTI",
  "GAGENI",
  "GALBINASI",
  "GARA BOBOCU",
  "GARA CILIBIA",
  "GAVANELE",
  "GAVANESTI",
  "GHERASENI",
  "GHERGHEASA",
  "GHIOCARI",
  "GHIZDITA",
  "GLODEANU SARAT",
  "GLODEANU-SILISTEA",
  "GLODU-PETCARI",
  "GLODURILE",
  "GOICELU",
  "GOIDESTI",
  "GOLU GRABICINA",
  "GOMOESTI",
  "GONTESTI",
  "GORANI",
  "GORNET",
  "GRABICINA DE JOS",
  "GRABICINA DE SUS",
  "GRAJDANA",
  "GREBANU",
  "GRECEANCA",
  "GRESIA",
  "GROSANI",
  "GURA BADICULUI",
  "GURA BASCEI",
  "GURA CALNAULUI",
  "GURA DIMIENII",
  "GURA FAGETULUI",
  "GURA SARATII",
  "GURA SIRIULUI",
  "GURA TEGHII",
  "GURA VAII",
  "HALES",
  "HELIADE RADULESCU",
  "HOMESTI",
  "ILEANA",
  "ISTRITA DE JOS",
  "IVANETU",
  "IZVOARELE",
  "IZVORANU",
  "IZVORU",
  "IZVORU DULCE",
  "JGHIAB",
  "JOSENI",
  "LACU",
  "LACU CU ANINI",
  "LACU SINAIA",
  "LACURILE",
  "LANURILE",
  "LARGU",
  "LEICULESTI",
  "LERA",
  "LIMPEZIS",
  "LIPANESCU",
  "LIPIA",
  "LIVADA",
  "LIVADA MICA",
  "LOPATAREASA",
  "LOPATARI",
  "LUCIU",
  "LUNCA",
  "LUNCA FRUMOASA",
  "LUNCA JARISTEI",
  "LUNCA PRIPORULUI",
  "LUNCILE",
  "LUNGESTI",
  "MACRINA",
  "MAGURA",
  "MAGURICEA",
  "MANASIA",
  "MANASTIREA",
  "MANASTIREA RATESTI",
  "MANZALESTI",
  "MANZU",
  "MARACINENI",
  "MARGARITESTI",
  "MARGARITI",
  "MARGINEANU",
  "MARUNTISU",
  "MATESTI",
  "MAXENU",
  "MEREI",
  "MIEREA",
  "MIHAILESTI",
  "MITROPOLIA",
  "MLAJET",
  "MODRENI",
  "MOISICA",
  "MOSESTI",
  "MOVILA BANULUI",
  "MOVILA OII",
  "MOVILITA",
  "MUCESTI-DANULESTI",
  "MURGESTI",
  "MUSCEL",
  "MUSCELU CARAMANESTI",
  "MUSCELUSA",
  "NAENI",
  "NEGOSINA",
  "NEHOIASU",
  "NEHOIU",
  "NEMERTEA",
  "NENCIU",
  "NENCIULESTI",
  "NICOLESTI",
  "NICULESTI",
  "NISCOV",
  "NISTORESTI",
  "NUCU",
  "ODAIA BANULUI",
  "ODAILE",
  "OGRAZILE",
  "OJASCA",
  "OLARI",
  "OLESESTI",
  "ORATIA",
  "OREAVUL",
  "PACLELE",
  "PACURILE",
  "PADINA",
  "PADURENII",
  "PALICI",
  "PALTINENI",
  "PALTINIS",
  "PANATAU",
  "PARDOSI",
  "PARJOLESTI",
  "PARSCOV",
  "PARSCOVELU",
  "PATARLAGELE",
  "PESTRITU",
  "PETRACHESTI",
  "PETRISORU",
  "PIATRA ALBA",
  "PIETRARU",
  "PIETROASA MICA",
  "PIETROASELE",
  "PIETROSU",
  "PINU",
  "PITULICEA",
  "PLAISOR",
  "PLAIU NUCULUI",
  "PLAVATU",
  "PLESCIOARA",
  "PLESCOI",
  "PLESESTI",
  "PLESI",
  "PLEVNA",
  "PLOPEASA",
  "PLOPI",
  "PLOSTINA",
  "PODGORIA",
  "PODU MUNCII",
  "POENITELE",
  "POGOANELE",
  "POGONELE",
  "POIANA PLETARI",
  "POIANA VILCULUI",
  "POIENILE",
  "POLICIORI",
  "POSOBESTI",
  "POSTA",
  "POSTA CALNAU",
  "POTARNICHESTI",
  "POTECU",
  "POTOCENI",
  "PROSCA",
  "PRUNENI",
  "PUIESTII DE JOS",
  "PUIESTII DE SUS",
  "PUNGA",
  "RACOVITENI",
  "RADUCESTI",
  "RAMNICELU",
  "RAMNICU SARAT",
  "RATESTI",
  "RECEA",
  "RIPILE",
  "ROBEASCA",
  "ROBESTI",
  "ROSIORU",
  "RUBLA",
  "RUGINOASA",
  "RUNCU",
  "RUSAVAT",
  "RUSETU",
  "SAGEATA",
  "SAHATENI",
  "SALCIA",
  "SALCIOARA",
  "SAPOCA",
  "SARANGA",
  "SARATA",
  "SARATA-MONTEORU",
  "SARBESTI",
  "SARENI",
  "SARILE",
  "SARILE-CATUN",
  "SARULESTI",
  "SASENII NOI",
  "SASENII PE VALE",
  "SASENII VECHI",
  "SATU NOU",
  "SATU VECHI",
  "SATUC",
  "SCAENI",
  "SCARISOARA",
  "SCARLATESTI",
  "SCOROSESTI",
  "SCORTEANCA",
  "SCORTOASA",
  "SCURTESTI",
  "SCUTELNICI",
  "SECUIU",
  "SERGENT IONEL STEFAN",
  "SIBICIU DE JOS",
  "SIBICIU DE SUS",
  "SINDRILA",
  "SLOBOZIA",
  "SMARDAN",
  "SMEENI",
  "SMEESTI",
  "SORESTI",
  "SPATARU",
  "SPIDELE",
  "STALPU",
  "STANCESTI",
  "STANILA",
  "STAVARASTI",
  "STIUBEI",
  "STREZENI",
  "STROESTI",
  "SUCHEA",
  "SUDITI",
  "TABACARI",
  "TABARASTI",
  "TARCOV",
  "TARLELE",
  "TATARLIGU",
  "TATULESTI",
  "TEGA",
  "TEISU",
  "TERCA",
  "TINTESTI",
  "TISAU",
  "TOCILENI",
  "TOPLICENI",
  "TOROPALESTI",
  "TRESTIA",
  "TRESTIENI",
  "TRESTIOARA",
  "TRONARI",
  "TULBUREA",
  "UDATI-LUCIENI",
  "UDATI-MANZU",
  "ULMENI",
  "ULMET",
  "UNGURIU",
  "URSOAIA",
  "VACAREASCA",
  "VADU OII",
  "VADU PASII",
  "VADU SORESTI",
  "VALCELE",
  "VALCELELE",
  "VALEA BANULUI",
  "VALEA CATINEI",
  "VALEA COTOAREI",
  "VALEA FANTANEI",
  "VALEA LARGA",
  "VALEA LARGA-SARULESTI",
  "VALEA LUI LALU",
  "VALEA LUPULUI",
  "VALEA NUCULUI",
  "VALEA PARULUI",
  "VALEA PURCARULUI",
  "VALEA PUTULUI MEREI",
  "VALEA RAMNICULUI",
  "VALEA RATEI",
  "VALEA ROATEI",
  "VALEA SALCIEI",
  "VALEA SALCIEI-CATUN",
  "VALEA SALCIILOR",
  "VALEA SCHIOPULUI",
  "VALEA SIBICIULUI",
  "VALEA STEFANULUI",
  "VALEA STINEI",
  "VALEA URSULUI",
  "VALEA VERZEI",
  "VALEA VIEI",
  "VALEANCA-VILANESTI",
  "VARF",
  "VARLAAM",
  "VARTEJU",
  "VAVALUCILE",
  "VERNESTI",
  "VIFORATA",
  "VINETISU",
  "VINTILA VODA",
  "VINTILEANCA",
  "VIPERESTI",
  "VISPESTI",
  "VIZIRENI",
  "VLADENI",
  "ZAHARESTI",
  "ZAPLAZI",
  "ZAPODIA",
  "ZARNESTI",
  "ZARNESTII DE SLANIC",
  "ZELETIN",
  "ZIDURI",
  "ZILISTEANCA",
  "ZOITA",
  "ZORESTI",
];
COUNTIES_CITIES["IASI"] = [
  "ALBESTI",
  "ALEXANDRU CEL BUN",
  "ALEXANDRU I. CUZA",
  "ALEXENI",
  "ALEXESTI",
  "ANDRIESENI",
  "ARAMA",
  "ARONEANU",
  "AVANTU",
  "BACU",
  "BADENI",
  "BAHLUIU",
  "BAICENI",
  "BALCIU",
  "BALS",
  "BALTATI",
  "BALTENI",
  "BALUSESTI",
  "BANU",
  "BARBATESTI",
  "BARLESTI",
  "BARNOVA",
  "BELCESTI",
  "BEREZLOGI",
  "BIVOLARI",
  "BLAGA",
  "BLAGESTI",
  "BOATCA",
  "BOCNITA",
  "BODESTI",
  "BOGDANESTI",
  "BOGONOS",
  "BOHOTIN",
  "BOJILA",
  "BOLDESTI",
  "BOROSESTI",
  "BOROSOAIA",
  "BORSA",
  "BOSIA",
  "BOSTENI",
  "BOURENI",
  "BRADICESTI",
  "BRAESTI",
  "BRAN",
  "BRATESTI",
  "BRATULENI",
  "BRATULESTI",
  "BREAZU",
  "BROSTENI",
  "BUDA",
  "BUDAI",
  "BUDESTI",
  "BUHAENI",
  "BUHALNITA",
  "BULBUCANI",
  "BURSUC-DEAL",
  "BURSUC-VALE",
  "BURUIENESTI",
  "BUTEA",
  "BUZDUG",
  "BUZNEA",
  "CARBUNARI",
  "CARJOAIA",
  "CARLIG",
  "CARNICENI",
  "CAUESTI",
  "CEPLENITA",
  "CERCU",
  "CHICEREA",
  "CHILISOAIA",
  "CHIPERESTI",
  "CHISCARENI",
  "CILIBIU",
  "CIOCA-BOCA",
  "CIOCARLESTI",
  "CIOHORANI",
  "CIORTESTI",
  "CIRESENI",
  "CIURBESTI",
  "CIUREA",
  "COADA STANCII",
  "COARNELE CAPREI",
  "COASTA MAGURII",
  "COGEASCA",
  "COLTU CORNII",
  "COMARNA",
  "CONTESTI",
  "CORCODEL",
  "CORNESTI",
  "COROPCENI",
  "COSITENI",
  "COSTESTI",
  "COSTULENI",
  "COTARGACI",
  "COTNARI",
  "COTU LUI IVAN",
  "COTU MORII",
  "COVASNA",
  "COZIA",
  "COZMESTI",
  "CRISTESTI",
  "CRIVESTI",
  "CRUCEA",
  "CUCOVA",
  "CUCUTENI",
  "CURAGAU",
  "CURATURI",
  "CUZA VODA",
  "DADESTI",
  "DAGATA",
  "DANCAS",
  "DANCU",
  "DELENI",
  "DOBROVAT",
  "DOLHESTI",
  "DOMNITA",
  "DOROBANT",
  "DOROSCANI",
  "DRAGANESTI",
  "DRAGUSENI",
  "DUMBRAVA",
  "DUMBRAVITA",
  "DUMESTI",
  "DUMITRESTII GALATII",
  "ERBICENI",
  "FAGAT",
  "FANTANELE",
  "FARCASENI",
  "FEDELESENI",
  "FEREDENI",
  "FETESTI",
  "FILIASI",
  "FOCURI",
  "FORASTI",
  "FRASULENI",
  "FRENCIUGI",
  "FRUMUSICA",
  "GANESTI",
  "GARBESTI",
  "GASTESTI",
  "GAURENI",
  "GIURGESTI",
  "GLAVANESTI",
  "GLODENII GINDULUI",
  "GOESTI",
  "GOLAIESTI",
  "GORBAN",
  "GORUNI",
  "GRADINARI",
  "GRAJDURI",
  "GRIESTI",
  "GROPNITA",
  "GROZESTI",
  "GURA BADILITEI",
  "GURA BOHOTIN",
  "GURA VAII",
  "HABASESTI",
  "HADAMBU",
  "HALAUCESTI",
  "HALCENI",
  "HANDRESTI",
  "HARLAU",
  "HARMANEASA",
  "HARMANESTII NOI",
  "HARMANESTII VECHI",
  "HARPASESTI",
  "HARTOAPE",
  "HECI",
  "HELESTENI",
  "HERMEZIU",
  "HILITA",
  "HLINCEA",
  "HODORA",
  "HOISESTI",
  "HOLBOCA",
  "HOLM",
  "HOMITA",
  "HORLESTI",
  "HORODISTEA",
  "HORPAZ",
  "HUMOSU",
  "IACOBENI",
  "IASI",
  "IAZU NOU",
  "IAZU VECHI",
  "ICUSENI",
  "IEPURENI",
  "ION NECULCE",
  "IORCANI",
  "IOSUPENI",
  "IPATELE",
  "ISAIIA",
  "IUGANI",
  "IZVOARELE",
  "JIGORENI",
  "KOGALNICENI",
  "LARGA-JIJIA",
  "LEAHU-NACU",
  "LESPEZI",
  "LETCANI",
  "LITENI",
  "LUCENI",
  "LUNCA",
  "LUNCA CETATUII",
  "LUNCA RATES",
  "LUNCASI",
  "LUNGANI",
  "LUPARIA",
  "MACARESTI",
  "MADARJAC",
  "MADARJESTI",
  "MALAESTI",
  "MANASTIREA",
  "MANJESTI",
  "MANZATESTI",
  "MAXUT",
  "MEDELENI",
  "MICLAUSENI",
  "MIHAIL KOGALNICEANU",
  "MIRCESTI",
  "MIRONEASA",
  "MIROSLAVA",
  "MIROSLOVESTI",
  "MITESTI",
  "MITOC",
  "MOARA CIORNEI",
  "MOGOSESTI",
  "MOGOSESTI-SIRET",
  "MOIMESTI",
  "MORENI",
  "MOSNA",
  "MOTCA",
  "MOVILENI",
  "MUNCELU DE SUS",
  "MUNTENI",
  "OBOROCENI",
  "OBRIJENI",
  "ONESTI",
  "OPRISENI",
  "OPROAIA",
  "ORZENI",
  "OSOI",
  "OTELENI",
  "PADURENI",
  "PARCOVACI",
  "PASCANI",
  "PAUN",
  "PAUSESTI",
  "PERIENI",
  "PETRESTI",
  "PETROSICA",
  "PICIORU LUPULUI",
  "PIETRARIA",
  "PIETRIS",
  "PIETROSU",
  "PISCU RUSULUI",
  "PLUGARI",
  "POCREACA",
  "PODISU",
  "PODOLENII DE JOS",
  "PODOLENII DE SUS",
  "PODU HAGIULUI",
  "PODU ILOAIEI",
  "PODU JIJIEI",
  "POIANA",
  "POIANA CU CETATE",
  "POIANA DE SUS",
  "POIANA MANASTIRII",
  "POIANA MARULUI",
  "POIANA SCHEII",
  "POIENI",
  "POIENILE",
  "POPESTI",
  "POPRICANI",
  "POTANGENI",
  "PRIGORENI",
  "PRISACANI",
  "PROBOTA",
  "PROSELNICI",
  "RACHITENI",
  "RADENI",
  "RADUCANENI",
  "RASBOIENI",
  "RAZBOIENI",
  "RECEA",
  "REDIU",
  "REDIU ALDEI",
  "REDIU MITROPOLIEI",
  "ROMANESTI",
  "ROSCANI",
  "ROSU",
  "ROTARIA",
  "RUGINOASA",
  "RUNCU",
  "RUSENII NOI",
  "RUSENII VECHI",
  "SACARESTI",
  "SALAGENI",
  "SANGERI",
  "SARCA",
  "SATU NOU",
  "SAVENI",
  "SCANTEIA",
  "SCHEIA",
  "SCHITU DUCA",
  "SCHITU HADAMBULUI",
  "SCHITU STAVNIC",
  "SCOBALTENI",
  "SCOBINTI",
  "SCOPOSENI",
  "SCULENI",
  "SENDRENI",
  "SERBESTI",
  "SINESTI",
  "SIPOTE",
  "SIRETEL",
  "SLOBOZIA",
  "SOCI",
  "SODOMENI",
  "SOLONET",
  "SOROGARI",
  "SPINENI",
  "SPINOASA",
  "SPRANCEANA",
  "STANCA",
  "STEJARII",
  "STICLARIA",
  "STOLNICENI-PRAJESCU",
  "STORNESTI",
  "STROESTI",
  "STRUNGA",
  "SUHULET",
  "TABARA",
  "TANSA",
  "TARGU FRUMOS",
  "TARNITA",
  "TATARUSI",
  "TAUTESTI",
  "TIBANA",
  "TIBANESTI",
  "TIGANASI",
  "TIPILESTI",
  "TODIREL",
  "TODIRESTI",
  "TOMESTI",
  "TOPILE",
  "TOTOESTI",
  "TRAIAN",
  "TRIFESTI",
  "TUDOR VLADIMIRESCU",
  "TUFESTII DE SUS",
  "TUNGUJEI",
  "TUTORA",
  "UDA",
  "ULMI",
  "UNGHENI",
  "URICANI",
  "URSARESTI",
  "URSITA",
  "URSOAIA",
  "VADU VEJEI",
  "VALCELELE",
  "VALCICA",
  "VALEA ADANCA",
  "VALEA LUNGA",
  "VALEA LUPULUI",
  "VALEA OILOR",
  "VALEA RACULUI",
  "VALEA SATULUI",
  "VALEA SEACA",
  "VALEA URSULUI",
  "VALENII",
  "VAMA",
  "VANATORI",
  "VASCANI",
  "VERSENI",
  "VICTORIA",
  "VISAN",
  "VLADENI",
  "VLADICENI",
  "VLADNICUT",
  "VLADOMIRA",
  "VOCOTESTI",
  "VOINESTI",
  "VOLINTIRESTI",
  "VOROVESTI",
  "VULTURI",
  "ZABOLOTENI",
  "ZAGAVIA",
  "ZBERENI",
  "ZBEROAIA",
  "ZECE PRAJINI",
  "ZLODICA",
  "ZMEU",
];
COUNTIES_CITIES["TIMIS"] = [
  "ALBINA",
  "ALIOS",
  "ALTRINGEN",
  "BABSA",
  "BACOVA",
  "BALINT",
  "BALOSESTI",
  "BANLOC",
  "BARA",
  "BARATEAZ",
  "BARNA",
  "BATESTI",
  "BAZOS",
  "BAZOSU NOU",
  "BEBA VECHE",
  "BECICHERECU MIC",
  "BEGHEIU MIC",
  "BELINT",
  "BENCECU DE JOS",
  "BENCECU DE SUS",
  "BERECUTA",
  "BEREGSAU MARE",
  "BEREGSAU MIC",
  "BERINI",
  "BETHAUSEN",
  "BICHIGI",
  "BILED",
  "BIRDA",
  "BLAJOVA",
  "BOBDA",
  "BODO",
  "BOGDA",
  "BOLDUR",
  "BOTESTI",
  "BOTINESTI",
  "BRANESTI",
  "BREAZOVA",
  "BRESTEA",
  "BRESTOVAT",
  "BUCOVAT (DUMBRAVA)",
  "BUCOVAT (REMETEA MARE)",
  "BULGARUS",
  "BULZA",
  "BUNEA MARE",
  "BUNEA MICA",
  "BUTIN",
  "BUZAD",
  "BUZIAS",
  "CADAR",
  "CALACEA",
  "CAPAT",
  "CARANI",
  "CARPINIS",
  "CEBZA",
  "CENAD",
  "CENEI",
  "CERNA",
  "CERNETEAZ",
  "CHARLOTENBURG",
  "CHECEA",
  "CHECHES",
  "CHEGLEVICI",
  "CHERESTUR",
  "CHEVERESU MARE",
  "CHISODA",
  "CHIZATAU",
  "CIACOVA",
  "CIRESU",
  "CIRESU MIC",
  "CLADOVA",
  "CLICIOVA",
  "CLOPODIA",
  "COLONIA BULGARA",
  "COLONIA FABRICII",
  "COLONIA MICA",
  "COMEAT",
  "COMLOSU MARE",
  "COMLOSU MIC",
  "CORNESTI",
  "COSARII",
  "COSAVA",
  "COSEVITA",
  "COSTEIU",
  "COSTEIU DE SUS",
  "COVACI",
  "CRAI NOU",
  "CRALOVAT",
  "CRICIOVA",
  "CRIVINA",
  "CRIVINA DE SUS",
  "CRIVOBARA",
  "CRUCENI",
  "CURTEA",
  "CUTINA",
  "DAROVA",
  "DEJAN",
  "DENTA",
  "DETA",
  "DINIAS",
  "DOBRESTI",
  "DOLAT",
  "DRAGOIESTI",
  "DRAGOMIRESTI",
  "DRAGSINA",
  "DRAGSINESTI",
  "DRINOVA",
  "DUBESTI",
  "DUBOZ",
  "DUDESTII NOI",
  "DUDESTII VECHI",
  "DUMBRAVA",
  "DUMBRAVITA",
  "FADIMAC",
  "FAGET",
  "FARASESTI",
  "FARDEA",
  "FERENDIA",
  "FIBIS",
  "FICATAR",
  "FOENI",
  "FOLEA",
  "GAD",
  "GAIU MIC",
  "GATAIA",
  "GAVOJDIA",
  "GELU",
  "GHERMAN",
  "GHILAD",
  "GHIRODA",
  "GHIZELA",
  "GIARMATA",
  "GIARMATA-VII",
  "GIERA",
  "GIROC",
  "GIULVAZ",
  "GLADNA MONTANA",
  "GLADNA ROMANA",
  "GOTTLOB",
  "GRABAT",
  "GRANICERII",
  "GROSI",
  "GRUNI",
  "HAUZESTI",
  "HERENDESTI",
  "HERNEACOVA",
  "HEZERIS",
  "HISIAS",
  "HITIAS",
  "HODONI",
  "HODOS",
  "HOMOJDIA",
  "HONORICI",
  "IANOVA",
  "ICLODA",
  "ICTAR-BUDINTI",
  "IECEA MARE",
  "IECEA MICA",
  "IERSNIC",
  "IGRIS",
  "IOHANISFELD",
  "IOSIF",
  "IOSIFALAU",
  "IVANDA",
  "IZVIN",
  "JABAR",
  "JAMU MARE",
  "JDIOARA",
  "JEBEL",
  "JENA",
  "JIMBOLIA",
  "JUPANESTI",
  "JUPANI",
  "JURESTI",
  "LAPUSNIC",
  "LATUNAS",
  "LENAUHEIM",
  "LEUCUSESTI",
  "LIEBLING",
  "LIVEZILE",
  "LOVRIN",
  "LUCARET",
  "LUGOJ",
  "LUGOJEL",
  "LUNCANII DE JOS",
  "LUNCANII DE SUS",
  "LUNGA",
  "MACEDONIA",
  "MAGURI",
  "MANASTIRE",
  "MANASTIUR",
  "MARGINA",
  "MASLOC",
  "MATNICU MIC",
  "MORAVITA",
  "MOSNITA NOUA",
  "MOSNITA VECHE",
  "MURANI",
  "NADAS",
  "NADRAG",
  "NEMESESTI",
  "NERAU",
  "NEVRINCEA",
  "NITCHIDORF",
  "OBAD",
  "OFSENITA",
  "OHABA LUNGA",
  "OHABA ROMANA",
  "OHABA-FORGACI",
  "OLOSAG",
  "OPATITA",
  "ORTISOARA",
  "OTELEC",
  "OTVESTI",
  "PADURANI",
  "PADURENI",
  "PANIOVA",
  "PARTA",
  "PARTOS",
  "PARU",
  "PECIU NOU",
  "PERCOSOVA",
  "PERIAM",
  "PESAC",
  "PETROASA MARE",
  "PETROMAN",
  "PETROVASELO",
  "PIETROASA",
  "PINI",
  "PISCHIA",
  "POGANESTI",
  "POIENI",
  "PORDEANU",
  "POVARGINA",
  "PUSTINIS",
  "RACHITA",
  "RACOVITA",
  "RADMANESTI",
  "RAUTI",
  "RECAS",
  "REMETEA MARE",
  "REMETEA MICA",
  "REMETEA-LUNCA",
  "ROMANESTI",
  "ROVINITA MARE",
  "ROVINITA MICA",
  "RUDICICA",
  "RUDNA",
  "SACALAZ",
  "SACENI",
  "SACOSU MARE",
  "SACOSU TURCESC",
  "SAG",
  "SALBAGEL",
  "SALCIUA NOUA",
  "SANANDREI",
  "SANDRA",
  "SANGEORGE",
  "SANMARTINU MAGHIAR",
  "SANMARTINU SARBESC",
  "SANMIHAIU GERMAN",
  "SANMIHAIU ROMAN",
  "SANNICOLAU MARE",
  "SANOVITA",
  "SANPETRU MARE",
  "SANPETRU MIC",
  "SARAVALE",
  "SARAZANI",
  "SARBOVA",
  "SATCHINEZ",
  "SCULIA",
  "SECAS",
  "SECEANI",
  "SEMLACU MARE",
  "SEMLACU MIC",
  "SILAGIU",
  "SINERSIG",
  "SINTAR",
  "SINTESTI",
  "SIPET",
  "SOCA",
  "SPATA",
  "STAMORA GERMANA",
  "STAMORA ROMANA",
  "STANCIOVA",
  "STIUCA",
  "SUDRIAS",
  "SURDUCU MIC",
  "SUSANI",
  "SUSTRA",
  "TAPIA",
  "TARGOVISTE",
  "TEMERESTI",
  "TEREMIA MARE",
  "TEREMIA MICA",
  "TES",
  "TIMISOARA",
  "TIPARI",
  "TOAGER",
  "TOMESTI",
  "TOMNATIC",
  "TOPLA",
  "TOPOLOVATU MARE",
  "TOPOLOVATU MIC",
  "TORMAC",
  "TRAIAN VUIA",
  "UIHEI",
  "UIVAR",
  "ULIUC",
  "UNIP",
  "URSENI",
  "UTVIN",
  "VALCANI",
  "VALEA LUNGA ROMANA",
  "VARIAS",
  "VICTOR VLAD DELAMARINA",
  "VISAG",
  "VIZEJDIA",
  "VIZMA",
  "VOITEG",
  "VUCOVA",
  "ZGRIBESTI",
  "ZOLT",
  "ZORANI",
];
COUNTIES_CITIES["HARGHITA"] = [
  "ALDEA",
  "ALEXANDRITA",
  "ALUNIS",
  "ANDREENI",
  "ARMASENI",
  "ARMASENII NOI",
  "ARVATENI",
  "ATIA",
  "ATID",
  "AVRAMESTI",
  "BADENI",
  "BAILE CHIRUI",
  "BAILE HOMOROD",
  "BAILE TUSNAD",
  "BALAN",
  "BANCU",
  "BARATCOS",
  "BARZAVA",
  "BECAS",
  "BENTID",
  "BETA",
  "BETESTI",
  "BILBOR",
  "BISERICANI",
  "BODOGAIA",
  "BORSEC",
  "BORZONT",
  "BRADESTI",
  "BUCIN",
  "BULGARENI",
  "CADACIU MARE",
  "CADACIU MIC",
  "CALIMANEL",
  "CALNACI",
  "CALONDA",
  "CALUGARENI",
  "CAPALNITA",
  "CAPU CORBULUI",
  "CARPITUS",
  "CARTA",
  "CASINU NOU",
  "CECHESTI",
  "CEHETEL",
  "CETATUIA",
  "CHEDIA MARE",
  "CHEDIA MICA",
  "CHILENI",
  "CHINUSU",
  "CIARACIO",
  "CIBA",
  "CICEU",
  "CIOBANIS",
  "CIRESENI",
  "CIUCANI",
  "CIUCSANGEORGIU",
  "CIUMANI",
  "COBATESTI",
  "COMANESTI",
  "COMIAT",
  "CORBU",
  "CORUND",
  "COTORMANI",
  "COVACIPETER",
  "COZMENI",
  "CRACIUNEL",
  "CRISENI",
  "CRISTURU SECUIESC",
  "CUSMED",
  "DAIA",
  "DANESTI",
  "DARJIU",
  "DEALU",
  "DEALU ARMANULUI",
  "DEJUTIU",
  "DELNITA",
  "DESAG",
  "DITRAU",
  "DOBENI",
  "DUDA",
  "EGHERSEC",
  "ELISENI",
  "FAGETEL",
  "FANCEL",
  "FANTANA BRAZILOR",
  "FELICENI",
  "FILIAS",
  "FILPEA",
  "FIRTANUS",
  "FIRTUSU",
  "FITOD",
  "FORTENI",
  "FRUMOASA",
  "FUNDOAIA",
  "GALAUTAS",
  "GALAUTAS-PIRAU",
  "GHEORGHENI",
  "GHIDUT",
  "GHIPES",
  "GHIURCHE",
  "GOAGIU",
  "HAGOTA",
  "HARGHITA-BAI",
  "HODOSA",
  "HOGHIA",
  "HOSASAU",
  "IACOBENI",
  "IASU",
  "IGHIU",
  "IMPER",
  "INEU",
  "INLACENI",
  "IZVOARE",
  "IZVORU MURESULUI",
  "IZVORUL TROTUSULUI",
  "JIGODIN-BAI",
  "JOLOTCA",
  "JOSENI",
  "LACU ROSU",
  "LAZAREA",
  "LAZARESTI",
  "LAZ-FIRTANUS",
  "LAZ-SOIMUS",
  "LELICENI",
  "LIBAN",
  "LIVEZI",
  "LOCODENI",
  "LUETA",
  "LUNCA DE JOS",
  "LUNCA DE SUS",
  "LUNCANI",
  "LUPENI",
  "LUTITA",
  "MADARAS",
  "MAGHERUS",
  "MARTINIS",
  "MARTONCA",
  "MATISENI",
  "MEDISORU MARE",
  "MEDISORU MIC",
  "MERESTI",
  "MIERCUREA CIUC",
  "MIHAILENI",
  "MINELE LUETA",
  "MISENTEA",
  "MOGLANESTI",
  "MORARENI",
  "MUGENI",
  "MUJNA",
  "NADEJDEA",
  "NICOLENI",
  "NICOLESTI",
  "NUTENI",
  "OBRANESTI",
  "OCLAND",
  "OCNA DE JOS",
  "OCNA DE SUS",
  "ODORHEIU SECUIESC",
  "ORASENI",
  "OTENI",
  "PALTINIS",
  "PALTINIS-CIUC",
  "PAULENI",
  "PAULENI-CIUC",
  "PETECU",
  "PETRENI",
  "PINTIC",
  "PLAIESII DE JOS",
  "PLAIESII DE SUS",
  "PLATONESTI",
  "PLOPIS",
  "POIANA FAGULUI",
  "POIANA TARNAVEI",
  "POLONITA",
  "PORUMBENII MARI",
  "PORUMBENII MICI",
  "POTIOND",
  "PRAID",
  "PRELUCA",
  "PUNTEA LUPULUI",
  "RACHITIS",
  "RACU",
  "RARES",
  "RECEA",
  "REMETEA",
  "RUGANESTI",
  "RUNC",
  "SACEL",
  "SANCEL",
  "SANCRAI",
  "SANCRAIENI",
  "SANDOMINIC",
  "SANMARTIN",
  "SANPAUL",
  "SANSIMION",
  "SANTIMBRU",
  "SANTIMBRU-BAI",
  "SARMAS",
  "SASVERES",
  "SATU MARE",
  "SATU MIC",
  "SATU NOU",
  "SECU",
  "SECUIENI",
  "SENETEA",
  "SICASAU",
  "SICLOD",
  "SICULENI",
  "SIMONESTI",
  "SINEU",
  "SOIMENI",
  "SOIMUSU MARE",
  "SOIMUSU MIC",
  "SUB CETATE",
  "SUBCETATE",
  "SUSENI",
  "TAIETURA",
  "TAMASU",
  "TARCESTI",
  "TARNOVITA",
  "TAURENI",
  "TELEAC",
  "TENGHELER",
  "TIBOD",
  "TOLESENI",
  "TOMESTI",
  "TOPLITA",
  "TULGHES",
  "TURDENI",
  "TUSNAD",
  "TUSNADU NOU",
  "UILAC",
  "ULCANI",
  "ULIES",
  "VACARESTI",
  "VAGANI",
  "VALE",
  "VALEA BOROS",
  "VALEA CAPELEI",
  "VALEA GARBEA",
  "VALEA INTUNECOASA",
  "VALEA LUI ANTALOC",
  "VALEA LUI PAVEL",
  "VALEA RECE",
  "VALEA ROTUNDA",
  "VALEA STRAMBA",
  "VALEA UGRA",
  "VALEA UZULUI",
  "VALENI",
  "VARGATAC",
  "VARSAG",
  "VASILENI",
  "VIDACUT",
  "VISAFOLIO",
  "VLAHITA",
  "VOSLABENI",
  "VRABIA",
  "ZAPODEA",
  "ZENCANI",
  "ZETEA",
];
COUNTIES_CITIES["CALARASI"] = [
  "ALEXANDRU I. CUZA",
  "ALEXANDRU ODOBESCU",
  "APROZI",
  "ARTARI",
  "BELCIUGATELE",
  "BOGATA",
  "BOGDANA",
  "BORCEA",
  "BOSNEAGU",
  "BUCIUMENI",
  "BUDESTI",
  "BUZOENI",
  "CALARASI",
  "CALARASII VECHI",
  "CALARETI",
  "CANDEASCA",
  "CASCIOARELE",
  "CEACU",
  "CETATEA VECHE",
  "CHIRNOGI",
  "CHISELET",
  "CIOCANESTI",
  "CLATESTI",
  "COCONI",
  "CODRENI",
  "COJESTI",
  "CONSTANTIN BRANCOVEANU",
  "COSLOGENI",
  "COTOFANCA",
  "CRIVAT",
  "CUCUIETI",
  "CUNESTI",
  "CURATESTI",
  "CURCANI",
  "CUZA VODA",
  "DALGA",
  "DALGA-GARA",
  "DANESTI",
  "DARVARI",
  "DICHISENI",
  "DOR MARUNT",
  "DOROBANTU",
  "DRAGALINA",
  "DRAGOS VODA",
  "DRAJNA NOUA",
  "FANTANA DOAMNEI",
  "FAUREI",
  "FLORICA",
  "FLOROAICA",
  "FRASINET",
  "FRASINETU DE JOS",
  "FRUMUSANI",
  "FUNDENI",
  "FUNDULEA",
  "GALATUI",
  "GALBINASI",
  "GALDAU",
  "GOSTILELE",
  "GRADISTEA",
  "GRUIU",
  "GURBANESTI",
  "IEZERU",
  "ILEANA",
  "INDEPENDENTA",
  "INFRATIREA",
  "JEGALIA",
  "LEHLIU",
  "LEHLIU-GARA",
  "LIBERTATEA",
  "LUICA",
  "LUNCA",
  "LUPSANU",
  "LUPTATORI",
  "MAGURENI",
  "MANASTIREA",
  "MARCULESTI-GARA",
  "MARIUTA",
  "MATARAUA",
  "MIHAI VITEAZU",
  "MITRENI",
  "MODELU",
  "NANA",
  "NEGOESTI",
  "NICOLAE BALCESCU",
  "NUCETU",
  "NUCI",
  "OGORU",
  "OLTENITA",
  "OLTINA",
  "ORASTI",
  "OSTROVU",
  "PADURISU",
  "PAICU",
  "PASAREA",
  "PELINU",
  "PERISORU",
  "PITIGAIA",
  "PLATARESTI",
  "PLEVNA",
  "PLUMBUITA",
  "PODARI",
  "PODU PITARULUI",
  "POLCESTI",
  "POPESTI",
  "POSTAVARI",
  "POTCOAVA",
  "PREASNA",
  "PREASNA VECHE",
  "PROGRESU",
  "RADOVANU",
  "RADU NEGRU",
  "RADU VODA",
  "RASA",
  "RASURILE",
  "RAZOARELE",
  "RAZVANI",
  "ROSETI",
  "SACELE",
  "SALCIOARA",
  "SANDULITA",
  "SAPUNARI",
  "SARULESTI",
  "SARULESTI-GARA",
  "SATNOENI",
  "SATU NOU",
  "SATUCU",
  "SEINOIU",
  "SILISTEA",
  "SOCOALELE",
  "SOHATU",
  "SOLACOLU",
  "SOLDANU",
  "SPANTOV",
  "STANCEA",
  "STEFAN CEL MARE",
  "STEFAN VODA",
  "STEFANESTI",
  "STOENESTI",
  "SULTANA",
  "TAMADAU MARE",
  "TAMADAU MIC",
  "TARICENI",
  "TONEA",
  "TUDOR VLADIMIRESCU",
  "ULMENI",
  "ULMU",
  "UNIREA",
  "VALCELELE",
  "VALEA ARGOVEI",
  "VALEA POPII",
  "VALEA PRESNEI",
  "VALEA ROSIE",
  "VALEA RUSULUI",
  "VALEA SEACA",
  "VALEA STANII",
  "VARASTI",
  "VASILATI",
  "VISINII",
  "VLAD TEPES",
  "VLADICEASCA",
  "VLAICULESTI",
  "ZIMBRU",
];
COUNTIES_CITIES["MARAMURES"] = [
  "ARDUSAT",
  "ARDUZEL",
  "ARIESU DE CAMP",
  "ARIESU DE PADURE",
  "ARINIS",
  "ASPRA",
  "ASUAJU DE JOS",
  "ASUAJU DE SUS",
  "BABA",
  "BAIA MARE",
  "BAIA SPRIE",
  "BAILE BORSA",
  "BAITA",
  "BAITA DE SUB CODRU",
  "BAIUT",
  "BARGAU",
  "BARSANA",
  "BASESTI",
  "BERBESTI",
  "BERCHEZ",
  "BERCHEZOAIA",
  "BERINTA",
  "BICAZ",
  "BISTRA",
  "BLIDARI",
  "BOCICOEL",
  "BOCICOIU MARE",
  "BOGDAN VODA",
  "BOIERENI",
  "BOIU MARE",
  "BONTAIENI",
  "BORCUT",
  "BORSA",
  "BOTIZA",
  "BOZANTA MARE",
  "BOZANTA MICA",
  "BREB",
  "BREBENI",
  "BUCIUMI",
  "BUDESTI",
  "BUSAG",
  "BUTEASA",
  "BUZESTI",
  "CALINESTI",
  "CAMPULUNG LA TISA",
  "CARBUNARI",
  "CARPINIS",
  "CATALINA",
  "CAVNIC",
  "CERNESTI",
  "CETATELE",
  "CHECHIS",
  "CHELINTA",
  "CHIUZBAIA",
  "CICARLAU",
  "CIOCOTIS",
  "CIOLT",
  "CIUTA",
  "COAS",
  "CODRU BUTESII",
  "COLTAU",
  "COLTIREA",
  "COPALNIC",
  "COPALNIC-DEAL",
  "COPALNIC-MANASTUR",
  "CORNESTI",
  "CORNI",
  "COROIENI",
  "CORUIA",
  "COSTENI",
  "COSTIUI",
  "CRACIUNESTI",
  "CRASNA VISEULUI",
  "CUFOAIA",
  "CULCEA",
  "CUPSENI",
  "CURTUIUSU MARE",
  "CURTUIUSU MIC",
  "DAMACUSENI",
  "DANESTI",
  "DANESTII CHIOARULUI",
  "DEALU CORBULUI",
  "DEALU MARE",
  "DESESTI",
  "DOBRICU LAPUSULUI",
  "DRAGHIA",
  "DRAGOMIRESTI",
  "DUMBRAVA",
  "DUMBRAVITA",
  "DURUSA",
  "FANATE",
  "FANTANELE",
  "FARCASA",
  "FAURESTI",
  "FERESTI",
  "FERICEA",
  "FERSIG",
  "FINTEUSU MARE",
  "FINTEUSU MIC",
  "FIRIZA",
  "FRANCENII BOIULUI",
  "GARDANI",
  "GIULESTI",
  "GLOD",
  "GROAPE",
  "GROSI",
  "GROSII TIBLESULUI",
  "HANDALU ILBEI",
  "HARNICESTI",
  "HIDEAGA",
  "HOTENI",
  "HOVRILA",
  "IADARA",
  "IAPA",
  "IEUD",
  "ILBA",
  "INAU",
  "INTRERAURI",
  "IZVOARELE",
  "JUGASTRENI",
  "LAPUS",
  "LAPUSEL",
  "LARGA",
  "LASCHIA",
  "LAZU BACIULUI",
  "LEORDINA",
  "LIBOTIN",
  "LUCACESTI",
  "LUNCA LA TISA",
  "MAGURENI",
  "MANASTIREA",
  "MANAU",
  "MARA",
  "MERISOR",
  "MESTEACAN",
  "MIRESU MARE",
  "MOCIRA",
  "MOGOSESTI",
  "MOISEI",
  "NANESTI",
  "NEGREIA",
  "NISTRU",
  "OARTA DE JOS",
  "OARTA DE SUS",
  "OCNA SUGATAG",
  "OCOLIS",
  "ODESTI",
  "ONCESTI",
  "ORTITA",
  "PETERITEA",
  "PETROVA",
  "PIATRA",
  "PLOPIS",
  "POIANA BOTIZII",
  "POIENILE DE SUB MUNTE",
  "POIENILE IZEI",
  "POSTA",
  "PRELUCA NOUA",
  "PRELUCA VECHE",
  "PRIBILESTI",
  "PRISLOP",
  "RAZOARE",
  "RECEA",
  "REMECIOARA",
  "REMETEA CHIOARULUI",
  "REMETI",
  "REMETI PE SOMES",
  "REPEDEA",
  "RODINA",
  "ROGOZ",
  "ROHIA",
  "ROMANESTI",
  "RONA DE JOS",
  "RONA DE SUS",
  "ROZAVLEA",
  "RUS",
  "RUSCOVA",
  "RUSOR",
  "SABISA",
  "SACALASENI",
  "SACEL",
  "SALISTE",
  "SALISTEA DE SUS",
  "SALNITA",
  "SALSIG",
  "SALTA",
  "SANDRESTI",
  "SAPANTA",
  "SARASAU",
  "SARBI",
  "SASAR",
  "SAT-SUGATAG",
  "SATU NOU DE JOS",
  "SATU NOU DE SUS",
  "SATULUNG",
  "SEINI",
  "SIEU",
  "SIGHETU MARMATIEI",
  "SISESTI",
  "SLATIOARA",
  "SOMCUTA MARE",
  "SOMES-UILEAC",
  "STEJERA",
  "STOICENI",
  "STRAMBU-BAIUT",
  "STRAMTURA",
  "STREMT",
  "SUCIU DE JOS",
  "SUCIU DE SUS",
  "SUGAU",
  "SURDESTI",
  "TAMAIA",
  "TAMASESTI",
  "TARGU LAPUS",
  "TAUTII DE SUS",
  "TAUTII-MAGHERAUS",
  "TECEU MIC",
  "TICAU",
  "TISA",
  "TOHAT",
  "TRESTIA",
  "TULGHIES",
  "ULMENI",
  "ULMOASA",
  "UNGURAS",
  "UNGURENI",
  "URMENIS",
  "VAD",
  "VADU IZEI",
  "VALEA CHIOARULUI",
  "VALEA CUFUNDOASA",
  "VALEA HOTARULUI",
  "VALEA NEAGRA",
  "VALEA STEJARULUI",
  "VALEA VISEULUI",
  "VALENI",
  "VALENII LAPUSULUI",
  "VALENII SOMCUTEI",
  "VARAI",
  "VICEA",
  "VIILE APEI",
  "VIMA MARE",
  "VIMA MICA",
  "VISEU DE JOS",
  "VISEU DE MIJLOC",
  "VISEU DE SUS",
];
COUNTIES_CITIES["BUCURESTI"] = [
  "BUCURESTI SECTORUL 1",
  "BUCURESTI SECTORUL 2",
  "BUCURESTI SECTORUL 3",
  "BUCURESTI SECTORUL 4",
  "BUCURESTI SECTORUL 5",
  "BUCURESTI SECTORUL 6",
];
