import moment from "moment";
import { setYupLocale } from "utils/setYupLocale";
import { numberValidation } from "utils/utils";

export const defaultOfferTab1Validation = (yup, t): any => {
  return {
    inp_lessee_type: yup.mixed().nullable().required(),
    inp_contract_type: yup.mixed().nullable().required(),
  };
};

export const calculateCascoSchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({
    inp_lessee_type: yup.mixed().nullable().required(),
    inp_vehicle_state: yup.mixed().nullable().required(),
    inp_policy_value: yup.string().nullable().required(),
    inp_vehicle_construction: yup.mixed().nullable().required(),
    inp_external_contractdate: yup
      .date()
      .required()
      .nullable()
      .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
        if (value && value.getFullYear() !== moment().year()) {
          return false;
        }
        return true;
      })
      .max(new Date(), t("greco.form.errors.date.future")),
    inp_vehicle_firstreg: yup.mixed().nullable().required(),
    inp_vehicle_category: yup.mixed().nullable().required(),
    inp_vehicle_brand: yup.mixed().nullable().required(),
    inp_vehicle_model: yup.string().nullable().required(),
    inp_vehicle_maximumgrossweight: numberValidation(yup, t, true, 1, 99999),
    inp_vehicle_places: numberValidation(yup, t, true, 0, 99),
    inp_vehicle_carusage: yup.mixed().nullable().required(),
    inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
    inp_policy_currency: yup.mixed().nullable().required(),
    inp_contract_type: yup.mixed().nullable().required(),

    // Added altghough not required by backend
    inp_vehicle_fuel: yup.mixed().nullable().required(),

    inp_discount_insurer: yup
      .mixed()
      .nullable()
      .when(["inp_increase_p", "inp_discount_p"], (field1, field2, schema) => {
        return field1 || field2 ? schema.required() : schema.notRequired();
      }),
  });
};

export const calculateCascoAndMtplSchema = (
  yup,
  t,
  offerRequestType,
  include_mtpl_offer
) => {
  setYupLocale(t);
  let obj: any = {
    comparisonType: yup.mixed().nullable().required(),

    inp_lessee_type: yup.mixed().nullable().required(),
    inp_vehicle_state: yup.mixed().nullable().required(),
    inp_vehicle_construction: yup.mixed().nullable().required(),
    inp_vehicle_firstreg: yup.mixed().nullable().required(),
    inp_vehicle_category: yup.mixed().nullable().required(),
    inp_vehicle_brand: yup.mixed().nullable().required(),
    inp_vehicle_model: yup.string().nullable().required(),
    inp_vehicle_maximumgrossweight: numberValidation(yup, t, true, 1, 99999),
    inp_vehicle_places: numberValidation(yup, t, true, 0, 99),
    inp_vehicle_carusage: yup.mixed().nullable().required(),

    inp_contract_type: yup.mixed().nullable().required(),

    // Added altghough not required by backend
    inp_vehicle_fuel: yup.mixed().nullable().required(),

    inp_lessee_street: yup.string().nullable().required(),
    inp_lessee_housenr: yup.string().nullable().required(),
    inp_lessee_city: yup.string().nullable().required(),
    inp_lessee_county: yup.mixed().nullable().required(),
    inp_lessee_mobile: yup.string().nullable().required(),
    inp_lessee_email: yup
      .string()
      .email(t("bcr.validation.invalidEmail.label"))
      .nullable()
      .required(),
    inp_vehicle_vin: yup.string().length(17).nullable().required(),
    inp_vehicle_kw: yup.string().nullable().required(),
    inp_vehicle_cubiccapacity: yup.string().nullable().required(),

    inp_lessee_firstname: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    inp_lessee_lastname: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    inp_lessee_compreg: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup
        .string()
        .nullable()
        .matches(/^(?:\d{13})?$/, t("bcr.validation.inp_lessee_compreg"))
        .required(),
      otherwise: yup
        .string()
        .nullable()
        .matches(/^(?:\d{13})?$/, t("bcr.validation.inp_lessee_compreg"))
        .notRequired(),
    }),

    inp_lessee_company_name: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(),
    }),
    inp_lessee_uid: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(),
    }),

    // inp_start_date,
    // inp_sicgroup,
    // inp_vehicle_id,
    // inp_vehicle_registration,
    // inp_licence_date,
    // inp_vehicle_licenseplate
  };
  if (include_mtpl_offer) {
    obj = {
      ...obj,

      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
      inp_sicgroup: yup.mixed().nullable().required(),
      inp_vehicle_id: yup
        .string()
        .matches(/^[A-Za-z]\d{6}$/, t("bcr.validation.vehicle_id_format.label"))
        .required(),
      inp_vehicle_registration: yup.mixed().nullable().required(),
      inp_licence_date: yup.date().when("inp_lessee_type", {
        is: (field) => field && field === "bcr.PersonType.CIVIL",
        then: yup.date().nullable().required(),
        otherwise: yup.date().nullable().notRequired(),
      }),
      inp_vehicle_licenseplate: yup.string().nullable().required(),
    };
  }
  if (offerRequestType !== "F100056") {
    obj = {
      ...obj,

      inp_policy_value: yup.string().nullable().required(),
      inp_external_contractdate: yup
        .date()
        .required()
        .nullable()
        .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
          if (value && value.getFullYear() !== moment().year()) {
            return false;
          }
          return true;
        })
        .max(new Date(), t("greco.form.errors.date.future")),
      inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
      inp_policy_currency: yup.mixed().nullable().required(),
    };
  }
  return yup.object().shape(obj);
};

export const defaultOfferTab2Validation = (yup, t): any => {
  return {
    inp_vehicle_state: yup.mixed().nullable().required(),
    inp_vehicle_construction: yup.mixed().nullable().required(),
    inp_vehicle_firstreg: yup.mixed().nullable().required(),
    inp_vehicle_brand: yup.mixed().nullable().required(),
    inp_vehicle_model: yup.string().nullable().required(),
    inp_vehicle_maximumgrossweight: numberValidation(yup, t, true, 1, 99999),
    inp_vehicle_places: numberValidation(yup, t, true, 0, 99),

    inp_vehicle_vin: yup.string().length(17).nullable().required(),
    inp_vehicle_kw: yup.string().nullable().required(),
    inp_vehicle_cubiccapacity: yup.string().nullable().required(),
    inp_vehicle_fuel: yup.mixed().nullable().required(),

    inp_vehicle_registration_expiration_date: yup
      .date()
      .nullable()
      .notRequired()
      .test("valid-year", t("bcr.validation.dateInThePast"), function (value) {
        if (value && value < moment().startOf("day").toDate()) {
          return false;
        }
        return true;
      }),
  };
};

export const defaultOfferTab3Validation = (yup, t): any => {
  return {
    inp_vehicle_category: yup.mixed().nullable().required(),
    inp_vehicle_carusage: yup.mixed().nullable().required(),
    inp_vehicle_fleet: yup.mixed().nullable().required(),
  };
};

export const defaultOfferTab4Validation = (yup, t): any => {
  return {
    // inp_asistentarutiera: yup
    //   .string()
    //   .nullable()
    //   .when(["avarie8"], (field1, schema) => {
    //     return (field1 && field1 === true) || field1 === "true"
    //       ? schema.required()
    //       : schema.notRequired();
    //   }),
  };
};

export const defaultOfferTab5Validation = (yup, t): any => {
  return {
    inp_discount_insurer: yup
      .mixed()
      .nullable()
      .when(["inp_increase_p", "inp_discount_p"], (field1, field2, schema) => {
        return field1 || field2 ? schema.required() : schema.notRequired();
      }),
  };
};

export const defaultOfferTab6Validation = (yup, t): any => {
  return {
    inp_lessee_firstname: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    inp_lessee_lastname: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().required(),
      otherwise: yup.string().nullable().notRequired(),
    }),
    inp_lessee_compreg: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup
        .string()
        .nullable()
        .matches(/^(?:\d{13})?$/, t("bcr.validation.inp_lessee_compreg"))
        .required(),
      otherwise: yup
        .string()
        .nullable()
        .matches(/^(?:\d{13})?$/, t("bcr.validation.inp_lessee_compreg"))
        .notRequired(),
    }),

    inp_lessee_company_name: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(),
    }),
    inp_lessee_uid: yup.string().when("inp_lessee_type", {
      is: (field) => field && field === "bcr.PersonType.CIVIL",
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required(),
    }),
    inp_lessee_street: yup.string().nullable().required(),
    inp_lessee_housenr: yup.string().nullable().required(),
    inp_lessee_city: yup.string().nullable().required(),
    inp_lessee_county: yup.string().nullable().required(),
    inp_lessee_building: yup.string().nullable().notRequired(),
    inp_lessee_entrance: yup.string().nullable().notRequired(),
    inp_lessee_door: yup.string().nullable().notRequired(),
    inp_lessee_floor: yup.string().nullable().notRequired(),
    inp_lessee_mobile: yup.string().nullable().required(),
    inp_lessee_email: yup
      .string()
      .email("Invalid email address")
      .nullable()
      .required(),
    inp_caen: yup
      .string()
      .nullable()
      .matches(/^(?:\d{2})?$/, t("bcr.validation.inp_caen.label"))
      .notRequired(),
  };
};

export const defaultOfferTab7Validation = (yup, t): any => {
  return {};
};

export const defaultOfferTab8Validation = (yup, t): any => {
  return {
    inp_driver_compreg: yup
      .string()
      .nullable()
      .matches(/^(?:\d{13})?$/, t("bcr.validation.inp_lessee_compreg"))
      .notRequired(),
  };
};

export const defaultOfferTab9Validation = (yup, t): any => {
  return {};
};

const generalBusiness = {
  offerTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab1Validation(yup, t),
      mtpl_commission_broker_p: numberValidation(yup, t, false, 0, 100),
      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
    });
  },
  offerTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab2Validation(yup, t),

      inp_vehicle_licenseplate: yup.string().when("inp_vehicle_registration", {
        is: (field) =>
          field &&
          (field === "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            field === "bcr.Registration_type_IN_ORDER_TO_MATRICULATE"),
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),

      inp_vehicle_registration: yup.mixed().nullable().required(),
      ...defaultOfferTab3Validation(yup, t),
      inp_vehicle_subcategory: yup.mixed().nullable().required(),
      inp_vehicle_id: yup
        .string()
        .length(7)
        .nullable()
        .matches(/^[A-Za-z]\d+$/, t("bcr.validation.inp_vehicle_id"))
        .required(),
    });
  },
  offerTab3: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      // ...defaultOfferTab3Validation(yup, t),
      // inp_vehicle_subcategory: yup.mixed().nullable().required(),
    });
  },
  offerTab4: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab4Validation(yup, t),
    });
  },
  offerTab5: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab5Validation(yup, t),
    });
  },
  offerTab6: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab6Validation(yup, t),
      inp_sicgroup: yup.mixed().nullable().required(),

      inp_licence_date: yup.date().when("inp_lessee_type", {
        is: (field) => field && field === "bcr.PersonType.CIVIL",
        then: yup.date().nullable().required(),
        otherwise: yup.date().nullable().notRequired(),
      }),
    });
  },
  offerTab7: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab7Validation(yup, t),
    });
  },
  offerTab8: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab8Validation(yup, t),
    });
  },
  offerTabCasco: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      //      offerTab1: (yup, t) => {
      ...defaultOfferTab1Validation(yup, t),
      // mtpl_commission_broker_p: numberValidation(yup, t, true, 0, 100),
      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
      //      offerTab2: (yup, t) => {

      ...defaultOfferTab2Validation(yup, t),
      inp_vehicle_licenseplate: yup.string().when("inp_vehicle_registration", {
        is: (field) =>
          field &&
          (field === "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            field === "bcr.Registration_type_IN_ORDER_TO_MATRICULATE"),
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),
      inp_vehicle_registration: yup.mixed().nullable().required(),

      //      offerTab3: (yup, t) => {
      ...defaultOfferTab3Validation(yup, t),
      inp_vehicle_subcategory: yup.mixed().nullable().required(),
      //      offerTab4: (yup, t) => {
      ...defaultOfferTab4Validation(yup, t),
    });
  },
  offerTab9: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      //      offerTab1: (yup, t) => {
      ...defaultOfferTab1Validation(yup, t),
      // mtpl_commission_broker_p: numberValidation(yup, t, true, 0, 100),
      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
      //      offerTab2: (yup, t) => {

      ...defaultOfferTab2Validation(yup, t),
      ...defaultOfferTab2Validation(yup, t),
      inp_vehicle_licenseplate: yup.string().when("inp_vehicle_registration", {
        is: (field) =>
          field &&
          (field === "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            field === "bcr.Registration_type_IN_ORDER_TO_MATRICULATE"),
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),
      inp_vehicle_registration: yup.mixed().nullable().required(),
      ...defaultOfferTab3Validation(yup, t),
      inp_vehicle_subcategory: yup.mixed().nullable().required(),
      inp_vehicle_id: yup
        .string()
        .length(7)
        .nullable()
        .matches(/^[A-Za-z]\d+$/, t("bcr.validation.inp_vehicle_id"))
        .required(),
      //      offerTab3: (yup, t) => {

      ...defaultOfferTab3Validation(yup, t),
      //      offerTab4: (yup, t) => {
      ...defaultOfferTab4Validation(yup, t),
      //      offerTab5: (yup, t) => {
      ...defaultOfferTab5Validation(yup, t),
      //      offerTab6: (yup, t) => {
      ...defaultOfferTab6Validation(yup, t),
      inp_sicgroup: yup.mixed().nullable().required(),

      inp_licence_date: yup.date().when("inp_lessee_type", {
        is: (field) => field && field === "bcr.PersonType.CIVIL",
        then: yup.date().nullable().required(),
        otherwise: yup.date().nullable().notRequired(),
        ...defaultOfferTab7Validation(yup, t),
      }),
      //      offerTab7: (yup, t) => {
      ...defaultOfferTab7Validation(yup, t),
      //      offerTab8: (yup, t) => {
      ...defaultOfferTab8Validation(yup, t),
      //      offerTab9: (yup, t) => {
      ...defaultOfferTab9Validation(yup, t),
    });
  },
  invoiceTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      insurerCasco: yup.mixed().nullable().required(),
      leasingctrno: yup.string().nullable().required(),
    });
  },
  invoiceTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      insurerMtpl: yup.mixed().nullable().required(),
      months: yup.mixed().nullable().required(),
      decontare_directa: yup.boolean().nullable().required(),
      leasingctrno_mtpl: yup.string().nullable().required(),
    });
  },
  policyTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      validFrom: yup.date().nullable().required(),
      // confirmationForCascoPolicyIssuing: yup
      //   .boolean()
      //   .required()

      //   .oneOf([true], t("bcr.Warning_Offer_must_be_signed")),
    });
  },
  policyTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      paymentDate: yup.date().nullable().required(),
      // confirmationForMtplPolicyIssuing: yup
      //   .boolean()
      //   .required()

      //   .oneOf([true], t("bcr.Warning_Offer_must_be_signed")),
    });
  },
};

const casco = {
  offerTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab1Validation(yup, t),
      inp_policy_value: yup.string().nullable().required(),
      inp_external_contractdate: yup
        .date()
        .required()
        .nullable()
        .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
          if (value && value.getFullYear() !== moment().year()) {
            return false;
          }
          return true;
        })
        .max(new Date(), t("greco.form.errors.date.future")),
      inp_policy_payment: yup.mixed().nullable().required(),
      inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
      inp_policy_currency: yup.mixed().nullable().required(),
    });
  },
  offerTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab2Validation(yup, t),
      ...defaultOfferTab3Validation(yup, t),
    });
  },
  offerTab3: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      // ...defaultOfferTab3Validation(yup, t),
    });
  },
  offerTab4: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab4Validation(yup, t),
    });
  },
  offerTab5: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab5Validation(yup, t),
    });
  },
  offerTab6: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab6Validation(yup, t),
      ...defaultOfferTab7Validation(yup, t),
    });
  },
  offerTab7: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab7Validation(yup, t),
    });
  },
  offerTab8: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab8Validation(yup, t),
    });
  },
  offerTabCasco: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      // offerTab1: (yup, t) => {
      ...defaultOfferTab1Validation(yup, t),
      inp_policy_value: yup.string().nullable().required(),
      inp_external_contractdate: yup
        .date()
        .required()
        .nullable()
        .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
          if (value && value.getFullYear() !== moment().year()) {
            return false;
          }
          return true;
        })
        .max(new Date(), t("greco.form.errors.date.future")),
      inp_policy_payment: yup.mixed().nullable().required(),
      inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
      inp_policy_currency: yup.mixed().nullable().required(),
      // offerTab2: (yup, t) => {
      ...defaultOfferTab2Validation(yup, t),
      //      offerTab3: (yup, t) => {
      ...defaultOfferTab3Validation(yup, t),
      //      offerTab4: (yup, t) => {
      ...defaultOfferTab4Validation(yup, t),
    });
  },
  offerTab9: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      // offerTab1: (yup, t) => {
      ...defaultOfferTab1Validation(yup, t),
      inp_policy_value: yup.string().nullable().required(),
      inp_external_contractdate: yup
        .date()
        .required()
        .nullable()
        .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
          if (value && value.getFullYear() !== moment().year()) {
            return false;
          }
          return true;
        })
        .max(new Date(), t("greco.form.errors.date.future")),
      inp_policy_payment: yup.mixed().nullable().required(),
      inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
      inp_policy_currency: yup.mixed().nullable().required(),
      // offerTab2: (yup, t) => {
      ...defaultOfferTab2Validation(yup, t),
      //      offerTab3: (yup, t) => {
      ...defaultOfferTab3Validation(yup, t),
      //      offerTab4: (yup, t) => {
      ...defaultOfferTab4Validation(yup, t),
      //      offerTab5: (yup, t) => {
      ...defaultOfferTab5Validation(yup, t),
      //      offerTab6: (yup, t) => {
      ...defaultOfferTab6Validation(yup, t),
      //      offerTab7: (yup, t) => {
      ...defaultOfferTab7Validation(yup, t),
      //      offerTab8: (yup, t) => {
      ...defaultOfferTab8Validation(yup, t),
      //      offerTab9: (yup, t) => {
      ...defaultOfferTab9Validation(yup, t),
    });
  },
  invoiceTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      insurerCasco: yup.mixed().nullable().required(),
      leasingctrno: yup.string().nullable().required(),
    });
  },
  invoiceTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      insurerMtpl: yup.mixed().nullable().required(),
      months: yup.mixed().nullable().required(),
      decontare_directa: yup.boolean().nullable().required(),
      leasingctrno_mtpl: yup.string().nullable().required(),
    });
  },
  policyTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      validFrom: yup.date().nullable().required(),
      // confirmationForCascoPolicyIssuing: yup
      //   .boolean()
      //   .required()

      //   .oneOf([true], t("bcr.Warning_Offer_must_be_signed")),
    });
  },
  policyTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      paymentDate: yup.date().nullable().required(),
      // confirmationForMtplPolicyIssuing: yup
      //   .boolean()
      //   .required()

      //   .oneOf([true], t("bcr.Warning_Offer_must_be_signed")),
    });
  },
};

const cascoMtpl = {
  offerTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab1Validation(yup, t),
      inp_policy_currency: yup.mixed().nullable().required(),

      inp_policy_value: yup.string().nullable().required(),
      inp_policy_payment: yup.mixed().nullable().required(),
      inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
      inp_external_contractdate: yup
        .date()
        .required()
        .nullable()
        .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
          if (value && value.getFullYear() !== moment().year()) {
            return false;
          }
          return true;
        })
        .max(new Date(), t("greco.form.errors.date.future")),
      mtpl_commission_broker_p: numberValidation(yup, t, false, 0, 100),

      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
    });
  },
  offerTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab2Validation(yup, t),
      inp_vehicle_licenseplate: yup.string().when("inp_vehicle_registration", {
        is: (field) =>
          field &&
          (field === "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            field === "bcr.Registration_type_IN_ORDER_TO_MATRICULATE"),
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),

      inp_vehicle_registration: yup.mixed().nullable().required(),
      inp_vehicle_id: yup
        .string()
        .length(7)
        .nullable()
        .matches(/^[A-Za-z]\d+$/, t("bcr.validation.inp_vehicle_id"))
        .required(),
      ...defaultOfferTab3Validation(yup, t),
    });
  },
  offerTab3: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      // ...defaultOfferTab3Validation(yup, t),
    });
  },
  offerTab4: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab4Validation(yup, t),
    });
  },
  offerTab5: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab5Validation(yup, t),
    });
  },
  offerTab6: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab6Validation(yup, t),
      inp_sicgroup: yup.mixed().nullable().required(),

      inp_licence_date: yup.date().when("inp_lessee_type", {
        is: (field) => field && field === "bcr.PersonType.CIVIL",
        then: yup.date().nullable().required(),
        otherwise: yup.date().nullable().notRequired(),
      }),
      ...defaultOfferTab7Validation(yup, t),
    });
  },
  offerTab7: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab7Validation(yup, t),
    });
  },
  offerTab8: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab8Validation(yup, t),
    });
  },
  offerTabCasco: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      //    offerTab1: (yup, t) => {
      ...defaultOfferTab1Validation(yup, t),
      inp_policy_currency: yup.mixed().nullable().required(),

      inp_policy_value: yup.string().nullable().required(),
      inp_policy_payment: yup.mixed().nullable().required(),
      inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
      inp_external_contractdate: yup
        .date()
        .required()
        .nullable()
        .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
          if (value && value.getFullYear() !== moment().year()) {
            return false;
          }
          return true;
        })
        .max(new Date(), t("greco.form.errors.date.future")),
      //mtpl_commission_broker_p:     numberValidation(yup, t, true, 0, 100),
      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
      //    offerTab2: (yup, t) => {
      ...defaultOfferTab2Validation(yup, t),
      inp_vehicle_licenseplate: yup.string().when("inp_vehicle_registration", {
        is: (field) =>
          field &&
          (field === "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            field === "bcr.Registration_type_IN_ORDER_TO_MATRICULATE"),
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),

      inp_vehicle_registration: yup.mixed().nullable().required(),

      //    offerTab3: (yup, t) => {
      ...defaultOfferTab3Validation(yup, t),
      //    offerTab4: (yup, t) => {
      ...defaultOfferTab4Validation(yup, t),
    });
  },
  offerTab9: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      //    offerTab1: (yup, t) => {
      ...defaultOfferTab1Validation(yup, t),
      inp_policy_currency: yup.mixed().nullable().required(),

      inp_policy_value: yup.string().nullable().required(),
      inp_policy_payment: yup.mixed().nullable().required(),
      inp_leasing_monthsinterval: numberValidation(yup, t, true, 12, 84),
      inp_external_contractdate: yup
        .date()
        .required()
        .nullable()
        .test("valid-year", t("bcr.validation.invalidYear"), function (value) {
          if (value && value.getFullYear() !== moment().year()) {
            return false;
          }
          return true;
        })
        .max(new Date(), t("greco.form.errors.date.future")),
      //mtpl_commission_broker_p:     numberValidation(yup, t, true, 0, 100),
      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
      //    offerTab2: (yup, t) => {
      ...defaultOfferTab2Validation(yup, t),
      inp_vehicle_licenseplate: yup.string().when("inp_vehicle_registration", {
        is: (field) =>
          field &&
          (field === "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            field === "bcr.Registration_type_IN_ORDER_TO_MATRICULATE"),
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),

      inp_vehicle_registration: yup.mixed().nullable().required(),
      inp_vehicle_id: yup
        .string()
        .length(7)
        .nullable()
        .matches(/^[A-Za-z]\d+$/, t("bcr.validation.inp_vehicle_id"))
        .required(),
      //    offerTab3: (yup, t) => {
      ...defaultOfferTab3Validation(yup, t),
      //    offerTab4: (yup, t) => {
      ...defaultOfferTab4Validation(yup, t),
      //    offerTab5: (yup, t) => {
      ...defaultOfferTab5Validation(yup, t),
      //    offerTab6: (yup, t) => {
      ...defaultOfferTab6Validation(yup, t),
      inp_sicgroup: yup.mixed().nullable().required(),

      inp_licence_date: yup.date().when("inp_lessee_type", {
        is: (field) => field && field === "bcr.PersonType.CIVIL",
        then: yup.date().nullable().required(),
        otherwise: yup.date().nullable().notRequired(),
      }),
      //    offerTab7: (yup, t) => {
      ...defaultOfferTab7Validation(yup, t),
      //    offerTab8: (yup, t) => {
      ...defaultOfferTab8Validation(yup, t),
      //    offerTab9: (yup, t) => {
      ...defaultOfferTab9Validation(yup, t),
    });
  },
  invoiceTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      insurerCasco: yup.mixed().nullable().required(),
      leasingctrno: yup.string().nullable().required(),
    });
  },
  invoiceTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      insurerMtpl: yup.mixed().nullable().required(),
      months: yup.mixed().nullable().required(),
      decontare_directa: yup.boolean().nullable().required(),
      leasingctrno_mtpl: yup.string().nullable().required(),
    });
  },
  policyTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      validFrom: yup.date().nullable().required(),
      // confirmationForCascoPolicyIssuing: yup
      //   .boolean()
      //   .required()

      //   .oneOf([true], t("bcr.Warning_Offer_must_be_signed")),
    });
  },
  policyTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      paymentDate: yup.date().nullable().required(),
      // confirmationForMtplPolicyIssuing: yup
      //   .boolean()
      //   .required()

      //   .oneOf([true], t("bcr.Warning_Offer_must_be_signed")),
    });
  },
};

const mtplRenewal = {
  offerTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab1Validation(yup, t),
      mtpl_commission_broker_p: numberValidation(yup, t, false, 0, 100),

      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
    });
  },
  offerTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab2Validation(yup, t),
      inp_vehicle_licenseplate: yup.string().when("inp_vehicle_registration", {
        is: (field) =>
          field &&
          (field === "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            field === "bcr.Registration_type_IN_ORDER_TO_MATRICULATE"),
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),

      inp_vehicle_registration: yup.mixed().nullable().required(),
      inp_vehicle_id: yup
        .string()
        .length(7)
        .nullable()
        .matches(/^[A-Za-z]\d+$/, t("bcr.validation.inp_vehicle_id"))
        .required(),
      ...defaultOfferTab3Validation(yup, t),
    });
  },
  offerTab3: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      // ...defaultOfferTab3Validation(yup, t),
    });
  },
  offerTab4: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab4Validation(yup, t),
    });
  },
  offerTab5: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab5Validation(yup, t),
    });
  },
  offerTab6: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab6Validation(yup, t),
      inp_sicgroup: yup.mixed().nullable().required(),

      inp_licence_date: yup.date().when("inp_lessee_type", {
        is: (field) => field && field === "bcr.PersonType.CIVIL",
        then: yup.date().nullable().required(),
        otherwise: yup.date().nullable().notRequired(),
        ...defaultOfferTab7Validation(yup, t),
      }),
    });
  },
  offerTab7: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab7Validation(yup, t),
    });
  },
  offerTab8: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      ...defaultOfferTab8Validation(yup, t),
    });
  },
  offerTabCasco: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      // offerTab1: (yup, t) => {
      ...defaultOfferTab1Validation(yup, t),
      // mtpl_commission_broker_p: yup
      //   .number()
      //   .nullable()
      //   .min(0)
      //   .max(100)
      //   .required(),
      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
      //      offerTab2: (yup, t) => {
      ...defaultOfferTab2Validation(yup, t),
      inp_vehicle_licenseplate: yup.string().when("inp_vehicle_registration", {
        is: (field) =>
          field &&
          (field === "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            field === "bcr.Registration_type_IN_ORDER_TO_MATRICULATE"),
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),

      inp_vehicle_registration: yup.mixed().nullable().required(),

      //      offerTab3: (yup, t) => {
      ...defaultOfferTab3Validation(yup, t),
      //      offerTab4: (yup, t) => {
      ...defaultOfferTab4Validation(yup, t),
    });
  },
  offerTab9: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      // offerTab1: (yup, t) => {
      ...defaultOfferTab1Validation(yup, t),
      // mtpl_commission_broker_p: yup
      //   .number()
      //   .nullable()
      //   .min(0)
      //   .max(100)
      //   .required(),
      inp_start_date: yup
        .date()
        .nullable()
        .required()
        .test(
          "valid-year",
          t("bcr.validation.dateInThePast"),
          function (value) {
            if (value && value < moment().startOf("day").toDate()) {
              return false;
            }
            return true;
          }
        ),
      //      offerTab2: (yup, t) => {
      ...defaultOfferTab2Validation(yup, t),
      inp_vehicle_licenseplate: yup.string().when("inp_vehicle_registration", {
        is: (field) =>
          field &&
          (field === "bcr.Registration_type_IN_ORDER_TO_REGISTER" ||
            field === "bcr.Registration_type_IN_ORDER_TO_MATRICULATE"),
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required(),
      }),

      inp_vehicle_registration: yup.mixed().nullable().required(),
      inp_vehicle_id: yup
        .string()
        .length(7)
        .nullable()
        .matches(/^[A-Za-z]\d+$/, t("bcr.validation.inp_vehicle_id"))
        .required(),
      //      offerTab3: (yup, t) => {
      ...defaultOfferTab3Validation(yup, t),
      //      offerTab4: (yup, t) => {
      ...defaultOfferTab4Validation(yup, t),
      //      offerTab5: (yup, t) => {
      ...defaultOfferTab5Validation(yup, t),
      //      offerTab6: (yup, t) => {
      ...defaultOfferTab6Validation(yup, t),
      inp_sicgroup: yup.mixed().nullable().required(),

      inp_licence_date: yup.date().when("inp_lessee_type", {
        is: (field) => field && field === "bcr.PersonType.CIVIL",
        then: yup.date().nullable().required(),
        otherwise: yup.date().nullable().notRequired(),
      }),
      //    offerTab7: (yup, t) => {
      ...defaultOfferTab7Validation(yup, t),
      //      offerTab8: (yup, t) => {
      ...defaultOfferTab8Validation(yup, t),
      //      offerTab9: (yup, t) => {
      ...defaultOfferTab9Validation(yup, t),
    });
  },
  invoiceTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      insurerCasco: yup.mixed().nullable().required(),
      leasingctrno: yup.string().nullable().required(),
    });
  },
  invoiceTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      insurerMtpl: yup.mixed().nullable().required(),
      months: yup.mixed().nullable().required(),
      decontare_directa: yup.boolean().nullable().required(),
      leasingctrno_mtpl: yup.string().nullable().required(),
    });
  },
  policyTab1: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      validFrom: yup.date().nullable().required(),
      // confirmationForCascoPolicyIssuing: yup
      //   .boolean()
      //   .required()
      //   .oneOf([true], t("bcr.Warning_Offer_must_be_signed")),
    });
  },
  policyTab2: (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({
      paymentDate: yup.date().nullable().required(),
      // confirmationForMtplPolicyIssuing: yup
      //   .boolean()
      //   .required()

      //   .oneOf([true], t("bcr.Warning_Offer_must_be_signed")),
    });
  },
};
export const productValidation = {
  generalBusiness,
  casco,
  cascoMtpl,
  mtplRenewal,
};
