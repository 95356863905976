import React, { useMemo } from "react";
import { Controller } from "react-hook-form";

import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";
import Skeleton from "react-loading-skeleton";
import { Body1Strong, Input } from "@fluentui/react-components";
import { formatNumber } from "utils/number";

type Props = {
  name: string;
  form: any;
  disabled?: boolean;
  isNegativeAllowed?: boolean;
  label: string;

  isFloat?: boolean;
  required: boolean;

  visible?: boolean;
  isLoading: boolean;
  contentAfter?: string;
  fixedWidth?: boolean;
  minPrecision?: number;
  maxPrecision?: number;
  onBlur?: (e: any) => void;
};

const NumberInputField = ({
  name,
  form,
  label,
  disabled = false,
  isFloat = false,
  isNegativeAllowed = false,
  required = false,
  visible,
  isLoading,
  contentAfter = null,
  onBlur = null,
  fixedWidth = true,
  minPrecision = 2,
  maxPrecision = 2,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = form;

  const parseInteger = useMemo(
    () => (string) => {
      const integerAccept = isNegativeAllowed
        ? /-?\d{1,3}(?: \d{3})*(?:,\d+)?/g
        : /\d{1,3}(?: \d{3})*(?:,\d+)?/g;
      return (string?.match(integerAccept) || []).join("");
    },
    [isNegativeAllowed]
  );

  const formatInteger = useMemo(() => {
    return (string) => {
      // const parsed = parseInt(string + "");
      const number = Number.parseInt(string, 10);
      if (Number.isNaN(number) && string === "-") {
        return string;
      }
      if (Number.isNaN(number)) {
        return "";
      }
      return number.toLocaleString("en").replace(/,/g, ".");
    };
  }, [parseInteger]);

  const formatFloat = useMemo(() => {
    return (string) => {
      if (!string) return "";
      const parsed = parseFloat(string);
      return parsed;
      // .toLocaleString("de-DE", {
      //   maximumFractionDigits: 4,
      //   minimumFractionDigits: 2,
      // });
    };
  }, []);

  const localeStringToFloat = (str: string) => {
    if (!str) return null;
    let r = null;
    if (isFloat) {
      r = parseFloat(str); //.replaceAll(".", "").replaceAll(",", "."));
    } else {
      r = parseInt(str); //.replaceAll(".", "").replaceAll(",", "."), 10);
    }
    return r;
  };

  if (!visible) return null;

  return (
    <FieldContainer
      isReadOnly={disabled}
      isTooltipHidden={false}
      tooltipText={label}
      fixedWidth={fixedWidth}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <>
              {disabled ? (
                <Body1Strong
                  id={`input_readonly_${name}`}
                  style={{
                    marginLeft: fixedWidth ? "15px" : undefined,
                    color: isLoading ? "#888888" : null,
                  }}
                >{`${
                  field.value
                    ? isFloat
                      ? formatNumber(
                          parseFloat(field.value),
                          minPrecision,
                          maxPrecision
                        )
                      : field.value
                    : "-"
                } ${contentAfter ? contentAfter : ""}`}</Body1Strong>
              ) : (
                <Input
                  accept="number"
                  disabled={disabled || isLoading}
                  key={name}
                  name={name}
                  id={"input_" + name}
                  // autoComplete="off"
                  value={
                    field.value ? (field.value + "").replaceAll(".", ",") : ""
                    // !isFloat
                    //   ? field.value.replace(/[^0-9]/g, "").replaceAll(".", "")
                    //   : field.value.replace(/[^0-9,]/g, "")
                  }
                  onBlur={(e) => {
                    field.onBlur();
                    onBlur && onBlur(e);
                  }}
                  onChange={(e, newValue) => {
                    let retVal = "";
                    if (!isFloat) {
                      retVal = newValue.value.replace(/[^0-9]/g, "");
                      retVal = retVal.replaceAll(".", "");
                    } else {
                      retVal = newValue.value.replace(/[^0-9,]/g, "");
                    }
                    // let parsedNumber = null;
                    // if (!isNegativeAllowed) {
                    //   retVal = retVal.replaceAll("-", "");
                    // }
                    // const flatNumber = retVal;
                    // if (isFloat) {
                    //   parsedNumber = parseFloat(flatNumber);
                    // } else {
                    //   parsedNumber = parseInt(flatNumber, 10);
                    // }

                    // if (
                    //   isNegativeAllowed &&
                    //   isNaN(parsedNumber) &&
                    //   newValue.value.startsWith("-")
                    // ) {
                    //   retVal = localeStringToFloat(newValue.value);
                    //   field.onChange(retVal);
                    //   return;
                    // }

                    // // retVal = parsedNumber.toLocaleString("de-DE", {
                    // //   minimumFractionDigits: 0,
                    // //   maximumFractionDigits: 10,
                    // // });
                    // if (retVal !== "NaN") {
                    //   if (isFloat && newValue.value.endsWith(",")) {
                    //     retVal = retVal + ",";
                    //   }
                    //   // } else {
                    //   //   retVal = localeStringToFloat(retVal);
                    //   // }
                    // } else {
                    //   retVal = localeStringToFloat("");
                    // }
                    if (retVal.match(/^(\d+)?(,\d*)?$/)) {
                      field.onChange(retVal);
                    }
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  contentAfter={contentAfter}
                />
              )}
              {errors && errors[name] ? (
                <ErrorMsg id={name + "_error"}>{errors[name].message}</ErrorMsg>
              ) : null}
            </>
          );
        }}
      />
    </FieldContainer>
  );
};

export default NumberInputField;
