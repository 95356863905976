import axios, { Axios } from "axios";
import moment from "moment";
import { Setting } from "types/types";
import { handleAxiosError } from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./ListPageStore";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };
export const setStoreValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };
export const loadListData =
  () =>
  async ({ setState, getState }) => {
    if (getState().listDataLoadStatus === "loading") return;
    try {
      setState({
        listDataLoadStatus: "loading",
      });
      const res = await API.getOfferList({
        appInfo: {
          dataObj: {},
        },
      });
      setState({
        listData: res.data.appInfo.dataObj,
        listDataLoadStatus: "success",
      });
    } catch (error) {
      handleAxiosError(error);
      setState({
        listDataLoadStatus: "error",
      });
    }
  };

let cancelSource;

export const loadVehicles =
  ({ reset = false, delay = false, skip = 0, top = 40, onSuccess = null }) =>
  async ({ setState, getState }) => {
    // if (cancelSource && reset) {
    //   cancelSource.cancel();
    // }
    cancelSource = axios.CancelToken.source();

    try {
      if (getState().vehicleItemsLoadStatus === "loading") return;
      setState({
        vehicleItemsLoadStatus: "loading",
      });
      const response = await API.LoadVehicles({
        columns: getState().cols,
        skip,
        top,
        showArchived: getState().showArchived,
        sort: getState().sort,
        search: getState().searchTerm,
        cancelToken: cancelSource.token,
        offerRequestType: getState().offerRequestType,
      });
      let newOffers = response.data.value;
      if (reset === false) {
        newOffers = [...getState().vehicleItems, ...response.data.value];
      }
      setState({
        vehicleItems: hydrateOffers(newOffers),
        count: response.data["@odata.count"],
        vehicleItemsLoadStatus: "success",
      });
      onSuccess && onSuccess();
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error);
        setState({
          vehicleItemsLoadStatus: "error",
        });
      }
    }
  };

export const loadVehiclesForReport =
  (payload) =>
  async ({ setState, getState }) => {
    const { onSuccess, onError } = payload;

    try {
      if (getState().offerItemsLoadStatus === "loading") return;

      const response = await API.LoadVehicles({
        columns: getState().cols,
        skip: 0,
        top: 1000000,
        showArchived: getState().showArchived,
        sort: getState().sort,
        search: getState().searchTerm,
        cancelToken: cancelSource.token,
        offerRequestType: getState().offerRequestType,
      });
      let newOffers = response.data.value;
      setState({
        offerItemsLoadStatus: "success",
      });
      onSuccess && onSuccess(hydrateOffers(newOffers));
    } catch (error: any) {
      onError && onError(error);
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error);
        setState({
          offerItemsLoadStatus: "error",
        });
      }
    }
  };

export const loadSettings =
  (applicationCodeId, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });

      const res = await API.getAppSettings(applicationCodeId);
      setState({
        settings: res.data,
        settingsLoadStatus: "success",
      });
      const defaultView = res.data.find((s) => s.isDefault);
      if (defaultView) {
        setState({
          selectedViewId: defaultView.userAppSettingId,
        });
      }
      onSuccess && onSuccess(defaultView);
    } catch (err) {
      handleAxiosError(err);
      setState({
        settingsLoadStatus: "error",
      });
      onError && onError(err);
    }
  };

export const loadOffer =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.getOffer(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const invalidateOffer =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().invalidateOfferStatus === "loading") return;
    try {
      setState({
        invalidateOfferStatus: "loading",
      });
      await API.invalidateOffer(payload);
      setState({
        invalidateOfferStatus: "success",
      });
      onSuccess && onSuccess();
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err);
      setState({
        invalidateOfferStatus: "error",
      });
      onError && onError(err);
    }
  };

export const createSetting =
  (newSetting: Setting) =>
  async ({ setState, getState }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.addAppSetting(newSetting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      const newSettingId = allSettings.find(
        (s) => s.userAppSettingName === newSetting.userAppSettingName
      )?.userAppSettingId;
      if (newSettingId) {
        setState({
          selectedViewId: newSettingId,
        });
      }
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err);
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const submitBcrForm =
  (data: any, t, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    setState({
      submitBcrFormStatus: "loading",
    });
    const payload: any = {
      appInfo: {
        dataObj: {
          issuePolicyDateStart: data.start_date
            ? moment(data.start_date).format("YYYY-MM-DD")
            : null,
          issuePolicyDateEnd: data.end_date
            ? moment(data.end_date).format("YYYY-MM-DD")
            : null,
        },
      },
    };
    const response = await API.sendBCRPolicies(payload);
    setState({
      submitBcrFormStatus: "success",
    });
    onSuccess && onSuccess(response.data.appInfo.dataObj.Message);
    return response;
  };

export const submitAsiromForm =
  (data: any, t, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    setState({
      submitAsiromFormStatus: "loading",
    });
    const payload: any = {
      appInfo: {
        dataObj: {
          issuePolicyDateStart: data.start_date
            ? moment(data.start_date).format("YYYY-MM-DD")
            : null,
          issuePolicyDateEnd: data.end_date
            ? moment(data.end_date).format("YYYY-MM-DD")
            : null,
        },
      },
    };
    const response = await API.sendAsiromPolicies(payload);
    setState({
      submitAsiromFormStatus: "success",
    });
    onSuccess && onSuccess(response.data.appInfo.dataObj.Message);
    return response;
  };

export const submitRenewalForm =
  (file: any, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    setState({
      submitRenewalFormStatus: "loading",
    });
    const payload: any = {
      appInfo: {
        dataObj: {
          renewalData: [
            {
              originalName: `${file.name}`,
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              url: `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file.base64str}`,
            },
          ],
        },
      },
    };
    try {
      const response: any = await API.sendExcelFile(payload);
      setState({
        submitRenewalFormStatus: "success",
      });
      if (response.status === 200) {
        onSuccess && onSuccess(response.data.appInfo.dataObj.Message);
      } else {
        setState({
          submitRenewalFormStatus: "error",
        });
        onError && onError(response.ExceptionMessage);
      }
      return response;
    } catch (error: any) {
      setState({
        submitRenewalFormStatus: "error",
      });
      onError && onError(error.response.data.ExceptionMessage);
    }
  };

export const submitRenewalNonGrecoForm =
  (file: any, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    setState({
      submitRenewalNonGrecoFormStatus: "loading",
    });
    const payload: any = {
      appInfo: {
        dataObj: {
          renewalData: [
            {
              originalName: `${file.name}`,
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              url: `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file.base64str}`,
            },
          ],
        },
      },
    };
    try {
      const response: any = await API.sendExcelNonGrecoFile(payload);
      setState({
        submitRenewalNonGrecoFormStatus: "success",
      });
      if (response.status === 200) {
        onSuccess && onSuccess(response.data.appInfo.dataObj.Message);
      } else {
        setState({
          submitRenewalFormStatus: "error",
        });
        onError && onError(response.ExceptionMessage);
      }
      return response;
    } catch (error: any) {
      setState({
        submitRenewalNonGrecoFormStatus: "error",
      });
      onError && onError(error.response.data.ExceptionMessage);
    }
  };

export const deleteSetting =
  (id: number) =>
  async ({ setState, getState }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.deleteAppSetting(id);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err);
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const updateSetting =
  (setting: Setting) =>
  async ({ setState, getState }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.updateAppSetting(setting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err);
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

const hydrateOffers = (newOffers: any) => {
  const columns = newOffers.map((row) => ({
    ...row,
    inp_lessee_username:
      row.inp_lessee_type === "Persoana fizica"
        ? row.inp_lessee_firstname + " " + row.inp_lessee_lastname
        : row.inp_lessee_company_name,
    inp_lessee_id:
      row.inp_lessee_type === "Persoana fizica"
        ? row.inp_lessee_compreg
        : row.inp_lessee_uid,
  }));
  return columns;
};
