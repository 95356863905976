import { max } from "lodash";

export const formatNumber = (n: number, minPrecision, maxPrecision) => {
  return n.toLocaleString("de-DE", {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  });
};

export const formatIntegerNumber = (n: number) => {
  return n.toLocaleString("de-DE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
