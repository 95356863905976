import { useTheme } from "@fluentui/react";
import { NavigationBar } from "components/styled";
import { mergeStyles } from "office-ui-fabric-react";
import React from "react";
import { useAppStoreState } from "store/AppStore";
import AsiromExportBtn from "./navigationbuttons/AsiromExportBtn";
import BCRExportBtn from "./navigationbuttons/BCRExportBtn";
import OfferBtn from "./navigationbuttons/NewOffer";
import NewOfferRCABtn from "./navigationbuttons/NewOfferRCA";
import NonGrecoBtn from "./navigationbuttons/NonGrecoRenewalBtn";
import RenewalBtn from "./navigationbuttons/RenewalBtn";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { routes } from "config/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ArrowCounterclockwiseRegular,
  ArrowExportLtrRegular,
  AddFilled,
} from "@fluentui/react-icons";
import { useFormPageStore } from "store/FormPageStore";
import { insuranceProducts } from "types/products";
import { useListPageStore, useListPageStoreActions } from "store/ListPageStore";

const ListPageNavigationSecondLine = () => {
  const { appInfoData } = useAppStoreState();
  const [{}, { setFormStoreValue }] = useFormPageStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [{ vehicleItemsLoadStatus }, { setListStoreValue }] =
    useListPageStore();
  const loading = vehicleItemsLoadStatus === "loading";
  if (!appInfoData) return null;

  return (
    <NavigationBar>
      {/* {typeof appInfoData.showButton !== "undefined" &&
      (appInfoData.showButton === "newoffer" ||
        appInfoData.showButton === "both" ||
        appInfoData.showButton === "admin") ? (
        <OfferBtn />
      ) : null}
      {typeof appInfoData.showButton !== "undefined" &&
      (appInfoData.showButton === "newofferRCA" ||
        appInfoData.showButton === "both" ||
        appInfoData.showButton === "admin") ? (
        <NewOfferRCABtn />
      ) : null} */}

      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton
            id="newofferbutton"
            icon={<AddFilled fontSize={16} />}
            appearance="subtle"
            disabled={loading}
          >
            {t("bcr.NewOffer.label")}
          </MenuButton>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {typeof appInfoData.showButton !== "undefined" &&
            (appInfoData.showButton === "newoffer" ||
              appInfoData.showButton === "both" ||
              appInfoData.showButton === "admin") ? (
              <>
                <MenuItem
                  id="newoffercascobutton"
                  icon={<AddFilled fontSize={16} />}
                  onClick={() => {
                    navigate(routes.formPageNewOfferProduct.getPath("casco"));
                  }}
                >
                  {t("bcr.product.casco")}
                </MenuItem>
                <MenuItem
                  id="newoffercascomtplbutton"
                  icon={<AddFilled fontSize={16} />}
                  onClick={() => {
                    setFormStoreValue(
                      "selectedProduct",
                      insuranceProducts.cascoMtpl
                    );
                    navigate(
                      routes.formPageNewOfferProduct.getPath("cascoMtpl")
                    );
                  }}
                >
                  {t("bcr.product.cascoMtpl")}
                </MenuItem>
              </>
            ) : null}
            {typeof appInfoData.showButton !== "undefined" &&
            (appInfoData.showButton === "newofferRCA" ||
              appInfoData.showButton === "both" ||
              appInfoData.showButton === "admin") ? (
              <MenuItem
                id="newoffergeneralbusinessbutton"
                icon={<AddFilled fontSize={16} />}
                onClick={() => {
                  setFormStoreValue(
                    "selectedProduct",
                    insuranceProducts.generalBusiness
                  );
                  navigate(
                    routes.formPageNewOfferProduct.getPath("generalBusiness")
                  );
                }}
              >
                {t("bcr.product.generalBusiness")}
              </MenuItem>
            ) : null}
            {typeof appInfoData.showButton !== "undefined" &&
            (appInfoData.showButton === "both" ||
              appInfoData.showButton === "admin" ||
              appInfoData.showRenewals === true) ? (
              <>
                <MenuItem
                  id="newoffermtplrenewalbutton"
                  icon={<AddFilled fontSize={16} />}
                  onClick={() => {
                    setFormStoreValue(
                      "selectedProduct",
                      insuranceProducts.mtplRenewal
                    );
                    navigate(
                      routes.formPageNewOfferProduct.getPath("mtplRenewal")
                    );
                  }}
                >
                  {t("bcr.product.mtplRenewal")}
                </MenuItem>
              </>
            ) : null}
          </MenuList>
        </MenuPopover>
      </Menu>
      {appInfoData.showButton !== "admin" &&
      appInfoData.showButton !== "both" &&
      (appInfoData?.offerRequestType === "F100004" ||
        appInfoData?.offerRequestType === "F100018") ? null : (
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton
              disabled={loading}
              icon={<ArrowExportLtrRegular fontSize={16} />}
              appearance="subtle"
            >
              {t("bcr.Export.label")}
            </MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem
                icon={<ArrowExportLtrRegular fontSize={16} />}
                onClick={() => {
                  setListStoreValue("asiromFormDialog", {
                    isOpen: true,
                    formData: { start_date: null, end_date: null },
                  });
                }}
              >
                {t("bcr.ExportAsirom.label")}
              </MenuItem>
              <MenuItem
                icon={<ArrowExportLtrRegular fontSize={16} />}
                onClick={() => {
                  setListStoreValue("bcrFormDialog", {
                    isOpen: true,
                    formData: { start_date: null, end_date: null },
                  });
                }}
              >
                {t("bcr.ExportBCR.label")}
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      )}

      {appInfoData.showButton !== "admin" &&
      appInfoData.showRenewals !== true &&
      appInfoData.showButton !== "both" &&
      (appInfoData?.offerRequestType === "F100004" ||
        appInfoData?.offerRequestType === "F100018") ? null : (
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton
              disabled={loading}
              icon={<ArrowCounterclockwiseRegular fontSize={16} />}
              appearance="subtle"
            >
              {t("bcr.Renewal.label")}
            </MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem
                icon={<ArrowCounterclockwiseRegular fontSize={16} />}
                onClick={() => {
                  setListStoreValue("renewalFormDialog", {
                    isOpen: true,
                    formData: { start_date: null, end_date: null },
                  });
                  // navigate(routes.asirom.path);
                }}
              >
                {t("bcr.Renewal.label")}
              </MenuItem>
              <MenuItem
                icon={<ArrowCounterclockwiseRegular fontSize={16} />}
                onClick={() => {
                  setListStoreValue("renewalNonGrecoFormDialog", {
                    isOpen: true,
                    formData: { start_date: null, end_date: null },
                  });
                }}
              >
                {t("bcr.Renewal_Non_Greco.label")}
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
    </NavigationBar>
  );
};

export default ListPageNavigationSecondLine;
