import { Label } from "office-ui-fabric-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";
import { Controller } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useAppStoreState } from "store/AppStore";
import { useTheme } from "@fluentui/react";
import { Body1Strong, Select } from "@fluentui/react-components";
import { toInteger } from "lodash";
import { taxonomy } from "utils/types";

type Props = {
  form: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  taxonomyKey: string;
  value?: any;
  visible?: boolean;
  isLoading: boolean;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
};

export const TaxonomyField = ({
  name,
  label,
  required = false,
  disabled = false,
  taxonomyKey,
  form,
  visible,
  isLoading,
  onBlur = null,
  onChange = null,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = form;

  const { t } = useTranslation();

  const options = useMemo(() => {
    if (!taxonomy || !taxonomy[taxonomyKey]) return [];
    const options = taxonomy[taxonomyKey]?.map((tax) => {
      return {
        value: tax.value,
        label: t(tax.label),
      };
    });

    return options;
  }, [t, taxonomy, taxonomyKey]);

  if (!visible) return null;

  return (
    <FieldContainer
      isTooltipHidden={false}
      tooltipText={label}
      isReadOnly={disabled}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          let value = "";
          if (!field.value) value = "";
          if (!options || options.length === 0) value = field.value;
          for (let i = 0; i < options.length; i++) {
            if (options[i].value === field.value) value = field.value;
            if (options[i].value === String(field.value).toUpperCase())
              value = String(field.value).toUpperCase();
          }

          return (
            <>
              {disabled ? (
                <Body1Strong
                  id={`taxonomy_readonly_${name}`}
                  style={{
                    marginLeft: "15px",
                    color: isLoading ? "#888888" : null,
                  }}
                >
                  {options.find((el) => el.value === value)?.label}
                  <input
                    type="hidden"
                    name={name}
                    value={field.value || ""}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </Body1Strong>
              ) : (
                <Select
                  required={required}
                  disabled={disabled || isLoading}
                  id={name}
                  name={field.name}
                  onBlur={(e) => {
                    field.onBlur();
                    onBlur && onBlur(e);
                  }}
                  onChange={(e, data) => {
                    field.onChange(data.value ? data.value : null);
                    onChange && onChange(data.value ? data.value : null);
                  }}
                  value={value || ""}
                  // placeholder={
                  //   disabled ? "" : t("greco.form.selectPlaceholder")
                  // }
                  // noOptionsMessage={() => t("greco.noResults")}
                  // isDisabled={disabled}
                  // isClearable
                  // inputId={name + "-input"}
                  // defaultValue={defaultValues[name]}
                >
                  <option key={"none"} value={""}></option>
                  {options.map((tax) => (
                    <option key={tax.value} value={tax.value}>
                      {t(tax.label)}
                    </option>
                  ))}
                </Select>
              )}
              {errors && errors[name] ? (
                <ErrorMsg id={name + "_error"}>{errors[name].message}</ErrorMsg>
              ) : null}
            </>
          );
        }}
      />
    </FieldContainer>
  );
};

export default TaxonomyField;
