import { TFunction } from "i18next";
import { ExpandedColumn } from "../types/columns";
import {  VehicleAzure } from "../types/types";

export const transformVehiclesForExcel = ({
  vehicles,
  columns,
  t,
}: {
  vehicles: VehicleAzure[];
  columns: ExpandedColumn[];
  t: TFunction;
}) => {
  // const actualColumns = columns.filter(
  //   (c) => c.key !== "options" 
  // );
  
  return vehicles.map((v) => {
    return columns.filter((c) => c.key !== "options")
      .reduce((transformedVehicle, c) => {
        if (c.excel && c.excel.render) {
          transformedVehicle[c.key] = c.excel.render({
            t,
            row: v,
          });
        }
        //
        else {
          transformedVehicle[c.key] = v[c.key];
        }
        
        return transformedVehicle;
      }, {});
  });
};
