export type FormPageTab = {
  selectedTab: string;
  visibleTabs: string[];
  disabledTabs: string[];
};

export const initialMainTab: FormPageTab = {
  selectedTab: "offer",
  visibleTabs: ["offer", "invoice", "policy"],
  disabledTabs: [],
};
export const initialOfferTab: FormPageTab = {
  selectedTab: "tab1",
  visibleTabs: [
    "tab1",
    "tab2",
    "tab3",
    "tab4",
    "tab5",
    "tab6",
    "tab7",
    "tab8",
    "tab9",
  ],
  disabledTabs: [],
};
export const initialInvoiceTab: FormPageTab = {
  selectedTab: "tab1",
  visibleTabs: ["tab1", "tab2"],
  disabledTabs: [],
};
export const initialPolicyTab: FormPageTab = {
  selectedTab: "tab1",
  visibleTabs: ["tab1", "tab2"],
  disabledTabs: [],
};

//   selectedMainTab: string;
//   selectedOfferTab: string;
//   selectedInvoiceTab: string;
//   selectedPolicyTab: string;
//   disabledTab1General: boolean;
//   disabledTab2VehicleInfo: boolean;
//   disabledTab3VehicleUsage: boolean;
//   disabledTab4CascoClauses: boolean;
//   disabledTab5BonusMalus: boolean;
//   disabledTab6ClientInfo: boolean;
//   disabledTab7ContactInfo: boolean;
//   disabledTab8DriverInfo: boolean;
//   disabledTab9CascoOffer: boolean;
