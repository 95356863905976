import { ExcelExport } from "@progress/kendo-react-excel-export";
import { TableSpinner } from "components/TableSpinner";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "store/AppStore";
import { useListPageStoreState } from "store/ListPageStore";
import { reverse_romanian_label } from "utils/types";
import { ExportExcelVehicles } from "./ExportExcelVehicles";
import { InvalidateDialog } from "./InvalidateOfferDialog";
import ListPageNavigation from "./ListPageNavigation";
import ListPageTable from "./ListPageTable";
import useListPage from "./useListPage";
import { AsiromFormDialog } from "./AsiromFormDialog";
import { BcrFormDialog } from "./BcrFormDialog";
import { RenewalFormDialog } from "./RenewalFormDialog";
import { RenewalNonGrecoFormDialog } from "./RenewalNonGrecoFormDialog";

const ListPage = () => {
  useListPage();
  const refExcelExport = useRef<ExcelExport>();

  const { cols, vehicleItemsLoadStatus } = useListPageStoreState();

  const { t } = useTranslation();
  const [{ appState, appInfoData }, { setAppStoreValue }] = useAppStore();

  useEffect(() => {
    setAppStoreValue("appState", {
      ...appState,
      headerConfig: {
        ...appState.headerConfig,
        application_name: t(reverse_romanian_label("BCR Leasing")),
      },
    });
  }, []);

  return (
    <>
      <ListPageNavigation refExcelExport={refExcelExport?.current} />
      <ListPageTable />
      <ExportExcelVehicles
        ref={refExcelExport}
        columns={cols?.filter((el) => {
          if (
            appInfoData?.offerRequestType === "F100018" &&
            el.key === "isRenewed"
          )
            return false;
          return true;
        })}
      />
      {vehicleItemsLoadStatus === "loading" && <TableSpinner />}
      <InvalidateDialog />
      <AsiromFormDialog />
      <BcrFormDialog />
      <RenewalFormDialog />
      <RenewalNonGrecoFormDialog />
    </>
  );
};

export default ListPage;
