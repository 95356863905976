import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
  makeStyles,
} from "@fluentui/react-components";
import {
  ArrowDownloadRegular,
  DocumentBulletListRegular,
} from "@fluentui/react-icons";
import { VSpace } from "components/Spacer";
import DatePickerField from "components/controls9/DatePickerField";
import TextInputField from "components/controls9/TextInputField";
import { HorizontalSpacer, StatusCellSpinner } from "components/styled";
import { routes } from "config/routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { TabProps } from "types/types";
import { resolverFn } from "utils/setYupLocale";
import { reverse_romanian_label } from "utils/types";
import {
  downloadOfferRequestResponseZipFile,
  fieldToTab,
  prepareFormItemsForSubmit,
} from "utils/utils";
import * as yup from "yup";
import CascoPremiumTable from "../CascoPremiumTable";
import MtplPremiumTable from "../MtplPremiumTable";
import TabCard from "../TabCard";
import CardFooterWithInfoCalculation from "./CardFooterWithInfoCalculation";
import { MessageBarType } from "office-ui-fabric-react";
import { toast } from "components/FluentToast";

const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const OfferTab9 = (props: TabProps) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { getValues, setError, watch, setValue } = props.form;
  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const [
    { romanianTranslations, appInfoData },
    { setNotificationMessage, clearAllNotifications },
  ] = useAppStore();

  const navigate = useNavigate();

  const [
    {
      selectedProduct,
      progress,
      mainTab,
      offerTab,
      selectedMtplInsurers,
      selectedCascoInsurers,
    },
    { setFormStoreValues, calculateCasco, calculateCascoAndMtpl, getOffer },
  ] = formPageStore;

  const requiredMap = selectedProduct?.requiredMap;
  const disabledMap = selectedProduct?.disabledMap;
  const visibleMap = selectedProduct?.visibleMap;
  const disabledTab = progress.cascoOfferCreated || progress.mtplOfferCreated;

  const [mtplOfferErrorMessage, setMtplOfferErrorMessage] = React.useState(
    getValues("mtplOfferErrorMessage")
  );

  const [cascoOfferErrorMessage, setCascoOfferErrorMessage] = React.useState(
    getValues("cascoOfferErrorMessage")
  );

  const showSpinnerCascoState = React.useState(false);
  const showSpinnerMtplState = React.useState(false);

  watch((data, { name, type }) => {
    // if (name === "mtplOfferErrorMessage") {
    setMtplOfferErrorMessage(data.mtplOfferErrorMessage);
    setCascoOfferErrorMessage(data.cascoOfferErrorMessage);
    // }
  });
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  const cardFooter = (
    <CardFooterWithInfoCalculation isLoading={isLoading} form={props.form}>
      {appInfoData &&
      getValues("highestRole") &&
      getValues("highestRole") !== "100018" &&
      (progress.cascoOfferCreated || progress.mtplOfferCreated) &&
      getValues("pathToOfferDocx") ? (
        <>
          <Button
            icon={
              isLoading ? (
                <Spinner size="tiny" />
              ) : (
                <DocumentBulletListRegular fontSize={16} />
              )
            }
            appearance="secondary"
            name="downloadOffer"
            onClick={() => {
              window.open(getValues("pathToOfferDocx"), "_blank");
            }}
          >
            {t(reverse_romanian_label("Oferta (.docx)"))}
          </Button>
          <HorizontalSpacer width={10} />
        </>
      ) : null}

      {(progress.cascoOfferCreated || progress.mtplOfferCreated) &&
      getValues("pathToOfferPdf") ? (
        <>
          <Button
            icon={
              isLoading ? (
                <Spinner size="tiny" />
              ) : (
                <DocumentBulletListRegular fontSize={16} />
              )
            }
            appearance="primary"
            name="downloadOffer"
            onClick={() => {
              window.open(getValues("pathToOfferPdf"), "_blank");
            }}
          >
            {t(reverse_romanian_label("Oferta"))}
          </Button>
        </>
      ) : null}
      {(progress.cascoOfferCreated || progress.mtplOfferCreated) &&
      getValues("pathToGapOfferPdf") ? (
        <>
          <HorizontalSpacer width={10} />

          <Button
            icon={
              isLoading ? (
                <Spinner size="tiny" />
              ) : (
                <DocumentBulletListRegular fontSize={16} />
              )
            }
            appearance="primary"
            name="downloadGapOffer"
            onClick={() => {
              window.open(getValues("pathToGapOfferPdf"), "_blank");
            }}
          >
            {t(reverse_romanian_label("Oferta Gap"))}
          </Button>
        </>
      ) : null}
      {!progress.cascoOfferCreated && !progress.mtplOfferCreated ? (
        <>
          <Button
            appearance="primary"
            disabled={isLoading || progress.cascoOfferCreated}
            icon={
              isLoading ? (
                <Spinner size="tiny" />
              ) : (
                <DocumentBulletListRegular fontSize={16} />
              )
            }
            onClick={() => {
              props.form.clearErrors();
              clearAllNotifications();
              resolverFn(
                selectedProduct?.validationMap["offerTab9"](yup, t),
                getValues()
              ).then((res) => {
                if (Object.keys(res.errors).length > 0) {
                  toast.error(t("bcr.validationErrors.label"));
                  Object.keys(res.errors).forEach((key) => {
                    setError(key, res.errors[key]);
                    setNotificationMessage({
                      errors: [],
                      key: key,
                      messageBarType: MessageBarType.error,
                      fieldName: t("bcr." + key + ".label"),
                      handleNotificationClick: (e) => {
                        setFormStoreValues({
                          mainTab: { ...mainTab, selectedTab: "offer" },
                          offerTab: {
                            ...offerTab,
                            selectedTab: fieldToTab(key),
                          },
                        });
                      },
                      notify: {
                        label: "label",
                        type: "error",
                        notifyCode: "notifyCode",
                        notifyText: (
                          <>
                            <b>{t("bcr." + key + ".label")}</b>
                            <br />
                            {res.errors[key].message}
                          </>
                        ),
                      },
                    });
                  });
                } else {
                  // if passes, data is valid
                  // clearResultFieldsCascoMtpl({
                  //   data: getValues(),
                  //   form: props.form,
                  // });
                  getOffer(
                    {
                      appInfo: {
                        dataObj: {
                          ...prepareFormItemsForSubmit(
                            {
                              ...getValues(),
                              selectedMtplInsurers: selectedMtplInsurers,
                              selectedCascoInsurers: selectedCascoInsurers,
                            },
                            romanianTranslations
                          ),
                        },
                        resolvedProperties: {
                          title: "BCR Leasing",
                        },
                      },
                    },
                    (retVal) => {
                      // setValue(
                      //   "inp_vehicle_vin2",
                      //   props.form.getValues().inp_vehicle_vin
                      // );

                      // const preparedRetVal = prepareFormReturnValue(retVal, t);
                      // for (const key in preparedRetVal) {
                      //   setValue(key, preparedRetVal[key]);
                      // }
                      if (retVal.pathToOfferPdf) {
                        window.open(retVal.pathToOfferPdf, "_blank");
                      }
                      if (retVal.pathToGapOfferPdf) {
                        window.open(retVal.pathToGapOfferPdf, "_blank");
                      }
                      navigate(routes.formPage.getPath(retVal.offerId));
                    },
                    (err) => {
                      setNotificationMessage({
                        errors: [],
                        key: "key",
                        messageBarType: MessageBarType.error,
                        fieldName: "fieldName",
                        handleNotificationClick: (e) => {},
                        notify: {
                          label: "label",
                          type: "error",
                          notifyCode: "notifyCode",
                          notifyText: (
                            <>
                              <b>{"GetOffer"}</b>
                              <br />
                              {err.response.data.Message}
                            </>
                          ),
                        },
                      });
                    }
                  );
                }
              });
            }}
            // "action success script": "formioForm.checkConditions();"
          >
            {/* {t(reverse_romanian_label("Oferta"))} */}
            {t("bcr.offerTab.createOfferButton.label")}
          </Button>
        </>
      ) : null}
    </CardFooterWithInfoCalculation>
  );

  return (
    <>
      <TabCard footer={cardFooter}>
        {selectedProduct?.hasCasco ? (
          <>
            <h3>{t("bcr.offerTabCascoOffer.label")}</h3>
            <VSpace height={10} />
            <CascoPremiumTable form={props.form} />
            {cascoOfferErrorMessage ? (
              <Dialog>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary">
                    {t("bcr.Arata_erori.label")}
                  </Button>
                </DialogTrigger>
                <DialogSurface style={{ maxWidth: "876px" }}>
                  <DialogBody>
                    <DialogTitle>{t("bcr.cascoErrors.label")}</DialogTitle>
                    <DialogContent>
                      <code
                        dangerouslySetInnerHTML={{
                          __html: cascoOfferErrorMessage,
                        }}
                      ></code>
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">
                          {t("bcr.closeButton.label")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            ) : null}
            {getValues("offerId") &&
            (getValues("highestRole") === "admin" ||
              getValues("highestRole") === "manager") ? (
              <>
                <Button
                  icon={
                    showSpinnerCascoState[0] ? (
                      <StatusCellSpinner />
                    ) : (
                      <ArrowDownloadRegular />
                    )
                  }
                  disabled={showSpinnerCascoState[0]}
                  appearance="secondary"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => {
                    downloadOfferRequestResponseZipFile(
                      getValues("offerId"),
                      "casco",
                      showSpinnerCascoState
                    );
                  }}
                ></Button>
              </>
            ) : null}
          </>
        ) : null}

        {selectedProduct?.hasMtpl && selectedProduct?.hasCasco ? (
          <>
            <VSpace height={20} />
            <hr />
            <VSpace height={20} />
          </>
        ) : null}

        {selectedProduct?.hasMtpl ? (
          <>
            <h3>{t("bcr.offerTabMtplOffer.label")}</h3>
            <VSpace height={10} />
            <MtplPremiumTable form={props.form} /> <VSpace height={20} />
            {mtplOfferErrorMessage ? (
              <Dialog>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary">
                    {t("bcr.Arata_erori.label")}
                  </Button>
                </DialogTrigger>
                <DialogSurface style={{ maxWidth: "876px" }}>
                  <DialogBody>
                    <DialogTitle>{t("bcr.mtplErrors.label")}</DialogTitle>
                    <DialogContent>
                      <code
                        dangerouslySetInnerHTML={{
                          __html: mtplOfferErrorMessage,
                        }}
                      ></code>
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">
                          {t("bcr.closeButton.label")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            ) : null}
            {getValues("offerId") &&
            (getValues("highestRole") === "admin" ||
              getValues("highestRole") === "manager") ? (
              <>
                <Button
                  icon={
                    showSpinnerMtplState[0] ? (
                      <StatusCellSpinner />
                    ) : (
                      <ArrowDownloadRegular />
                    )
                  }
                  disabled={showSpinnerMtplState[0]}
                  appearance="secondary"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => {
                    downloadOfferRequestResponseZipFile(
                      getValues("offerId"),
                      "mtpl",
                      showSpinnerMtplState
                    );
                  }}
                ></Button>
              </>
            ) : null}
          </>
        ) : null}
        {getValues("offerId") !== null ? (
          <>
            <VSpace height={20} />

            <hr />
            <VSpace height={20} />

            <div className={styles.grid}>
              <div style={labelStyle(1, 1)}>
                {t(reverse_romanian_label("Oferta nr."))}
                {requiredMap.offerId ? "*" : ""}
              </div>
              <div style={fieldStyle(1, 2)}>
                <TextInputField
                  isLoading={isLoading}
                  name="offerId"
                  label={reverse_romanian_label("Oferta nr.")}
                  form={props.form}
                  required={false}
                  disabled={disabledMap?.offerId}
                  visible={visibleMap?.offerId}
                />
              </div>
              <div style={labelStyle(2, 1)}>
                {t(reverse_romanian_label("Oferta ceruta la:"))}
                {requiredMap.offerRequestedAt ? "*" : ""}
              </div>
              <div style={fieldStyle(2, 2)}>
                <DatePickerField
                  form={props.form}
                  isLoading={isLoading}
                  name="offerRequestedAt"
                  label={reverse_romanian_label("Oferta ceruta la:")}
                  disabled={disabledMap?.offerRequestedAt}
                  data-format="dd.MM.yyyy HH:mm:ss"
                  data-dateFormat="DD.MM.YYYY HH:mm"
                  required={false}
                  visible={visibleMap?.offerRequestedAt}
                  includeTime={true}
                />
              </div>
              <div style={labelStyle(3, 1)}>
                {t(reverse_romanian_label("Oferta ceruta de:"))}
                {requiredMap.offerRequestedByName ? "*" : ""}
              </div>
              <div style={fieldStyle(3, 2)}>
                <TextInputField
                  isLoading={isLoading}
                  name="offerRequestedByName"
                  label={reverse_romanian_label("Oferta ceruta de:")}
                  form={props.form}
                  required={false}
                  disabled={disabledMap?.offerRequestedByName}
                  visible={visibleMap?.offerRequestedByName}
                />
              </div>
            </div>
          </>
        ) : null}
      </TabCard>
    </>
  );
};

export default OfferTab9;
