import { initial } from "lodash";
import { DataObj } from "./types";
import {
  initialDisableMap,
  initialOffer,
  initialRequiredMap,
  initialVisibilityMap,
} from "store/FormPageStoreInitialValues";
import { setYupLocale } from "utils/setYupLocale";
import { productValidation } from "./productsValidation";

export enum InsuranceProductType {
  "generalBusiness" = "generalBusiness",
  "mtplRenewal" = "mtplRenewal",
  "casco" = "casco",
  "cascoMtpl" = "cascoMtpl",
}
export type InsuranceProduct = {
  type: InsuranceProductType;
  hasCasco: boolean;
  hasMtpl: boolean;
  validationMap: { [key: string]: any };
  disabledMap: { [key: string]: boolean };
  visibleMap: { [key: string]: boolean };
  requiredMap: { [key: string]: boolean };
  initialOffer: DataObj;
};

export type PolicyProgress = {
  cascoOfferCreated: boolean;
  cascoInvoiceCreated: boolean;
  cascoPolicyCreated: boolean;

  mtplOfferCreated: boolean;
  mtplInvoiceCreated: boolean;
  mtplPolicyCreated: boolean;

  mtplPolicyWithoutInvoice: boolean;
  cascoPolicyWithoutInvoice: boolean;
  policyWithoutInvoice: boolean;
};
const generalBusiness: InsuranceProduct = {
  type: InsuranceProductType.generalBusiness,
  hasCasco: false,
  hasMtpl: true,
  validationMap: productValidation["generalBusiness"],
  initialOffer: {
    ...initialOffer,
    inp_contract_type: "Contract de credit",
    isRenewed: false,
    include_mtpl_offer: true,
    inp_policy_payment: "trimestriala",
  },
  disabledMap: {
    ...initialDisableMap,
    inp_contract_type: true,
  },
  visibleMap: {
    ...initialVisibilityMap,
    inp_policy_value: false,
    inp_external_contractdate: false,
    inp_policy_payment: false,
    inp_leasing_monthsinterval: false,
    inp_policy_currency: false,
  },
  requiredMap: {
    ...initialRequiredMap,
    inp_lessee_street: true,
    inp_lessee_housenr: true,
    inp_lessee_city: true,
    inp_lessee_county: true,
    inp_lessee_mobile: true,
    inp_lessee_email: true,
    inp_start_date: true,
    inp_licence_date: true,
    inp_vehicle_subcategory: true,

    // mtpl_commission_broker_p: true,
    // inp_start_date: true,
  },
};

const casco: InsuranceProduct = {
  type: InsuranceProductType.casco,
  hasCasco: true,
  hasMtpl: false,
  validationMap: productValidation["casco"],

  initialOffer: {
    ...initialOffer,
    isRenewed: false,
    include_mtpl_offer: false,
    inp_policy_payment: "trimestriala",
  },
  disabledMap: {
    ...initialDisableMap,
    avarie6: true,
    avarie9: true,
    avarie10: true,
    avarie4: true,
  },
  visibleMap: {
    ...initialVisibilityMap,
    inp_start_date: false,
    inp_sicgroup: false,
    inp_vehicle_id: true,
    inp_vehicle_registration: false,
    inp_vehicle_licenseplate: false,
    inp_licence_date: false,
    deliveryType: false,
    inp_vehicle_vin2: false,
  },
  requiredMap: {
    ...initialRequiredMap,
    inp_lessee_type: true,
    inp_contract_type: true,
    inp_policy_currency: true,
    inp_policy_value: true,
    inp_leasing_monthsinterval: true,
    inp_external_contractdate: true,
    inp_policy_payment: true,
    inp_start_date: true,

    EURExchangeRate: false,
    EURExchangeRateDate: false,
    insurerCasco: true,
    leasingctrno: true,
  },
};

const cascoMtpl: InsuranceProduct = {
  type: InsuranceProductType.cascoMtpl,
  hasCasco: true,
  hasMtpl: true,
  validationMap: productValidation["cascoMtpl"],

  initialOffer: {
    ...initialOffer,
    isRenewed: false,
    include_mtpl_offer: true,
    inp_policy_payment: "trimestriala",
  },

  disabledMap: {
    ...initialDisableMap,
    avarie6: true,
    avarie9: true,
    avarie10: true,
    avarie4: true,
  },
  visibleMap: {
    ...initialVisibilityMap,
    deliveryType: false,
    inp_vehicle_vin2: false,
  },
  requiredMap: {
    ...initialRequiredMap,
    inp_lessee_type: true,
    inp_contract_type: true,
    inp_policy_currency: true,
    inp_policy_value: true,
    inp_leasing_monthsinterval: true,
    inp_external_contractdate: true,
    inp_policy_payment: true,
    inp_start_date: true,
    EURExchangeRate: false,
    EURExchangeRateDate: false,
    insurerCasco: true,
    leasingctrno: true,
  },
};

const mtplRenewal: InsuranceProduct = {
  type: InsuranceProductType.mtplRenewal,
  hasCasco: false,
  hasMtpl: true,

  validationMap: productValidation["mtplRenewal"],

  initialOffer: {
    ...initialOffer,
    isRenewed: true,
    include_mtpl_offer: true,
    inp_policy_payment: "trimestriala",
    comparisonType: "euro",
  },

  disabledMap: {
    ...initialDisableMap,
    inp_policy_payment: true,
  },
  visibleMap: {
    ...initialVisibilityMap,
    inp_policy_currency: false,
    inp_policy_value: false,
    inp_leasing_monthsinterval: false,
    inp_external_contractdate: false,
  },
  requiredMap: {
    ...initialRequiredMap,
    inp_start_date: true,
  },
};

export const insuranceProducts = {
  generalBusiness: generalBusiness,
  mtplRenewal: mtplRenewal,
  casco: casco,
  cascoMtpl: cascoMtpl,
};

export const determineProduct = (data): InsuranceProduct => {
  if (data.offerRequestType === "F100056") {
    return insuranceProducts.generalBusiness;
  } else if (data.isRenewed === true) {
    return insuranceProducts.mtplRenewal;
  } else if (
    data.offerRequestType !== "F100056" &&
    data.include_mtpl_offer === false
  ) {
    return insuranceProducts.casco;
  } else if (
    data.offerRequestType !== "F100056" &&
    data.include_mtpl_offer === true
  ) {
    return insuranceProducts.cascoMtpl;
  }
  return insuranceProducts.cascoMtpl;
};

export const determineContractTypeOptions = (
  productType: InsuranceProductType,
  offerRequestType: string,
  t
) => {
  let retVal = [];
  if (productType === "generalBusiness") {
    retVal = [
      {
        label: t("bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT"),
        value: "bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT",
      },
    ];
  } else {
    if (offerRequestType === "F100018") {
      retVal = [
        {
          label: t("bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_AGREEMENT"),
          value: "bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_AGREEMENT",
        },

        {
          label: t("bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT"),
          value: "bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT",
        },
      ];
    } else {
      retVal = [
        {
          label: t("bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_AGREEMENT"),
          value: "bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_AGREEMENT",
        },

        {
          label: t("bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT"),
          value: "bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT",
        },
      ];
    }
  }
  return retVal;
};

export const hasCascoOfferValues = (data) => {
  const hasCascoOfferValuesVal =
    data["omniasig_premium"] !== null ||
    data["omniasig_promotional_premium"] !== null ||
    data["omniasig_partial_premium"] !== null ||
    data["omniasig_total_premium"] !== null ||
    data["omniasig_partial_monthly_premium"] !== null ||
    data["omniasig_total_monthly_premium"] !== null ||
    data["asirom_premium"] !== null ||
    data["asirom_promotional_premium"] !== null ||
    data["asirom_partial_premium"] !== null ||
    data["asirom_total_premium"] !== null ||
    data["asirom_partial_monthly_premium"] !== null ||
    data["asirom_total_monthly_premium"] !== null ||
    data["groupama_premium"] !== null ||
    data["groupama_promotional_premium"] !== null ||
    data["allianz_premium"] !== null ||
    data["allianz_promotional_premium"] !== null ||
    data["generali_premium"] !== null ||
    data["generali_promotional_premium"] !== null ||
    data["uniqa_premium"] !== null ||
    data["uniqa_promotional_premium"] !== null ||
    data["uniqa_partial_premium"] !== null ||
    data["uniqa_partial_monthly_premium"] !== null ||
    data["uniqa_total_premium"] !== null ||
    data["uniqa_total_monthly_premium"] !== null;
  return hasCascoOfferValuesVal;
};

export const hasMtplOfferValues = (data) => {
  const hasMtplOfferValuesVal =
    data["allianz_premium_1"] !== null ||
    data["allianz_premium_1d"] !== null ||
    data["allianz_premium_6"] !== null ||
    data["allianz_premium_6d"] !== null ||
    data["allianz_premium_12"] !== null ||
    data["allianz_premium_12d"] !== null ||
    data["asirom_premium_1"] !== null ||
    data["asirom_premium_1d"] !== null ||
    data["asirom_premium_6"] !== null ||
    data["asirom_premium_6d"] !== null ||
    data["asirom_premium_12"] !== null ||
    data["asirom_premium_12d"] !== null ||
    data["generali_premium_1"] !== null ||
    data["generali_premium_1d"] !== null ||
    data["generali_premium_6"] !== null ||
    data["generali_premium_6d"] !== null ||
    data["generali_premium_12"] !== null ||
    data["generali_premium_12d"] !== null ||
    data["grawe_premium_1"] !== null ||
    data["grawe_premium_1d"] !== null ||
    data["grawe_premium_6"] !== null ||
    data["grawe_premium_6d"] !== null ||
    data["grawe_premium_12"] !== null ||
    data["grawe_premium_12d"] !== null ||
    data["groupama_premium_1"] !== null ||
    data["groupama_premium_1d"] !== null ||
    data["groupama_premium_6"] !== null ||
    data["groupama_premium_6d"] !== null ||
    data["groupama_premium_12"] !== null ||
    data["groupama_premium_12d"] !== null ||
    data["omniasig_premium_1"] !== null ||
    data["omniasig_premium_1d"] !== null ||
    data["omniasig_premium_6"] !== null ||
    data["omniasig_premium_6d"] !== null ||
    data["omniasig_premium_12"] !== null ||
    data["omniasig_premium_12d"] !== null ||
    data["uniqa_premium_1"] !== null ||
    data["uniqa_premium_1d"] !== null ||
    data["uniqa_premium_6"] !== null ||
    data["uniqa_premium_6d"] !== null ||
    data["uniqa_premium_12"] !== null ||
    data["uniqa_premium_12d"] !== null ||
    data["cityinsurance_premium_1"] !== null ||
    data["cityinsurance_premium_1d"] !== null ||
    data["cityinsurance_premium_6"] !== null ||
    data["cityinsurance_premium_6d"] !== null ||
    data["cityinsurance_premium_12"] !== null ||
    data["cityinsurance_premium_12d"] !== null;
  return hasMtplOfferValuesVal;
};
