import { Setting } from "@greco/components/dist/types/types";
import {
  createStore,
  createHook,
  createActionsHook,
  createStateHook,
} from "react-sweet-state";
import { ExpandedColumn } from "types/columns";
import { LoadStatus } from "types/types";
import { columns } from "../pages/ListPage/columns";
import {
  createSetting,
  deleteSetting,
  invalidateOffer,
  loadOffer,
  loadSettings,
  loadVehicles,
  loadVehiclesForReport,
  setStoreValue,
  setStoreValues,
  submitAsiromForm,
  submitBcrForm,
  submitRenewalForm,
  submitRenewalNonGrecoForm,
  updateSetting,
} from "./ListPageStoreActions";

export type StoreState = {
  settings: Setting[];
  offerRequestType: string;
  settingsLoadStatus: LoadStatus;
  invalidateOfferStatus: LoadStatus;
  searchTerm: string;
  showArchived: boolean;
  vehicleItems: any[];
  vehicleItemsLoadStatus: LoadStatus;
  submitBcrFormStatus: LoadStatus;
  submitAsiromFormStatus: LoadStatus;
  submitRenewalFormStatus: LoadStatus;
  submitRenewalNonGrecoFormStatus: LoadStatus;
  offerLoadStatus: LoadStatus;
  sort?: {
    key: string;
    dir: string;
  };
  skip: number;
  top?: number;
  cols: ExpandedColumn[];
  selectedViewColumns: ExpandedColumn[];
  selectedViewId: number;
  selectedView: any;
  tempColumns: ExpandedColumn[];
  count: number;
  invalidateOfferDialog: {
    isOpen: boolean;
    offer: any;
  };
  asiromFormDialog: {
    isOpen: boolean;
    formData: any;
  };
  bcrFormDialog: {
    isOpen: boolean;
    formData: any;
  };
  renewalFormDialog: {
    isOpen: boolean;
    formData: any;
  };
  renewalNonGrecoFormDialog: {
    isOpen: boolean;
    formData: any;
  };
  areAnyColumnsFiltered: boolean;
  columnOptionsSettings: Setting[];
  selectedOfferRequestType: any;
  searchState: string;
  debouncedState: string;
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  settings: [],
  offerRequestType: "",
  settingsLoadStatus: "none",
  invalidateOfferStatus: "none",
  searchTerm: "",
  vehicleItems: [],
  vehicleItemsLoadStatus: "none",
  submitBcrFormStatus: "none",
  submitAsiromFormStatus: "none",
  submitRenewalFormStatus: "none",
  submitRenewalNonGrecoFormStatus: "none",
  offerLoadStatus: "none",
  sort: {
    key: "",
    dir: "",
  },
  showArchived: false,
  skip: 0,
  top: 20,
  cols: columns,
  selectedViewColumns: null,
  selectedViewId: null,
  selectedView: null,
  tempColumns: columns,
  count: 0,
  invalidateOfferDialog: {
    isOpen: false,
    offer: null,
  },
  asiromFormDialog: {
    isOpen: false,
    formData: null,
  },
  bcrFormDialog: {
    isOpen: false,
    formData: null,
  },
  renewalFormDialog: {
    isOpen: false,
    formData: null,
  },
  renewalNonGrecoFormDialog: {
    isOpen: false,
    formData: null,
  },
  areAnyColumnsFiltered: false,
  columnOptionsSettings: [],
  searchState: "",
  debouncedState: "",
  selectedOfferRequestType: null,
};

// All the actions that mutate the store
const actions = {
  setListStoreValue: setStoreValue,
  setListStoreValues: setStoreValues,
  loadOffer: loadOffer,
  loadSettings: loadSettings,
  loadVehicles: loadVehicles,
  loadVehiclesForReport: loadVehiclesForReport,
  invalidateOffer: invalidateOffer,
  createSetting: createSetting,
  deleteSetting: deleteSetting,
  updateSetting: updateSetting,
  submitBcrForm: submitBcrForm,
  submitAsiromForm: submitAsiromForm,
  submitRenewalForm: submitRenewalForm,
  submitRenewalNonGrecoForm: submitRenewalNonGrecoForm,
};

const ListPageStore = createStore({
  initialState,
  actions,
  name: "ListPageStore",
});
export const useListPageStore = createHook(ListPageStore);
export const useListPageStoreState = createStateHook(ListPageStore);
export const useListPageStoreActions = createActionsHook(ListPageStore);
